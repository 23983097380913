import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import DeclineConfirm from './components/DeclineConfirm';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import useProcessAcceptOrDecline from './useProcessAcceptOrDecline';
import Done from './components/Done';

export default ({ item }) => {
    const { t } = useTranslation('notifications');

    const { doAccept, doDecline, state: { processing, done, error } } = useProcessAcceptOrDecline();
    const [showDeclineConfirm, setShowDeclineConfirm] = useState(false);

    const { reservation: { id } } = item;

    if (processing) {
        return <MiniLoader />
    }

    if (showDeclineConfirm) {
        return <>
            <MiniLoader />
            <DeclineConfirm {...{t, doDecline: () => {setShowDeclineConfirm(false); doDecline(id);}, onClose: () => setShowDeclineConfirm(false)}} />
        </>
    }

    if (done) {
        return <Done {...{t}} />;
    }

    return (<div>
        <SecondaryButton className='zt-notifications__button' onClick={() => setShowDeclineConfirm(true)}>{t('actions.accept.buttons.decline', 'Decline')}</SecondaryButton>
        <PrimaryButton className='zt-notifications__button' onClick={() => doAccept(id)}>{t('actions.accept.buttons.accept', 'Accept')}</PrimaryButton>
    </div>);
}