import * as constants from './constants';

const defaultDetailsState = {
    eventId: null, //Id for which this info is loaded
    loading: false,
    loaded: false,
    error: null
}

const defaultState = {
    grouped: true,
    qty: 1,
    section: null,
    tickets: [], //Array of selected places {row: Y, seat: X}
    details: defaultDetailsState
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    const eventId = +payload;

    switch (type) {

        case constants.EVENTBUY_FETCH_DETAILS_START: {
            return {
                ...state,
                details: {
                    ...defaultDetailsState,
                    eventId,
                    loading: true,
                }
            }           
        }

        case constants.EVENTBUY_FETCH_DETAILS_SUCCESS: {

            return {
                ...state,
                details: {
                    ...defaultDetailsState,
                    eventId: state.details.eventId,
                    loaded: true
                }
            }               
        }

        case constants.EVENTBUY_FETCH_DETAILS_FAILURE: {
            return {
                ...state,
                details: {
                    ...defaultDetailsState,
                    eventId: state.details.eventId,
                    error
                }
            }
        }

        case constants.EVENTBUY_RESET: {
            return defaultState;
        }
        
        case constants.EVENTBUY_TICKETS_RESET: {
            return {
                ...state,
                tickets: []
            }
        }

        case constants.EVENTBUY_RESET_SECTION: {
            return {
                ...state,
                section: null,
                tickets: []
            }
        }

        case constants.EVENTBUY_SELECT_SECTION: {
            return {
                ...state,
                section: payload
            }
        }

        case constants.EVENTBUY_SET_TICKETS_QTY: {
            const qty = +payload || 1;
            const grouped = qty === 1 ? false : state.grouped;
            if (qty < state.tickets.length) {
                const tickets = state.tickets;
                tickets.splice(-1, tickets.length-qty);
                return {
                    ...state,
                    grouped,
                    qty,
                    tickets: [...tickets]
                }
            }
            return {
                ...state,
                grouped,
                qty
            }
        }

        case constants.EVENTBUY_SET_TICKETS_GROUP_MODE:  {
            return {
                ...state,
                tickets: (!!payload && !state.grouped) ? [] : state.tickets,
                grouped: !!payload
            }
        }

        case constants.EVENTBUY_ADD_TICKET: {
            const ticket = payload;
            if (state.tickets.find(s => s.row === ticket.row && s.seat === ticket.seat)) {
                return state;
            }
            return {
                ...state,
                tickets: [...state.tickets, ticket]
            }
        }

        case constants.EVENTBUY_REMOVE_TICKET: {
            const index = payload;
            const tickets = state.tickets;
            tickets.splice(index, 1); //Remove 1 ticket at index
            return {
                ...state,
                grouped: false,  //If we have removed ticked - they can't be grouped anymore
                tickets: [...tickets] //Immutable

            }
        }

        default:
            return state;
    }
}