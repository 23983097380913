import { useSelector } from "react-redux";

export default function useCartItems() {
    const items = useSelector(state => state.shop.cart.items);
    const unpacked = [];
    Object.keys(items)
        .forEach(event_id => Object.keys(items[event_id]) 
            .forEach(item_id => Object.keys(items[event_id][item_id])
                .forEach(option => unpacked.push({...items[event_id][item_id][option], option: option}))
            )
        );
    return unpacked;
}