import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import useDeclineReservation from './useDeclineReservation';
import DeclineConfirm from '../AcceptOrDecline/components/DeclineConfirm';
import usePayForReservation from './usePayForReservation';
import useTimeLeft from './useTimeLeft';
import Timer from '../Timer';
import './styles.scss';

export default ({ item }) => {
    const { t } = useTranslation('notifications');

    const { doDecline, state: { processing, done, error } } = useDeclineReservation();
    const [showDeclineConfirm, setShowDeclineConfirm] = useState(false);

    const { reservation: { id, items } } = item;

    const ticket = items?.find(t => t.type === 'ticket');

    const { event_id } = ticket;

    const seconds = useTimeLeft(item);

    const [dismissed, setDismissed] = useState(false);

    const { goToPay } = usePayForReservation();

    if (processing) {
        return <MiniLoader />
    }

    if (showDeclineConfirm) {
        return <>
            <MiniLoader />
            <DeclineConfirm {...{t, doDecline: () => {setShowDeclineConfirm(false); doDecline(id);}, onClose: () => setShowDeclineConfirm(false)}} />
        </>
    }

    if (done || dismissed || (seconds !== null && seconds <= 0)) {
        return null;
    }

    return (<div className={`zt-payordecline ${error ? 'zt-payordecline--error' : null}`}>
        <SecondaryButton className='zt-notifications__button' onClick={() => setShowDeclineConfirm(true)}>{t('actions.pay.buttons.decline', 'Decline')}</SecondaryButton>
        <PrimaryButton className='zt-notifications__button' onClick={() => goToPay(event_id, id)}>{t('actions.pay.buttons.accept', 'Accept')}</PrimaryButton>
        {seconds !== null && <Timer {...{item, seconds, t, onDismiss: () => setDismissed(true)}} />}
    </div>);
}