import React from "react";
import { useTranslation } from "react-i18next";

export default props => {
    const {t} = useTranslation('events');

    return (
        <div>
            <h4>
                {t('hello', 'Hello!')}
                <br/>
                <span className='zt-p1'>{t('message','What are you looking for?')}</span>
            </h4>
        </div>        
    )
}
