import React from 'react';
import Divider from 'components/Divider';
import format from 'config/date/format';
import moment from 'moment';
import Actions from './components/Actions';

export default ({ t, item, last, ...props }) => {

    const date = moment(item.created_at, format).format(t('message.dateFormat', 'ddd. MMMM D, h:mm A'))

    const { title:description, description:title } = item; 

    const { action_taken } = item;

    return (
        <>
        <div className={'zt-notifications__card'}>
            <div className={'zt-p3 zt-notifications__card__date'}>{date}</div>
            <h4 className={'zt-notifications__card__title'}>{title}</h4>
            <div className={'zt-p1 zt-notifications__card__description'}>{description}</div>
            {!action_taken && <Actions {...{item}} />}
        </div>
        {!last && <Divider />}
        </>
    );

}