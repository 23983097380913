import * as actions from './constants';

export const defaultState = {
    loading: false,
    reservation: null,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;

    switch (type) {

        case actions.RESET_CREATE_RESERVATION: {
            return {
                ...defaultState
            }
        }

        case actions.FETCH_RESERVATION_START:
        case actions.CREATE_RESERVATION_START:
            return {
                ...defaultState,
                loading: true
            }

        case actions.FETCH_RESERVATION_SUCCESS:
        case actions.CREATE_RESERVATION_SUCCESS:
            const reservation = payload;
            return {
                ...defaultState,
                reservation
            }
            
        case actions.FETCH_RESERVATION_FAILED:
        case actions.CREATE_RESERVATION_FAILED:
            return {
                ...defaultState,
                error: error
            }

        default:
            return state;
    }
}
