import React from 'react';
import AppleStore from 'components/buttons/stores/AppleStore';
import GooglePlay from 'components/buttons/stores/GooglePlay';
import AvailableEvents from 'containers/AvailableEvents';
import FilteredEvents from 'containers/FilteredEvents';

export default () => <>
    {/* <div style={{display:'flex', flexDirection:'row', alignContent: 'center', justifyContent: 'center'}}>
        <AppleStore style={{margin: 8}} />
        <GooglePlay style={{margin: 8}} />
    </div> */}
    <FilteredEvents />
    <AvailableEvents />
</>;
