import React, { useEffect, useState } from 'react';
import { ClearIcon } from 'components/Icons/Glyphs'
import DatePicker from 'react-date-picker/dist/entry.nostyle';
// import DatePicker from 'react-date-picker';
// import 'components/Calendar/styles.scss';
import moment from 'moment'
import './style.scss';

export default React.forwardRef(({ label, name, required=false, error, description, onChange, ...props }, ref) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        onChange && onChange(value)
    }, [value]);

    return (
    <div className={`zt-inputs--date ${error ? 'zt-inputs--date--error' : ''}`}>
        <input className='zt-inputs--date__shadowinput' name={name} value={value && (moment(value).format('YYYY-MM-DD') || '')} ref={ref} />
        { !!label &&
            <div className='zt-inputs--date__label'><span className='zt-p3'>{label}</span></div>
        }    
        <DatePicker 
            required={required}
            disableCalendar={true} 
            monthPlaceholder='mm'
            dayPlaceholder='dd'
            yearPlaceholder='yyyy'
            format='d/MM/yyyy'
            clearIcon={<ClearIcon />}
            maxDate={new Date()}
            showLeadingZeros={false}
            onChange={setValue}
            value={value}            
        />
        { (!!error || !!description) &&
        <div className='zt-inputs--date__message'>
            <div className={`zt-inputs--date__message__first ${error ? 'zt-inputs--date__message__first--error' : ''}`}><span className='zt-p3'>{error || description}</span></div>
            <div className='zt-inputs--date__message__second'><span className='zt-p3'></span></div>
        </div>
        }          
    </div>
    );
});