import { useReducer } from 'react';
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';
import * as actions from './actions';

export default function useSetNewPassword() {

    const [state, dispatch] = useReducer(logger(reducer), defaultState);
    const resetPassword = actions.resetPassword(dispatch);

    return {
        state,
        resetPassword
    }
}
