import api from 'api';

const url = '/api/reservations';
const urlFetch = id => `/api/reservations/${id}`;

export const createReservation = order => api.post({path: url, params: order});
export const fetchReservation = id => api.get({path: urlFetch(id)});

export default {
    createReservation,
    fetchReservation
}
