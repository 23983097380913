import selected from './../pages/Details/store/reducer';
import cart from './../pages/Cart/store/reducer';
import sell from './../../MyTickets/pages/SellItem/store'
import events from './reducer';

const { combineReducers } = require("redux");

export default combineReducers({
    selected,
    events,
    cart,
    sell
})