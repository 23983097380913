import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as validateActions from "./store/validate/actions";
import * as actions from './store/selected/actions';

const params = item => [item.merchandise.id, item.option?.id, item.id, item.available_quantity - +(item.delivered || 0)];

const STEP_INITIATE = 'STEP_INITIATE';
const STEP_VALIDATE = 'STEP_VALIDATE';

export default function useSelectItemByParams({ event_id, item_id }) {
    
    const { events } = useSelector(state => state.tickets);
    const { selected } = useSelector(state => state.shop.sell.selected);
    const dispatch = useDispatch();

    const [step, setStep] = useState(STEP_INITIATE);

    useEffect(() => {
        if(!events) {
            return;
        }

        const event = events.find(e => e.id === +event_id);

        if (!event) {
            return;
        }
        
        if (!+item_id) {
            dispatch(actions.all());
            setStep(STEP_VALIDATE);
            return;
        }

        const { purchased_merchandise } = event;

        if (!purchased_merchandise?.length) {
            return;            
        }

        const item = purchased_merchandise.find(i => +i?.id === +item_id) 
        if (!item) {
            return;
        }

        dispatch(actions.select(...params(item)));
        setStep(STEP_VALIDATE);

    }, [events, event_id, item_id]);

    useEffect(() => {
        if (step === STEP_INITIATE) {
            return;
        }

        dispatch(validateActions.validateRefund(event_id, selected));
    }, [step, selected])

}