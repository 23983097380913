import React from 'react';
import EventInfoLine, { TYPE_EVENT_INFO_LINE_DATE, TYPE_EVENT_INFO_LINE_LOCATION } from 'components/EventInfo';
import { useTranslation } from 'react-i18next';
import Tickets from './components/Tickets';
import { Col, Row } from 'react-bootstrap';
import Logotype from 'components/Logotype';
import FORMAT from 'config/date/format';
import moment from 'moment';

export default ({ event: item, tickets }) => {

    const { t } = useTranslation('tickets');

    return <Row>
        <Col xs md={8}>
            <h2 >{item.name}</h2>
            <div className='zt-p1 mb-4'>{item.performer_name}</div>
            <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION} card>{item?.map?.venue?.name || '--'}</EventInfoLine>
            <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>{t('date', {date: moment(item.start_date, FORMAT)})}</EventInfoLine>  
            {tickets && <Tickets {...{tickets}} />}
        </Col>
        <Col className='d-none d-md-block' md={4}>
            <div className="float-right">
                <div className='zt-webshop__event__media'>
                    {item.thumbnail ?
                    <img alt={item.name} className='zt-webshop__event__media__image' src={`${item.thumbnail}`} />
                    :
                    <Logotype className='zt-webshop__event__media__logotype'/>
                    }    
                </div>
            </div>
        </Col>        
    </Row>
}