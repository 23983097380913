import React, { useEffect, useState } from 'react';
import { PrimaryButton, LightButton, LinkButton } from 'components/buttons/Button';
import * as documentActions from './../methods/Document/actions';
import { link as documentLink } from '../methods/Document';
import useIsAuthorised from 'hooks/auth/useIsAuthorised';
import { link as bankIdLink } from '../methods/BankID';
import { NavLink, useHistory } from 'react-router-dom';
import { link as privacyLink } from 'pages/Privacy';
import useErrors from './components/Form/useErrors';
import { BankID } from 'components/Icons/Glyphs';
import { useTranslation } from 'react-i18next';
import { link as termsLink} from 'pages/Terms'
import * as actions from './state/actions';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Agree from './components/Agree';
import Form from './components/Form';
import './style.scss';

export default props => {

    const [enabled, setEnbaled] = useState(false);

    const { t } = useTranslation('registration');
    const { match } = props;
    const current = match.url;

    const history = useHistory();

    const dispatch = useDispatch();
    
    const form = useForm({
        mode: 'onBlur',
        validateCriteriaMode: "all"
    });
    
    const { formState: { errors }, watch, setError, handleSubmit, setValue } = form;
    
    const [email, phone] = watch(["email", "phone"]);

    const disabled = !email || !phone || !enabled || !(Object.keys(errors || {}).length === 0);

    const save = () => dispatch(actions.set({email, phone}));

    const doBankIdRegistration = handleSubmit(() => {
        save();
        history.push(`${current}${bankIdLink}`);
    });

    const doDocumentRegistration = handleSubmit(() => {
        save();
        history.push(`${current}${documentLink}`);
    });    

    const authorised = useIsAuthorised();
    useEffect(() => {
        if (authorised) {
            history.push('/');
        }
    }, [authorised]);

    const [init, setInit] = useState(false);

    useEffect(() => {
        setValue('email', '');
        setValue('phone', '');
        dispatch(actions.reset());
        dispatch(documentActions.reset());
        setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useErrors(setError, type => t(`coreid.errors.${type}`, `This ${type} is already used`));


    if (!init) {
        return <div className='zt-registration__selectmethod'></div>
    }

    return (
        <div className='zt-registration__selectmethod'>
            <h4>{t('select_method.label', 'Registration')}</h4>
            <Form {...{form, t}} />
            <Agree {...{t, onChange: e => setEnbaled(e.target.checked)}} />
            <div className='zt-registration__selectmethod__links'>
                <NavLink to={termsLink} className='zt-registration__selectmethod__links__link'><span className='zt-p2'>{t('common:links.privacy', 'Terms and Conditions')}</span></NavLink>
                <NavLink to={privacyLink} className='zt-registration__selectmethod__links__link'><span className='zt-p2'>{t('common:links.terms', 'Data Privacy Policy')}</span></NavLink>
            </div>             

            <div className="zt-registration__selectmethod__label zt-p1">{t('select_method.method', 'Registration method:')}</div>
            <div className='zt-registration__selectmethod__controls'>
                <PrimaryButton {...{disabled}} className='zt-registration__selectmethod__button zt-registration__selectmethod__button--bankid' onClick={doBankIdRegistration}>
                    <BankID />
                    {t('select_method.buttons.bankid', 'Register')}
                </PrimaryButton>                

                <LightButton {...{disabled}}  onClick={doDocumentRegistration} className='zt-registration__selectmethod__button zt-registration__selectmethod__button--document'>{t('select_method.buttons.passport_or_id', 'Passport or National ID Card')}</LightButton>
            </div>
                   
        </div>
    )
}