import React from 'react';
import Divider from 'components/Divider';
import * as Logotypes from 'pages/Registration/SelectMethod/logotypes';
import AppleStore from 'components/buttons/stores/AppleStore';
import GooglePlay from 'components/buttons/stores/GooglePlay';
import { NavLink } from 'react-router-dom';
import { coreIdLink } from '../.';

const Card = ({ icon, children, link, ...params }) => <div className='zt-profile__bankid__card'>
    <NavLink to={link}>
        <div className='zt-profile__bankid__card__icon'>{icon}</div>
        <div className='zt-profile__bankid__card__label'><span className='zt-nav light'>{children}</span></div>
    </NavLink>
</div>

export default ({ t, ...props }) => {
    return (
        <div className='zt-profile__bankid'>
               <h4 className='zt-profile__label zt-profile__bankid__label'>{t('bankid.label', 'Register with Mobile Bank ID')}</h4>
               <Card icon={<Logotypes.SwedenBankID mini={true} />} link={coreIdLink}>{t('common:countries.Sweden', 'Sweden')}</Card>
               <div className='zt-p1'>{t('bankid.description', 'Get the app and use your Phone as the ticket')}</div>
               <div className='zt-profile__bankid__gettheapp'>
                    <AppleStore className='zt-profile__bankid__gettheapp__icon'/>
                    <GooglePlay className='zt-profile__bankid__gettheapp__icon'/>
                </div>               
               <Divider />
        </div>
    );
}