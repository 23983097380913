import { useReducer } from "react";
import { reducer, defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';
import * as sellModes from './../sellMode';

export default function useValidate({ mode, eventId, ticketId }) {

    if (!Object.keys(sellModes).includes(mode)) {
        throw 'use Validate: Invalid on not defined selling mode (need to be set to \'sell all\' or \'sell mine\' ticket';
    }

    if (!+eventId) {
        throw 'No eventId selected';
    }

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    const kids = state.tickets?.[0].item?.child_tickets;

    const validate = actions.validate(dispatch, mode, eventId, ticketId);

    return {
        state,
        validate,
        kids
    }

}