import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoPage from 'components/InfoPage';

export const path = '/terms';
export const link = path;

export default props => {
    const [t] = useTranslation("terms");
    return <InfoPage t={t} />
}