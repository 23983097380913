import * as constants from './constants'
import api from './api';

export const reset = () => ({type: constants.AUTH_RESET})

export const auth = {
    start: email => ({type: constants.AUTH_START, payload: {email}}),
    success: payload => ({type: constants.AUTH_SUCCESS, payload}),
    failure: error => ({type: constants.AUTH_FAILURE, error})
}

export function login(email, password) {
    return dispatch => {
        dispatch(auth.start(email));
        api.login(email, password)
            .then(data => {
                const token = data?.data?.token || null;
                dispatch(auth.success({token}));
            })
            .catch( error => dispatch(auth.failure(error)));
    }
}

export function logout() {
    return dispatch => {
        dispatch({type:constants.AUTH_CLEAN});
    }
}