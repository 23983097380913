import FORMAT from 'config/date/format';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const RESTRICTION_SOLD_OUT = 'no_seats_available';
const RESTRICTION_AGE = 'age_below_restricted';
const RESTRICTION_BLACKLISTED = 'blacklisted';

export default function useRestrictions(item) {

    const { t } = useTranslation('event');
    const tt = (label, params) => t(`buying_restrictions.${label}`, params);

    if (!item) {
        return {
            disabled: true
        }
    }

    const { restrictions, tickets_release_date } = item;

    const releaseDate = moment(tickets_release_date, FORMAT).toDate();
    const todaysDate = new Date();

    if (releaseDate > todaysDate) {
        return {
            disabled: true,
            restriction: tt('coming_soon', {defaultValue: 'Coming soon.', date: releaseDate})
        }
    }

    const restriction = Object.keys(restrictions).find(k => restrictions[k] === true);
    if (restriction) {
        
        switch (restriction) {

            case RESTRICTION_SOLD_OUT: {
                return {
                    disabled: true,
                    restriction: tt('sold_out', 'Sorry, we`re sold out all the tickets.')
                }
            }

            case RESTRICTION_AGE: {
                return {
                    disabled: true,
                    restriction: tt('age_restriction', 'You will be not permitted to attend the event because of age restriction')
                }
            }

            case RESTRICTION_BLACKLISTED: {
                return {
                    disabled: true,
                    restriction: tt('blacklisted', 'You are disallowed to attend this event. Please contact support.')
                }
            }

            default: {
                return {
                    disabled: true,
                    restriction: tt('other', 'Buying is restricted!')
                }
            }
        }
    }


    return {
        disabled: false
    }

}