import * as constants from './constants';
import api from'./api';

export const reset = () => ({type: constants.EVENTBUY_RESET});

export const setTicketsQty = qty => ({type: constants.EVENTBUY_SET_TICKETS_QTY, payload: qty});
export const setTicketsGroupMode = mode => ({type: constants.EVENTBUY_SET_TICKETS_GROUP_MODE, payload: mode});

export const selectSection = section => ({type: constants.EVENTBUY_SELECT_SECTION, payload: section});
export const resetSection = () => ({type: constants.EVENTBUY_RESET_SECTION});

export const resetTickets = () => ({type: constants.EVENTBUY_TICKETS_RESET});
export const addTicket = ticket => ({type: constants.EVENTBUY_ADD_TICKET, payload: ticket});
export const removeTicket = index => ({type: constants.EVENTBUY_REMOVE_TICKET, payload: index});

const fetch = {
    start: eventId => ({type: constants.EVENTBUY_FETCH_DETAILS_START, payload: eventId}),
    success: payload => ({type: constants.EVENTBUY_FETCH_DETAILS_SUCCESS, payload }),
    failure: error => ({type: constants.EVENTBUY_FETCH_DETAILS_FAILURE, error})
}

export const fetchEventSections = id => {
    return dispatch => {
        dispatch(fetch.start(id));
        api.fetchSections(id)
            .then( response => {
                const { data=null } = response;
                dispatch(fetch.success(data));
            })
            .catch( error => dispatch(fetch.failure(error)));         
    }
}