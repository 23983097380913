import { useReducer } from 'react';
import { reducer, defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';

export default function useGetPayment(id) {

    const state = useSelector(state => state.confirm);
    const dispatch = useDispatch();

    const get = id => dispatch(actions.get(id));

    return {
        state,
        get
    }

}