import { useSelector } from "react-redux";
import parseJwt from 'utils/parseJwt';

export default function useCurrentUserId() {
    const { token } = useSelector(state => state.auth);

    if (!token) {
        return null;
    }

    const { sub: userId } = parseJwt(token);

    return userId;
}