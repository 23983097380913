import * as actions from './constants';

export const defaultState = {
    gui: null,
    loading: false,
    error: null
}

export const reducer = (state = defaultState, action) => {
    const { type, payload, error=null } = action;
    switch (type) {
        case actions.RESET_WEBPAY: {
            return {
                ...defaultState
            }
        }

        case actions.WEBPAY_START: {
            return {
                ...defaultState,
                loading: true
            }
        }

        case actions.WEBPAY_SUCCESS: {
            const { snippet } = payload;
            return {
                ...defaultState,
                gui: snippet
            }
        }

        case actions.WEBPAY_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }

    }
}