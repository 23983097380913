import * as constants from './constants';
import api from './api';

export const fetch = {
    start: () => ({type: constants.FETCH_CITIES_START}),
    success: payload => ({type: constants.FETCH_CITIES_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_CITIES_FAILURE, error})
}

export const setFilter = filter => {
    return (dispatch, getState) => {
        dispatch({type: constants.SET_CITIES_FILTER, payload: filter});
        dispatch(fetchCities());
    }
}
export const resetFilter = () => ({type: constants.RESET_CITIES_FILTER})

export function fetchCities() {
    return (dispatch, getState) => {
        dispatch(fetch.start());
        const filter = getState().cities.filter;
        if (!filter || filter === '') {
            api.fetch()
                .then(response => dispatch(fetch.success(response.data || null)))
                .catch(error => dispatch(fetch.failure(error)));         
        } else {
            api.filteredFetch(filter)
                .then(response => dispatch(fetch.success(response.data || null)))
                .catch(error => dispatch(fetch.failure(error)));
        }
    }
}
