import React, { useEffect, useRef, useState } from 'react';
import useProfileItemUpdate from 'pages/Profile/useProfileItemUpdate';
import { PrimaryButton } from 'components/buttons/Button';
import PhoneInput from 'components/Inputs/PhoneInput';

const isPhone = phone => phone?.length > 5 && /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(phone);

export default ({ t, profile, refresh, ...props }) => {

    const { state: {loading, profile: updated, saved, error}, proceedUpdate, reset } = useProfileItemUpdate();
    const [phone, setPhone] = useState(profile?.phone);
    
    const savePhone = () => {
        proceedUpdate({phone: phone?.replace(/^\+/g, "")});
    };

    const inputerror = !isPhone(phone);
    const enabled = phone !== profile?.phone && !inputerror;

    useEffect(() => {
        if (saved) {
            reset();
            refresh(updated);
        }
    }, [saved, updated, refresh, profile, reset]);

    return (
        <form>
            <PhoneInput 
                autoFocus={false} 
                label={t('contacts.phone.input.label', 'Phone number:')} 
                defaultValue={profile?.phone || ''} 
                onChange={(value, country) => setPhone(value)}
                error={inputerror && t('contacts.phone.error.input', 'Please, input a correct phone number')}
                />
            <PrimaryButton {...{loading, disabled: !enabled, error: error && t('contacts.phone.error.save','Error saving the phone')}} onClick={() => savePhone()}>{t('contacts.phone.button', 'Change phone number')}</PrimaryButton>
        </form>        
    );
}