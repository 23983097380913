import React from 'react';
import { ClearIcon } from 'components/Icons/Glyphs';
import './style.scss';

export default props => <button className='zt-chip d-flex justify-content-center align-items-center' onClick={props.onClick}>
        { !!props.icon &&
        <props.icon className='zt-chip__icon'/>
        }
        <span className='zt-p2 zt-chip__label'>{props.children}</span>
        <ClearIcon className='zt-chip__icon-close'/>
    </button>