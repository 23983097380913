import React, { useEffect, useState } from 'react';
import Button, { PrimaryButton } from 'components/buttons/Button';
import PayModeInfoModal from './components/PaymentInfoModal';
import RadioGroup from 'components/Inputs/RadioGroup';
import { Info } from 'components/Icons/Glyphs';
import * as payTypes from './../payTypes';
import FRIENDMODE from './mode';

const PayModeSelector = ({ mode, friend, setValue, tt, onShowModal, ...props }) => {

    const i_pay_label = tt('inputs.paytype.i_pay', 'I Pay Now');
    const friend_pays_label = tt('inputs.paytype.friend_pays', 'My Friend Pays');

    const onChange = value => setValue('paytype', value);
    const defaultValue = friend?.pay;
    const name = 'paytype';

    useEffect(() => {
        onChange(defaultValue);
    }, []);

    return {
        [FRIENDMODE.LOADING]: null,
        
        [FRIENDMODE.KID]: <RadioGroup 
            {...{name, onChange, defaultValue}}
            className='zt-event-buy__add-friends__friend__form__controlls__payselect'
            items={
                [
                    {id: payTypes.PAYTYPE_IPAY, label: i_pay_label} 
                ]} 
        />,

        [FRIENDMODE.EXISTED]: <RadioGroup 
            {...{name, onChange, defaultValue}}
            className='zt-event-buy__add-friends__friend__form__controlls__payselect'
            
            items={
                [
                    {id: payTypes.PAYTYPE_IPAY, label: i_pay_label}, 
                    {id: payTypes.PAYTYPE_FRIENDPAY, label: friend_pays_label}
                ]} 
        />,

        [FRIENDMODE.NEWUSER]: <><RadioGroup 
            {...{name, onChange, defaultValue}}
            className='zt-event-buy__add-friends__friend__form__controlls__payselect'
            
            items={
                [
                    {id: payTypes.PAYTYPE_FRIENDPAY, label: friend_pays_label}
                ]} 
        /><PrimaryButton onClick={() => onShowModal && onShowModal()} icon={Info} className='zt-event-buy__add-friends__friend__form__controlls__info'  /></>
    }[mode] || null;
}


export default props => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <PayModeSelector {...props} onShowModal={() => setShowModal(true)} />
            {showModal && <PayModeInfoModal onHide={() => setShowModal(false)} />}
        </>
    );
}