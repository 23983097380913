import * as actions from './constants';

const defaultState = {
    token: null,
    error: null,
    loading: false
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch (type) {
        case actions.AUTH_RESET: {
            return {
                ...defaultState
            }
        }
        case actions.AUTH_START:
            return {
                ...state,
                loading: true
            }
        case actions.AUTH_SUCCESS:
            const token = payload?.token || null;
            return {
                ...state,
                token,
                loading: false
            }
        case actions.AUTH_FAILURE:
            return {
                ...state,
                loading: false,
                error
            }
        case actions.AUTH_CLEAN:
            return {
                ...state,
                token: null
            }
        default:
            return state;
    }
}