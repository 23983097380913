import styleClasses from './styleClasses';
import colorByLevel from './colorByLevel';

const SELECTOR_ROWS = '*[data-row]';
const SELECTOR_SEATS = '*[data-seat]';

const initSeats = (mapElement, levels, data) => {
    const matrix = new Map();
    const rows = mapElement.querySelectorAll(SELECTOR_ROWS);
    rows.forEach(rowElement => {
        const row = rowElement.dataset?.row;
        const seats = rowElement.querySelectorAll(SELECTOR_SEATS);
        const matrixRow = new Map()
        seats.forEach( seatElement => {
            const seat = seatElement.dataset?.seat;
            matrixRow.set(seat, data[row]?.[seat]);
            if (data[row] && data[row][seat]) {
                const price = data[row][seat];
                seatElement.style.fill = colorByLevel(levels, price);
            } else {
                seatElement.classList.add(styleClasses['empty']);
            }
        });
        matrix.set(row,  matrixRow);
    });
    return matrix;
}

export default initSeats;