import { useSelector } from "react-redux";

export default function useTotalsFromPayment() {
    const { payment, loading, error } = useSelector(state => state.confirm);

    let total = 0;
    let tickets = 0;
    let service = 0;
    let admin = 0;

    if (!loading && !error && !!payment) {
        const { summary } = payment;
        total = summary?.total?.net_price || 0;
        tickets = summary?.ticket?.original_price;
        service = summary?.ticket_service_fee?.original_price;
        admin = summary?.ticket_admin_fee?.original_price;
    }

    return { 
        fees: 
            { 
                total, 
                tickets, 
                service, 
                admin 
            }, 
        loading, 
        error 
    };
}