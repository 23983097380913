import api from 'api';

const requestTokenUrl = 'api/auth/bankid/token';
const validateCoreIDTokenUrl = 'api/auth/login';
const registerCoreIDUserUrl = 'api/auth/register';

export default {
    requestToken: () => api.get({path: requestTokenUrl}),
    validateCoreIDToken: coreid_token => api.post({path: validateCoreIDTokenUrl, params:{coreid_token}}),
    registerCoreIDUser: data => api.post({path: registerCoreIDUserUrl, params:data})
}