
export default function useSelectedEventPurchases(item) {
    if (!item) {
        return null;
    }

    const { ticket_reservations } = item;
    
    if (!ticket_reservations) {
        return null;
    }

    const { merchandise_reservations_groups } = item;

    return merchandise_reservations_groups;
}