export const RESET_TICKETSELL = 'RESET_TICKETSELL';

export const SELL_TICKETSELL_START = "SELL_TICKETSELL_START";
export const SELL_TICKETSELL_SUCCESS = "SELL_TICKETSELL_SUCCESS";
export const SELL_TICKETSELL_FAILURE = "SELL_TICKETSELL_FAILURE";

export const SET_TICKETSELL_MODE = "SET_TICKETSELL_MODE";

export const SET_TICKETSELL_KIDSFRIEND = "SET_TICKETSELL_KIDSFRIEND";

export const SET_TICKETSELL_TOFRIEND = "SET_TICKETSELL_TOFRIEND";