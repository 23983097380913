import React from 'react';

export const Back = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M11 17L5 11L11 5" />
</svg>

export const BackPlus = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props}  xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 16.5L7 11L12.5 5.5H7.5L2 11L7.5 16.5H12.5Z" />
</svg>

export const Forward = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props}  xmlns="http://www.w3.org/2000/svg">
    <path d="M5 17L11 11L5 5" />
</svg>

export const ForwardPlus = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props}  xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 16.5L9 11L3.5 5.5H8.5L14 11L8.5 16.5H3.5Z" />
</svg>

