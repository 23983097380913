import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';
import Banner from 'components/Header/Banner';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useValidateOrder from './state/useValidateOrder';
import Error from 'pages/Event/components/Error';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';

export const path = '/confirm/:order_id';
export const link = path;

export default props => {

    const { t } = useTranslation('merchandise');
    const { order_id } = useParams();

    const { validate, loading, error } = useValidateOrder(order_id);

    const revalidate = () => +order_id && validate();

    useEffect(() => {
        revalidate();
    }, [order_id]);


    if (error || !+order_id) {
        return <Error retry={() => revalidate()}/>
    }

    if (loading) {
        return <MiniLoader />
    }

    return <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-webshop-cart-confirm'>
            <Container>
                <Row>
                    <Col>
                        <div className='zt-webshop-cart-confirm__message'>
                            <h4 className='bold zt-webshop-cart-confirm__message__header'>{t('confirm.label', 'Thank you for your purchase!')}</h4>
                            <div className='zt-p1 zt-webshop-cart-confirm__message__description'>{t('confirm.description_1', {defaultValue: 'Your order #{{order}} is proceeded.', order: order_id})}</div>
                            <div className='zt-p1 zt-webshop-cart-confirm__message__description'>{t('confirm.description_2', 'You will receive an order confirmation email shortly!')}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Banner />
                </Row>
            </Container>
        </div>
    </CSSTransition>
}
