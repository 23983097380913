import * as constants from './constants';
import api from './api';

const flow = {
    start: payload => ({type: constants.VALIDATE_RESERVEDTICKET_START, payload}),
    success: payload => ({type: constants.VALIDATE_RESERVEDTICKET_SUCCESS, payload}),
    failure: error => ({type: constants.VALIDATE_RESERVEDTICKET_FAILURE, error})
}

export function validate(dispatch, orderId) {
    return () => {
        dispatch(flow.start());
        api.validate(orderId)
            .then(response => dispatch(flow.success(response?.data)))
            .catch(error => dispatch(flow.failure(error)));
    }
}

const updateFlow = {
    start: payload => ({type: constants.UPDATE_RESERVEDTICKET_START, payload}),
    success: payload => ({type: constants.UPDATE_RESERVEDTICKET_SUCCESS, payload}),
    failure: error => ({type: constants.UPDATE_RESERVEDTICKET_FAILURE, error})
}

export function update(dispatch, id) {
    return params => {
        dispatch(flow.start());
        api.update(id, params)
            .then(response => dispatch(updateFlow.success(response?.data)))
            .catch(error => dispatch(updateFlow.failure(error)));
    }
}
