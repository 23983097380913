import React from 'react';
import ToolTipBase from './../../components/ToolTip';

export default ({ seat, group, onSelect=null, ...props }) => {
    if (!seat) {
        return null;
    }    
    const groupMode = group && group.length > 1;
    return (
    <ToolTipBase {...props}>
        {!groupMode ?
        <>
            <div className='zt-event-buy__tooltip__header zt-p3'>Seat</div>
            <div className='zt-nav'>{seat.seat}</div>
            <div className='zt-event-buy__tooltip__header zt-p3'>Row {seat.row}</div>
        </>
        :
        <>
            <div className='zt-event-buy__tooltip__header zt-p3'>{group.length} seats</div>
            <div className='zt-nav zt-seats-map__tooltip__seats'>
                {group.map((gseat, i) => <span >{(gseat === seat.seat)? <span className='zt-seats-map__tooltip__seats__seat--current'>{gseat}</span> : `${gseat}`}{i !== group.length-1 && ', '}</span>)}
            </div>
            <div className='zt-event-buy__tooltip__header zt-nav zt-seats-map__tooltip__row zt-p3'>Row {seat.row}</div>
        </>
        }
    </ToolTipBase>);
}