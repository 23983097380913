import { combineReducers } from 'redux';
import event from 'pages/Event/reducer';
import events from 'containers/AvailableEvents/reducer';
import filteredevents from 'containers/FilteredEvents/reducer';
import register from 'pages/Registration/reducer';
import activation from 'pages/Registration/methods/Document/Activation/reducer';
import cities from 'components/Header/Filter/City/reducer';
import filter from 'components/Header/Filter/reducer';
import tickets from 'pages/MyTickets/pages/List/store/reducer';
import notifications from 'pages/Notifications/store/reducer';
import profile from 'pages/Profile/reducer';
import auth from 'containers/Login/state/reducer';
import shop from 'pages/WebShop/store';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from "redux-persist/lib/storage";
import confirm from 'pages/Event/pages/BuyTickets/Confirm/useGetPayment/reducer';

const authPersistedReducer = persistReducer({
        key: "ZTStorage",
        storage,
        whitelist: ['token']
    }, auth);

export default combineReducers({
    auth: authPersistedReducer,
    profile,
    activation,
    register,
    cities, //TODO: move to and combine with filter reducer
    filter,
    filteredevents,
    notifications,
    tickets,
    events,
    event,
    shop,
    confirm
})