import * as actions from './constants';

export const defaultState = {
    loading: false,
    reservation: null,
    error: null,
    checked: false
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;

    switch (type) {

        case actions.RESET_UNPAIDRESERVATIONS: {
            return {
                ...defaultState
            }
        }

        case actions.GET_UNPAIDRESERVATIONS_START:
            return {
                ...defaultState,
                loading: true
            }

        case actions.GET_UNPAIDRESERVATIONS_SUCCESS:
            const reservation = payload;
            return {
                ...defaultState,
                reservation,
                checked: true
            }

        case actions.GET_UNPAIDRESERVATIONS_FAILED:
            if (error.response.status === 404) { // No unpaid reservations found
                return {
                    ...defaultState,
                    checked: true
                }
            }
            return {
                ...defaultState,
                error: error,
                checked: true
            }

        default:
            return state;
    }
}
