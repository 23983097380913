import * as actions from './constants';

export const defaultState = {
    loading: false,
    items: null,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;

    switch (type) {

        case actions.RESET_PROMOEVENTS: {
            return {
                ...defaultState
            }
        }

        case actions.GET_PROMOEVENTS_START:
            return {
                ...defaultState,
                loading: true
            }

        case actions.GET_PROMOEVENTS_SUCCESS:
            const items = payload?.collection;
            return {
                ...defaultState,
                items
            }

        case actions.GET_PROMOEVENTS_FAILED:
            return {
                ...defaultState,
                error: error
            }

        default:
            return state;
    }
}
