import CONFIGURATIONS from './configurations';
import * as constants from './constants';
import api from './api';

const fetch = {
    start: () => ({type: constants.FETCH_EVENT_START}),
    success: payload => ({type: constants.FETCH_EVENT_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_EVENT_FAILURE, error})
}

export function fetchEvent(id) {
    return (dispatch, getState) => {
        dispatch(fetch.start());
        api.fetch(+id, {sg: [CONFIGURATIONS.RESTRICTIONS, CONFIGURATIONS.CHILDREN]})
            .then(response => dispatch(fetch.success(response?.data || null)))
            .catch(error => dispatch(fetch.failure(error)));         
    }
}

export const reset = () => ({type: constants.FETCH_EVENT_RESET});
