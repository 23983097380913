import React from "react";
import { Modal } from "react-bootstrap";
import Panel from "components/Panel";
import { PrimaryButton } from "components/buttons/Button";
import { useTranslation } from "react-i18next";

export default ({ onHide }) => {

    const { t } = useTranslation('buying');

    return (
        <Modal show={true} size="md" centered onHide={() => onHide && onHide()} className='zt-modal-centered'>
            <Panel className="d-flex flex-column justify-content-center">
                <div className='zt-p1' style={{textAlign: 'center'}}>{t('add_friends.info.description', 'If you want to pay for a friend that has not registered yet, please use tha app instead.')}</div>
                <div className='zt-notifications__modal__actions' style={{justifyContent: 'center'}}>
                    <PrimaryButton onClick={() => onHide()}>{t('add_friends.info.button', 'Ok')}</PrimaryButton>
                </div>
            </Panel>
        </Modal>
    );
}