const DEVELOPMENT = 'development';
const PRODUCTION = 'production';
const TEST = 'test';

const env = process.env.NODE_ENV || DEVELOPMENT;

const isDevelopment = env === DEVELOPMENT;
const isTest = env === TEST;
const isProduction = env === PRODUCTION;

Object.assign(exports, {
    __esModule: true,
    default: env,
    isDevelopment,
    isProduction,
    isTest
})