import axios from 'axios';
import { AUTH_CLEAN, AUTH_SUCCESS } from 'containers/Login/state/constants';
import { isTokenValid, refreshToken } from './tokenUtils';
import { API as baseURL } from 'config/api';
import platform from 'config/platform';
import parseJwt from 'utils/parseJwt';
import state from '../store'; //getState, dispatch
import i18n from '../i18n';

const getToken = () => {
  const { token } = state.getState().auth;
  return token;
}

const instance = axios.create({
    baseURL,
    headers: {
        accept: 'application/json', 
        'content-type': 'application/json'
    }
  });

const demilitarized = ['api/auth/login'];

instance.interceptors.request.use(config => {
    const { url } = config;
    config.headers.platform = platform;
    config.headers['platform-lang'] = {'en':'en', 'se':'sv', 'sv':'sv'}[i18n.language] || 'en';
   
    if (demilitarized.includes(url)) {
        return config; //This apis don't require tokens in header
    }

    const token = getToken();

    if (!token) {
        console.log(' >>> no token, guest access');
        return config;
    }

    const { exp } = parseJwt(token);
    console.log(`>>> * Token will be expired in ${Math.floor((+(new Date()) - +exp*1000)/1000)} sec`);

    if (!isTokenValid(token)) {
        return refreshToken(token)
            .then(token => {
                console.log('got new token!');
                state.dispatch({type: AUTH_SUCCESS, payload: {token}});
                config.headers.Authorization = `Bearer ${token}`;
                return config; 
            })
            .catch(err => {
                console.log('can\'t get new token, clear authorisation...');
                state.dispatch({type: AUTH_CLEAN});
                return config
            });
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  }, error => {
    return Promise.reject(error);
});

// declare a response interceptor
instance.interceptors.response.use((response) => {
    // do something with the response data
    console.log('Response was received');
  
    return response;
  }, error => {
    // handle the response error
    if (+error?.response?.status === 401) {
      // state.dispatch(logout());
      console.log('>>> -- call auth clean due to 401 from BE server');
      state.dispatch({type: AUTH_CLEAN});
    }    
    return Promise.reject(error);
});

const CancelToken = axios.CancelToken;

export default {
    get: ({ params, path , cancelCallback}) => instance.get(path, {params, cancelToken: new CancelToken(c => cancelCallback && cancelCallback(c))}),
    post: ({ params, path }) => instance.post(path, params),
    put: ({ params, path }) => instance.put(path, params),
    patch: ({ params, path }) => instance.patch(path, params),
    delete: ({ params, path }) => instance.delete(path, params)
}