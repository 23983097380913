import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Total from './components/Total';
import Card from './components/Card';
import { useHistory } from 'react-router-dom';
import { link as webShopLink } from 'pages/WebShop';
import useCartItems from './store/useCartItems';
import { useTranslation } from 'react-i18next';

export const path = '/cart';
export const link = path;

export default props => {

    const items = useCartItems();
    const history = useHistory();

    const { t } = useTranslation('merchandise')

    useEffect(() => {
        if (!items?.length) {
            history.push(webShopLink);
        }
    }, [items, history])

    return <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-webshop-cart'>
            <Container>
                <Row>
                    <Col md={9} className='zt-webshop-cart__feed'>
                        <Row>
                            <Col md={0} lg={2}>
                            </Col>
                            <Col md={12} lg={10}>
                                <div><h4 className='bold'>{t('cart.label', 'Cart')}</h4></div>
                                {items.map((i,k) => <Card key={k} item={i.item} qty={i.qty} option_id={i.option}/>)}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}><Total valid={true}/></Col>
                </Row>
            </Container>
        </div>
    </CSSTransition>
}