import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function useErrors(setError, t, fields = null)  {
    const { error } = useSelector(state => state.register.user);

    useEffect(() => {
        if (!error) {
            return;
        }

        const validation_errors = error.response?.data?.validation_errors;
        Object.keys(validation_errors).forEach(error => {
            if (!fields || fields.includes(error)) {
                setError(error, {type: 'validation', message: t(error, `${validation_errors[error]}`)}, { shouldFocus: true });
            }
        });
    }, [error, fields, setError, t]);
}