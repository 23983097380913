import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Divider from 'components/Divider';
import Socials from 'components/Icons/Social';
import EventInfoLine, { TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE } from 'components/EventInfo';
import ContentRestrictions from 'pages/Event/components/ContentRestrictions';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import BuyTicketButton from './components/BuyTicketsButton';
import useIsAuthorised from 'hooks/auth/useIsAuthorised';
import { PortfolioIcon } from 'components/Icons/Glyphs';
import { CSSTransition } from 'react-transition-group';
import Error from 'pages/Event/components/Error';
import useRestrictions from './useRestrictions';
import { useTranslation } from 'react-i18next';
import { buyTicketsLink } from 'pages/Event'; 
import More from './../../components/More';
import FORMAT from 'config/date/format';
import { Modal } from 'react-bootstrap';
import * as actions from './actions';
import Login from 'containers/Login';
import moment from 'moment';
import './style.scss';

const httpWarrior = url => {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        return 'http://' + url;
    }
    return url;
}

const BeOurPartner = ({ t, ...props }) => <div className={`zt-event__aside__bepartner ${props.className}`}>
    <div className='zt-event__aside__bepartner__label'>
        <PortfolioIcon/><span className='zt-p1'>{t('bepartner.label', 'Be Our Partner')}</span>
    </div>
    <div className='zt-p3 zt-event__aside__bepartner__info'>{t('bepartner.description', 'Send a request for corporate tickets bookings.')}</div>
</div>;

const BuyTicketBlock = ({ event, className, restrictions, buyAction=null, aside=false }) => {
    const { t } = useTranslation('event');
    const { disabled, restriction=null } = restrictions;
    const Button = () => <BuyTicketButton className='zt-buyticketblock__button' onClick={disabled ? () => {} : buyAction} disabled={disabled} id={event.id}>{t('common:buttons.buy')}</BuyTicketButton>
    const Info = () => <React.Fragment>
        <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION}>{event.map?.venue?.name}</EventInfoLine>
        <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE}>{t('date', {date: moment(event.start_date, FORMAT)})}</EventInfoLine>
    </React.Fragment>

    return (<div className={`zt-buyticketblock ${aside ? '' : 'zt-buyticketblock--article'} ${className}`}>
        {restriction && <p>{restriction}</p>}
        <Button />       
        <Info />
        <Divider className='zt-event__aside__divider'/>
    </div>);
}

export default props => {
    
    const { item: event, loading, error } = useSelector( store => store.event.details);
    const [showLogin, setShowLogin] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [t] = useTranslation('event');
    const authorised = useIsAuthorised();
    const history = useHistory();

    useEffect(() => {
        if (+id) {
            if (!error) {
                dispatch(actions.fetchEvent(id));
            }
        }
    }, [dispatch, id, error]);
    
    useEffect(() => {
        return () => {
            dispatch(actions.reset());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buyAction = useCallback(() => authorised ? history.push({pathname: buyTicketsLink(event?.id), state: {checked: true}}) : setShowLogin(true), [authorised, event, history]);

    useEffect(() => {
        if (showLogin && authorised) {
            setShowLogin(false);
        }
    }, [authorised, showLogin, buyAction]);

    const restrictions = useRestrictions(event);

    if (!(+id > 0)) {
        return <div className='zt-event__error'>Parameter or Url Error</div>; //TODO: neet to move to 404 or error page here
    }

    if (loading || (!event && !error)) {
       return <div className='zt-event__loading'><MiniLoader className='zt-event__loading__icon'/></div>;
    }

    if (error) {
        return <Container><Row><Col><Error retry={() => dispatch(actions.fetchEvent(id))}/></Col></Row></Container>
    }


    const { socialLinks={} } = event;  


    const Page = () =>   <Row style={{marginLeft: 0, marginRight: 0}}>
        <Col md={{ span: 3 }} className='zt-event__aside order-md-last d-none d-sm-none d-md-block'>
            <BuyTicketBlock {...{event, restrictions, buyAction}} aside />
        </Col>        
        <Col md={{ span: 7, offset: 1 }} className='zt-event__content'>
            <h1 className='bold zt-event__content__title'>{event.name}</h1>
            <h4 className='light zt-event__content__performer'>{event.performer_name}</h4>
            <BuyTicketBlock {...{event, restrictions, buyAction}} className='d-block d-md-none'/>
            {showLogin && !authorised && <Modal show size="md" centered dialogClassName='zt-modal-small' className='zt-modal-centered' >
                <Login onClose={() => setShowLogin(false)}/>
            </Modal>}             
            <More ><div className='zt-p1 zt-event__content__text' dangerouslySetInnerHTML={{__html:event.description}} ></div></More>
            <Divider  className='d-block d-md-none'/>
            <ContentRestrictions t={t} event={event}/>
            { Object.keys(socialLinks).length !== 0 &&
            <>
                <Divider />    
                <h4>{t('follow', {name: event.performerName})}</h4>
                <ul className='zt-event__content__socials d-flex justify-content-start'>
                    {Object.keys(socialLinks).filter(type => !!socialLinks[type]?.trim()).map(type =><li key={type}><a href={httpWarrior(socialLinks[type]) || '#'} target='_blank' rel='noopener noreferrer'>{Socials[type]}</a></li>)}
                </ul>       
            </>
            }
        </Col>
    </Row>        


    return (
    <CSSTransition in={true} appear={true} timeout={200} classNames="zt-event__transition">
        <Container className='zt-event'>
            <Row className="justify-content-md-center" style={{marginLeft: 0, marginRight: 0}}>
                <Col xs lg="9" style={{padding:0}}>
                    <div className='zt-event__banner'>
                        { event.medium && <img alt={event.name} className='zt-event__banner__image' src={`${event.medium}`} />}
                    </div>
                    <Page />
                </Col>
            </Row>
        </Container>
    </CSSTransition>
);
}