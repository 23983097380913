import { useState, useEffect } from 'react';
import * as steps from './steps';
import useCreateReservation from './useCreateReservation';
import useGetWebPay from './useGetWebPay';

export default function useProceedPayment({ order=null, reservationId=null }) {

    const [step, setStep] = useState(steps.STEP_INIT);

    // const { state: orderState, create: createOrder } = useCreateOrder(order);
    const { state: reservationState, activePaymentId, create: createReservation, fetch: fetchReservation, reset: resetReservation } = useCreateReservation();
    const { state: webPayState, getWebPayGUI, reset: resetGUI } = useGetWebPay();

    useEffect(() => {
        switch(step){

            case steps.STEP_INIT: {
                return;
            }

            case steps.STEP_START: {
                setStep(steps.STEP_CREATEORDER);
                resetGUI();
                return;
            }

            case steps.STEP_CREATEORDER: {
                return;
            }

            case steps.STEP_GETWEBPAYGUI: {
                return;
            }            

            case steps.STEP_WEBPAYPROCEED: {
                return;
            }

            default: {
                return;
            }
        }

    }, [step]);

    // 1. Create or get reservation info
    useEffect(() => {
        if (step !== steps.STEP_CREATEORDER) {
            return;
        }

        if (reservationState.error) {
            setStep(steps.STEP_ERROR);
            return;
        }

        if (!reservationState || reservationState.loading) {
            return;
        }

        if (reservationState.reservation?.id) {
            setStep(steps.STEP_GETWEBPAYGUI);
            return;
        }

        if (order) {
            createReservation(order);
            return;
        }

        if (reservationId) {
            fetchReservation(reservationId);
        }

    }, [step, reservationState]);

    useEffect(() => {
        if (step !== steps.STEP_GETWEBPAYGUI) {
            return;
        }
        
        if (webPayState.error) {
            setStep(steps.STEP_ERROR);
            return;
        }
        
        if (webPayState.loading) {
            return;
        }
        
        if (webPayState.gui) {
            setStep(steps.STEP_WEBPAYPROCEED);
            return;
        }

        getWebPayGUI(activePaymentId);
    }, [step, webPayState, reservationState, getWebPayGUI]);

    console.log(`**** *** ** ${step}`);

    return {
        proceedPayment: () => {setStep(steps.STEP_START)}, 
        processing: ![steps.STEP_INIT, steps.STEP_DONE, steps.STEP_ERROR, steps.STEP_WEBPAYPROCEED].includes(step),
        reset: () => setStep(steps.STEP_INIT), 
        step,
        gui: [steps.STEP_WEBPAYPROCEED].includes(step) && webPayState.gui
    };    
}