import * as constants from './constants';
import api from './api';

const get = {
    start: () => ({type: constants.WEBPAY_START}),
    success: payload => ({type: constants.WEBPAY_SUCCESS, payload }),
    failure: error => ({type: constants.WEBPAY_FAILURE, error})
}

export const getGUI = dispatch => id => {
    dispatch(get.start());
    api.webPay(id)
        .then(response => dispatch(get.success(response?.data || null)))
        .catch(error => dispatch(get.failure(error)));         
}

export const reset = dispatch => () => dispatch({type: constants.RESET_WEBPAY});