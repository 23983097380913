import React from 'react';
import './style.scss';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { useHistory } from 'react-router-dom';

const Button = buttonClass => React.forwardRef(({ error=null, description=null, ...props }, ref) => <>
    <button 
        ref={ref}
        type="button"
        className={`zt-button ${buttonClass || ''} ${props.icon ? 'zt-button--iconed' : ''} ${props.disabled ? 'zt-button--disabled' : ''} ${props.selected ? 'zt-button--selected' : ''} ${error ? 'zt-button--error' : ''} ${props.className || ''}`} onClick={!props.disabled ? props.onClick : null}>
        {props.icon &&
            <props.icon className='zt-button__icon'/>
        }
        {props.loading ?
        <MiniLoader style={{marginTop:3}}/>
        :
        props.children && <span className="zt-nat light zt-button__label">{props.children}</span> 
        }
    </button>
    { (!!error || !!description) &&
        <div className={`zt-button__message  ${props.disabled ? 'zt-button--disabled' : ''}`}>
            <div className={`zt-button__message__first ${error ? 'zt-button__message__first--error' : ''}`}><span className='zt-p3'>{error || description}</span></div>
            <div className='zt-button__message__second'><span className='zt-p3'></span></div>
        </div>
    }     
</>);

export const SecondaryButton = Button();
export const SecondaryFixedButton = Button('zt-button--fixed');

export const PrimaryButton = Button('zt-button--primary');
export const PrimaryFixedButton = Button('zt-button--primary zt-button--fixed');

export const NavigationButton = Button('zt-button--navigation');

export const LightButton = Button('zt-button--light');

export const LinkButton = ({ to, ...props }) => {
    const history = useHistory();
    return React.cloneElement(props.children, {onClick: () => history.push(to)});
}

export default Button();