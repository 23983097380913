import * as actions from './constants';

const defaultState = {
    item: null
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.WEBSHOP_SELECT_ITEM: {
            return {
                ...defaultState,
                item: payload ? {...payload} : null
            }
        }

        case actions.WEBSHOP_SELECT_ITEM_RESET: {
            return {
                ...defaultState
            }
        }

        default: {
            return state;
        }

    }
    
}