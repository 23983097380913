import React from 'react';
import { ChildrenTickets, OwnerTicket } from "../../Tickets";
import Actions from '../../Actions';

export default ({ item: event }) => {

    const { ticket_reservations: tickets } = event;

    const owner = tickets.find(t => t.is_child === false);
    const children = tickets.filter(t => t.is_child === true);

    return <div className='zt-tickets__group__event__tickets'>
        <OwnerTicket ticket={owner} />
        {!!children?.length && <ChildrenTickets tickets={children} />}
        <Actions {...{event_id: event?.id, tickets}} />
    </div>
}