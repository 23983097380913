import React, { useEffect, useState } from 'react';
import { Timer } from 'components/Icons/Glyphs';
import './style.scss';
import moment from 'moment';


const formattedTime = (seconds, t) => {
    
    if (+seconds > 86400) {
        return `${Math.floor(seconds/86400)} day(s)`;
    }

    if(+seconds > 0) {
        return moment().startOf('day').seconds(seconds)?.format(t('reserved.time_format', 'H:mm:ss'));
    }
    
    return '--';
}


export default ({ seconds, onDismiss, t, ...props }) => {

    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        const timer = setInterval(() => setTimeLeft(t => t > 0 ? t - 1 : (onDismiss && onDismiss()) && 0),  1000);
        return () => clearInterval(timer);
    }, []);

    const timeFormatted = formattedTime(timeLeft, t);

    return (
        timeLeft > 0 && <div className={'zt-nav zt-timer'}><Timer className={'zt-timer__icon'} /> {timeFormatted}</div>
    )
}