import React, { useState, useEffect, useRef } from 'react';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { ZoomIn, ZoomOut } from 'components/Icons/Glyphs';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useDispatch, useSelector } from 'react-redux';
import styleClasses from './styleClasses';
import useAddTicket from './useAddTicket';
import * as actions from '../actions';
import initSeats from './initSeats';
import ToolTip from './ToolTip';
import './style.scss';
// import selectGroup from './selectGroup';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import seatElement from './seatElement';
import useOnHover from './useOnHover';

const markSelected = (tickets, mapElement) => {
    const marked = mapElement?.querySelectorAll(`.${styleClasses['selected']}`);
    if (marked) {
        marked.forEach( element => element.classList.remove(styleClasses['selected']));
    }
    tickets.forEach(({ row, seat }) => seatElement(mapElement, row, seat)?.classList.add(styleClasses['selected']));
}


export default ({ event, ...props }) => {

    const [matrix, setMatrix] = useState(null);
    const [hover, setHover] = useState(null);
    const [group, setGroup] = useState(null);

    const { tickets, qty, grouped: groupMode, section } = useSelector(state => state.event.tickets);
    const selected = tickets.length;

    const { loading, prices: levels, error, seats: { map: mapSVG, data } } = useSelector(state => state.event.maps.seats);

    const dispatch = useDispatch();

    const mapRef = useRef();

    useEffect(() => {
        if (loading === false && mapSVG) {
            if (mapRef.current) {
                //Map to Object conversion function
                const fromMap = map => {
                    const obj = {}
                    for(let [k,v] of map) {
                        v instanceof Map 
                        ? obj[k] = fromMap(v)
                        : obj[k] = v
                    }
                    return obj
                }
                const matrix = initSeats(mapRef.current, levels, data);
                setMatrix(matrix);
                dispatch(actions.setMatrix(fromMap(matrix)));
            } else {
            }
        }
    },[mapRef, setMatrix, loading, mapSVG, levels, data]);

    const [debouncedCallback] = useDebouncedCallback(element => {cancelHide(); onHover(element)}, 300/* delay in ms */);    
    const [debouncedHide, cancelHide] = useDebouncedCallback(() => setHover(null), 700 /* delay in ms */);        

    const { addTicket, selectGroup } = useAddTicket({matrix});
    const onHover = useOnHover({matrix, mapRef, selectGroup, setHover, debouncedHide, setGroup});
 
    // const onError = (row, seat) => {
    //     mapRef.current.querySelectorAll(`.${styleClasses.error}`).forEach(element => element.classList.remove(styleClasses.error));
    //     const element = seatElement(mapRef.current, row, seat);
    //     alert(`Error: ${row} ${seat}`);
    //     // element.classList.add(styleClasses.error);
    // };
    
    const onError = seats => {
        mapRef.current.querySelectorAll(`.${styleClasses.error}`).forEach( element => element.classList.remove(styleClasses.error));
        seats.forEach(seatData => {
            const { row, seat} = seatData;
            const element = seatElement(mapRef.current, row, seat);
            element.classList.add(styleClasses.error);
        });
    }    



    const selectHovered = () => hover && addTicket(hover.row, hover.seat);

    const onSelect = element => {
        const seat = element?.dataset?.seat;
        console.log(`Select seat: ${seat}`);

        if (seat) {
            const row = element?.parentElement?.dataset?.row;
            console.log(`At row: ${row}`);
            console.log(`Qty: ${qty}, alrdy selected: ${selected}`);
            console.log(`Group mode: ${groupMode}`);
            addTicket(row, seat, onError);
        }    
    }

    useEffect(() => {
        if (hover) {
            mapRef.current.querySelectorAll(`.${styleClasses.error}`).forEach(element => element.classList.remove(styleClasses.error));
            const toolTip = document.getElementsByClassName('zt-seats-map__tooltip')[0]; //TODO: use ref here!
            // toolTip.
            if (toolTip) {
                toolTip.style.left = hover.coords.x + "px";
                toolTip.style.bottom = hover.coords.y + "px";
                toolTip.style.visibility = 'visible';
                toolTip.style.zIndex = 100;
            }
        }
    }, [hover])    

    useEffect(() => {
        if (mapRef.current) {
            markSelected(tickets, mapRef.current);
        }
    }, [tickets, mapRef]);

    const [init, setInit] = useState(false);

    if (loading) {
        return  <div className='zt-seats-map'><MiniLoader /></div>
    }

    if (error) {
        return <div className='zt-seats-map'>Error...</div>
    }

    return (
        <div className='zt-seats-map'>
            <div className="zt-seats-map__leaflet">
                <TransformWrapper centerOnInit centerZoomedOut limitToBounds={false} onInit={() => setTimeout(() => setInit(true), 100) /* hide centering move */}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                            <div className="zt-seats-map__controlls" >
                                <button type="button" className='zt-seats-map__controlls__button' onClick={() => zoomIn()}><ZoomIn /></button>
                                <button type="button" className='zt-seats-map__controlls__button' onClick={() => zoomOut()}><ZoomOut /></button>
                            </div>                       
                            <TransformComponent wrapperStyle={{width: 'unset', ...(init ? {} : {opacity: 0})}}>                
                                <div ref={mapRef} 
                                    className="zt-seats-map__leaflet__map" 
                                    onClick={e => onSelect(e?.target)} 
                                    onMouseOver={e => debouncedCallback(e?.target)}
                                    dangerouslySetInnerHTML={{__html: mapSVG}} 
                                />
                            </TransformComponent>
                        </>
                    )
                    }
                </TransformWrapper>
            </div>
            <ToolTip className='zt-seats-map__tooltip' seat={hover} group={groupMode ? group : null} onClick={e => selectHovered()}/>
        </div>    
    )
}