import React, { useEffect } from 'react';
import useEmailRecoveryRequest from './useEmailRecoveryRequest';
import { PrimaryButton } from 'components/buttons/Button';
import { ArrowRight } from 'components/Icons/Glyphs';
import Input from 'components/Inputs/Input'
import { useForm } from 'react-hook-form';
import * as formRules from 'config/forms';

export default ({ t, onSuccess, ...props }) => {
    const { register, handleSubmit, reset, watch, formState: { errors }, setValue, setError } = useForm({validateCriteriaMode: "all"});
    
    const { state: { loading, error, sent }, request } = useEmailRecoveryRequest();

    const onSubmit = ({ email }) => request(email); 
    const email = watch('email', false);
    const filled = (email !== false && email !== null && email !== '');

    useEffect(() => {
        if (error) {
            setError("email", {type: "notMatch", message: t('reset_error', 'Email not found')});
        }
    }, [error]);

    useEffect(() => {
        if (sent) {
            onSuccess && onSuccess(email);
        }
    }, [sent, email]);

    return <>
        <h4 className='bold zt-password-recovery__header'>{t('label', 'Reset password?')}</h4>
        <form onSubmit={handleSubmit(onSubmit)} >
            <Input
                autoFocus
                name="email"
                required
                {...register('email', {
                    ...formRules.email,
                    required: 'Required',
                    pattern: {
                        ...formRules.email.pattern,
                        message: t('inputs.email.error', 'invalid email address')
                }})}            
                label={t('inputs.email.label', 'Email:')}
                placeholder={t('inputs.email.placeholder', 'Enter login email')}
                description={t('inputs.email.description', 'Instructions on how to reset your password will be sent to your email address')}
                error={errors?.email?.message}
                onClear={() => reset()}
                disabled={loading}
            />
        </form>
        <PrimaryButton disabled={!filled} loading={loading} className='zt-password-recovery__button' onClick={handleSubmit(onSubmit)}>{t('buttons.reset_password', 'Reset password')} <ArrowRight /></PrimaryButton>
    </>
}