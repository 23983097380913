import React from 'react';

import './style.scss';

export default ({ steps, current=null, onSelect=null, ...props }) => <div className='zt-inputs-steps'>
<label >
    <ul className='zt-inputs--steps__progressbar'>
    { steps?.map( (step, id) => (
        <li className={`zt-inputs--steps__progressbar__step ${id === current ? 'zt-inputs--steps__progressbar__step--current' : (id < current ? `zt-inputs--steps__progressbar__step--passed ${(current-id === 1 ? 'zt-inputs--steps__progressbar__step--precurrent' : '')}` : '')}`} onClick={() => onSelect && onSelect(id)} key={id}>
            <span className='zt-inputs--steps__progressbar__step__number'>{id+1}</span><span className='zt-nav light zt-inputs--steps__progressbar__step__label'>{step}</span>
        </li>)
    )}
    </ul>
</label>
</div>