import React from "react";
import AppleStore from "components/buttons/stores/AppleStore";
import GooglePlay from "components/buttons/stores/GooglePlay";
import Banner from "components/Header/Banner";

export default props => {

    return <>
       <Banner />
       <div style={{display:'flex', flexDirection:'row', alignContent: 'center', justifyContent: 'center'}}>
            <AppleStore style={{margin: 8}} />
            <GooglePlay style={{margin: 8}} />
        </div>
    </>
}