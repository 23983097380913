import { useSelector } from "react-redux";

export const TYPE_DOCUMENT = "document";
export const TYPE_BANKID = "bankid";

export default function useIsBankIdProfileUser() {
    const { profile } = useSelector(state => state.profile);

    if (!profile) {
        return null;
    }

    if (profile.register_type === TYPE_BANKID) {
        return true;
    }
    
    return false;
}