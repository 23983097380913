import { useReducer } from "react";
import { reducer, defaultState } from "./reducer";
import * as actions from './actions';
import logger from "use-reducer-logger";

export default function useGetWebPay() {
    
    const [state, dispatch] = useReducer(logger(reducer), defaultState);

    const getWebPayGUI = actions.getGUI(dispatch);
    const reset = actions.reset(dispatch);

    return {
        state,
        getWebPayGUI,
        reset
    }
}