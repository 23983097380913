import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import EventInfoLine, {TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE} from 'components/EventInfo';
import useForgetForEvent from 'pages/Profile/components/ForgetForEvent/useForgetForEvent';
import { PrimaryButton } from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import Logotype from 'components/Logotype';
import FORMAT from 'config/date/format';
import moment from 'moment';

export default ({ t:tt, item, onForget }) => {

    const { t } = useTranslation('tickets');

    const { state: forgetState, proceedForget } = useForgetForEvent(item.id);

    const forgetMe = () => {
        proceedForget();
    }

    useEffect(() => {
        if (forgetState.forgotten) {
            onForget && onForget();
        }
    }, [forgetState.forgotten]);    

    return (
        <div className='zt-tickets__group__event'>
            <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="11">
                <Row>
                <Col xs md={8}>
                    <h2 >{item.name}</h2>
                    <div className='zt-p1 mb-4'>{item.performer_name}</div>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION} card>{item?.map?.venue?.name || '--'}</EventInfoLine>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>{t('date', {date: moment(item.start_date, FORMAT)})}</EventInfoLine>  
                </Col>
                <Col className='d-none d-lg-block' lg={4}>
                    <div className="float-right">
                        <div className='zt-webshop__event__media'>
                            {item.thumbnail ?
                            <img alt={item.name} className='zt-webshop__event__media__image' src={`${item.thumbnail}`} />
                            :
                            <Logotype className='zt-webshop__event__media__logotype'/>
                            }    
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className='zt-tickets__group__event__actions'>
                    <PrimaryButton onClick={() => forgetMe()}>{tt('button', 'Forget me')}</PrimaryButton>
                </div>
            </Row>
            </Col>
            </Row>
            </Container>
        </div>
    );
}