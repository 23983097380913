import React from "react";
import { useTranslation } from "react-i18next";
import FORMAT from "config/date/format";
import moment from "moment";

export default ({ item }) => {
    const { t } = useTranslation('event');
    
    const dateStart=item?.start_date;
    const dateEnd=item?.end_date;

    const mdateStart = dateStart ? moment(dateStart, FORMAT) : null;
    const mdateEnd = dateEnd ? moment(dateEnd, FORMAT) : null;

    return (
        t('season.date_range', {defaultValue: '{{start, MMMM D\, YYYY}} - {{end, MMMM D\, YYYY}}', start: mdateStart || '...', end: mdateEnd || '...'})
    ); 
}