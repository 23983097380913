import { SEARCH_FRIEND_TO_PICKUP_KIDS, SEARCH_FRIEND_TO_SELL_TICKET } from "./modes";
import { ERROR_USER_NOT_APPLICABLE, ERROR_USER_NOT_FOUND } from "./store/reducer";

export default function useError(tt, mode, friend, checked, error) {

    if (!friend && checked && error === ERROR_USER_NOT_FOUND) {
        return tt('not_found', 'The person is not found in ZipperTic. Please tell your friend to create a ZipperTic account.');
    }
    if (error === ERROR_USER_NOT_APPLICABLE)  {
        return {
            [SEARCH_FRIEND_TO_PICKUP_KIDS]: tt('no_ticket', 'The person has no ticket for this event'),
            [SEARCH_FRIEND_TO_SELL_TICKET]: tt('already_has_ticket', 'The person already has ticket for this event')
        }[mode] || 'Unknown error';
    }

    return null;
}