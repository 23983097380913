import * as constants from './constants';
import api from './api';
import { ALL } from './../selected/actions';

const validateRefundFlow = {
    start: () => ({type: constants.VALIDATE_SELLITEMS_START}),
    success: payload => ({type: constants.VALIDATE_SELLITEMS_SUCCESS, payload}),
    failure: error => ({type: constants.VALIDATE_SELLITEMS_FAILURE, error})
}

export const validateRefund = (eventId, item) => {
    
    if (!item && item !== ALL) {
        throw 'No information about item or items provided for validate';
    }

    return (dispatch, getState) => {
        dispatch(validateRefundFlow.start());
        if (!+eventId) {
            throw 'No tickets or event selected (event id is not defined)';
        }

        let params = null;
        if ( item !== ALL ) { //If ALL items - no parameters
            params = {id: item.id, optionId: item.optionId, merchandise_order_id: item.orderId, quantity: item.qty || 1};
        }

        api.validate(eventId, params)
            .then( response => dispatch(validateRefundFlow.success(response.data || null)))
            .catch( error => dispatch(validateRefundFlow.failure(error)));
    };
}
