import React, { useEffect, useState } from 'react';
import Divider from 'components/Divider';
import CheckBox from 'components/Inputs/CheckBox';
import { PrimaryButton } from 'components/buttons/Button';
import usePrivacyModeUpdate from './usePrivacyModeUpdate';
import { useSelector } from 'react-redux';

export default ({ t, refresh, ...props }) => {

    const { profile: {privacy_mode = false} } = useSelector(state => state.profile);
    const [privacy, setPrivacy] = useState(false);

    const { state: {loading, saved, error}, proceedUpdate, reset } = usePrivacyModeUpdate();

    const disabled = +privacy_mode === +privacy;

    useEffect(() => {
        setPrivacy(privacy_mode);
    }, [privacy_mode]);

    const save = () => proceedUpdate(privacy);

    useEffect(() => {
        if (!saved) {
            return;
        }
        refresh();
        reset();
    }, [saved]);

    return (
        <div className='zt-profile__agreements'>
             <h4 className='zt-profile__label'>{t('agreements.label', 'Agreements and Subscriptions')}</h4>
             {/* <CheckBox value={true}>{t('agreements.checkboxes.send_news', 'Send to me news and notifications about the events in ZipperTic')}</CheckBox> */}
             {/* <CheckBox value={true}>{t('agreements.checkboxes.hide_my_name', 'Hide my name and data in ZipperTic')}</CheckBox> */}
             <CheckBox value={privacy} disabled={loading} onChange={e => setPrivacy(e.target.checked)}>{t('agreements.checkboxes.privacy_mode.label', 'Privacy Mode')}</CheckBox>
             <div className='zt-p3 zt-profile__description' style={{marginBottom: 16}}>{t('agreements.checkboxes.privacy_mode.description', 'In privacy mode, you can not be found on ZipperTic. However, when YOU buy or reserve tickets for your friends, your name will always be displayed to your friends in notifications.')}</div>
             <PrimaryButton {...{disabled, loading}} onClick={() => save()}>{t('agreements.button', 'Apply Changes')}</PrimaryButton>
             <Divider />
        </div>
    )
}