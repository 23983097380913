import api from 'api';

const registerUrl = 'api/auth/register';

export default {
    register: data => api.post({
        path: registerUrl, 
        params: (({name, password_confirm, ...data}) => ({
            ...data, 
            password_confirmation: data.password, 
            name: `${data.given_name} ${data.family_name}`
        }))(data)
    }),
}
