import React from 'react';
import BankID from 'pages/Registration/methods/BankID';
import { CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './style.scss';

export const path = '/coreid';
export const link = path;

export default props => {

	const history = useHistory();

	return (
		<CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
			<div className='zt-profile__coreid'>
				<Container>
					<Row className="justify-content-md-center">
						<Col xs md={8} lg={6}>
							<BankID onSuccess={() => history.push('/profile')} {...{backPath: '/profile'}} />
						</Col>
					</Row>
				</Container>
			</div>
		</CSSTransition>
	);
}