import React, { useState } from 'react';
import { Expand as ExpandIcon, Collapse as CollapseIcon } from 'components/Icons/Glyphs';
import { Collapse } from 'react-bootstrap';
import './style.scss'
import { useSelector, useDispatch } from 'react-redux';
// import * as actions from './../../actions';

const Control = ({ state, ...props }) => <div className='zt-help__article__header__control'>{state ? <CollapseIcon /> : <ExpandIcon />}</div>

export default ({ item, opened, onOpen: openCurrent, ...props }) => {

    return (
    <div className='zt-help__article'>
        <div onClick={() => openCurrent()} className='zt-help__article__header'>
            <h4 className={`${opened ? 'bold' : 'light'}`}>{item.title}</h4>
            <Control state={opened}/>
        </div>
        <Collapse in={opened}>
            <div className='zt-p1 zt-help__article__content' dangerouslySetInnerHTML={{__html:item.description}} />
        </Collapse>
    </div>
    );
}