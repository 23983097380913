import * as constants from "./constants";
import api from "./api";

const flow = {
    start: () => ({type: constants.RESETREQUEST_START}),
    success: payload => ({type: constants.RESETREQUEST_SUCCESS, payload }),
    failure: error => ({type: constants.RESETREQUEST_FAILURE, error})
}

export const request = dispatch => email => {
    dispatch(flow.start());
    api.request(email)
        .then(response => dispatch(flow.success(response?.data)))
        .catch(error => dispatch(flow.failure(error)));
}
