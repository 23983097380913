import * as constants from './constants';
import api from './api';

export const flow = {
    start: () => ({type: constants.FETCH_NOTIFICATIONS_START}),
    success: payload => ({type: constants.FETCH_NOTIFICATIONS_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_NOTIFICATIONS_FAILURE, error})
}

export const getNotifications = () => {
    return dispatch => {
        dispatch(flow.start());
        // setTimeout(() => dispatch(flow.success(data)), 5000);
        api.get()
            .then(data => dispatch(flow.success(data?.data)))
            .catch(error => (console.log(error),dispatch(flow.failure('Network or server error'))))
    }
}

export const select = id => ({type: constants.SELECT_NOTIFICATIONS_ITEM, payload: id});
export const unselect = () => ({type: constants.SELECT_NOTIFICATIONS_ITEM, payload: null});