import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Panel from "components/Panel";
import { ClearIcon } from "components/Icons/Glyphs";
import { PrimaryButton } from "components/buttons/Button";
import useVoidReservation from "./useVoidReservation";
import { useTranslation } from "react-i18next";

export default ({ onClose, onContinue, id }) => {

    const { state: { processing, error, done }, voidReservation } = useVoidReservation();
    
    const { t } = useTranslation('event');

    useEffect(() => {
        if (done) {
            onContinue && onContinue();
        }
    }, [done]);

    const onVoid = () => voidReservation(id);

    return <Modal show={true} size="md" centered dialogClassName='zt-modal-small' className='zt-modal-centered' onHide={() => onClose && onClose()}>
        <Panel>
            <ClearIcon className='zt-document-samples__closeicon' onClick={onClose} />
            <div className="zt-p4" style={{marginTop: 16, maringBottom: 16}}>{t('active_reservation.description', "You have an incomplete reservation already. Pressing \"continue\" will enable you to start over.")}</div>
            <br/>
            <center>
                <PrimaryButton onClick={onVoid} loading={processing}>{t('active_reservation.button', "Continue")}</PrimaryButton>
            </center>
        </Panel>
    </Modal>;
}