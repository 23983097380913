import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default ({ left, onMore }) => {
    const [t] = useTranslation('home');

    return (
        <Row className="eventsfooter">
            <Col className="d-flex flex-column justify-content-start align-items-center">
                <span className='zt-p3'>{t('eventslist.n_are_not_loaded', {count: left})}</span>
                <button className='btn' onClick={onMore}><span className="zt-nav light">{t('eventslist.load_more', 'Load more events')}</span></button>
            </Col>
        </Row>);
}