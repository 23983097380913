import useCartItems from './useCartItems';

export default function useCartItemsCount() {
    const items = useCartItems();
    if (!items?.length) {
        return 0;
    }
    let qty = 0;
    items.forEach(i => qty+=i.qty)
    return qty;
}