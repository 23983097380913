import React, { useEffect } from 'react';
import RegisterWithMobileBankID from './components/RegisterWithMobileBankID';
import Loading from 'pages/WebShop/pages/List/components/Loading';
import CoreId, { link as coreIdBaseLink } from './pages/CoreId';
import useIsBankIdProfileUser from './useIsBankIdProfileUser';
import ManageDocument from './components/ManageDocument';
import ForgetForEvent from './components/ForgetForEvent';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Row, Col, Container } from 'react-bootstrap';
import PersonalData from './components/PersonalData';
import Agreements from './components/Agreements';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Header/Banner';
import Contacts from './components/Contacts';
import Security from './components/Security';
import { Route } from 'react-router-dom';
import Forget from './components/Forget';
import * as actions from './actions';
import './style.scss';

export const path = '/profile';
export const link = path;

export const coreIdLink = link + coreIdBaseLink;

const Profile = props => {
    
    const { t } = useTranslation("profile");
    const { loading, profile } = useSelector(state => state.profile);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getProfile());
    }, [dispatch])

    const refresh = updated => {
        if (!!updated) {
            dispatch(actions.update(updated));
            return;
        }
        dispatch(actions.getProfile());
    }
    const bankIdUser = useIsBankIdProfileUser();

    if (loading) {
        return <Loading />
    }    

    const isSwedenUser = profile?.country === 'SE';

    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
            <div className='zt-profile'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs md={8} lg={6}>
                            <h4 className='zt-profile__title'>{t('label')}</h4>
                            {!bankIdUser && <RegisterWithMobileBankID t={t}/>}
                            {!bankIdUser && !isSwedenUser && <ManageDocument {...{t, refresh}} />}
                            <Contacts {...{t, refresh}} />
                            {!bankIdUser && <Security {...{t, refresh}} />}
                            <PersonalData {...{t, isSwedenUser}} />
                            <Agreements {...{t, refresh}} />
                            <ForgetForEvent {...{t}} />
                            <Forget {...{t}} />
                            <Banner />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    );
}

export default props => {

    const { match: { url } } = props;


    return (
        <>
            <Route path={`${url}/`} component={Profile} exact />
            <Route path={`${url}${coreIdBaseLink}`} component={CoreId} exact />
        </>
    )
}