import { useDispatch, useSelector } from "react-redux";
import * as ticketsActions from '../../store/actions';
import styleClasses from './styleClasses';
import seatElement from './seatElement';
import useSelectSeatRules from "./useSelectSeatRules";
import useUnselectSeatRules from "./useUnselectSeatRules";

// onErrors = seats => {
//     mapRef.current.querySelectorAll(`.${styleClasses.error}`).forEach( element => element.classList.remove(styleClasses.error));
//     seats.forEach( seat => {
//         const element = seatElement(mapRef.current, row, seat);
//         element.classList.add(styleClasses.error);
//     });
// }

const DIR_LEFT = -1;
const DIR_RIGHT = 1;

export default function useAddTicket({ matrix }) {
    const tickets = useSelector(state => state.event.tickets.tickets);
    const selected =  tickets?.length || 0; //QTY of selected tickets
    const { qty, grouped: groupMode } = useSelector(state => state.event.tickets);
    const { matrix: matrixObj } = useSelector(state => state.event.maps.seats.seats);
    const dispatch = useDispatch();

    const isValid = useSelectSeatRules(matrix, tickets);
    const isValidToRemove = useUnselectSeatRules(matrixObj, tickets);

    const selectGroup = ({row, seat}, onError) => {
        const group = [seat];

        let blockedDir = null;
        let dir = DIR_RIGHT;
        let leftPosition = 0;
        let rightPosition = 0;

        const rowMap = matrix.get(row);
        const seats = Array.from(rowMap.keys());
        const seatIndex = seats.indexOf(seat);

        const next = dir => {
            switch(dir) {
                case DIR_LEFT: {
                    leftPosition--;
                    return seats[seatIndex + leftPosition];
                }
                case DIR_RIGHT: {
                    rightPosition++;
                    return seats[seatIndex + rightPosition];
                    break;
                }
            }
        }

        const isVacant = seat => !!rowMap.get(seat);

        if (!isVacant(seat)) {
            return onError && onError([{row, seat}]);
        }                

        for (let i = 0; i < qty-1; i++) {
            const seat = next(dir);
            const valid = !!seat ? isVacant(seat) : false;

            if (valid) {
                group.push(seat);
                if (!blockedDir) {
                    dir = -1 * dir;
                }
                continue;
            }

            if (!blockedDir) {
                blockedDir = dir; 
                dir = -1*dir; 
                i--;
                continue;
            }

            break;
        }

        group.sort((a,b) => seats.indexOf(a) - seats.indexOf(b));
        return group;
    }

    const addTicketGroup = (row, seat, onError) => {
        const group = selectGroup({row, seat}, onError);

        if (!group) {
            return;
        }
        
        const selected = group.map(s => ({row, seat:s}));

        if (!isValid(row, group[0], selected)){
            console.log(`start on ${seat} FAIL ON Left EDGE: seat: ${group[0]}`);
            onError && onError(selected);
            return;

        }
        
        if (!isValid(row, group[group.length-1], selected)) {
            console.log(`FAIL ON Right EDGE: ${group[group.length-1]}`);
            onError && onError(selected);
            return;
        }

        if (group?.length === qty ) {
            //Second select of the group unselect it
            if (tickets.filter(t => t.row === row).filter(t => group.includes(t.seat)).length === qty) {
                dispatch(ticketsActions.resetTickets());
                return; 
            }
            //Else - move the group to a new place
            dispatch(ticketsActions.resetTickets());
            group.forEach( seat =>  dispatch(ticketsActions.addTicket({row, seat}))); 
            return;
        } 

        onError([{row, seat}]);
    }


    const addTicket = (row, seat, onError) => {

        const seatsMap = matrix.get(row);

        if (!seatsMap) {
            return;
        }

        //Check if it is already selected
        console.log(`search for ${row} and ${seat}`);
        const existIndex = tickets.findIndex(t => t.row === row && t.seat === seat);
        console.log(existIndex);     
        
        if (existIndex !== -1) { 
            //Deselect
            console.log('Already exist - deselect');
            if (!isValidToRemove(row, seat)) {
                onError && onError([{row, seat}]);
                return;
            } 
            dispatch(ticketsActions.removeTicket(existIndex)); //
            return;
        }        

        const valid = isValid(row, seat, qty === 1? [] : tickets);

        if (!valid) {
            onError && onError([{row, seat}]);
            return;
        }
        
        if (qty === 1) {
            //If it is one ticket - just move it to a new position
            dispatch(ticketsActions.resetTickets());
            dispatch(ticketsActions.addTicket({row, seat}));
            return;   
        }

        if (selected < qty) {
                //New ticket - add it;
                console.log(`adding ${row} and ${seat}`);
                dispatch(ticketsActions.addTicket({row, seat}));
                return;
        } 
          
        //Error here 
        onError && onError([{row, seat}]);
        
    }

    return {
        addTicket: groupMode ? addTicketGroup : addTicket,
        selectGroup
    }
}