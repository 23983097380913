import * as actions from './constants';

export const defaultState = {
    
    info: null,

    loading: false,
    error: null,
    validated: false,
    updated: false
}

export function reducer(state={}, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.UPDATE_RESERVEDTICKET_START: {
            return {
                ...state,
                loading: true
            }
        }

        case actions.UPDATE_RESERVEDTICKET_SUCCESS: {
            const info = payload;
            return {
                ...defaultState,
                info,
                loading: false,
                updated: true,
                validated: true,
                error: null
            }
        }

        case actions.UPDATE_RESERVEDTICKET_FAILURE: {
            return {
                ...state,
                loading: false,
                error
            }
        }

        case actions.VALIDATE_RESERVEDTICKET_START: {
            return {
                ...defaultState,
                loading: true
            }
        }

        case actions.VALIDATE_RESERVEDTICKET_SUCCESS: {
            const info = payload;
            return {
                ...defaultState,
                info,
                validated: true
            }
        }

        case actions.VALIDATE_RESERVEDTICKET_FAILURE: {
            if (error?.response?.status === 406) {

                const info = error?.response?.data;

                return {
                    ...defaultState,
                    info,
                    validated: true,
                    error
                }
            }

            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }
    }
}

