import React, { useEffect } from 'react'
import Loading from 'pages/WebShop/pages/List/components/Loading';
import Error from 'pages/WebShop/pages/List/components/Error';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentUserId from 'utils/useCurrentUserId';
import Notification from './components/Notification';
import * as actions from './../../store/actions';
import { useTranslation } from 'react-i18next';
import NoItems from './components/NoItems';

export default props => {

    const { t } = useTranslation('notifications');

    const { items, loading, error, selected } = useSelector(state => state.notifications);
    const dispatch = useDispatch();
    const userId = useCurrentUserId(); 
    const refresh = () => dispatch(actions.getNotifications());    

    useEffect(() => {
        refresh();
    }, []);


    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error onRetry={() => refresh()} />
    }

    if (!items || !+items.length) {
        return <NoItems />
    }    

    return (
    <>
        {items.map((item, key) => <Notification {...{item, key, t, last: key === items?.length -1}} />)}
    </>
    );
}
