import React, { useEffect, useState } from 'react';
import useBankId, { STEP_PREPARE, STEP_REGISTER_USER, STEP_AUTHORISE_USER, STEP_START_COREIDAUTH, STEP_VALIDATE_USER } from './useBankId';
import Loading from 'pages/WebShop/pages/List/components/Loading';
import * as userActions from './../../SelectMethod/state/actions';
import { SecondaryButton } from 'components/buttons/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { path as parentPath } from 'pages/Registration';
import { useDispatch, useSelector } from 'react-redux';
import Authorised from './components/Authorised';
import Registered from './components/Registered';
import { useTranslation } from 'react-i18next';
import RegisterUser from './RegisterUser';
import Page from './components/Page';
import './style.scss';

export const path = '/bankid';
export const link = path;
export const loginlink = `${path}/login`;

export default ({ onSuccess = null, backPath = parentPath })=> {

    const location = useLocation();
    const login = location.pathname.split('/').slice(-1)?.[0] === 'login'; //Is it a login using bankid url page?

    const { t } = useTranslation('registration');
    const history = useHistory();
    const { step, token, error, coreid_error, coreid_loaded, loading, reset } = useBankId({onError: () => history.push(login ? '/' : backPath)});
    const { email, phone } = useSelector(state => state.register.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!onSuccess) {
            return;
        }
        if (step === STEP_AUTHORISE_USER) {
            onSuccess();
        }
    }, [step, onSuccess]);

    useEffect(() => {
        
        if (login) {
            return;
        }

        if (!email || !phone) {
            history.push(backPath);
        }

    }, [email, phone, login, history, backPath]);    

    if (coreid_error || error) {
        return <Page t={t}>
            {t('coreid.error', 'Something is going wrong... Please, contact support or try again later.')}
        </Page>
    }

    return (
        <Page form={[STEP_REGISTER_USER, STEP_AUTHORISE_USER].includes(step)} {...{t, login}}>
            {/* <div className='diag'>
                <p>{`step: ${step} token: ${token?.substring(0,10)}.. coreid loaded: ${coreid_loaded}`}</p>
                <SecondaryButton onClick={() => reset()} >Reset</SecondaryButton>
            </div> */}
            { 
                {
                [STEP_PREPARE]: <Loading />,
                [STEP_START_COREIDAUTH]: <Loading />,
                [STEP_VALIDATE_USER]: <Loading />,
                [STEP_REGISTER_USER]: <RegisterUser {...{t, onError: error => {
                    dispatch(userActions.error(error));
                    history.push(backPath);
                }}} />,
                [STEP_AUTHORISE_USER]: (login ? <Authorised {...{t, history, login}} /> : <Registered {...{t, history}} />)
                }[step]
            }
        </Page>
    );
}