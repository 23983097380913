import * as actions from './constants';

export const defaultState = {
    processing: false,
    error: null,
    done: false
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch(type) {

        case actions.VOID_ORDER_START: {
            return {
                ...defaultState,
                processing: true
            }
        }

        case actions.VOID_ORDER_SUCCESS: {
            return {
                ...defaultState,
                done: true,
            }
        }

        case actions.VOID_ORDER_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }
    }
}