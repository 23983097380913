import * as constants from './constants';
import api from './api';

const flow = {
    start: payload => ({type: constants.DO_ACCEPTRESERVATION_START, payload}),
    success: payload => ({type: constants.DO_ACCEPTRESERVATION_SUCCESS, payload}),
    failure: error => ({type: constants.DO_ACCEPTRESERVATION_FAILURE, error})
}

export const doAccept = dispatch => ticketId => {
    dispatch(flow.start());
    api.accept(ticketId)
        .then(response => dispatch(flow.success(constants.ACCEPTED)))
        .catch(error => dispatch(flow.failure(error)));        
}


export const doDecline = dispatch => ticketId => {
    dispatch(flow.start());
    api.decline(ticketId)
        .then(response => dispatch(flow.success(constants.DECLINED)))
        .catch(error => dispatch(flow.failure(error)));        
}