import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function useResetToken() {
    const { search } = useLocation();
    const { token } = useParams();
    const { email } = queryString.parse(search);
    const isValid = (!!token && !!email); //we have not empty token & email in the url

    return { isValid, token, email };
}