import React from 'react';

export const DropdownIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M4 9L8 13L12 9" strokeLinejoin="round"/>
</svg>;

export const ClearIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 15.5L12.5 6.5" strokeLinejoin="round"/>
    <path d="M12.5 15.5L3.5 6.5" strokeLinejoin="round"/>
</svg>

export const StarIcon = props => <svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 11.6702L4.8855 14.6631L6.46044 9.82515L2.3426 6.83688L7.43047 6.83972L9 2L10.5695 6.83972L15.6574 6.83688L11.5396 9.82515L13.1145 14.6631L9 11.6702Z" />
</svg>;

export const UserIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="3.5" width="15" height="15" rx="7.5" />
    <path d="M11 9.5C11 11.1569 9.65685 12.5 8 12.5C6.34315 12.5 5 11.1569 5 9.5C5 7.84315 6.34315 6.5 8 6.5C9.65685 6.5 11 7.84315 11 9.5Z" />
    <path d="M3 16C4 15 5.5669 14 8 14C11 14 12.5 15.5 13 16" />
</svg>

export const ExportIcon = props => (<svg width='16' height='22' viewBox='0 0 16 22' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.5 6.5H14' />
    <path d='M2 6.5H4.5' />
    <path d='M11.5 10.5H14' />
    <path d='M2 10.5H7.5' />
    <path d='M9.5 14.5H14' />
    <path d='M2 14.5H5.5' />
    <rect x='5.5' y='5.5' width='2' height='2' rx='0.5' />
    <rect x='8.5' y='9.5' width='2' height='2' rx='0.5' />
    <rect x='6.5' y='13.5' width='2' height='2' rx='0.5' />
</svg>)

export const SettingsIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="6.5" y="4.5" width="3" height="3" rx="1.5" />
    <rect x="6.5" y="9.5" width="3" height="3" rx="1.5" />
    <rect x="6.5" y="14.5" width="3" height="3" rx="1.5" />
</svg>;

export const CityIcon = props=> <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 18.5H15" />
    <path d="M2.5 18.5V8C2.5 7.72386 2.72386 7.5 3 7.5H8C8.27614 7.5 8.5 7.72386 8.5 8V11.5" />
    <path d="M13.5 18.5V4C13.5 3.72386 13.2761 3.5 13 3.5H11C10.7239 3.5 10.5 3.72386 10.5 4V12.5" />
    <path d="M5.5 18.5V15C5.5 14.7239 5.72386 14.5 6 14.5H11C11.2761 14.5 11.5 14.7239 11.5 15V18.5" />
    <path d="M3.5 7.5V6" />
    <path d="M5.5 7.5V6" />
    <path d="M7.5 7.5V6" />
    <path d="M11.5 15.5V14.7676C11.5 14.6004 11.4164 14.4443 11.2774 14.3516L8.77735 12.6849C8.6094 12.5729 8.3906 12.5729 8.22265 12.6849L5.72265 14.3516C5.58355 14.4443 5.5 14.6004 5.5 14.7676V15.5" />
    <path d="M4 9.5H7" />
    <path d="M4 11.5H7" />
    <path d="M8.5 18.5V16" />
    <path d="M4 13.5H5" />
    <path d="M4 15.5H4.5" />
</svg>;

export const CalendarIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="4.5" width="15" height="14" rx="2.5" />
    <path d="M4.5 5V3" />
    <path d="M4.5 7V6" />
    <path d="M11.5 7V6" />
    <path d="M11.5 5V3" />
    <path d="M3 11.5H4" />
    <path d="M3 9.5H4" />
    <path d="M5 11.5H7" />
    <path d="M5 9.5H7" />
    <path d="M8 11.5H10" />
    <path d="M8 9.5H10" />
    <path d="M11 11.5H13" />
    <path d="M11 9.5H13" />
    <path d="M3 13.5H4" />
    <path d="M5 13.5H7" />
    <path d="M8 13.5H10" />
    <path d="M11 13.5H13" />
    <path d="M3 15.5H4" />
    <path d="M5 15.5H7" />
    <path d="M8 15.5H10" />
    <path d="M11 15.5H13" />
</svg>;

export const SearchIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="4.5" width="11" height="11" rx="5.5" />
    <path d="M11 14L15.5 18.5"/>
</svg>

export const LocationIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5 9.93333C14.5 12.3828 12.8841 14.5237 11.1626 16.0977C10.312 16.8753 9.45923 17.4928 8.81824 17.9161C8.49823 18.1274 8.23227 18.2895 8.04743 18.3981C8.03097 18.4078 8.01515 18.417 8 18.4259C7.98485 18.417 7.96904 18.4078 7.95258 18.3981C7.76773 18.2895 7.50177 18.1274 7.18176 17.9161C6.54077 17.4928 5.68799 16.8753 4.83738 16.0977C3.11591 14.5237 1.5 12.3828 1.5 9.93333C1.5 6.3848 4.40563 3.5 8 3.5C11.5944 3.5 14.5 6.3848 14.5 9.93333Z" />
    <path d="M11 9.5C11 11.1569 9.65685 12.5 8 12.5C6.34315 12.5 5 11.1569 5 9.5C5 7.84315 6.34315 6.5 8 6.5C9.65685 6.5 11 7.84315 11 9.5Z" />
</svg>

export const PortfolioIcon = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 6.5C0.5 5.94772 0.947715 5.5 1.5 5.5H14.5C15.0523 5.5 15.5 5.94772 15.5 6.5V11.0586L8 11.9961L0.5 11.0586V6.5Z" />
    <path d="M15 11.5V17C15 17.8284 14.3284 18.5 13.5 18.5H2.5C1.67157 18.5 1 17.8284 1 17V11.5" />
    <path d="M5.5 5V5C5.5 4.53406 5.5 4.30109 5.57612 4.11732C5.67761 3.87229 5.87229 3.67761 6.11732 3.57612C6.30109 3.5 6.53406 3.5 7 3.5H9C9.46594 3.5 9.69891 3.5 9.88268 3.57612C10.1277 3.67761 10.3224 3.87229 10.4239 4.11732C10.5 4.30109 10.5 4.53406 10.5 5V5" />
    <path d="M6.5 11.5V12.5C6.5 12.9659 6.5 13.1989 6.57612 13.3827C6.67761 13.6277 6.87229 13.8224 7.11732 13.9239C7.30109 14 7.53406 14 8 14V14C8.46594 14 8.69891 14 8.88268 13.9239C9.12771 13.8224 9.32239 13.6277 9.42388 13.3827C9.5 13.1989 9.5 12.9659 9.5 12.5V11.5" />
</svg>

export const Theatre = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M8.42046 13.3219C8.3333 14.3181 9.0431 15.384 10.2385 15.4886C11.434 15.5932 12.3181 14.6667 12.4052 13.6705M8.14025 10.7878L9.63454 10.9186M11.6269 11.0929L13.1212 11.2236M14.8334 8.86384C14.601 11.5204 14.5545 12.0517 14.4848 12.8486C14.2669 15.3391 12.5547 17.6989 10.0642 17.481C7.57374 17.2631 6.29734 14.6419 6.51523 12.1514C6.68954 10.159 6.67501 10.325 6.86385 8.1666C6.86385 8.1666 9.33174 7.37869 10.9358 7.51903C12.5398 7.65936 14.8334 8.86384 14.8334 8.86384Z"/>
    <path d="M9.34717 7.65739C9.27745 6.86043 9.3617 7.82342 9.12928 5.1669C9.12928 5.1669 6.6614 4.37899 5.05735 4.51933C3.4533 4.65966 1.15973 5.86415 1.15973 5.86415C1.34856 8.02257 1.33404 7.85653 1.50835 9.84892C1.72624 12.3394 3.08208 14.8886 5.57257 14.6707M3.81262 12.3279C3.72546 11.3317 4.38485 10.6011 5.58028 10.4965M2.87191 8.2239L4.3662 8.09316"/>
</svg>

export const Music = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 15.589C1.5 14.628 2.1948 13.8078 3.14278 13.6498L5.31704 13.2874C5.59968 13.2403 5.7845 13.2098 5.92648 13.1969C6.06487 13.1843 6.11681 13.1937 6.13946 13.2003C6.27597 13.24 6.38915 13.3358 6.4507 13.464C6.46091 13.4852 6.47872 13.5349 6.48906 13.6735C6.49967 13.8157 6.5 14.003 6.5 14.2895V14.9586C6.5 15.3745 6.49878 15.5316 6.48027 15.6624C6.37238 16.4249 5.83631 17.0577 5.10195 17.2894C4.97596 17.3292 4.82124 17.3562 4.411 17.4246L3.78919 17.5282C2.59086 17.728 1.5 16.8039 1.5 15.589Z" />
    <path d="M6.5 15.5V6.30217C6.5 5.83347 6.82553 5.42766 7.28307 5.32598L14.2831 3.77043C14.9076 3.63165 15.5 4.10687 15.5 4.74662V13.5" />
    <path d="M10.5 13.589C10.5 12.628 11.1948 11.8078 12.1428 11.6498L14.317 11.2874C14.5997 11.2403 14.7845 11.2098 14.9265 11.1969C15.0649 11.1843 15.1168 11.1937 15.1395 11.2003C15.276 11.24 15.3891 11.3358 15.4507 11.464C15.4609 11.4852 15.4787 11.5349 15.4891 11.6735C15.4997 11.8157 15.5 12.003 15.5 12.2895V12.9586C15.5 13.3745 15.4988 13.5316 15.4803 13.6624C15.3724 14.4249 14.8363 15.0577 14.1019 15.2894C13.976 15.3292 13.8212 15.3562 13.411 15.4246L12.7892 15.5282C11.5909 15.728 10.5 14.8039 10.5 13.589Z" />
    <path d="M6.5 8.5L15.5 6.5" />
</svg>

export const Sport = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5001 11C15.5001 15.1421 12.1423 18.5 8.00014 18.5C3.858 18.5 0.500137 15.1421 0.500137 11C0.500137 6.85786 3.858 3.5 8.00014 3.5C12.1423 3.5 15.5001 6.85786 15.5001 11Z" />
    <path d="M1 11H15" strokeLinecap="round"/>
    <path d="M3.5 5.5C3.5 5.5 5 7.5 8 7.5C11 7.5 12.5 5.5 12.5 5.5" strokeLinecap="round"/>
    <path d="M12.5 16.5C12.5 16.5 11 14.5 8 14.5C5 14.5 3.5 16.5 3.5 16.5" strokeLinecap="round"/>
</svg>

export const ArrowLeft = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2 11H16" />
    <path d="M9 4L2 11L9 18" />
</svg>

export const ArrowRight = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0 11H14"/>
    <path d="M7 4L14 11L7 18"/>
</svg>

export const SignOut = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M6 11H16" />
    <path d="M9 8L6 11L9 14" />
    <path d="M12.5 9V4.93207C12.5 4.42566 12.5 4.17245 12.4056 3.97088C12.3224 3.79331 12.1889 3.64411 12.0216 3.54184C11.8317 3.42574 11.58 3.39778 11.0767 3.34185L5.27669 2.69741C4.66176 2.62908 4.3543 2.59492 4.11632 2.6938C3.90731 2.78064 3.73394 2.93581 3.62455 3.13395C3.5 3.35956 3.5 3.66891 3.5 4.28762V17.7124C3.5 18.3311 3.5 18.6404 3.62455 18.866C3.73394 19.0642 3.90731 19.2194 4.11632 19.3062C4.3543 19.4051 4.66176 19.3709 5.27669 19.3026L11.0767 18.6581C11.58 18.6022 11.8317 18.5743 12.0216 18.4582C12.1889 18.3559 12.3224 18.2067 12.4056 18.0291C12.5 17.8275 12.5 17.5743 12.5 17.0679V13" />
</svg>

export const WebShop = props => <svg width="18" height="22" viewBox="0 0 18 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 10V17.5C2.5 18.0523 2.94772 18.5 3.5 18.5H14.5C15.0523 18.5 15.5 18.0523 15.5 17.5V10"  strokeLinecap="round"/>
    <path d="M5.5 15.5V12.5C5.5 11.9477 5.94772 11.5 6.5 11.5H11.5C12.0523 11.5 12.5 11.9477 12.5 12.5V15.5H5.5Z" />
    <path d="M0.978124 7.735L2.70625 4.97C2.88899 4.67762 3.20946 4.5 3.55425 4.5H14.4458C14.7905 4.5 15.111 4.67762 15.2937 4.97L17.0219 7.735C17.23 8.06802 16.9906 8.5 16.5979 8.5H1.40212C1.00941 8.5 0.769984 8.06802 0.978124 7.735Z" />
    <path d="M4.5 8.5C4.5 9.32843 3.82843 10 3 10C2.17157 10 1.5 9.32843 1.5 8.5"  strokeLinecap="round"/>
    <path d="M7.5 8.5C7.5 9.32843 6.82843 10 6 10C5.17157 10 4.5 9.32843 4.5 8.5" />
    <path d="M10.5 8.5C10.5 9.32843 9.82843 10 9 10C8.17157 10 7.5 9.32843 7.5 8.5" />
    <path d="M13.5 8.5C13.5 9.32843 12.8284 10 12 10C11.1716 10 10.5 9.32843 10.5 8.5" />
    <path d="M16.5 8.5C16.5 9.32843 15.8284 10 15 10C14.1716 10 13.5 9.32843 13.5 8.5"  strokeLinecap="round"/>
    <path d="M13.5 8.5L13 4.5" />
    <path d="M4.5 8.5L5 4.5" />
    <path d="M7.5 8.5V4.5" />
    <path d="M10.5 8.5V4.5" />
</svg>

export const Cart = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 3L3 5L15.5 5.5L14.5 11.5H4.5" stroke="white" strokeLinejoin="round"/>
    <path d="M3 5L5.5 14.5H14" strokeLinejoin="round" />
    <path d="M6.5 17.5C6.5 18.0523 6.05228 18.5 5.5 18.5C4.94772 18.5 4.5 18.0523 4.5 17.5C4.5 16.9477 4.94772 16.5 5.5 16.5C6.05228 16.5 6.5 16.9477 6.5 17.5Z" />
    <path d="M13.5 17.5C13.5 18.0523 13.0523 18.5 12.5 18.5C11.9477 18.5 11.5 18.0523 11.5 17.5C11.5 16.9477 11.9477 16.5 12.5 16.5C13.0523 16.5 13.5 16.9477 13.5 17.5Z" />
</svg>

export const QR = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="5.5" width="5" height="5" />
    <rect x="9.5" y="5.5" width="5" height="5" />
    <rect x="1.5" y="13.5" width="5" height="5" />
    <rect x="3.5" y="15.5" width="1" height="1" />
    <rect x="3.5" y="7.5" width="1" height="1" />
    <rect x="11.5" y="7.5" width="1" height="1" />
    <path d="M9.5 16V17" />
    <path d="M9.5 14V15" />
    <path d="M10.5 14V15" />
    <path d="M9.5 13V14" />
    <path d="M10.5 15V16" />
    <path d="M11.5 14V15" />
    <path d="M12.5 13V14" />
    <path d="M10.5 17V18" />
    <path d="M12.5 18V19" />
    <path d="M14.5 18V19" />
    <path d="M13.5 17V18" />
    <path d="M14.5 13V16.5H12.5V15" />
</svg>

export const Bell = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 16.5C9.5 17.5 9.5 18.5 8 18.5C6.5 18.5 6.5 17.5 6.5 16.5" />
    <path d="M9.5 5.5C9.5 4.52095 9.5 3.5 8 3.5C6.50568 3.5 6.50279 4.51611 6.50003 5.48891L6.5 5.5C5 6 3.5 7 3.5 9.5L3.5 12C3.5 13 3.5 13.5 2.5 13.5C1 13.5 1 15.5 2.5 15.5H13.5C15 15.5 15 13.5 13.5 13.5C12.5 13.5 12.5 13 12.5 12L12.5 9.5C12.5 7 11 6 9.5 5.5Z" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const Info = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="3.5" width="15" height="15" rx="7.5" />
    <path d="M6 9.5H8.5L6.9092 13.9542C6.59695 14.8285 7.541 15.6194 8.34708 15.1588L9.5 14.5" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 8C9.05228 8 9.5 7.55228 9.5 7C9.5 6.44772 9.05228 6 8.5 6C7.94772 6 7.5 6.44772 7.5 7C7.5 7.55228 7.94772 8 8.5 8Z" />
</svg>

export const Seat = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 11C0.5 10.1716 1.17157 9.5 2 9.5C2.82843 9.5 3.5 10.1716 3.5 11V15C3.5 15.2761 3.27614 15.5 3 15.5H1C0.723858 15.5 0.5 15.2761 0.5 15V11Z"/>
    <path d="M12.5 11C12.5 10.1716 13.1716 9.5 14 9.5C14.8284 9.5 15.5 10.1716 15.5 11V15C15.5 15.2761 15.2761 15.5 15 15.5H13C12.7239 15.5 12.5 15.2761 12.5 15V11Z"/>
    <path d="M3.5 12V5.5C3.5 4.39543 4.39543 3.5 5.5 3.5H10.5C11.6046 3.5 12.5 4.39543 12.5 5.5V12"/>
    <path d="M13.5 15.5V18.5"/>
    <path d="M2.5 15.5V18.5"/>
    <path d="M14.5 18.5H12.5" strokeLinecap="round"/>
    <path d="M3.5 18.5H1.5" strokeLinecap="round"/>
    <path d="M3.5 13.5C3.5 12.9477 3.94772 12.5 4.5 12.5H11.5C12.0523 12.5 12.5 12.9477 12.5 13.5C12.5 14.0523 12.0523 14.5 11.5 14.5H4.5C3.94772 14.5 3.5 14.0523 3.5 13.5Z"/>
</svg>

export const ZoomOut = props => <svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.5" width="19" height="19" rx="9.5" stroke="white"/>
    <path d="M14 7L10.5 10.5" stroke="white" strokeLinejoin="round"/>
    <path d="M9.5 11.5L6 15" stroke="white" strokeLinejoin="round"/>
    <path d="M13.5 10.5H10.5V7.5" stroke="white"/>
    <path d="M6.5 11.5H9.5V14.5" stroke="white"/>
</svg>

export const ZoomIn = props => <svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.5" width="19" height="19" rx="9.5" stroke="white"/>
    <path d="M14 7L6 15" stroke="white" strokeLinejoin="round"/>
    <path d="M11 7H14V10" stroke="white"/>
    <path d="M9 15H6V12" stroke="white"/>
</svg>


export const Price = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 5.66667C0.5 5.01449 0.504295 4.78649 0.551111 4.61177C0.689812 4.09413 1.09413 3.68981 1.61177 3.55111C1.78649 3.50429 2.01449 3.5 2.66667 3.5H13.3333C13.9855 3.5 14.2135 3.50429 14.3882 3.55111C14.9059 3.68981 15.3102 4.09413 15.4489 4.61177C15.4957 4.78649 15.5 5.01449 15.5 5.66667V11C15.5 15.1421 12.1421 18.5 8 18.5C3.85786 18.5 0.5 15.1421 0.5 11V5.66667Z" />
    <path d="M10.5 7.5H7C6.17157 7.5 5.5 8.17157 5.5 9V9C5.5 9.82843 6.17157 10.5 7 10.5H9C9.82843 10.5 10.5 11.1716 10.5 12V12C10.5 12.8284 9.82843 13.5 9 13.5H5.5" strokeLinejoin="round"/>
    <path d="M8 6V15" strokeLinejoin="round"/>
</svg>

export const Category = props => <svg width="16" height="22" viewBox="0 -1 16 21" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M7 6.5L14 6.5" />
    <path d="M7 10.5H14" />
    <path d="M7 14.5H14" />
    <rect x="2.5" y="5.5" width="2" height="2" rx="0.5" />
    <rect x="2.5" y="9.5" width="2" height="2" rx="0.5" />
    <rect x="2.5" y="13.5" width="2" height="2" rx="0.5" />
</svg>

export const Size = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M5 17.5H11C11.2761 17.5 11.5 17.2761 11.5 17V6C11.5 5.72386 11.2761 5.5 11 5.5H5C4.72386 5.5 4.5 5.72386 4.5 6V17C4.5 17.2761 4.72386 17.5 5 17.5Z" />
    <path d="M7 7.5H5" />
    <path d="M7 11.5H5" />
    <path d="M7 15.5H5" />
    <path d="M8 13.5H5" />
    <path d="M8 9.5H5" />
</svg>

export const Track = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="16" r="1.5" />
    <circle cx="4" cy="16" r="1.5" />
    <path d="M13.5 15.5H14.0858C14.351 15.5 14.6054 15.3946 14.7929 15.2071L15.2071 14.7929C15.3946 14.6054 15.5 14.351 15.5 14.0858V11.6623C15.5 11.5548 15.4827 11.448 15.4487 11.346L14.6085 8.8255C14.5382 8.61454 14.3995 8.43303 14.2145 8.30968L11.7519 6.66795C11.5877 6.55844 11.3946 6.5 11.1972 6.5H1.5C0.947715 6.5 0.5 6.94772 0.5 7.5V14.5C0.5 15.0523 0.947715 15.5 1.5 15.5H2.5" />
    <path d="M10.5 15.5H5.5" />
    <path d="M15.5 11.5H12.3471C11.8583 11.5 11.4411 11.1466 11.3607 10.6644L11.1941 9.6644C11.0925 9.05487 11.5625 8.5 12.1805 8.5H14.5" />
    <path d="M9 11.5H0.5" />
    <path d="M8 9.5H0.5" />
</svg>

export const Expand = props => <svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.5" width="19" height="19" rx="9.5" />
    <path d="M5.5 9.5L10 14L14.5 9.5" />
</svg>

export const Collapse = props => <svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.5" width="19" height="19" rx="9.5" />
    <path d="M14.5 12.5L10 8L5.5 12.5" />
</svg>

export const Kid = props => <svg width="17" height="22" viewBox="0 0 17 22" fill="#7a7a7a" {...props} xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.03125 19.5C11.0806 19.5 13.6745 17.5502 14.6344 14.8293C15.6282 14.623 16.375 13.7425 16.375 12.6875C16.375 11.6788 15.6923 10.8296 14.7637 10.5767C13.928 7.64587 11.2302 5.5 8.03125 5.5C4.78574 5.5 2.0561 7.70874 1.26351 10.7051C0.517111 11.054 0 11.8116 0 12.69C0 13.6188 0.578189 14.4125 1.39427 14.731C2.32572 17.5031 4.94528 19.5 8.03125 19.5Z"/>
    </mask>
    <path d="M14.6344 14.8293L14.4311 13.8502L13.8789 13.9648L13.6913 14.4967L14.6344 14.8293ZM14.7637 10.5767L13.802 10.8509L13.9567 11.3933L14.5009 11.5415L14.7637 10.5767ZM1.26351 10.7051L1.68706 11.6109L2.11067 11.4129L2.23026 10.9608L1.26351 10.7051ZM1.39427 14.731L2.34219 14.4124L2.19329 13.9693L1.75775 13.7994L1.39427 14.731ZM13.6913 14.4967C12.8681 16.8302 10.6434 18.5 8.03125 18.5V20.5C11.5178 20.5 14.4809 18.2702 15.5774 15.162L13.6913 14.4967ZM15.375 12.6875C15.375 13.259 14.9703 13.7383 14.4311 13.8502L14.8376 15.8085C16.2861 15.5078 17.375 14.226 17.375 12.6875H15.375ZM14.5009 11.5415C15.0054 11.6789 15.375 12.1413 15.375 12.6875H17.375C17.375 11.2163 16.3791 9.98021 15.0265 9.61183L14.5009 11.5415ZM8.03125 6.5C10.7718 6.5 13.0856 8.33821 13.802 10.8509L15.7254 10.3025C14.7705 6.95353 11.6887 4.5 8.03125 4.5V6.5ZM2.23026 10.9608C2.9097 8.39221 5.25077 6.5 8.03125 6.5V4.5C4.3207 4.5 1.2025 7.02527 0.296759 10.4493L2.23026 10.9608ZM1 12.69C1 12.2142 1.2792 11.8016 1.68706 11.6109L0.839958 9.79918C-0.244976 10.3065 -1 11.409 -1 12.69H1ZM1.75775 13.7994C1.3126 13.6257 1 13.1931 1 12.69H-1C-1 14.0445 -0.15622 15.1994 1.03079 15.6626L1.75775 13.7994ZM8.03125 18.5C5.38768 18.5 3.14097 16.7897 2.34219 14.4124L0.446352 15.0495C1.51048 18.2164 4.50289 20.5 8.03125 20.5V18.5Z" fill="#7A7A7A" mask="url(#path-1-inside-1)"/>
    <path d="M6 15.5C6 15.5 7 16 8 16C9 16 10 15.5 10 15.5" stroke="#7A7A7A"/>
    <path d="M8.00048 8.5C7.16501 7 7.00048 5 8.50048 3" stroke="#7A7A7A"/>
    <circle cx="6" cy="12.5" r="1" fill="#7A7A7A"/>
    <circle cx="10" cy="12.5" r="1" fill="#7A7A7A"/>
</svg>


export const QRArrow = props => <svg width="16" height="23" viewBox="0 0 16 23" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6H1.5L1.5 11H4.5" stroke="#7A7A7A"/>
    <path d="M8 11L8 2.5" stroke="white"/>
    <path d="M5 8.5L8 11.5L11 8.5" stroke="white"/>
    <path d="M10 6H14.5V11H11.5" stroke="#7A7A7A"/>
    <rect x="1.5" y="14" width="5" height="5" stroke="#7A7A7A"/>
    <rect x="3.5" y="16" width="1" height="1" stroke="#7A7A7A"/>
    <path d="M9.5 16.5V17.5" stroke="#7A7A7A"/>
    <path d="M9.5 14.5V15.5" stroke="#7A7A7A"/>
    <path d="M10.5 14.5V15.5" stroke="#7A7A7A"/>
    <path d="M9.5 13.5V14.5" stroke="#7A7A7A"/>
    <path d="M10.5 15.5V16.5" stroke="#7A7A7A"/>
    <path d="M11.5 14.5V15.5" stroke="#7A7A7A"/>
    <path d="M12.5 13.5V14.5" stroke="#7A7A7A"/>
    <path d="M10.5 17.5V18.5" stroke="#7A7A7A"/>
    <path d="M12.5 18.5V19.5" stroke="#7A7A7A"/>
    <path d="M14.5 18.5V19.5" stroke="#7A7A7A"/>
    <path d="M13.5 17.5V18.5" stroke="#7A7A7A"/>
    <path d="M14.5 13.5V17H12.5V15.5" stroke="#7A7A7A"/>
</svg>

export const Refund = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 11C0.5 15.1421 3.85786 18.5 8 18.5C12.1421 18.5 15.5 15.1421 15.5 11C15.5 6.85786 12.1421 3.5 8 3.5H1.5" stroke="#7A7A7A"/>
    <path d="M3 1.5L1 3.5L3 5.5" stroke="#7A7A7A"/>
    <path d="M10.5 8H7C6.17157 8 5.5 8.67157 5.5 9.5V9.5C5.5 10.3284 6.17157 11 7 11H9C9.82843 11 10.5 11.6716 10.5 12.5V12.5C10.5 13.3284 9.82843 14 9 14H5.5" stroke="#7A7A7A" strokeLinejoin="round"/>
    <path d="M8 6.5V15.5" stroke="#7A7A7A" strokeLinejoin="round"/>
</svg>

export const Timer = props => <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3.5C12.1421 3.5 15.5 6.85786 15.5 11C15.5 15.1421 12.1421 18.5 8 18.5C3.85786 18.5 0.5 15.1421 0.5 11C0.5 8.32844 1.89683 5.98313 4 4.65456" stroke="white" stroke-linecap="round"/>
    <path d="M8 3.5C8 5.16667 8 5.5 8 5.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.5 7.5L8 11" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


export const BankID = props => <svg width="42" height="40" viewBox="0 0 42 40" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M13.256 20.8839L15.0117 9.74992H13.1335C12.2557 9.74992 11.1261 9.2566 10.7926 8.34533C10.6837 8.037 10.432 6.97499 11.8814 5.94724C12.3986 5.58411 12.732 5.18671 12.8001 4.87153C12.8681 4.5495 12.7865 4.26859 12.5619 4.05619C12.2421 3.74786 11.616 3.56972 10.8198 3.56972C9.47926 3.56972 8.53338 4.34395 8.43811 4.91264C8.37006 5.33059 8.68989 5.65947 8.96889 5.87873C9.8059 6.50908 10.01 7.4135 9.49287 8.27681C8.95528 9.16067 7.80525 9.73621 6.56675 9.74992H4.65457C4.49806 10.8325 1.88496 27.4615 1.68762 28.7701H12.0719C12.1672 28.1809 12.6436 25.036 13.2968 20.8839H13.256Z" fill="white"/>
    <path d="M27.1653 0H16.5904L15.1818 9.00994H16.9783C17.965 9.00994 18.8973 8.55773 19.292 7.89997C19.4213 7.68071 19.4757 7.49572 19.4757 7.32443C19.4757 6.94759 19.2239 6.67352 18.9722 6.48167C18.2781 5.96095 18.1352 5.41281 18.1352 5.02227C18.1352 4.94005 18.1352 4.87153 18.1488 4.80301C18.2917 3.85063 19.571 2.82288 21.2586 2.82288C22.2726 2.82288 23.0415 3.06269 23.5111 3.5012C23.9262 3.89174 24.0827 4.43988 23.9602 5.01542C23.8173 5.69373 23.1368 6.26242 22.7489 6.53649C21.7282 7.26276 21.8575 7.89311 21.9255 8.07811C22.1365 8.63995 22.953 9.00308 23.5723 9.00308H26.2943V9.01679C30.0166 9.04419 32.0104 10.7708 31.3912 14.7859C30.8059 18.52 27.9615 20.1233 24.5658 20.1576L23.2252 28.777H25.2055C33.5687 28.777 40.4009 23.371 41.7415 14.827C43.4087 4.22748 36.733 0 27.1653 0Z" fill="white"/>
    <path d="M27.1653 0H16.5904L15.1818 9.00994H16.9783C17.965 9.00994 18.8973 8.55773 19.292 7.89997C19.4213 7.68071 19.4757 7.49572 19.4757 7.32443C19.4757 6.94759 19.2239 6.67352 18.9722 6.48167C18.2781 5.96095 18.1352 5.41281 18.1352 5.02227C18.1352 4.94005 18.1352 4.87153 18.1488 4.80301C18.2917 3.85063 19.571 2.82288 21.2586 2.82288C22.2726 2.82288 23.0415 3.06269 23.5111 3.5012C23.9262 3.89174 24.0827 4.43988 23.9602 5.01542C23.8173 5.69373 23.1368 6.26242 22.7489 6.53649C21.7282 7.26276 21.8575 7.89311 21.9255 8.07811C22.1365 8.63995 22.953 9.00308 23.5723 9.00308H26.2943V9.01679C30.0166 9.04419 32.0104 10.7708 31.3912 14.7859C30.8059 18.52 27.9615 20.1233 24.5658 20.1576L23.2252 28.777H25.2055C33.5687 28.777 40.4009 23.371 41.7415 14.827C43.4087 4.22748 36.733 0 27.1653 0Z" fill="white"/>
    <path d="M1.12962 32.6071H5.36908C7.17919 32.6071 7.61471 33.532 7.48542 34.3748C7.37654 35.06 6.9138 35.567 6.11763 35.9027C7.12476 36.2933 7.52625 36.8893 7.38334 37.8417C7.1928 39.0339 6.17207 39.9178 4.82469 39.9178H0L1.12962 32.6071ZM3.93325 35.6355C4.75664 35.6355 5.14452 35.197 5.21938 34.6694C5.30104 34.1076 5.04925 33.717 4.22586 33.717H3.49773L3.20512 35.6286C3.20512 35.6355 3.93325 35.6355 3.93325 35.6355ZM3.48412 38.7941C4.33474 38.7941 4.82469 38.4447 4.94718 37.7321C5.04245 37.1155 4.6954 36.7592 3.872 36.7592H3.02819L2.70836 38.8078H3.47732C3.48412 38.8078 3.48412 38.7941 3.48412 38.7941Z" fill="white"/>
    <path d="M13.324 39.9726C12.2216 40.0548 11.6909 39.9315 11.4187 39.4519C10.8334 39.815 10.1802 40 9.49287 40C8.24076 40 7.80525 39.3491 7.92093 38.6228C7.97537 38.2734 8.17271 37.9376 8.49254 37.6567C9.18664 37.0538 10.8879 36.9716 11.548 36.5194C11.6024 36.0123 11.405 35.8273 10.779 35.8273C10.0441 35.8273 9.43162 36.0671 8.38366 36.7934L8.63545 35.1353C9.5405 34.4776 10.4183 34.1692 11.4255 34.1692C12.7116 34.1692 13.8616 34.7037 13.6439 36.122L13.3921 37.7321C13.2968 38.2939 13.324 38.4721 13.9501 38.4858L13.324 39.9726ZM11.405 37.4512C10.8198 37.828 9.73104 37.7595 9.60855 38.5337C9.55411 38.8969 9.77867 39.1641 10.1393 39.1641C10.4864 39.1641 10.9083 39.0202 11.2553 38.7736C11.2281 38.6434 11.2417 38.5063 11.2826 38.2528L11.405 37.4512Z" fill="white"/>
    <path d="M15.3859 34.2583H17.5907L17.4682 34.9983C18.1759 34.3953 18.7067 34.1692 19.4008 34.1692C20.6393 34.1692 21.2109 34.9298 21.0068 36.1768L20.4352 39.9109H18.2236L18.6999 36.8208C18.7952 36.259 18.6183 35.9918 18.1964 35.9918C17.8493 35.9918 17.5363 36.1768 17.2301 36.5947L16.7265 39.9041H14.5149L15.3859 34.2583Z" fill="white"/>
    <path d="M22.7216 32.6071H24.9264L24.3684 36.1905L26.4847 34.2515H29.2067L26.4983 36.6564L28.6759 39.8972H25.8995L24.2255 37.2936H24.1983L23.8036 39.9041H21.592L22.7216 32.6071Z" fill="white"/>
    <path d="M30.5405 32.6071H33.0787L31.9627 39.9041H29.4245L30.5405 32.6071Z" fill="white"/>
    <path d="M34.3173 32.6071H37.9443C40.7479 32.6071 41.5645 34.6557 41.2923 36.3549C41.0405 38.013 39.734 39.9041 37.2774 39.9041H33.1808L34.3173 32.6071ZM36.665 38.1638C37.9035 38.1638 38.584 37.5471 38.7813 36.2521C38.9242 35.2861 38.6384 34.3337 37.2706 34.3337H36.5901L36.0049 38.1638H36.665Z" fill="white"/>
</svg>
