import { useMemo } from "react";
import thisMonth from "./filters/thisMonth";
import upcoming from "./filters/upcoming";
import { useSelector } from "react-redux";
import categories from './categories';
import thisYear from "./filters/thisYear";
import archive from "./filters/archive";

const filters = {
    [categories.UPCOMING]: upcoming,
    [categories.THISMONTH]: thisMonth,
    [categories.THISYEAR]: thisYear,
    [categories.ARCHIVED]: archive,
    [categories.ALL]: items => items 
}

export default function useSectionedEventsList() {

    const { events:items, loading, error } = useSelector(state => state.tickets);
    
    //Each new filter takes items without already used by previous
    const events = useMemo( () => items && items.length && Object.keys(filters).reduce((events, filter) => {
        console.log(`Running filter: ${filter}`);
        events.push(filters[filter](items.filter(e => !events.flat().map(e => e.id).includes(e.id))));
        return events;
    },[]) || null, [items]);
    // console.log(events);
   
    return {
        events: events?.map((items, i) => ({data: items, type: Object.keys(filters)[i]}))?.filter(e => !!e.data?.length),
        loading,
        error
    }
}