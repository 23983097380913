import React from 'react';
import InfoLine from 'pages/MyTickets/pages/List/components/Feed/components/Group/components/Event/components/Tickets/components/InfoLine';
import { Seat as SeatIcon } from 'components/Icons/Glyphs';

const Ticket = ({ ticket: { row, seat } }) => <div className='zt-tickets__group__event__tickets__owner'>
    <InfoLine bold><SeatIcon className='event-info-line__icon' />{`Row ${row} Seat ${seat}`}</InfoLine>
</div>

export default ({ tickets }) => {

    return <div className='zt-tickets__group__event__tickets'>
        {tickets.map((ticket, key) => <Ticket {...{ticket: ticket?.item, key}} />)}
    </div>
}