import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DropdownIcon, ClearIcon } from 'components/Icons/Glyphs';
import { useDebouncedCallback } from 'use-debounce'
import Menu from 'components/Menu';
import './style.scss';
import Search from '../Search';

const Selected = ({ item }) => {
    return (
        <span>{item.icon ? <><item.icon /> </> : null}{item.label}</span>
    );
}

export default React.forwardRef(({ label=null, name, items, placeholder, searchplaceholder, error, description, nosearch=false, onChange, ...props }, selectRef) => {
    const [value, setValue] = useState('');
    const [active, setActive] = useState(false);
    const [search, setSearch] = useState(false);
    const ref = useRef();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setActive(false);
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });   

    const onSelect = useCallback(item => {
        setValue(item.id);
        onChange && onChange(item.id);
        setActive(false);
    }, [setActive, setValue])

    const [debouncedCallback] = useDebouncedCallback(
        (value) => {
            setSearch(value||false);
        },
        // delay in ms
        100
    );  

    return (
        <div className={`zt-inputs--select ${!!label ? '' : 'zt-inputs--select--nolabel'}`} ref={ref} >
            { !!label &&
                <div className='zt-inputs--select__label'><span className='zt-p3'>{label}</span></div>
            }              
            <select ref={selectRef} name={name} value={value}>
                {items.map(({ id, label }, key) => <option value={id} key={key}>{label}</option>)}
            </select>
            <button type="button" className={`zt-inputs--select__button ${value ? 'zt-inputs--select__button--selected' : ''} ${error ? 'zt-inputs--select__button--error' : ''}`} onClick={() => { 
                    if (value) {
                        setValue('');
                        setSearch('');
                        onChange && onChange(null);
                        return;
                     }
                     setActive(a => !a);
                }}>
                {!value ?
                <>
                    {placeholder}
                    <DropdownIcon className='zt-inputs--select__button__icon'/>
                </>
                :
                <>
                    <Selected item={items.find( item => item.id === value)} />
                    <ClearIcon className='zt-inputs--select__button__icon'/>
                </>
                }
                
            </button>
            { (!!error || !!description) &&
            <div className='zt-inputs--select__message'>
                <div className={`zt-inputs--select__message__first ${error ? 'zt-inputs--select__message__first--error' : ''}`}><span className='zt-p3'>{error || description}</span></div>
                <div className='zt-inputs--select__message__second'><span className='zt-p3'></span></div>
            </div>
            }            
            { active && 
            <Menu items={search ? items.filter(item => item.id).filter( ({ label }) => label.toLowerCase().includes(search.trim().toLowerCase())) : items} className={`zt-inputs--select__menu`} onSelect={item => onSelect(item)} >
                 {!nosearch && <Search placeholder={searchplaceholder} onChange={e => debouncedCallback(e.target.value)} />}
            </Menu>
            }
        </div>
    )
});