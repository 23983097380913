import useAdminFee from './useAdminFee';
import useServiceFee from './useServiceFee';
import useTicketsTotal from '../../../AddFriends/useTicketsTotal';

export default function useFeesDetails() {

    const ticketsTotal = useTicketsTotal(); //Tickets prices sum as total
    const adminFee = useAdminFee() || 0;
    const serviceFee = useServiceFee() || 0;
    const discount = 0;//useDiscount(ticketsTotal);

    if (ticketsTotal === null || ticketsTotal === 0) {
        return {totalFee: 0, ticketsTotal: 0, adminFee: 0, serviceFee: 0, discount: 0}
    }

    const totalFee = (ticketsTotal - discount > 0 ? ticketsTotal - discount : 0) + adminFee + serviceFee;

    return { totalFee, ticketsTotal, adminFee, serviceFee, discount };
}