import { useEffect } from "react";

export default function useValidationErrors(error, t, setError, fields = null) {

    useEffect(() => {
        if (!error) {
            return;
        }

        const validation_errors = error.response?.data?.validation_errors || {};
        Object.keys(validation_errors).forEach(error => {
            if (!fields || fields.includes(error)) {
                setError(error, {type: 'validation', message: t(error, `${validation_errors[error]}`)}, true);
            }
        });
    }, [error, setError]);

}