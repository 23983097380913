import React from 'react';
import { ArrowRight as ArrowRightIcon, QR } from 'components/Icons/Glyphs';
import * as availableTargetModes from './../../store/sell/sellToModes';
import { PrimaryButton } from 'components/buttons/Button';
import { link as tickets_url } from './../../../List';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './style.scss';

const Restricted = ({ children, ...props }) => {
    const { t } = useTranslation('tickets');
    const tt = (label, params) => t(`sell.no_return.${label}`, params);

    const history = useHistory();
    const goToTickets = () => history.push(tickets_url);

    return (
    <div className='zt-tickets__sell__restricted'>{children}
        <PrimaryButton onClick={goToTickets} className='zt-tickets__sell__restricted__gotobutton' icon={QR}>{tt('button', 'Goto My Tickets')}  <ArrowRightIcon className='zt-tickets__sell__restricted__gotobutton__arrow'/></PrimaryButton>
    </div>);
}

const Error = ({ error, t, mode=null, ...props }) => <div className='zt-p1 zt-tickets__sell__restricted__info'>{mode ? `${t(`sell.modes.${mode}.label`, mode)}: ` : ''}{error}</div>

export default ({ error, tickets, ...props }) => {

    const { t } = useTranslation('tickets');

    const Title = () => <h4 className='bold zt-tickets__sell__restricted__header'>{t('sell.no_return.label', 'Ticket cannot be sold')}</h4>;


    if (Array.isArray(error)) {
        return (
            <Restricted>
                <Title />
                {error.map((error, key) => <Error {...{key, error, t}} />)}
            </Restricted>
        )
    }

    if (tickets) {
        return (
            <Restricted>
                <Title />
                {/* {tickets?.map((ticket, key) => Object.values(availableTargetModes).filter(m => ticket[m]?.errors).map((m, key) => ticket[m].errors.map((error, _key) => <Error {...{error, t, mode: m, key: `${key} ${_key}`}} />)))} */}
            </Restricted>
        )        
    }

    return (<Restricted>
            <Title />
            <Error {...{t}} error='There is a problem to sell the ticket(s).' />        
        </Restricted>);
}