import React, { useEffect, useRef, useState } from 'react';
import InfoLine from 'pages/MyTickets/pages/List/components/Feed/components/Group/components/Event/components/Tickets/components/InfoLine';
import isCorrectPhone from 'pages/Event/pages/BuyTickets/AddFriends/Friend/components/PhoneSearch/isCorrectPhone';
import usePhoneSearch, { ERROR_USER_NOT_FOUND, ERROR_USER_NOT_APPLICABLE } from './store/usePhoneSearch';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { useDebouncedCallback } from 'use-debounce/lib';
import PhoneInput from 'components/Inputs/PhoneInput';
import { UserIcon } from 'components/Icons/Glyphs';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import useError from './useError';

export default ({ mode, eventId, isFound, ...props }) => {

    const { t } = useTranslation('tickets');
    const tt = (label, params) => t(`sell.search_phone.${label}`, params);
    const phoneErrorMessage = t('errors.validation.phone', 'Phone number should be in the right format');

    const { state: { loading, friend, checked, error, phone }, search, reset, retry } = usePhoneSearch(mode/* mode: transfer kids, sell */, eventId);
    const [phoneError, setPhoneError] = useState(null);

    const userName = !!friend ? `${friend?.first_name} ${friend?.last_name}` : null;   

    useEffect(() => {
        if (checked && !error && friend?.id) {
            isFound && isFound(+friend.id);
        }
    }, [checked, error, friend]);

    const proceedSearch = phone => {
        if (!isCorrectPhone(phone)) {
            setPhoneError(phoneErrorMessage);
            return;
        }

        setPhoneError(null);
        search(phone);
    }

    const [debouncedCallback, cancelCallback] = useDebouncedCallback(
        (value) => {
            // dispatch(actions.setFilter(value));
            if (value?.length >= 5) {
                console.log(`Search for phone ${value}`);
                proceedSearch(value);
            }
        },
        // delay in ms
        1500
    ); 

    const phoneRef = useRef();

    const showError = phoneError || useError(tt, mode, friend, checked, error);

    return (
        <Row>
            <Col md lg="6">
                <PhoneInput 
                    ref={phoneRef}
                    label={'Mobile number:'}
                    name="phone"
                    onChange={value => debouncedCallback(value)}
                    disabled={loading}
                    onClear={() => reset()}
                    error={showError}
                />
            </Col>
            <Col md lg="6" className='zt-tickets__sell__form__user'>
                {loading && <MiniLoader />}
                {userName && <InfoLine><UserIcon className='event-info-line__icon' /> {userName}</InfoLine>}
            </Col>
        </Row>        
    )
}