import * as constants from './constants';
import api from './api';

const flow = {
    start: () => ({type: constants.VOID_ORDER_START}),
    success: payload => ({type: constants.VOID_ORDER_SUCCESS, payload}),
    failure: error => ({type: constants.VOID_ORDER_FAILURE, error})
}

export const voidReservation = dispatch => id => {
    dispatch(flow.start());

    api.void(id)
        .then(result => dispatch(flow.success(result?.data)))
        .catch(error => dispatch(flow.failure(error)));
}