import api from "./api";
import * as constants from "./constants";

const resetPasswordActions = {
    start: () => ({type: constants.RESET_PASSWORD_START}),
    success: payload => ({type: constants.RESET_PASSWORD_SUCCESS, payload}),
    failure: payload => ({type: constants.RESET_PASSWORD_FAILED, payload})
}

export const resetPassword = dispatch => data => {
    dispatch(resetPasswordActions.start());

    api.resetPassword(data)
        .then(response => dispatch(resetPasswordActions.success(response?.data)))
        .catch(error => dispatch(resetPasswordActions.failure(error?.response?.data)));
}
