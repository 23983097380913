import React from 'react';
import InfoElement from '../../../InfoElement';
import { Row, Col } from 'react-bootstrap';
import { QR as QRIcon, Price as PriceIcon } from 'components/Icons/Glyphs';
import PriceSelector from '../../../components/PriceSelector';
import { useDispatch } from 'react-redux';
import * as friendsActions from './../../../actions';
import usePrices from '../../../usePrices';

export default ({ t, friend, nokid }) => {

    const { user = null, ticket } = friend;

    const dispatch = useDispatch();
    const showkids = !!friend.kid && !nokid; 
    const prices = usePrices(user, friend.id, showkids);

    const setPriceType = p => {
        dispatch(friendsActions.setPrice(friend.id, p.id, p.type, p.kids));
    }

    return (
        <Row>
            <Col md={6}>
                {friend?.ticket?.type && <InfoElement dark icon={QRIcon}>{t(`pricetypes.${friend.ticket.type}`, friend.ticket.type)}</InfoElement>}
            </Col>
            <Col md={6}>
                { (!!ticket.price || ticket.price === 0) && prices.length === 1 ?
                <InfoElement icon={PriceIcon}>{t('common:price', {price: Number(prices[0].label)})}</InfoElement>
                :
                <PriceSelector {...{items:prices, ticket, t}} onChange={p => setPriceType(p)} />
                }
            </Col>
        </Row>        
    )
}