import * as actions from './constants'

export const NOOPT = 'NOOPT'; //Mean no option

/*
{
    event_id: {
        item_id: {
            [option_id||NOOPT]: {
                item: {iteminfo},
                cnt: 1
            }
        }
    }
}
*/

const defaultState = {
    items: {}
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.WEBSHOP_RESET: {
            return {
                ...defaultState
            }
        }

        case actions.WEBSHOP_ADD_CART_ITEM: {
            const { event_id, option, qty, item } = payload
            const item_id = item.id;
            return {
                ...state,
                items: {
                    ...state.items,
                    [event_id]: {
                        ...state.items[event_id],
                        [item_id]: {
                            ...state.items[event_id]?.[item_id],
                            [option]: {
                                item,
                                qty: qty + (state.items[event_id]?.[item_id]?.[option]?.qty||0)
                            }
                        }
                    }
                }
            }
        }

        case actions.WEBSHOP_CHANGE_CART_ITEM_QTY: {
            const { event_id, item_id, option, delta } = payload;
            const item = state.items[event_id]?.[item_id]?.[option]?.item;
            if (!item) {
                return state;
            }
            return {
                ...state,
                items: {
                    ...state.items,
                    [event_id]: {
                        ...state.items[event_id],
                        [item_id]: {
                            ...state.items[event_id]?.[item_id],
                            [option]: {
                                item,
                                qty: delta + (state.items[event_id]?.[item_id]?.[option]?.qty||0)
                            }
                        }
                    }
                }
            }            
        }

        case actions.WEBSHOP_REMOVE_CART_ITEM: {
            const { event_id, option, item_id } = payload
            const items = state.items;
            delete state.items[event_id]?.[item_id]?.[option];
            return {
                ...state,
                items: {...items}
            }
        }

        default: {
            return state;
        }
    }
}