import React from 'react';
import { Route } from 'react-router-dom';
import List from './pages/List';
import Details, { path as detailsPath } from './pages/Details';
import Cart, { path as cartPath, link as cartLinkLocal } from './pages/Cart';
import Checkout, { path as checkoutPath, link as checkoutLinkLocal } from './pages/Checkout';
import Confirm, { path as confirmPath } from './pages/Confirm';
import UseApp from './components/UseApp';

export const path = '/webshop';
export const link = path;

export const cartLink = link + cartLinkLocal;
export const checkoutLink = link + checkoutLinkLocal;

export default props => {
    const { match } = props;

    return <UseApp />;

    return  (<>
        <Route path={match.path} exact component={List} />
        <Route path={match.path + detailsPath} exact component={Details} />
        <Route path={match.path + cartPath} exact component={Cart} />
        <Route path={match.path + checkoutPath} exact component={Checkout} />
        <Route path={match.path + confirmPath} exact component={Confirm} />
    </>);
}