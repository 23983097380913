import * as actions from './constants';

const defaultState = {
    profile: {},
    loading: false
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action
    switch (type) {
        case actions.GET_PROFILE_START: {
            return {
                ...defaultState,
                loading: true
            }
        }
        case actions.GET_PROFILE_SUCCESS: {
            const {
                birthday = null,
                country = null,
                document = null, 
                email = null,
                email_verified_at = null,
                first_name = null,
                last_name = null,
                id = null,
                name = null,
                phone = null,
                privacy_mode = null, 
                type = null, 
                uuid = null,
                register_type = null
             } = payload;
            
            return {
                ...defaultState,
                profile: {
                    birthday,
                    country,
                    document, 
                    email,
                    email_verified_at,
                    first_name,
                    last_name,
                    id,
                    name,
                    phone,
                    privacy_mode, 
                    type, 
                    uuid,
                    register_type
                }
            }
        }
        case actions.GET_PROFILE_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }
        default: {
            return state;
        }
    }
}