import React from 'react';
import './style.scss';

const Radio = React.forwardRef(({ name, value, checked=false, className='', ...props }, ref) => <label className={`zt-inputs--radiogroup__item ${className}`}>
    <input type="radio" name={name} defaultChecked={checked} value={value} ref={ref}></input>
    <span className='zt-inputs--radiogroup__item__label zt-nav light'>{props.children}</span>
    <span className="zt-inputs--radiogroup__item__checkmark"></span>
</label>);

export default ({ label, name, items, defaultValue=null, register, error, description, description2='', className, ...props }) => {
    return (
        <div className={`zt-inputs--radiogroup ${className ? className : ''}`} onChange={e => props.onChange && props.onChange(e.target.value)}>
            { !!label &&
                <div className='zt-inputs--radiogroup__label'><span className='zt-p3'>{label}</span></div>
            }            
            { items.map( ({ id, label }, key) => <Radio name={name} value={id} key={key} checked={defaultValue === id} ref={register} className={error && 'zt-inputs--radiogroup__item--error'}>{label}</Radio> )}
            { (!!error || !!description) &&
                <div className='zt-inputs--radiogroup__message'>
                    <div className={`zt-inputs--radiogroup__message__first ${error ? 'zt-inputs--radiogroup__message__first--error' : ''}`}><span className='zt-p3'>{error || description}</span></div>
                    <div className='zt-inputs--radiogroup__message__second'><span className='zt-p3'>{description2}</span></div>
                </div>
            }            
        </div>         
    );
};