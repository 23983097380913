import * as actions from './constants';
import * as payTypes from './payTypes';
import defaultTicket from './defaultTicket';

const defaultUser = {
    id: null,
    phone: null,
    first_name: null,
    last_name: null,
    types: null
}

export const defaultFriendState = {
    kid: false,
    searching: false,
    user: defaultUser,
    ticket: defaultTicket,
    pay: payTypes.PAYTYPE_IPAY, //Pay type: Friend or Me
    error: null
}

const friendReducer = (state = defaultFriendState, action, prices) => {

    const { type, payload=null, error=null } = action;

    switch (type) {        
        case actions.ADDFRIENDS_RESET_FRIENDS: {
            return defaultFriendState;
        }

        case actions.ADDFRIENDS_FRIEND_UPDATENAME: {
            return {
                ...state,
                user: {
                    ...state.user,
                   ...payload 
                }
            }
        }

        case actions.ADDFRIENDS_FRIEND_UPDATEPAY: {
            const pay = payload;
            return {
                ...state,
                pay
            }
        }

        case actions.ADDFRIENDS_FINDBYPHONE_START: {
            const { phone } = payload;
            return {
                ...state,
                ticket: {
                    ...defaultTicket
                },
                user: {...defaultUser, phone},
                searching: true
            }
        }

        case actions.ADDFRIENDS_FINDBYPHONE_SUCCESS: {
            const { user, ticket } = payload;
            const { id, first_name, last_name, register_type } = user;
            const { types } = ticket;
            const price = prices?.length === 1 ? 0 : null;
            return {
                
                ...state,
                searching: false,
                pay: payTypes.PAYTYPE_IPAY,

                ticket: {
                    type: types?.[0],
                    price
                },

                user: {
                    ...state.user,
                    id,
                    first_name,
                    last_name,
                    register_type,
                    types //Applicable types                    
                }
            }
        }

        case actions.ADDFRIENDS_FINDBYPHONE_FAILURE: {
            
            if (error && error.__CANCEL__) { //Request cancelled 
                return {
                    ...state,
                    searching: false
                }
            }

            const { status = null } = error?.response;
            if (+status === 404) { //User not found
                return {
                    ...defaultFriendState,
                    uuid: state.uuid,
                    pay: payTypes.PAYTYPE_FRIENDPAY,
                    user: {
                        ...defaultUser,
                        phone: state.user.phone,
                        id: 0
                    }

                }
            }

            return {
                ...state,
                searching: false,
                error
            }
        }

        case actions.ADDFRIENDS_FRIEND_SETKID: {
            const kid = !!payload;
            // const price = prices?.length === 1 ? 0 : null;
            return {
                ...state,
                kid,
                pay: payTypes.PAYTYPE_IPAY,
                user: defaultUser,
                ticket: {
                    ...defaultTicket,
                    type: null,
                    price: null// state.ticket.price
                    // price
                }
            }
        }

        case actions.ADDFRIENDS_FRIEND_SETPRICE: {
            const { price = null, type = null, kids = null } = payload || {};

            return {
                ...state,
                ticket: {
                    ...state.ticket,
                    price,
                    type
                },
                kid: kids === null ? state.kid : kids,
                pay: kids ? payTypes.PAYTYPE_IPAY : state.pay
            }
        }

        default: {
            return state
        }
    }
}


export default friendReducer;