import React from 'react';
import * as types  from './../../../../../../store/types';

// import ViewTicket from './components/ViewTicket';
// import KidsPickUp from './components/KidsPickUp';
// import BuyTicket from './components/BuyTicket';


// import ViewTicket from './components/ViewTicket';
// import KidsPickUp from './components/KidsPickUp';
// import BuyTicket from './components/BuyTicket';
// import Assigned from './components/Assigned';
// import Refused from './components/Refused';

import AcceptOrDecline from './components/AcceptOrDecline';
import PayOrDecline from './components/PayOrDecline';

const AcceptSelector = ({ item, ...props }) => {
    
    const { reservation } = item;
    const ticket = reservation?.items?.find(t => t.type === 'ticket')
    
    if (ticket && ticket?.alternative_prices?.length > 1) {
        return <PayOrDecline {...{item, ...props}}  />;
    }
    
    return  <AcceptOrDecline {...{item, ...props}} />;;
}

export default ({ item, navigation, ...props })=> {

    const { type=null } = item || {};

    if (!type) {
        return null;
    }

    switch (type) {
        case types.TYPE_ACCEPT: 
            return <AcceptSelector {...{item, navigation}} />;

        case types.TYPE_PAY: {
            const { reservation: { id, items } } = item;
            if (!items?.length) {
                return null;
            }
            return <PayOrDecline {...{item, navigation}}  />;
            //<Pay {...{key:i, index:i, item, navigation}} />,
        // case types.TYPE_TICKET_ASSIGNED: {
        //     return <Assigned {...{item, navigation}} /> //TODO: Check buy
        // }

        // case types.TYPE_TICKET_RESERVED: {
        //     return <BuyTicket {...{item, navigation}} />
        // }

        // case types.TYPE_TICKET_PURCHASED: {
        //     return <ViewTicket {...{item, navigation}} />
        // }

        // case types.TYPE_KIDS_PICKUP_REQUEST: {
        //     return <KidsPickUp {...{item, navigation}} />
        // }

        // case types.TYPE_FRIEND_REFUSED_TICKET: {
        //     return <Refused {...{item, navigation}} />
        // }
        }

        default: {
            return null;
        }

    }
}