import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoPage from 'components/InfoPage';

export const path = '/scanningapp-dataprivacy';
export const link = path;

export default props => {
    const { t } = useTranslation("scanning_privacy");
    return <InfoPage t={t} />
}