import * as constants from "./constants";
import api from "./api";

const flow = {
    start: () => ({type: constants.GET_PROMOEVENTS_START}),
    success: payload => ({type: constants.GET_PROMOEVENTS_SUCCESS, payload }),
    failure: error => ({type: constants.GET_PROMOEVENTS_FAILED, error})
}

export const get = dispatch => (promoter) => {
    dispatch(flow.start());
    api.fetch({promoter})
        .then(response => dispatch(flow.success(response?.data)))
        .catch(error => dispatch(flow.failure(error)));
}

export const reset = dispatch => () => dispatch({type: constants.RESET_PROMOEVENTS});
