import React from 'react';
import RequestSent from 'pages/MyTickets/pages/Sell/components/RequestSent/components/icons/RequestSent';
import { ArrowRight as ArrowRightIcon, QR } from 'components/Icons/Glyphs';
import { PrimaryButton } from 'components/buttons/Button';
import { link as tickets_url } from './../../../List';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// import './style.scss';

export default props => {
    
    const { t } = useTranslation('tickets')
    const tt = (label, params) => t(`sell_item.request_sent.${label}`, params);

    const history = useHistory();
    const goToTickets = () => history.push(tickets_url);

    return (
        <div className='zt-tickets__sell__confirm'>
            <h4 className='bold zt-tickets__sell__confirm__header'>{tt('label', 'Request sent!')}</h4> 
            <RequestSent />
            <div className='zt-p1 zt-tickets__sell__confirm__info'>{tt('description', `We received your sale request.
You will receive money in 24 hours.`)}</div>
            <PrimaryButton onClick={goToTickets} className='zt-tickets__sell__confirm__gotobutton' icon={QR}>{tt('button', 'Goto My Tickets')}  <ArrowRightIcon className='zt-tickets__sell__confirm__gotobutton__arrow'/></PrimaryButton>
        </div>
    )
}