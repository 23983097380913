import React from 'react';
import './style.scss';
import { CalendarIcon, LocationIcon } from 'components/Icons/Glyphs';

export const TYPE_EVENT_INFO_LINE_LOCATION = 'TYPE_EVENT_INFO_LINE_LOCATION';
export const TYPE_EVENT_INFO_LINE_DATE = 'TYPE_EVENT_INFO_LINE_DATE';

const icons = {
    [TYPE_EVENT_INFO_LINE_DATE]: <CalendarIcon  className='event-info-line__icon'/>,
    [TYPE_EVENT_INFO_LINE_LOCATION]: <LocationIcon  className='event-info-line__icon'/>
}

export default ({ type, children, card=false }) => {

    const Icon = () => icons[type || TYPE_EVENT_INFO_LINE_LOCATION];

    return (<div className={`event-info-line ${card ? 'event-info-line--card' : ''}`}>
        <Icon/><span className={`zt-p1 ${type === TYPE_EVENT_INFO_LINE_DATE ? 'bold': null}`}>{children}</span>
    </div>);
}