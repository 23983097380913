import * as constants from './constants';
import api from './api';

const activate = {
    start: () => ({type: constants.ACTIVATE_USER_BY_TOKEN_START}),
    success: () => ({type: constants.ACTIVATE_USER_BY_TOKEN_SUCCESS}),
    failure: error => ({type: constants.ACTIVATE_USER_BY_TOKEN_FAILURE, error})
}


export function activateUser(params) {
    return dispatch => {
        dispatch(activate.start());
        api.activateUser(params)
            .then(() => dispatch(activate.success()))
            .catch(error => dispatch(activate.failure(error)))
    }
}