import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';

export default function () {

    const token = useSelector(state => state.register.coreid.coreid.token);

    if (!token) {
        return {};
    }

    const { sub, 'sub.family_name': family_name, 'sub.full_name': full_name, 'sub.given_name': given_name, 'sub.national_id': id } = jwt.decode(token);
    return {
        id,
        sub,
        family_name,
        given_name,
        full_name,
        token
    }
}