import React from 'react';
import { Col, Row } from 'react-bootstrap';
import EventInfoLine, {TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE} from 'components/EventInfo';
import categories from '../../../../useSectionedEventsList/categories';
import SeasonTickets from './components/SeasonTickets';
import { useTranslation } from 'react-i18next';
import Purchases from './components/Purchases';
import Actions from './components/Actions';
import Tickets from './components/Tickets';
import Logotype from 'components/Logotype';
import FORMAT from 'config/date/format';
import moment from 'moment';
import types from './types';
import SeasonDate from './components/SeasonDate';
import Schedule from './components/Schedule';


export default ({ item, type }) => {

    const { t } = useTranslation('tickets');

    const { ticket_reservations: tickets, merchandise_reservations_groups: purchases } = item;

    const { event_type } = item;
    const ordinary_event = event_type === types.TYPE_ORDINARY;
    const seasonal_event = event_type === types.TYPE_SEASON;

    return (
        <div className='zt-tickets__group__event'>
            <Row>
                <Col xs md={8}>
                    <h2 >{item.name}</h2>
                    <div className='zt-p1 mb-4'>{item.performer_name}</div>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION} card>{item?.map?.venue?.name || '--'}</EventInfoLine>
                    {ordinary_event && <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>{t('date', {date: moment(item.start_date, FORMAT)})}</EventInfoLine>}
                    {seasonal_event && <><EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card><SeasonDate {...{item}} /></EventInfoLine><Schedule {...{items: item?.child_events_with_item_reservations}} /></>}
                    <Tickets {...{tickets}} />
                    <Purchases {...{event: item}} />
                    {type !== categories.ARCHIVED && !seasonal_event && <Actions {...{event_id: item?.id, tickets}} />}
                    {seasonal_event && <SeasonTickets {...{items: item?.child_events_with_item_reservations}} />}
                </Col>
                <Col className='d-none d-md-block' md={4}>
                    <div className="float-right">
                        <div className='zt-webshop__event__media'>
                            {item.thumbnail ?
                            <img alt={item.name} className='zt-webshop__event__media__image' src={`${item.thumbnail}`} />
                            :
                            <Logotype className='zt-webshop__event__media__logotype'/>
                            }    
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}