import Error from 'pages/Event/components/Error';
import NoTickets from './components/NoTickets';
import Loading from 'pages/WebShop/pages/List/components/Loading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './../../store/actions';
import { Col, Container, Row } from 'react-bootstrap';
import useSectionedEventsList from './useSectionedEventsList';
import useHaveTickets from './useHaveTickets';
import Group from './components/Group';

export default props => {

    const dispatch = useDispatch();

    const { events: groups, loading, error } = useSectionedEventsList();
    const haveTickets = useHaveTickets();
    const refresh = () => dispatch(actions.getTickets());

    useEffect(() => {
        refresh();
    }, []);


    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Container>
                <Row>
                    <Col xs lg="9">
                        <Error retry={() => refresh()} />
                    </Col>
                </Row>
            </Container>
    }

    if (!haveTickets) {
        return <NoTickets />
    }    

    return groups?.map((group, key) => <Group {...{key, group}} />);
}