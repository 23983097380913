import FRIENDMODE from './mode'

export default function useFriendMode(friend) {
    if (!friend) {
        return FRIENDMODE.UNSET;         
    }

    const { user = null, searching: loading, kid, error } = friend;

    if (loading) {
        return FRIENDMODE.LOADING;
    }

    if (error) {
        return FRIENDMODE.ERROR;
    }

    if (kid) {
        return FRIENDMODE.KID;
    }

    if (+user.id) {
        return FRIENDMODE.EXISTED;
    }

    if (user.id === 0) {
        return FRIENDMODE.NEWUSER;
    }

    return FRIENDMODE.UNSET; 
}