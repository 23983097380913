import React, { useState } from 'react';
import Panel from 'components/Panel';
import { ClearIcon } from 'components/Icons/Glyphs';
import './style.scss';
import { SecondaryButton } from 'components/buttons/Button';
import RadioGroup from 'components/Inputs/RadioGroup';
import Driver from './swe/Driver.png';
import Passport from './swe/Passport.png';
import IDCard from './swe/IdCard.png';

import FinIDCard from './international/finidcard.png';
import FinPassport from './international/finpassport.png';

export const MODE_INTERNETIONAL = 'MODE_INTERNETIONAL';
export const MODE_SWEDEN = 'MODE_SWEDEN';

const samples = {
    [MODE_SWEDEN]: {
        driving_licence: {
            view: Driver,
            code: '630821-2817',
            label: 'Driving Licence',
            description: ''
        },
        passport_swe: {
            view: Passport,
            code: '870314-2391',
            label: 'Passport',
            description: ''

        },
        id_card_swe: {
            view: IDCard,
            code: '870514-2391',
            label: 'ID Card',
            description: ''
        }
    },
    [MODE_INTERNETIONAL]: {
        passport: {
            view: FinPassport,
            code: 'FX1234567',
            label: 'Passport',
            description: ''
        },
        id_card: {
            view: FinIDCard,
            code: '000000000',
            label: 'National ID Card',
            description: ''
        }        
    }
};

export default ({ t, mode, ...props }) => {
   

    const close = () => props.onClose && props.onClose();
    const update = () => {props.feedback && props.feedback(type); props.onClose && props.onClose();};

    if (![MODE_INTERNETIONAL, MODE_SWEDEN].includes(mode)) {
        throw Error('Mode not set or unrecognized mode');
    }

    const currentSamples = samples[mode];
    const [type, setType] = useState(props.defaultValue || Object.keys(currentSamples)?.[0]);

    const items = Object.keys(currentSamples).map(key => ({id: key, label: t(`using_document.document.inputs.type.types.${key}.label`, currentSamples[key].label)}));

    return (
    <Panel className='zt-document-samples '>
        <ClearIcon className='zt-document-samples__closeicon' onClick={close} />
        <div className='zt-document-samples__content d-flex flex-column justify-content-center'>
            <h4 className=''>{t('using_document.document.samples.label', 'Samples of document which you\'ll use as a Ticket')}</h4>
            <RadioGroup
                required
                name='type'                                    
                label={t('using_document.document.inputs.type.label', 'Choose a Document type')} 
                defaultValue={type}
                onChange={value => setType(value)}
                items={items} />
            <img className='zt-document-samples__content__image' src={samples[mode][type].view} alt='sample' />
            <div className='zt-document-samples__content__codelabel zt-p3'>{mode === MODE_INTERNETIONAL ? t(`using_document.document.inputs.type.types.passport.description`, 'Example of a document number') : t(`using_document.document.inputs.type.types.${type}.description`, 'Example of a document number')}</div>
                <div className='zt-document-samples__content__code '><h4 className='light'>{samples[mode][type].code}</h4></div>
            <SecondaryButton onClick={update}>{t('using_document.document.samples.button', 'Done')}</SecondaryButton>
        </div>
    </Panel>
    );
}