export const email = {
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    }
}

export const password = {
    minLength: 6 
}

export const name = {
    minLength: 2,
    pattern: /[A-Za-z]{3}/
}

export const lastName = {
    minLength: 2,
    pattern: /[A-Za-z]{3}/
}