import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Event from './components/Event';

export default ({ group }) => {

    const { type, data: events } = group;

    const { t } = useTranslation('tickets');

    return (
    <>
        <Container className='zt-tickets__group'>
            <Row className="justify-content-md-center">
                <Col xs lg="9">
                    <h4 className='light zt-tickets__group__header'>{t(`feed.categories.${type}`, type)}</h4>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs lg="9">
                    {events?.map((item, key) => <Event {...{key, item, type}} />)}
                </Col>
            </Row>            
        </Container>
    </>
    );
}