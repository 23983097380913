export default [
    '#4CB050',
    '#2196F5',
    '#FFE302',
    '#F34334',
    '#FE9900',
    '#B80743',
    '#673BB7',
    '#1E37C2',
    '#007E72',
    '#B7512F'
];