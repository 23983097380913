import * as constants from './constants';
import api from './api';

export const reset = () => ({type: constants.RESET_SEATS});

export const setMatrix = matrix => ({type: constants.SET_SEATSMAP_MATRIX, payload: matrix});

export const fetchSeatsActions = {
    start: section => ({type: constants.FETCH_SEATSMAP_START, payload: section}),
    success: payload => ({type: constants.FETCH_SEATSMAP_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_SEATSMAP_FAILURE, error})
}

export function fetchSeatsMap(id, section) {
    return (dispatch, getState) => {
        dispatch(fetchSeatsActions.start(section));
        api.fetchSeatsMap(id, section)
            .then(response => {
                const { data=null } = response;
                dispatch(fetchSeatsActions.success(data || null));
            })
            .catch(error => dispatch(fetchSeatsActions.failure(error)));         
    }
}
