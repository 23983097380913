import React, { useRef, useEffect, useState } from 'react';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import colorByLevel from './../PickSeats/Seats/colorByLevel';
import { PrimaryButton } from 'components/buttons/Button';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRight } from 'components/Icons/Glyphs';
import * as ticketsActions from '../store/actions';
import ToolTipBase from './../components/ToolTip';
import { useTranslation } from 'react-i18next';
import './style.scss'

const ToolTip = ({ section, onSelect=null, t, ...props }) => {
    if (!section) {
        return null;
    }    

    return (
    <ToolTipBase {...props}>
        <div className='zt-event-buy__tooltip__header zt-p3'>{t('sections.tooltip.label', 'Section')} <b>{section.label}</b></div>
        <div className='zt-nav'>{t('sections.tooltip.available', {defaultValue: 'Available', count: section.info?.available || '0'})}</div>
        { !!section.info?.available && <PrimaryButton className='zt-event-buy__tooltip__button' onClick={onSelect}>{t('sections.tooltip.button', 'Pick Seats')} <ArrowRight /></PrimaryButton> }
    </ToolTipBase>);
}

const classes = {
    empty: 'zt-section-map__leaflet__section--empty',
    selected: 'zt-section-map__leaflet__section--selected'
}

const getCoords = elem => {
    const parent = document.getElementsByClassName('zt-section-map__leaflet')[0].getBoundingClientRect(); 
    const box = elem.getBoundingClientRect();
    const width = box.right - box.left;
    const height = box.bottom - box.top;

    return {
        top: box.top - parent.top + height/2,// + window.pageYOffset,
        bottom: parent.bottom - box.bottom + height/2,
        left: box.left - parent.left + width/2,// + window.pageXOffset,
        width,
        height
    };
};

const initalizeSections = (levels, sections, sectionEls) => {
    if (sectionEls) {
        sectionEls.forEach(sectionShape => {
            const sectionId = sectionShape.dataset.section;
            if (sections[sectionId]) {
                const info = sections[sectionId];
                if (info.level) {
                    sectionShape.style.fill = colorByLevel(levels, sections[sectionId].level);
                }
                if (!info?.places) {
                    sectionShape.classList.add(classes['empty']);
                }
            }else{
                sectionShape.classList.add(classes['empty']);
            }
        })
    }
}

const Leaflet = ({ children, ...props}) => <div className='zt-section-map__leaflet' {...props}>{children}</div>

export default ({ className, event, ...props }) => {
    const ref = useRef();
    const { t } = useTranslation('buying');
    
    const { map:sectionMapSVG, sections, levels, loading } = useSelector( store => (sections => ({
        map: sections.map, 
        sections: sections.sections,  // Data for map
        levels: sections.prices, 
        loading: sections.loading
    }))(store.event.maps.sections));

    const [selected, setSelected] = useState(null); // {label:'SECTION', coords: {x, y, w, h}}

    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(actions.fetchSectionMap(event.id));
    // }, [dispatch, event.id]);

    useEffect(() => {
        if (sectionMapSVG && ref.current && levels && sections) {
            const sectionShapes = document.querySelectorAll("*[data-section]");
            initalizeSections(levels, sections, sectionShapes);
        }
    }, [sectionMapSVG, ref, sections, levels]);

    useEffect(() => {
        if (selected) {
            const toolTip = document.getElementsByClassName('zt-section-map__leaflet__tooltip')[0];
            // toolTip.
            if (toolTip) {
                toolTip.style.left = selected.coords.x + "px";
                toolTip.style.bottom = selected.coords.y + "px";
                toolTip.style.visibility = 'visible';
            }
        }
    }, [selected])

    const onHover = element => {
        const section = element?.dataset?.section;
        if (section) { //&& (!selected || section !== selected.label)
            Array.from(document.getElementsByClassName(classes['selected'])).forEach( element => element.classList.remove(classes['selected']));
            const coords = getCoords(element); 
            setSelected({ label: section, coords: {x:coords.left, y:coords.bottom, w:coords.width, h:coords.height}});
            element.classList.add(classes['selected']);
        } else {
            Array.from(document.getElementsByClassName(classes['selected'])).forEach( element => element.classList.remove(classes['selected']));
            setSelected(null);
        }

    }

    const [debouncedCallback] = useDebouncedCallback(
        // function
        (element) => {
          onHover(element)
        },
        // delay in ms
       300 
      );

    const onSelect = section => {
        dispatch(ticketsActions.selectSection(section));
    }

    return (
        <div className='zt-event-buy__map'>
        {
         loading ?
            <Leaflet>
                <MiniLoader />
            </Leaflet>
            :
            <>
            <Leaflet>
                <div className='zt-section-map__leaflet__map' ref={ref} dangerouslySetInnerHTML={{__html:sectionMapSVG}} onClick={e => {
                    // const rect = e.target.getBoundingClientRect();
                    // console.log(rect.top, rect.right, rect.bottom, rect.left);
                }} onMouseOver={e => debouncedCallback(e?.target)}></div>
                <ToolTip className='zt-section-map__leaflet__tooltip' section={selected && {label: selected.label, info: sections[selected.label]}} onSelect={e => onSelect(selected.label)} t={t} />
            </Leaflet>
            </>
        }
        </div>
    );
}
