import * as actions from './constants';

export const ERROR_USER_NOT_FOUND = 'ERROR_USER_NOT_FOUND';
export const ERROR_USER_NOT_APPLICABLE = 'ERROR_USER_NOT_APPLICABLE';

export const defaultState = {
    phone: null,
    loading: false,
    checked: false,
    phone: null,
    friend: null /*{
        id,
        first_name,
        last_name
    },*/, // {id: "16", first_name: "Astrid", last_name: "Sudström", register_type: "document"}
    ticket: null, //{type: "normal"}
    error: null
}

export function reducer(state=defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.SEARCHFRIEND_RESET: {
            return {
                ...defaultState
            }
        }

        case actions.SEARCHFRIEND_START: {
            return {
                ...defaultState,
                phone: payload,
                loading: true
            }
        }

        case actions.SEARCHFRIEND_SUCCESS: {
            const { user: friend, ticket = null } = payload;
            return {
                ...defaultState,
                phone: state.phone,
                checked: true,
                friend,
                ticket,
                valid: true
            }
        }

        case actions.SEARCHFRIEND_FAILURE: {
            if (error && error.__CANCEL__) { //Request cancelled 
                return {
                    ...defaultState,
                }
            }

            const { status = null } = error?.response;
            
            if (+status === 404) { //User not found
                console.log('user not found!');
                return {
                    ...defaultState,
                    phone: state.phone,
                    checked: true,
                    error: ERROR_USER_NOT_FOUND
                }
            }

            if (+status === 406) {
                console.log('user not applicable');
                return {
                    ...defaultState,
                    phone: state.phone,
                    checked: true,
                    error: ERROR_USER_NOT_APPLICABLE
                }                
            }

            return {
                ...defaultState,
                checked: true,
                error
            }
        }

        default: {
            return state;
        }
    }
}