import React, { useCallback, useEffect } from 'react';

import { PrimaryButton, LightButton, LinkButton } from 'components/buttons/Button';
import { ClearIcon, ArrowRight, BankID } from 'components/Icons/Glyphs';
import { link as registrationLink, bankIdLink } from 'pages/Registration';
import { link as recoveryLink } from 'pages/PasswordRecovery';
import GooglePlay from 'components/buttons/stores/GooglePlay';
import AppleStore from 'components/buttons/stores/AppleStore';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from 'components/Inputs/Input';
import * as actions from './state/actions';
import * as formRules from 'config/forms';
import { useForm } from 'react-hook-form';
import Divider from 'components/Divider';
import Panel from 'components/Panel';
import './style.scss';

export default ({ onClose, ...props })=> {
    const { t } = useTranslation('login');
    const {  formState: { errors }, register, handleSubmit, watch, setValue, clearErrors, setError, reset } = useForm({
            criteriaMode: "all"
        });
    const { loading, error } = useSelector(state => state.auth);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            setError("email", {type: 'notMatch', message: t('error', 'Invalid email and password, or it is a BankId account')});
            // setError("password", "notMatch", "Invalid email or password");
            setValue('password', '');
        } else {
            reset();
        }
    }, [error, setError, setValue, reset, t]);

    const resetField = useCallback( name => {
        setValue(name, '');
        clearErrors(name);
    }, [setValue, clearErrors]);

    const fields = watch(['email', 'password'], false);
    const onSubmit = () => { const [email, password] = fields; dispatch(actions.login(email, password)); }
    const filled = Object.values(fields).every(field => (field !== null && field !== '' ));
    const disabled = !filled || loading;

    return <Panel className='zt-login d-flex flex-column justify-content-center'>
        <ClearIcon className='zt-login__closeicon' onClick={() => onClose && onClose()} />
        <h4 className='zt-login__header'>{t('label', 'Login')}</h4>
        <form onSubmit={handleSubmit(onSubmit)} >
            <Input 
                required
                disabled={loading}
                name="email"  
                {...register('email', {
                    ...formRules.email,
                    required: 'Required',
                    pattern: {
                        ...formRules.email.pattern,
                        message: t('inputs.email.error', 'invalid email address')
                }})} 
                label={t('inputs.email.label','Email:')} 
                placeholder={t('inputs.email.placeholder', 'Enter login email')}
                onClear={() => resetField('email')}
                error={errors?.email?.message}
            />
            <Input 
                required
                disabled={loading}
                name="password" 
                type="password"  
                {...register('password', {
                    ...formRules.password,
                    required: 'Required',
                })} 
                label={t('inputs.password.label','Password:')} 
                placeholder={t('inputs.password.placeholder', 'Enter password')} 
                onClear={() => resetField('password')}
                error={errors?.password?.message}
            />
            {!disabled && <input type="submit" name="login" value="Login" style={{position: 'absolute', height: 0, width: 0, border: 'none', padding: '0'}} hidefocus="true" tabindex="-1" />}
        </form>
        <PrimaryButton {...{disabled, loading}} error={error ? t('error', 'Invalid email or password') : null} className='zt-login__login-button' onClick={handleSubmit(onSubmit)}>{t('buttons.login', 'Login')}</PrimaryButton>
        <NavLink className='zt-login__resetpassword' onClick={() => onClose && onClose()} to={recoveryLink}><span className='zt-p2'>{t('links.reset_password', 'Reset password?')}</span></NavLink>
        <div className='zt-login__empty' />
        <div className='zt-login__or zt-p2 '>{t('labels.or', 'or')}</div>
        <PrimaryButton disabled={false} className='zt-login__login-button--bankid' onClick={() => history.push(bankIdLink)}>
            <BankID />
            {t('buttons.login_bankid', 'Login using BankID')}
        </PrimaryButton>
        <div className='zt-login__empty' />
        <div className='zt-login__noaccount'><span className='zt-p2'>{t('labels.dont_have_an_account', 'Don\'t have an account?')}</span></div>
        <LinkButton to={registrationLink}><LightButton className='zt-login__registration-button'>{t('buttons.registration', 'Registration')} <ArrowRight /></LightButton></LinkButton>
        <Divider className='zt-login__divider' />
            <div className='zt-login__gettheapp__label'>
                <span className='zt-p2'>{t('labels.get_the_app', 'For your convenience – get the App!')}</span>
            </div>
            <div className='zt-login__gettheapp__icons'>
                <AppleStore className='zt-login__gettheapp__icons__icon'/>
                <GooglePlay className='zt-login__gettheapp__icons__icon'/>
            </div>
        <Divider className='zt-login__divider' />
        <div className='zt-login__footer'>
            <NavLink to='/' className='zt-login__footer__link'><span className='zt-p2'>{t('links.privacy', 'Privacy')}</span></NavLink>
            <NavLink to='/' className='zt-login__footer__link'><span className='zt-p2'>{t('links.terms', 'Terms')}</span></NavLink>
        </div>
    </Panel>
}