import { useSelector } from "react-redux";

export default function useHaveTickets() {
    const { events } = useSelector(state => state.tickets);

    if (events && events.length) {
        return true;
    }

    return false;
}