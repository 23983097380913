import React, { useEffect } from 'react';
import usePaymentError from 'pages/Event/pages/BuyTickets/Checkout/store/usePaymentError';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import InnerHTML from 'dangerously-set-html-content';
import Error from 'pages/Event/components/Error';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useProceedPayment from 'pages/Event/pages/BuyTickets/Checkout/store/useProceedPayment';

export const path = '/checkout';
export const link = path;

export default ({ reservationId, ...props}) => {
    const history = useHistory();

    const { t } = useTranslation('buying');

    const { proceedPayment, processing, reset, step, gui } = useProceedPayment({reservationId});
    const error = usePaymentError()

    const reload = () => reset();

    useEffect(() => {
        if (!reservationId) {
            return;
        }
        proceedPayment();
    }, [reservationId]);

    return (
        <>
            {error ?
            <Error retry={() => reload()}/>
            :
            (gui ? 
            <div className='zt-webshop-cart-checkout__gui__widget'>
                {gui && <InnerHTML html={gui} />}
            </div>
            :
            <div style={{height:'50vh'}}><MiniLoader /></div>
            )
            }
        </>
    );
}