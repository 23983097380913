import * as actions from './constants'

export const defaultState = {
    loading: false,
    done: false,
    error: null
}

export default (state = defaultState, action) => {
    const { type, payload = null } = action;

    switch (type) {
        case actions.RESET_PASSWORD_START:
            return {
                ...defaultState,
                loading: true
            }
        case actions.RESET_PASSWORD_SUCCESS:
            return {
                ...defaultState,
                loading: false,
                done: true,
            }
        case actions.RESET_PASSWORD_FAILED:
            return {
                ...defaultState,
                error: payload
            }
        default:
            return state;
    }
}
