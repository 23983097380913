import React, { useEffect } from 'react';
import useSectionSeatingType from '../PickSeats/useSectionSeatingType';
import { SEATING_TYPE_GENERALADMISSION } from '../store/seatingTypes';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import OwnerTicketType from './components/OwnerTicketType';
import { PrimaryButton } from 'components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import useAreKidsAllowed from './useAreKidsAllowed';
import { UserIcon } from 'components/Icons/Glyphs';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import * as actions from './actions';
import Friend from './Friend';
import './style.scss';

export default ({ event, qty, next, dispatcher, ...props }) => {

    const { t } = useTranslation('buying');
    const tt = (code, def) => t(`add_friends.${code}`, def);

    const { friends, user, loading, ticket, error } = useSelector(state => state.event.friends);
    const ownerNotAbleToAttend = !user?.types?.length;

    const dispatch = useDispatch();

    const is_GA = useSectionSeatingType() === SEATING_TYPE_GENERALADMISSION;

    useEffect(()=> {
        dispatch(actions.resetFriends());
        dispatch(actions.getPricesInfo(is_GA));
    }, [dispatch, is_GA]);

    const kidsAllowed = useAreKidsAllowed();

    const checkout = () => next();

    if (loading) {
        return  <div className='zt-event-buy__add-friends'>
            <MiniLoader />
        </div>;
    }

    if (ownerNotAbleToAttend) {
        return (
        <div className='zt-event-buy__add-friends'>
            <div className='zt-p1'>{tt('not_attending','You already have a ticket or you are not able to attend to this event.')}</div>
        </div>
        );
    }

    return (
    <div className='zt-event-buy__add-friends'>
       {qty > 1 &&
        <>
            <h4 className='bold zt-event-buy__add-friends__header'>{tt('header', 'Assign tickets to your friends')}</h4> 
            <div className='zt-p1'>{tt('subheader','Please fill in your friends requested contact details, choose ticket type and if you or your friend pay ticket.')}</div>
        </>
        }
       <div className='zt-event-buy__add-friends__myticket'>
           <OwnerTicketType {...{t, tt}} />
        </div>
        { !!friends.length ?
            friends?.map((friend, i)=><Friend key={i} {...{kidsAllowed}} friend={{...friend, id: i}} last={i === friends.length - 1} checkout={i === qty - 2 && checkout} {...{dispatcher}} />)
            :
            qty > 1 && <div className='zt-event-buy__add-friends__controlls'>
                <Row>
                    <Col md={{ span: 3, offset: 9 }}>
                        <PrimaryButton disabled={loading || error || ticket?.price === null } className='zt-event-buy__add-friends__controlls__addbutton' icon={UserIcon} onClick={()=>dispatch(actions.addFriend())}>{t('buttons.add_friend', 'Add Friend')}</PrimaryButton>
                    </Col>
                </Row>
            </div>
        }
    </div>);
}