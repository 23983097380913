import React, { useEffect } from 'react';
import * as actions from 'pages/MyTickets/pages/List/store/actions';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { PrimaryButton } from 'components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import Event from './components/Event';
import Panel from 'components/Panel';
import { Col, Container, Row } from 'react-bootstrap';

export default ({ t, onClose, ...props }) => {

    const { events, loading, error } = useSelector(state => state.tickets);

    const dispatch = useDispatch();

    const refresh = () => dispatch(actions.getTickets());

    useEffect(() => {
        refresh();
    }, []);    

    if (loading) {
        return <MiniLoader />
    }

    if (!events?.length) {
        return (
            <Panel>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="11">
                            <div className='zt-p2' style={{marginBottom: 16}} >
                                {t('forget_me.empty.label', 'There is no events you have purchase tickets for')}
                            </div>
                            <PrimaryButton onClick={() => onClose && onClose()}>{t('forget_me.empty.close', 'Close')}</PrimaryButton>
                        </Col>                
                    </Row>
                </Container>
                <></>
            </Panel>
        )
    }

    return (
        <Panel>
            {events?.map((item, key) => <Event {...{item, key, t: (label, def) => t(`forget_me.event.${label}`, def), onForget:() => onClose()}} />)}
        </Panel>
    )
}