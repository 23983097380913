import * as constants from './constants';
import api from './api';

export const addFriend = kid => ({type: constants.ADDFRIENDS_ADD_FRIEND, payload: kid ? ({kid}) : undefined});
export const removeFriend = friendId => ({type: constants.ADDFRIENDS_REMOVE_FRIEND, payload: friendId});
export const resetFriends = () => ({type: constants.ADDFRIENDS_RESET_FRIENDS});
export const resetFriend = friendId => ({type: constants.ADDFRIENDS_RESET_FRIENDS, friendId});

export const updateFriendName = (friendId, user) => ({type: constants.ADDFRIENDS_FRIEND_UPDATENAME, friendId, payload: user});
export const updateFriendPay = (friendId, payType) => ({type: constants.ADDFRIENDS_FRIEND_UPDATEPAY, friendId, payload: payType});

const prices = {
    start: () => ({type: constants.ADDFRIENDS_GETTINFO_START}),
    success: data => ({type: constants.ADDFRIENDS_GETTINFO_SUCCESS, payload: data}),
    failure: error => ({type: constants.ADDFRIENDS_GETTINFO_FAILURE, error})    
}

export const getPricesInfo = (ga = false) => {
    return (dispatch, getState) => {

        const eventId = getState().event.details.item?.id;
        const { section, tickets: seats, qty } =  getState().event.tickets;

        // dispatch(payActions.resetPayment());
        
        dispatch(prices.start());
        
        api.pricesInfo(eventId, ga ? 
                Array.from(Array(qty).keys()).map(i => ({section}))
            : 
                seats.map(s => ({section, row: `${s.row}`, seat: `${s.seat}`}))
            )
            .then(result => {
                dispatch(prices.success(result?.data || null));
            })
            .catch(error => {
                dispatch(prices.failure(error));
            });
    }
}

const myticket = {
    start: () => ({type: constants.ADDFRIENDS_GETTICKETINFO_START}),
    success: data => ({type: constants.ADDFRIENDS_GETTICKETINFO_SUCCESS, payload: data}),
    failure: error => ({type: constants.ADDFRIENDS_GETTICKETINFO_FAILURE, error})       
}

export const getMyTicketInfo = () => {
    return (dispatch, getState) => {
        const eventId = getState().event.details.item?.id;
        if (!+eventId) {
            return;
        } 
        dispatch(myticket.start());
        api.ticketInfo(eventId)
        .then( result => {
            dispatch(myticket.success(result.data || null));
        })
        .catch( error => dispatch(myticket.failure(error)));
    }    
}

export const setKid = (friendId, isKid) => {
    return (dispatch, getState) => {
        dispatch({type: constants.ADDFRIENDS_FRIEND_SETKID, friendId, payload: isKid});
    }
}

export const setPrice = (friendId, priceId, type, kids=false) => {
    return (dispatch, getState) => {
        if (friendId === null) {
            //Reset price if it is used in another friend:
            getState().event.friends.friends
                .map( (f, i) => ({...f, id: i}))
                .filter(f => f.ticket?.price === priceId)
                    .forEach( f => dispatch({type: constants.ADDFRIENDS_FRIEND_SETPRICE, friendId: f.id, payload: null}));
        }
        dispatch({type: constants.ADDFRIENDS_FRIEND_SETPRICE, friendId, payload: {price: priceId, type, kids}});
    }
}

const search = {
    start: (friendId, phone) => ({type: constants.ADDFRIENDS_FINDBYPHONE_START, friendId, payload: {phone}}),
    success: (friendId, data) => ({type: constants.ADDFRIENDS_FINDBYPHONE_SUCCESS, friendId, payload: data}),
    failure: (friendId, error) => ({type: constants.ADDFRIENDS_FINDBYPHONE_FAILURE, friendId, error})    
}
let searchRequestCancel = null;

export const searchFriend = (friendId, searchPhone) => {
    let phone;
    if (searchPhone.charAt(0) === '+') {
        phone = searchPhone.substring(1);
    } else {
        phone = searchPhone;
    }
    
    return (dispatch, getState) => {
        const eventId = getState().event.details.item?.id;

        if (!+eventId) {
            throw 'No event id, can\'t searching for friends';
        }

        dispatch(search.start(friendId, phone));
        
        api.searchFriend(eventId, phone, c => searchRequestCancel = c)
            .then(response => dispatch(search.success(friendId, response?.data || null)))
            .catch(error => dispatch(search.failure(friendId, error)));
    }
}

export const cancelSearchFriend = (friendId) => {
    if (searchRequestCancel) {
        searchRequestCancel();
        searchRequestCancel = null;
    }
}