import React from 'react';
import { PrimaryButton } from 'components/buttons/Button';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRight } from 'components/Icons/Glyphs';
import useSelectedSection from '../useSelectedSection';
import Stepper from 'components/Inputs/Stepper';
import * as actions from './../../store/actions';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default ({ event, next, ...props }) => {

    const section = useSelectedSection();
    const { available = null } = section || {};

    const maxNumberTickets = event?.max_number_tickets || 0;
    const { qty:ticketsQty } = useSelector(state => state.event.tickets);
    const dispatch = useDispatch();

    const { t } = useTranslation('event');

    return (
    <div className='zt-event-buy-ga'>
        <div className='zt-event-buy-ga__tickets-number-picker'>
            <h4 className='zt-event-buy-ga__tickets-number-picker__title'>{t('number_of_tickets.label', 'Number of ticktes')}</h4>
            <div className='zt-event-buy-ga__tickets-number-picker__info zt-p3'>{maxNumberTickets} {t('restrictions.max_in_group', 'Max number of tickets in a group')}</div>
            <div className='zt-event-buy-ga__tickets-number-picker__controll'>
                <Stepper max={Math.min(maxNumberTickets, available)} value={ticketsQty} onChange={qty => dispatch(actions.setTicketsQty(qty))} />
            </div>
            {/* <div className='zt-event-buy-ga__tickets-number-picker__info zt-p3'>{t('restrictions.available', {defaultValue: 'Number of available tickets: {{count}}', count: available})}</div> */}
        </div>
    </div>);
}