import { useReducer } from "react";
import { reducer, defaultState } from './reducer';

import logger from 'use-reducer-logger';

import * as actions from './actions';
import { useSelector } from "react-redux";

export { ERROR_USER_NOT_FOUND, ERROR_USER_NOT_APPLICABLE } from './reducer';

export default function usePhoneSearch(mode, eventId) {
    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    if (!eventId) {
        throw 'No eventId is set (no ticket selected or information is broken)';
    }

    const search = actions.search(dispatch, eventId, mode);
    const reset = actions.reset(dispatch);

    return {
        state,
        search,
        reset,
        retry: () => search(state.phone)
    }
}