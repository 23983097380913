import React, { useState } from 'react';
import { ArrowRight } from 'components/Icons/Glyphs';
import Button from 'components/buttons/Button';
import Divider from 'components/Divider';
import { Modal } from 'react-bootstrap';
import Events from './components/Events';

export default ({ t, ...props }) => {

    const [showEvents, setShowEvents] = useState(false);

    return (
        <div>
            <h4 className='zt-profile__label'>{t('forget_me.label', 'Forget me for specific event')}</h4>
            <Button onClick={() => setShowEvents(true)}>{t('forget_me.button', 'Go to event list')} <ArrowRight /></Button>
            <Divider />
            <Modal show={showEvents} size="xl" centered onHide={() => setShowEvents(false)} className='zt-modal-centered'>
                {showEvents && <Events {...{t, onClose: () => setShowEvents(false)}} />}
            </Modal>              
        </div>
    );
}