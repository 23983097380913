import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import useConfiguration from 'components/Footer/useConfiguration';
import { SearchIcon, ExportIcon } from 'components/Icons/Glyphs';
import AppleStore from 'components/buttons/stores/AppleStore';
import GooglePlay from 'components/buttons/stores/GooglePlay';
import { NavigationButton } from 'components/buttons/Button';
import { NavLink, useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import UserControlls from './UserControlls';
import Logotype from 'components/Logotype';
import Filter from './Filter';
import Search from './Search';
import Banner from './Banner';
import './style.scss';

const HEADER_MODE_SEARCH = 'HEADER_MODE_SEARCH';
const HEADER_MODE_FILTER = 'HEADER_MODE_FILTER';

const Extra = () => {
    const location = useLocation();
    //TODO: Path logic need to be moved to ZTRouter hooks/component
    return location.pathname === '/' ? <Banner /> : null; 
}

const AppInstallIcons = props => {
    return <>
        <AppleStore style={{margin: 8}} />
        <GooglePlay style={{margin: 8}} />
    </>
}

export default () => {
    const [mode, setMode] = useState(HEADER_MODE_SEARCH); 
    const location = useLocation();
    useEffect(()=>{
        if (location.pathname === '/filter') {
            setMode(HEADER_MODE_FILTER);
            return;
        }
        if (!['/', '/fliter'].includes(location.pathname)) {
            setMode(null);
            return;
        }
        setMode(HEADER_MODE_SEARCH);
    }, [location]);

    const onSearchClick = () => setMode(HEADER_MODE_SEARCH);
    const onFilterClick = () => setMode(HEADER_MODE_FILTER);

    const isHome = ['/', '/filter'].includes(location.pathname);

    const { trim } = useConfiguration();

    return (
        <Container>
            <Row className='header'>
                <Col xs={6} sm={4} className='logo d-flex justify-content-start align-items-center'>
                    <NavLink to='/'><Logotype /></NavLink>
                </Col>
                {!trim &&<Col sm={4} className='order-2 order-sm-1 d-flex justify-content-center align-items-center'>
                    <AppInstallIcons />
                </Col>}
                {!trim && <Col xs={6} sm={4} className='order-1 order-sm-2 controls d-flex justify-content-end align-items-center'>
                    {isHome &&
                    <React.Fragment>
                        <NavigationButton icon={SearchIcon} onClick={onSearchClick} selected={mode === HEADER_MODE_SEARCH} />
                        <NavigationButton icon={ExportIcon} onClick={onFilterClick} selected={mode === HEADER_MODE_FILTER} />
                    </React.Fragment>
                    }
                    <UserControlls />
                </Col>}
            </Row>
            <SwitchTransition>
            <CSSTransition key={mode || 'banner'} in={mode === HEADER_MODE_FILTER} timeout={200} classNames={mode === null ? 'headertr-banner' : 'headertr'} unmountOnExit>
                {{
                    [HEADER_MODE_SEARCH]: <Search />,
                    [HEADER_MODE_FILTER]: <Filter /> 
                }[mode] || <Extra />}
            </CSSTransition> 
            </SwitchTransition>
        </Container>
    );
};