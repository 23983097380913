import React, { useCallback, useEffect } from 'react';
import useFetchUnpaidReservations from './useFetchUnpaidReservations';
import ActiveReservationDialog from './components/ActiveReservationDialog';
import useIsAuthorised from 'hooks/auth/useIsAuthorised';
import Button from 'components/buttons/Button';
import './styles.scss';

export default ({ className, children, disabled, onClick, id, ...props }) => {

    const { state: { loading, reservation, error, checked }, fetch, reset } = useFetchUnpaidReservations();
    const authorised = useIsAuthorised();

    const checkReservation = useCallback(() => authorised ? fetch(id) : onClick && onClick(), [authorised]);

    useEffect(() => {
        if (!checked) {
            return;
        }

        if (reservation === null) {
            onClick && onClick();
        }
    }, [checked, reservation]);

    const active_reservation = checked && +reservation?.id;

    const onClose = () => reset();
    const onContinue = () => onClick && onClick();

    return (
        <>
            <Button className={`zt-bigbutton ${className} ${(disabled ? 'zt-bigbutton__disabled' : '')}`} {...props} {...{onClick: checkReservation, loading}}><h3 className='zt-bigbutton__label'>{children}</h3></Button>
            {active_reservation && <ActiveReservationDialog {...{id: reservation?.id, onClose, onContinue}} />}
        </>
    )
}