import React from 'react';
import { useSelector } from 'react-redux';
import Divider from 'components/Divider';
import Phone from './components/Phone';
import Email from './components/Email';

export default ({ t, refresh, ...props }) => {

    const { profile } = useSelector(state => state.profile);

    return (
        <div className='zt-profile__contacts'>
            <h4 className='zt-profile__label zt-profile__contacts__label'>{t('contacts.label', 'Contactable at')}</h4>
            <Phone {...{t, profile, refresh}} />
            <Email {...{t, profile, refresh}} />
            <Divider />
        </div>
    );
}