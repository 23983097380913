import React, { useState } from 'react';
import { ArrowRight, Kid as KidIcon, ClearIcon, QR as QRIcon, Seat as SeatIcon} from 'components/Icons/Glyphs';
import InfoLine from '../../../Tickets/components/InfoLine';
import { PrimaryButton } from 'components/buttons/Button';
import RadioGroup from 'components/Inputs/RadioGroup';
import Panel from 'components/Panel';


const Ticket = ({ ticket: { name, row, seat, type }}) => <div className='zt-tickets__group__event__actions__modal__select__ticket'>
    <InfoLine bold><KidIcon className='event-info-line__icon' />{name}</InfoLine>
    <InfoLine bold><SeatIcon className='event-info-line__icon' /> {`Row ${row} Seat ${seat}`}</InfoLine>
    <InfoLine gray><QRIcon className='event-info-line__icon' /> {type}</InfoLine>
</div>;

export default ({ tickets, onSelect, onClose, t }) => {

    const [ticket, setTicket] = useState(null);

    const owner = tickets.find(t => t.is_child === false);
    const children = tickets.filter(t => t.is_child === true);

    //Sell children tickets first, then owner's
    const ticketsToSell = children?.length ? [...children] : [owner];

    return (
        <Panel className='zt-tickets__group__event__actions__modal'>
            <ClearIcon className='zt-tickets__group__event__actions__modal__closeicon' onClick={() => onClose && onClose()} />         
            <h4 className='zt-tickets__group__event__actions__modal__title'>{t('label', 'Choose Tickets to sell')}</h4>
            <RadioGroup 
                name='ticket_select'
                className='zt-tickets__group__event__actions__modal__select'
                defaultValue={null}
                items={ticketsToSell.map((ticket, key) => ({id: ticket.id, label: <Ticket {...{ticket}} />}))}
                onChange={id => setTicket(id)}          
            />
            <PrimaryButton disabled={!ticket} className='zt-tickets__group__event__actions__modal__button' onClick={() => onSelect && onSelect(ticket)}>{t('button', 'Proceed')} <ArrowRight /></PrimaryButton>
        </Panel>
    );
}