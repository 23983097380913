import * as constants from './constants';
import api from './api';

export const reset = () => ({type: constants.UPDATTE_PROFILE_PRIVACYMODE_RESET});

const flow = {
    start: () => ({type: constants.UPDATTE_PROFILE_PRIVACYMODE_START}),
    success: payload => ({type: constants.UPDATTE_PROFILE_PRIVACYMODE_SUCCESS, payload}),
    failure: error => ({type: constants.UPDATTE_PROFILE_PRIVACYMODE_FAILURE, error})    
}

export const update = dispatch => mode => {
    dispatch(flow.start());
    api.save(mode)
        .then(response => dispatch(flow.success(response)))
        .catch(error => dispatch(flow.failure(error)));
}
