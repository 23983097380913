import adoptStats from '../adoptStats';
import { EVENTBUY_FETCH_DETAILS_SUCCESS } from '../store/constants';
import * as actions from './constants'

const defaultState = {
    map: null, //String <svg... 
    prices: null,
    sections: null,
    loading: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    
    switch (type) {
        case actions.FETCH_SECTIONMAP_START:
            return {
                ...defaultState,
                loading: true
            }

        case EVENTBUY_FETCH_DETAILS_SUCCESS:
        case actions.FETCH_SECTIONMAP_SUCCESS:
            const { svg: map, stats, sections: sectionsInfo } = payload;
            const { prices } = adoptStats(stats);

            const sections = {}
            sectionsInfo.forEach(section => {
                const { stats, ...info } = section;
                const { level, prices, priced, places, available } = adoptStats(stats);
                sections[section.name] = {...info, level, prices, priced, places, available};
            });
            return {
                ...state,
                map, 
                prices:prices?.sort((a, b) => a - b).reverse(), //Decrement sorting (5040, 3050, 2080 ...)
                sections,
                loading: false
            }

        case actions.FETCH_SECTIONMAP_FAILURE:
            return {
                ...defaultState,
                error
            }

        default:
            return state;
    }
}