import { useReducer } from "react";
import { reducer, defaultState } from './reducer';

import logger from 'use-reducer-logger';

import * as actions from './actions'

export default function useProceedSale(mode, eventId, ticketId) {

    if (!mode) {
        throw 'No sell mode provided';
    }

    if (!eventId) {
        throw 'No event id provided';
    }

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    const proceedSale = actions.proceedSale(dispatch, mode, eventId, ticketId, state.mode, state.kidsFriend, state.friendId);

    const setTargetMode = actions.setTargetMode(dispatch);

    const setKidsFriend = actions.setKidsFriend(dispatch);

    const setFriend = actions.setSellToFriend(dispatch);

    return {
        state,
        setTargetMode,
        proceedSale,
        setKidsFriend,
        setFriend
    }

}