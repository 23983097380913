import * as actions from './constants';

export const defaultState = {
    loading: false,
    saved: false,
    error: null
}

export default function reducer(state=defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.UPDATTE_PROFILE_PRIVACYMODE_RESET: {
            return {
                ...defaultState
            }
        }

        case actions.UPDATTE_PROFILE_PRIVACYMODE_START: {
            return {
                ...defaultState,
                loading: true
            }
        }


        case actions.UPDATTE_PROFILE_PRIVACYMODE_SUCCESS: {
            return {
                ...defaultState,
                saved: true
            }
        }

        case actions.UPDATTE_PROFILE_PRIVACYMODE_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }        

        default: {
            return state;
        }
    }
}