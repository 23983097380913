import FORMAT from 'config/date/format';
import moment from 'moment';

const DAYS_TO_EVENT = 7*24*60; //in minutes

const upcoming = (startdate, enddate) => {
    const minutesleft = moment(startdate, FORMAT).diff(moment(), 'minutes');
    const minutestillend = moment(enddate, FORMAT).diff(moment(), 'minutes');

    console.log(`${startdate} : minutes left: ${minutesleft}`);
    
    if (minutesleft < 0 && minutestillend < 0) { //Event in the past
        return false;
    }

    if (minutesleft > DAYS_TO_EVENT) { //Too early to show the event
        return false;
    }

    return true;
}


export default events => events?.filter(e => upcoming(e?.start_date, e?.end_date)) || [];
// ({
//         type: categories.UPCOMING,
//         data: events?.filter(e => true) || []
//     });