import React from 'react';
import Divider from 'components/Divider';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FeeRow = ({ children, label, ...props }) => <Row>
    <Col xs md="6" className="zt-tickets__sell__total__info"><span className='zt-p3'>{label}</span></Col>
    <Col xs md="6" className="zt-tickets__sell__total__info"><span className='zt-p1'>{children}</span></Col>
</Row>;

const TotalRow = ({ children, label, ...props }) => <Row>
    <Col xs md="6" className="zt-tickets__sell__total__info"><span className='zt-p3'>{label}</span></Col>
    <Col xs md="6" className="zt-tickets__sell__total__info"><h4>{children}</h4></Col>
</Row>;

export default ({ qty, price, fee }) => {

    const { t } = useTranslation('tickets')

    const priceFormat = price => t('common:price', {price})

    return <div className="zt-tickets__sell__total">
        <Row>
            <Col xs lg="12">
                <p className='zt-p1 zt-tickets__sell__form__description'>{t('sell.total.description', 'Service fee and other fees are not refundable.')}</p>
            </Col>
        </Row>        
        <Row>
            <Col sm md="6">
                <FeeRow label={t('sell_item.total.item', "Item price:")}>{priceFormat(price)}</FeeRow>
                <FeeRow label={t('sell_item.total.fee', "Refund service fee:")}>{priceFormat(-(fee))}</FeeRow>
                <Divider />
                <TotalRow label={t('sell_item.total.refund', "What you will get back:")}>{priceFormat((+price-fee)*qty)}</TotalRow>
            </Col>
        </Row>
    </div>
}