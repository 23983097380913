import * as constants from './constants';
import api from './api';

import * as modes from './../sellMode';
import * as targetModes from './sellToModes';

const flow = {
    start: () => ({type: constants.SELL_TICKETSELL_START}),
    success: payload => ({type: constants.SELL_TICKETSELL_SUCCESS, payload}),
    failure: error => ({type: constants.SELL_TICKETSELL_FAILURE, error})
}


export const proceedSale = (dispatch, mode, eventId, ticketId, targetMode, kidsFriend, friendId) => () => {
    dispatch(flow.start());

    const params = {
        friend: friendId || undefined,
        kids_friend: kidsFriend || undefined
    };

    const call = (() => {
        switch(mode) {

            case (modes.SELL_ALL): {
                return {
                    [targetModes.SELLTOANYONE]: () => api.all.toMarket(eventId, params),
                    [targetModes.SELLTOFRIEND]: () => api.all.toFriend(eventId, params)
                }[targetMode] || (() => Promise.reject(`no appropriate target mode: ${targetMode}`));
            }

            case (modes.SELL_TICKET):
            case (modes.SELL_ONLY_MINE): 
            {
                return {
                    [targetModes.SELLTOANYONE]: () => api.one.toMarket(ticketId, params),
                    [targetModes.SELLTOFRIEND]: () => api.one.toFriend(ticketId, params)
                }[targetMode] || (() => Promise.reject(`no appropriate target mode: ${targetMode}`));
            }

            default: {
                return (() => Promise.reject(`no appropriate mode: ${mode}`));
            }
    }});

    call()()
        .then(data => dispatch(flow.success(data.data)))
        .catch(error => dispatch(flow.failure(error)));

}

export const setTargetMode = dispatch => mode => dispatch({type: constants.SET_TICKETSELL_MODE, payload: mode});

export const setKidsFriend = dispatch => friendId => dispatch({type: constants.SET_TICKETSELL_KIDSFRIEND, payload: friendId});

export const setSellToFriend= dispatch => friendId => dispatch({type: constants.SET_TICKETSELL_TOFRIEND, payload: friendId});

export const resetSell = dispatch => () => dispatch({type: constants.RESET_TICKETSELL});