import { useSelector } from 'react-redux';
import * as payTypes from './payTypes';
import useTickets from './useTickets';

export default function useTicketsTotal() {
    
    const { prices } = useSelector(state => state.event.friends);
    const tickets = useTickets();

    if (!prices?.length || tickets[0]?.price === null) {
        return null;
    }

    let price = 0;
    tickets.forEach(t => {
        if (t.price === null || t.type === null) {
            return;
        }
        if (t.pay === payTypes.PAYTYPE_IPAY) {
            price += prices?.[t.price]?.[t.type]?.value || 0;
        }
    });

    return price;
}