import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import zipperticLangDetector from 'i18n/detector';
import numeral from 'numeral';
import moment from 'moment';
import 'moment/locale/sv';
const languageDetector = new LanguageDetector();
languageDetector.addDetector(zipperticLangDetector);

// load a locale
numeral.register('locale', 'se', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    // ordinal : function (number) {
        // return number === 1 ? 'er' : 'ème';
    // },
    currency: {
        symbol: 'SEK'
    }
});

const resources = {
    en: require('./locales/en.json'),
    se: require('./locales/se.json')
}

i18n
    .use(initReactI18next)
    .use(languageDetector)
    .init({
    resources,

    detection: {
          // order and from where user language should be detected
        order: ['localStorage', 'zippertic', 'navigator'],

          // cache user language on
        caches: ['localStorage'],
    },
    load: 'languageOnly',
    defaultLanguage: 'en',
    otherLanguages: ['se'],
    // fallbackLng: 'en',
    defaultNS: 'common',
    keySeparator: '.',
    returnObjects: true,
    fallbackLng: code => {
        if (code.startsWith('sv')) {
            return 'se';
        }
        if (code.startsWith('en-')) {
            return 'en';
        }
        return 'en';
    },
    nonExplicitSupportedLngs: true,
    interpolation: {
        escapeValue: false,
        format: function(value, format, lng = 'en') {
            const langStr = new Intl.Locale(lng);
            if (format === 'uppercase') return value.toUpperCase();
            if (format === 'price') {
                try {
                    const langStr = new Intl.Locale(lng);
                    numeral.locale(langStr.language === 'sv' ? 'se' : langStr.language);    
                } catch (error) {
                    numeral.locale('en');        
                }
                return numeral(value).format('0,0[.]00');
            }
            if (value instanceof Date) return moment(value).locale(langStr.language).format(format);
            if (value instanceof moment) return value.isValid() ? value.locale(langStr.language).format(format) : 'error in date';
            return value;
        }
    }
});

export default i18n;

