import * as actions from './constants'

const defaultState = {
    filter: null,
    items: null,
    loading: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch (type) {
        case actions.SET_CITIES_FILTER:
            return {
                ...state,
                filter: payload
            }
        case actions.RESET_CITIES_FILTER:
            return {
                ...state,
                filter: null
            }
        case actions.FETCH_CITIES_START:
            return {
                ...state,
                loading: true
            }
        case actions.FETCH_CITIES_SUCCESS:
            // const cities_fake = [
            //         'Stockholm',
            //         'Göteborg',
            //         'Malmö',
            //         'Uppsala',
            //         'Sollentuna',
            //         'Södermalm',
            //         'Örebro',
            //         'Linköping ',
            //         'Helsingborg'
            // ].map( (label, id) => ({id, label}) );
            const cities = payload?.collection?.map(item => ({id: item?.name, label: item?.name}));
            console.log(cities);
            return {
                ...state,
                items: cities,
                loading: false
            }
        case actions.FETCH_CITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error
            }
        default:
            return state;
    }
}