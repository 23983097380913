import { reservationCheckoutLink } from 'pages/Event';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function usePayForReservation() {

    const history = useHistory();

    return {
        goToPay: (id, event_id) => history.push(reservationCheckoutLink(id)(event_id))
    }
}