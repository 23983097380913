import React from 'react';

export const Age = () => <svg width="70" height="64" viewBox="0 0 70 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2784 2H41.7216C43.2852 2 44.787 2.61038 45.9073 3.70117L60.1857 17.6038C61.3457 18.7333 62 20.2836 62 21.9027V42.0973C62 43.7164 61.3457 45.2667 60.1857 46.3961L45.9073 60.2988C44.787 61.3896 43.2852 62 41.7216 62H20.7729C19.1102 62 17.522 61.31 16.3873 60.0947L3.61439 46.4142C2.57699 45.3031 2 43.8397 2 42.3196V21.9027C2 20.2836 2.6543 18.7333 3.8143 17.6039L18.0927 3.70117C19.213 2.61038 20.7148 2 22.2784 2Z" stroke="#C00000" strokeWidth="4"/>
    <path d="M25.0599 20.6V43H19.8759V24.76H15.3959V20.6H25.0599ZM44.2791 31.224C45.3885 31.7787 46.2311 32.504 46.8071 33.4C47.4045 34.296 47.7031 35.3307 47.7031 36.504C47.7031 37.8907 47.3085 39.1067 46.5191 40.152C45.7298 41.176 44.6205 41.976 43.1911 42.552C41.7618 43.1067 40.1085 43.384 38.2311 43.384C36.3538 43.384 34.7005 43.1067 33.2711 42.552C31.8631 41.976 30.7645 41.176 29.9751 40.152C29.2071 39.1067 28.8231 37.8907 28.8231 36.504C28.8231 35.3307 29.1111 34.296 29.6871 33.4C30.2845 32.504 31.1271 31.7787 32.2151 31.224C31.3831 30.712 30.7431 30.072 30.2951 29.304C29.8471 28.5147 29.6231 27.6187 29.6231 26.616C29.6231 25.3147 29.9858 24.184 30.7111 23.224C31.4365 22.264 32.4498 21.528 33.7511 21.016C35.0525 20.4827 36.5458 20.216 38.2311 20.216C39.9378 20.216 41.4418 20.4827 42.7431 21.016C44.0658 21.528 45.0898 22.264 45.8151 23.224C46.5405 24.184 46.9031 25.3147 46.9031 26.616C46.9031 27.6187 46.6791 28.5147 46.2311 29.304C45.7831 30.072 45.1325 30.712 44.2791 31.224ZM38.2311 24.024C37.1858 24.024 36.3431 24.28 35.7031 24.792C35.0845 25.2827 34.7751 25.9653 34.7751 26.84C34.7751 27.6933 35.0845 28.3653 35.7031 28.856C36.3218 29.3467 37.1645 29.592 38.2311 29.592C39.2978 29.592 40.1511 29.3467 40.7911 28.856C41.4311 28.3653 41.7511 27.6933 41.7511 26.84C41.7511 25.9653 41.4311 25.2827 40.7911 24.792C40.1511 24.28 39.2978 24.024 38.2311 24.024ZM38.2311 39.576C39.5325 39.576 40.5565 39.288 41.3031 38.712C42.0711 38.1147 42.4551 37.3253 42.4551 36.344C42.4551 35.3627 42.0711 34.584 41.3031 34.008C40.5565 33.432 39.5325 33.144 38.2311 33.144C36.9511 33.144 35.9378 33.432 35.1911 34.008C34.4445 34.584 34.0711 35.3627 34.0711 36.344C34.0711 37.3467 34.4445 38.136 35.1911 38.712C35.9378 39.288 36.9511 39.576 38.2311 39.576Z" fill="white"/>
    <path d="M53 23L64 15V49L53 41V23Z" fill="black"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M64 30V24H60L60 30H54V34H60L60 40H64V34H70V30H64Z" fill="#C00000"/>
</svg>

export const BabyNotAllowed = () => <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2784 2H41.7216C43.2852 2 44.787 2.61038 45.9073 3.70117L60.1857 17.6038C61.3457 18.7333 62 20.2836 62 21.9027V42.0973C62 43.7164 61.3457 45.2667 60.1857 46.3961L45.9073 60.2988C44.787 61.3896 43.2852 62 41.7216 62H20.7729C19.1102 62 17.522 61.31 16.3873 60.0947L3.61439 46.4142C2.57699 45.3031 2 43.8397 2 42.3196V21.9027C2 20.2836 2.6543 18.7333 3.8143 17.6039L18.0927 3.70117C19.213 2.61038 20.7148 2 22.2784 2Z" stroke="#C00000" strokeWidth="4"/>
    <mask id="path-3-inside-1" fill="white">
    <path fillRule="evenodd" clipRule="evenodd" d="M32 48C39.149 48 45.2029 43.3113 47.2545 36.8413C49.4087 36.2847 51 34.3282 51 32C51 29.6718 49.4087 27.7153 47.2545 27.1587C45.2029 20.6887 39.149 16 32 16C24.851 16 18.7971 20.6887 16.7455 27.1587C14.5913 27.7153 13 29.6718 13 32C13 34.3282 14.5913 36.2847 16.7455 36.8413C18.7971 43.3113 24.851 48 32 48Z"/>
    </mask>
    <path d="M47.2545 36.8413L46.7542 34.9049L45.6826 35.1818L45.348 36.2368L47.2545 36.8413ZM47.2545 27.1587L45.348 27.7632L45.6826 28.8182L46.7542 29.0951L47.2545 27.1587ZM16.7455 27.1587L17.2458 29.0951L18.3174 28.8182L18.652 27.7632L16.7455 27.1587ZM16.7455 36.8413L18.652 36.2368L18.3174 35.1818L17.2458 34.9049L16.7455 36.8413ZM45.348 36.2368C43.5523 41.9 38.2524 46 32 46V50C40.0457 50 46.8535 44.7227 49.1609 37.4458L45.348 36.2368ZM49 32C49 33.3942 48.0472 34.5708 46.7542 34.9049L47.7548 38.7777C50.7703 37.9986 53 35.2622 53 32H49ZM46.7542 29.0951C48.0472 29.4292 49 30.6058 49 32H53C53 28.7378 50.7703 26.0014 47.7548 25.2223L46.7542 29.0951ZM32 18C38.2524 18 43.5523 22.1 45.348 27.7632L49.1609 26.5542C46.8535 19.2773 40.0457 14 32 14V18ZM18.652 27.7632C20.4477 22.1 25.7476 18 32 18V14C23.9543 14 17.1465 19.2773 14.8391 26.5542L18.652 27.7632ZM15 32C15 30.6058 15.9528 29.4292 17.2458 29.0951L16.2452 25.2223C13.2297 26.0014 11 28.7378 11 32H15ZM17.2458 34.9049C15.9528 34.5708 15 33.3942 15 32H11C11 35.2622 13.2297 37.9986 16.2452 38.7777L17.2458 34.9049ZM32 46C25.7476 46 20.4477 41.9 18.652 36.2368L14.8391 37.4458C17.1465 44.7227 23.9543 50 32 50V46Z" fill="white" mask="url(#path-3-inside-1)"/>
    <path d="M31.5 23.5C28.5 12 37 11 37.5773 18.0961" stroke="white" strokeWidth="2"/>
    <circle cx="27.5" cy="34.5" r="1.5" fill="white"/>
    <circle cx="36.5" cy="34.5" r="1.5" fill="white"/>
    <path d="M29 40.5H34" stroke="white"/>
</svg>

export const BabyAllowed = () => <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="30" stroke="#2FBF6B" strokeWidth="4"/>
    <mask id="path-3-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M32 48C39.149 48 45.2029 43.3113 47.2545 36.8413C49.4087 36.2847 51 34.3282 51 32C51 29.6718 49.4087 27.7153 47.2545 27.1587C45.2029 20.6887 39.149 16 32 16C24.851 16 18.7971 20.6887 16.7455 27.1587C14.5913 27.7153 13 29.6718 13 32C13 34.3282 14.5913 36.2847 16.7455 36.8413C18.7971 43.3113 24.851 48 32 48Z"/>
    </mask>
    <path d="M47.2545 36.8413L46.7542 34.9049L45.6826 35.1818L45.348 36.2368L47.2545 36.8413ZM47.2545 27.1587L45.348 27.7632L45.6826 28.8182L46.7542 29.0951L47.2545 27.1587ZM16.7455 27.1587L17.2458 29.0951L18.3174 28.8182L18.652 27.7632L16.7455 27.1587ZM16.7455 36.8413L18.652 36.2368L18.3174 35.1818L17.2458 34.9049L16.7455 36.8413ZM45.348 36.2368C43.5523 41.9 38.2524 46 32 46V50C40.0457 50 46.8535 44.7227 49.1609 37.4458L45.348 36.2368ZM49 32C49 33.3942 48.0472 34.5708 46.7542 34.9049L47.7548 38.7777C50.7703 37.9986 53 35.2622 53 32H49ZM46.7542 29.0951C48.0472 29.4292 49 30.6058 49 32H53C53 28.7378 50.7703 26.0014 47.7548 25.2223L46.7542 29.0951ZM32 18C38.2524 18 43.5523 22.1 45.348 27.7632L49.1609 26.5542C46.8535 19.2773 40.0457 14 32 14V18ZM18.652 27.7632C20.4477 22.1 25.7476 18 32 18V14C23.9543 14 17.1465 19.2773 14.8391 26.5542L18.652 27.7632ZM15 32C15 30.6058 15.9528 29.4292 17.2458 29.0951L16.2452 25.2223C13.2297 26.0014 11 28.7378 11 32H15ZM17.2458 34.9049C15.9528 34.5708 15 33.3942 15 32H11C11 35.2622 13.2297 37.9986 16.2452 38.7777L17.2458 34.9049ZM32 46C25.7476 46 20.4477 41.9 18.652 36.2368L14.8391 37.4458C17.1465 44.7227 23.9543 50 32 50V46Z" fill="white" mask="url(#path-3-inside-1)"/>
    <path d="M29 40.5C29 40.5 30 41.5 31.5 41.5C33 41.5 34 40.5 34 40.5" stroke="white"/>
    <path d="M31 25C28 20.5 28 14 32.5 10" stroke="white" strokeWidth="2"/>
    <circle cx="27.5" cy="34.5" r="1.5" fill="white"/>
    <circle cx="36.5" cy="34.5" r="1.5" fill="white"/>
</svg>


export const Numbers = () => <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2784 2H41.7216C43.2852 2 44.787 2.61038 45.9073 3.70117L60.1857 17.6038C61.3457 18.7333 62 20.2836 62 21.9027V42.0973C62 43.7164 61.3457 45.2667 60.1857 46.3961L45.9073 60.2988C44.787 61.3896 43.2852 62 41.7216 62H20.7729C19.1102 62 17.522 61.31 16.3873 60.0947L3.61439 46.4142C2.57699 45.3031 2 43.8397 2 42.3196V21.9027C2 20.2836 2.6543 18.7333 3.8143 17.6039L18.0927 3.70117C19.213 2.61038 20.7148 2 22.2784 2Z" stroke="#C00000" strokeWidth="4"/>
    <path d="M32.8445 28.952C34.2738 28.952 35.5752 29.24 36.7485 29.816C37.9218 30.392 38.8498 31.2133 39.5325 32.28C40.2152 33.3253 40.5565 34.5413 40.5565 35.928C40.5565 37.4213 40.1832 38.7333 39.4365 39.864C38.6898 40.9947 37.6658 41.8693 36.3645 42.488C35.0845 43.0853 33.6552 43.384 32.0765 43.384C28.9405 43.384 26.4872 42.424 24.7165 40.504C22.9458 38.584 22.0605 35.8427 22.0605 32.28C22.0605 29.7413 22.5405 27.5653 23.5005 25.752C24.4605 23.9387 25.7938 22.5627 27.5005 21.624C29.2285 20.6853 31.2232 20.216 33.4845 20.216C34.6792 20.216 35.8205 20.3547 36.9085 20.632C38.0178 20.888 38.9565 21.2613 39.7245 21.752L37.8045 25.56C36.6952 24.8133 35.2978 24.44 33.6125 24.44C31.7138 24.44 30.2098 24.9947 29.1005 26.104C27.9912 27.2133 27.3938 28.824 27.3085 30.936C28.6312 29.6133 30.4765 28.952 32.8445 28.952ZM31.7885 39.512C32.8978 39.512 33.7938 39.2133 34.4765 38.616C35.1805 38.0187 35.5325 37.208 35.5325 36.184C35.5325 35.16 35.1805 34.3493 34.4765 33.752C33.7938 33.1333 32.8765 32.824 31.7245 32.824C30.5938 32.824 29.6658 33.144 28.9405 33.784C28.2152 34.4027 27.8525 35.2027 27.8525 36.184C27.8525 37.1653 28.2045 37.9653 28.9085 38.584C29.6125 39.2027 30.5725 39.512 31.7885 39.512Z" fill="white"/>
</svg>

export const Polygon = props => <svg width="64" height="64" viewBox="0 0 64 64" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2784 2H41.7216C43.2852 2 44.787 2.61038 45.9073 3.70117L60.1857 17.6038C61.3457 18.7333 62 20.2836 62 21.9027V42.0973C62 43.7164 61.3457 45.2667 60.1857 46.3961L45.9073 60.2988C44.787 61.3896 43.2852 62 41.7216 62H20.7729C19.1102 62 17.522 61.31 16.3873 60.0947L3.61439 46.4142C2.57699 45.3031 2 43.8397 2 42.3196V21.9027C2 20.2836 2.6543 18.7333 3.8143 17.6039L18.0927 3.70117C19.213 2.61038 20.7148 2 22.2784 2Z" stroke="#C00000" strokeWidth="4"/>
</svg>

export const PolygonPlus = props => <svg width="70" height="64" viewBox="0 0 70 64" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2784 2H41.7216C43.2852 2 44.787 2.61038 45.9073 3.70117L60.1857 17.6038C61.3457 18.7333 62 20.2836 62 21.9027V42.0973C62 43.7164 61.3457 45.2667 60.1857 46.3961L45.9073 60.2988C44.787 61.3896 43.2852 62 41.7216 62H20.7729C19.1102 62 17.522 61.31 16.3873 60.0947L3.61439 46.4142C2.57699 45.3031 2 43.8397 2 42.3196V21.9027C2 20.2836 2.6543 18.7333 3.8143 17.6039L18.0927 3.70117C19.213 2.61038 20.7148 2 22.2784 2Z" stroke="#C00000" strokeWidth="4"/>
    <path d="M53 23L64 15V49L53 41V23Z" fill="black"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M64 30V24H60L60 30H54V34H60L60 40H64V34H70V30H64Z" fill="#C00000"/>
</svg>

export const PolygonNumber = props => {
    const { plus, count, ...params } = props;
    const Border = plus ? PolygonPlus : Polygon;
    const textStyle = {fontFamily: 'Montserrat', fontStyle: 'normal',fontWeight: 'bold', fontSize: '32px', lineHeight: '22px'};
    if (props.plus) {
        textStyle.marginLeft = '-4px';
    }

    return (
    <div style={{width:70, height:64, textAlign: 'center', verticalAlign: 'middle', lineHeight: '74px'}} {...params}>
        <Border style={{position:'absolute', margin:'auto', top:0, left:0, bottom: 0, right: 0}}/>
        <span style={textStyle}>{count}</span>
    </div>);
}
