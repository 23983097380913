import React, { useCallback, useEffect } from 'react';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { useDispatch, useSelector } from 'react-redux';
import useCoreIdToken from './useCoreIdToken';
import * as actions from './../actions';

const Loading = props => <div style={{height:'50vh'}}><MiniLoader /></div>

export default ({ t, onError, ...props }) => {
    const { id: nationalId, token: coreid_token } = useCoreIdToken();

    const { loading, registered, error } = useSelector(state => state.register.coreid.register);
    const { email, phone } = useSelector(state => state.register.user);

    const dispatch = useDispatch();

    const register = useCallback((email, phone) => dispatch(actions.registerCoreIDUser({email, phone, coreid_token})), [coreid_token, dispatch]);

    useEffect(() => {
        if (!email || !phone) {
            return;
        }

        register(email, phone);

    }, [email, phone, register]);

    useEffect(() => {
        if (error) {
            onError && onError(error);
        }
    }, [error, onError]);

    return (
        <div>
            <Loading />
        </div>
    );
}