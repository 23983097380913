import * as constants from './constants';
import api from './api';

const flow = {
    start: payload => ({type: constants.GET_PAYMENT_START, payload}),
    success: payload => ({type: constants.GET_PAYMENT_SUCCESS, payload}),
    failure: error => ({type: constants.GET_PAYMENT_FAILURE, error})
}

export const get = id => dispatch => {
    dispatch(flow.start());
    api.get(id)
        .then(response => dispatch(flow.success(response?.data)))
        .catch(error => dispatch(flow.failure(error)));
}
