import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useIsAuthorised from 'hooks/auth/useIsAuthorised';

export default ({ component: Component, redirect, ...props }) => {

    const authorised = useIsAuthorised();

    return (
        <Route 
            {...props}
            render={props => 
                authorised ?
                <Component {...props} />
                :
                <Redirect to={{ pathname: redirect, state: {from: props.location}}} />
            }
        />
    )
}