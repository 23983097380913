import * as actions from './constants';

const defaultState = {
    activated: false,
    loading: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, error=null } = action;
    switch(type){
        case actions.ACTIVATE_USER_BY_TOKEN_START:
            return {
                ...defaultState,
                loading: true
            }
        case actions.ACTIVATE_USER_BY_TOKEN_SUCCESS:
            return {
                ...defaultState,
                activated: true
            }
        case actions.ACTIVATE_USER_BY_TOKEN_FAILURE:
            return {
                ...defaultState,
                error: error
            }
        default:
            return state;
    }
}
