import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import bsBreakpoints from 'bs-breakpoints';
import store, { persistor } from "./store";
import Loading from 'components/Loading';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
// eslint-disable-next-line 
import i18n from "./i18n";
import App from './App';
import './index.css';


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
        <PersistGate
            loading={<Loading />}
            persistor={persistor}>            
            <Router>
                <App />
            </Router>
        </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

document.addEventListener('DOMContentLoaded', function () {
  bsBreakpoints.init()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
