import colors from './../../colorLevels';

export default ( priceLevels, price ) => {

    const index = priceLevels?.findIndex(level => level <= price);

    const colorsCount = colors?.length;
    const levelsCount = priceLevels?.length;

    const ratio = colorsCount / levelsCount;

    if (index !== -1 && colorsCount) {
        if (ratio < 1) {
            return colors[Math.floor((index)*ratio)];
        }
        return colors[Math.ceil(index*ratio)];
    }
}