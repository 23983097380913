import React, { useEffect, useState } from 'react';
import * as ticketsActions from './../../pages/List/store/actions';
import Loading from 'pages/WebShop/pages/List/components/Loading';
import CheckoutButton from './../Sell/components/CheckoutButton';
import useProceedRefund from './store/refund/useProceedRefund';
import useSelectItemByParams from './useSelectItemByParams';
import useReturnType from './store/selected/useReturnType';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import * as actions from './store/validate/actions';
import RequestSent from './components/RequestSent';
import Error from 'pages/Event/components/Error';
import Restricred from './components/Restricted';
import * as types from './store/selected/types';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Header/Banner';
import ItemInfo from './components/ItemInfo';
import { useParams } from 'react-router';
import Total from './components/Total';
import Info from './components/Info';
import './styles.scss';
import AllInfo from './components/AllInfo';

export const path = '/sellitem/:event_id/:item_id?';
export const link = (event_id, item_id = null) => path.replace(':event_id', event_id).replace(':item_id?', item_id || '');

const Sell = props => {

    const { events, loading: ticketsLoading, error: ticketsError } = useSelector(state => state.tickets);
    const { loading, validated, item, qty, price, fee, total, error } = useSelector(state => state.shop.sell.validate);
    
    const { event_id, item_id } = useParams();
    const { selected } = useSelector(state => state.shop.sell.selected);
    const dispatch = useDispatch();

    const refresh = () => dispatch(actions.validateRefund(event_id, selected));
    const refreshTickets = () => dispatch(ticketsActions.getTickets());

    const [refundQty, setRefundQty] = useState(1);
    const type = useReturnType();

    const event = events?.find(event => event.id === +event_id);

    useSelectItemByParams({event_id, item_id});

    const { state, refund } = useProceedRefund(event_id, selected);

    useEffect(() => {
        if (!events) {
            refreshTickets();
            return;
        }
    }, [events]);   
    
    useEffect(() => {
        if (type === types.ITEMRETURN ) { 
            setRefundQty(qty);
        } else {
            setRefundQty(1);
        }
    }, [qty, type]);


    if (ticketsLoading || loading || selected === null) {
        return <Loading />
    }

    if (!event) {
        return <Container>
                <Row>
                    <Col xs lg="9">
                        <Error retry={() => refresh()} />
                    </Col>
                </Row>
            </Container>
    }    

    if (validated && !!error) {
        return <Restricred {...{error, event}} />
    }

    if (state.refunded) {
        return <RequestSent />
    }

    return (
        <>
            <Info {...{event}} />
            {type === types.ITEMRETURN && item && <ItemInfo {...{qty: refundQty, item, updateQty: setRefundQty}} />}
            {type === types.ALLRETURN && <AllInfo {...{event}} />}
            <Total {...{qty: refundQty, price, fee}} />
            <CheckoutButton {...{valid: true, error: state.error ? 'Error proceed refund, please try again' : null, loading: state.loading, onPress: () => refund(refundQty)}} />
        </>
    )
}

export default props => {
    const { t } = useTranslation('tickets');

    return  (<CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-tickets'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="9">
                        <h4 className='zt-tickets__title'>{t('sell_item.label', 'Sell merchandise')}</h4>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs lg="9">
                        <Sell {...props} />
                    </Col>
                </Row>
            </Container>
            <Banner />
        </div>
    </CSSTransition>
    );
}