import React from 'react';
import Stepper from 'components/Inputs/Stepper';
import Logotype from 'components/Logotype';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useItemQtys from './useItemQtys';

export default ({ item, qty, updateQty, ...props }) => {
    console.log('>>>>>')
    console.log(item);

    const { t } = useTranslation('merchandise');

    const { merchandise, option } = item;
    const image = merchandise?.media?.[0] || null;

    const { bought, pickedUp } = useItemQtys(item);

    return (
        <Row className='zt-tickets__sell_item__item'>
            <Col>
                <h3>{merchandise.name}</h3>
                <div className='zt-p1 mb-4'>{merchandise.description}</div>
                <Row>
                    <Col md={9}>
                        <div className='d-flex justify-content-between align-items-center zt-webshop-cart__feed__card__parameter'>
                            <div className='zt-p2 zt-webshop-cart__feed__card__parameter__label'>{t('cart.price', 'Price')}:</div>
                            <div className='zt-p1 bold zt-webshop-cart__feed__card__parameter__value'>{t('common:price', {price: item.unit_net_price})}</div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center zt-webshop-cart__feed__card__parameter'>
                            <div className='zt-p2 zt-webshop-cart__feed__card__parameter__label'>{t('cart.option', 'Option')}:</div>
                            <div className='zt-p1 bold zt-webshop-cart__feed__card__parameter__value'>{option ? option?.name : '-'}</div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center zt-webshop-cart__feed__card__parameter'>
                            <div className='zt-p2 zt-webshop-cart__feed__card__parameter__label'>{t('cart.bought', 'Bought')}:</div>
                            <div className='zt-p1 bold zt-webshop-cart__feed__card__parameter__value'>{bought}{pickedUp ? ` (Delivered: ${pickedUp})` : ``}</div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center zt-webshop-cart__feed__card__parameter__count'>
                            <Stepper value={qty} max={bought-pickedUp} onChange={c => updateQty && updateQty(c)} />
                            <div className='zt-p1 bold zt-webshop-cart__feed__card__parameter__value'>{t('common:price', {price: (+item.unit_net_price)*qty})}</div>
                        </div>                                                                     
                    </Col>
                    <Col md={3} className='d-none d-md-block'>
                    </Col>
                </Row>
            </Col>
            <Col md={5} className='d-none d-md-block'>
                <div className='zt-webshop-cart__feed__card__imageholder'>
                    { image?
                    <img alt={item.name} className='zt-webshop-cart__feed__card__imageholder__image' src={image} />
                    :
                    <Logotype className='zt-webshop__feed__card__imageholder__logotype'/>
                    }            
                </div>
            </Col>
        </Row>);
}