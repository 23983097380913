import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Panel from 'components/Panel';
import { PrimaryButton } from 'components/buttons/Button';

export default ({ t }) => {
    const [close, setClose] = useState(false);

    return (
        <Modal show={!close} size="md" centered dialogClassName='zt-modal-small' className='zt-modal-centered' >
            <Panel className='zt-login d-flex flex-column justify-content-center'>
                <div className='zt-p3' style={{textAlign: 'center'}}>{t('actions.accept.complete.description', 'Operation complete')}</div>
                <div className='zt-notifications__modal__actions' style={{justifyContent: 'center'}}>
                    <PrimaryButton onClick={() => setClose(true)}>{t('actions.accept.complete.button', 'Done')}</PrimaryButton>
                </div>
            </Panel>
        </Modal>
    )
}