import React from 'react';
import InfoLine from './components/InfoLine';
import { LocationIcon, Seat as SeatIcon, QRArrow as QRIcon, Kid as KidIcon } from 'components/Icons/Glyphs';

export const Place = ({ ticket }) => {
    const { section, row, seat, general_admission: ga = false } = ticket;

    const sectionName = `Section: ${section}`;
    const rowSeat = ga ? `` : `Row ${row} Seat ${seat}`;

    return `${sectionName} ${rowSeat}`;
}

export const OwnerTicket = ({ ticket }) => {

    const { user } = ticket;
    const { name } = user;

    return (<div className='zt-tickets__group__event__tickets__owner'>
        <InfoLine><QRIcon className='event-info-line__icon' />{name}</InfoLine>
        <InfoLine bold><SeatIcon className='event-info-line__icon' /><Place {...{ticket}} /></InfoLine>
    </div>);
}

export const ChildrenTickets = ({ tickets }) => {

    return (
        <div className='zt-tickets__group__event__tickets__children'>
            {tickets.map((ticket, key) => <InfoLine {...{key}} bold><KidIcon className='event-info-line__icon' /><Place {...{ticket}} /></InfoLine>)}
        </div>);
}

export default ({ tickets }) => {

    const owner = tickets.find(t => t.is_child === false);
    const children = tickets.filter(t => t.is_child === true);

    return <div className='zt-tickets__group__event__tickets'>
        <OwnerTicket ticket={owner} />
        {!!children?.length && <ChildrenTickets tickets={children} />}
    </div>
}