import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Merch = ({ item }) => {
    const { merchandise, option } = item;
    return <p className='zt-p1'>{`${merchandise.name}${option ? ` (${option.name})` : ''} x ${item.quantity}`}</p>;
}

export default ({ event, ...props }) => {

    const { purchased_merchandise: items } = event;
    const { t } = useTranslation('tickets');

    return (
        <Row className='zt-tickets__sell_item__item'>
            <Col>
                <h3>{t('sell_item.all.label', 'Refound all items')}</h3>
                <div className='zt-p1 mb-4'>
                    {items.map((item, key) => <Merch {...{item, key}} />).reduce((prev, curr) => [prev, <br/>, curr])}
                </div>
            </Col>
        </Row>
    )
}