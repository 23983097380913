import * as actions from './constants';
import * as availableTargetModes from './../sell/sellToModes';

export const defaultState = {
    
    merch: null,
    tickets: null,
    targetModes: {},

    loading: false,
    validated: false,
    restricted: false,
    error: null
}

export const reducer = (state = defaultState, action) => {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.VALIDATE_TICKETSELL_START: {
            return {
                ...defaultState,
                loading: true
            }
        }

        case actions.VALIDATE_TICKETSELL_SUCCESS: {

            const { tickets, merchandises, merchandise_total={} } = payload;

            // const merch = merchTemplate;

            const targetModes = Object.values(availableTargetModes).reduce((targetModes, mode) =>({...targetModes, ...(payload[mode]?.allowed === true ? {[mode]: payload[mode]}: {})}), {});

            return {
                ...defaultState,
                tickets,
                merch: merchandises ? {
                    items: merchandises,
                   ...merchandise_total
                } : null,

                targetModes,

                validated: true
            }
        }

        case actions.VALIDATE_TICKETSELL_FAILURE: {
            const errorPayload = error?.response?.data;
            const restricted = error?.response?.status === 406 || error?.response?.status === 403;
            

            if (restricted) {
                const { tickets, merchandises: merch, error } = errorPayload;
                
                return {
                    ...defaultState,
                    tickets,
                    merch,
                    error,

                    validated: true,
                    restricted
                }
            }

            return {
                ...defaultState,
                validated: true,
                error
            }
        }

        default: {
            return state;
        }
    }
}