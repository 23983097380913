import { useSelector } from 'react-redux';

export default function usePriceForTicket(ticket) {
    const prices = useSelector(state => state.event.friends.prices);

    if (!prices || !prices.length || !ticket || ticket.price === null || !ticket.type) {
        return null;
    }

    return prices[ticket.price]?.[ticket.type]?.value || null;
}