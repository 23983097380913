import { useEffect, useReducer } from "react";
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';

export default function useProfileItemUpdate() {

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    useEffect(() => {
        dispatch(actions.reset());
    }, [dispatch]);
    
    const proceedUpdate = actions.update(dispatch);
    const reset = () => dispatch(actions.reset());

    return {
        state,
        proceedUpdate,
        reset
    }

}