import * as payTypes from '../../AddFriends/payTypes';

//TODO: Support for general admission

export default function orderInfo(state, is_GA = false) {

    if (!state) {
        throw('State is null!!');
    }

    const event = state.event;
    const id =  event.details.item.id;
    const section = event.tickets.section;
    const qty = event.tickets.qty;
    const seats = event.tickets.tickets;
    const prices = event.friends.prices;

    const iamnotattending = false;

    if (!event.friends?.friends) {
        throw 'No tickets in state';
    }

    const tickets = [ ...(iamnotattending ? [] : [{...event.friends.ticket, pay: true}] ), //Owner ticket if !amnotattending
      ...event.friends.friends?.map(f => ({
          ...f.ticket, 
          pay: f.pay === payTypes.PAYTYPE_IPAY ? true : false, 
          ...(f.kid ? 
                {kid_first_name: f.user.first_name, kid_last_name: f.user.last_name, register_type: undefined}
                : 
                {user: +f.user?.id ? 
                    {id: +f.user.id} //Registered user
                    : //New user:
                    {
                        phone: f.user.phone,
                        first_name: f.user.first_name,
                        last_name: f.user.last_name
                    }
                })
        }))
    ]?.map(t => ({...t, price: Number.isInteger(t.price) && prices[t.price][t.type]?.value}))

    return {
        event: id,
        tickets: 
            is_GA ?
            Array.from(Array(qty).keys()).map(i => ({section, event: id}))
            :
            seats.map(seat => ({seat: `${seat.seat}`, row: `${seat.row}`, section, event: id})), 
        assign_tickets: tickets
    } 
}