import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Divider from 'components/Divider';
import './style.scss';

const FeeRow = ({ label, fee, total=false, ...props }) => <Row>
    <Col md={5} className={`zt-event-buy__aside__total-fee__line zt-event-buy__aside__total-fee__line--left ${total ? 'zt-event-buy__aside__total-fee__line--left--total' :''}`}>
        <span className='zt-p3'>{label}</span>
    </Col>
    <Col className='zt-event-buy__aside__total-fee__line zt-event-buy__aside__total-fee__line--right'>{total ? <h4 className='bold' style={{whiteSpace: 'nowrap'}}>{fee}</h4> : <span className='zt-p1'>{fee}</span>}
    </Col>
</Row>

export default ({ fees: { admin, service, tickets, total}, children, ...props }) => {

    const {t} = useTranslation('buying');
    const tt = (code, def) => t(`add_friends.total.${code}`, def);

    return (<div className='zt-event-buy__aside__total-fee'>
        <FeeRow label={tt('tickets_fees', 'Ticket fees:')} fee={t('common:price', {price: Number(tickets)})} />
        {!!service && <FeeRow label={tt('service_fees', 'Service fees:')} fee={t('common:price', {price: Number(service)})} />}
        {!!admin && <FeeRow label={tt('admin_fees', 'Admin fees:')} fee={t('common:price', {price: Number(admin)})} />}
        <Divider/>
        <FeeRow label={tt('total_fee', 'Total fee:')} fee={t('common:price', {price: Number(total)})} total/>
        {children}
    </div>)
}