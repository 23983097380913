import * as actions from './constants'
import { Sport, Music, Theatre } from 'components/Icons/Glyphs';
import * as DATEMODES from 'containers/FilteredEvents/dateModes';

const defaultState = {
    
    category: null,
    categories: [
        {id: 'theater', label:'theatre', icon: Theatre}, 
        {id: 'musical', label:'music', icon: Music},
        {id: 'sport', label:'sport', icon: Sport}
    ],

    city: null,

    date: null,
    dateModes: [
        {id: DATEMODES.MODE_TODAY, label: 'today'},
        {id: DATEMODES.MODE_NEXT_WEEK, label: 'next_week'},
        {id: DATEMODES.MODE_NEXT_MONTH, label: 'next_month'},
        {id: DATEMODES.MODE_THIS_YEAR, label: 'this_year'},
        {id: DATEMODES.MODE_PERIOD, label: 'range'}
    ]
}

export default function(state = defaultState, action) {
    switch(action.type) {
        case actions.FILTER_SET_CITY:
            return {
                ...state,
                city: action.payload
            };
        case actions.FILTER_SET_CATEGORY:
            return {
                ...state,
                category: action.payload
            }
        case actions.FILTER_SET_DATE:
            return {
                ...state,
                date: action.payload
            }   
        case actions.FILTER_RESET:
            return defaultState;
        default:
            return state;
    }
}