import * as constants from './constants';
import api from './api';

export const fetch = {
    start: () => ({type: constants.FETCH_AVAILABLE_EVENTS_START}),
    success: payload => ({type: constants.FETCH_AVAILABLE_EVENTS_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_AVAILABLE_EVENTS_FAILURE, error})
}

export function setPageSize(size) {
    return {type: constants.SET_AVAILABLE_EVENTS_PAGE_SIZE, payload: size};
}

export const resetAvailbleEvents = () => ({type: constants.RESET_AVAILABLE_EVENTS});

export function fetchAvailableEvents() {
    return (dispatch, getState) => {
        dispatch(fetch.start());
        const { page, page_size } = getState().events;
        api.fetch(dispatch, getState, {page, per_page:page_size})
            .then(response => dispatch(fetch.success(response.data || null)))
            .catch(error => dispatch(fetch.failure(error)));
    };
}