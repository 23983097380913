import React from 'react';
import useIsAllFriendsAdded from '../../../AddFriends/useIsAllFriendsAdded';
import useIsAllPricesSet from '../../../AddFriends/useIsAllPricesSet';
import { PrimaryButton } from 'components/buttons/Button';
import { ArrowRight } from 'components/Icons/Glyphs';
import { useTranslation } from 'react-i18next';
import useFeesDetails from './useFeesDetails';
import Total from './components/Total';
import { useSelector } from 'react-redux';
import useTotalsFromPayment from '../../../Confirm/useTotalsFromPayment';

const FlowTotal = ({ controlls = false, next, ...props }) => {
    const { totalFee:total, ticketsTotal:tickets, serviceFee: service, adminFee:admin } = useFeesDetails();

    const {t} = useTranslation('buying');
    const tt = (code, def) => t(`add_friends.total.${code}`, def);
    const allPriceSelected = useIsAllPricesSet();
    const allFriendsAdded = useIsAllFriendsAdded();
    const valid = allFriendsAdded; //&& allPriceSelected;

    const { user, loading } = useSelector(state => state.event.friends);
    const ownerNotAbleToAttend = !user?.types?.length;

    if (loading || ownerNotAbleToAttend) {
        return null;
    }

    return (<Total fees={{total, tickets, service, admin}}>
        { controlls &&
        <PrimaryButton onClick={() => next && next()} disabled={!valid} className='zt-event-buy__aside__total-fee__checkoutbutton' error={valid ? null : tt('error', 'Please add friends to all tickets before checkout.')}>{t('buttons.checkout', 'Checkout')} <ArrowRight /></PrimaryButton>
        }
        </Total>
    )
}

const ConfirmTotal = props => {

    const { fees: { total, tickets, service, admin }, loading, error } = useTotalsFromPayment();

    if (loading || error) {
        return null;
    }

    return <Total fees={{total, tickets, service, admin}} />;
}


export default ({ confirm, ...props }) => {

    if (!confirm) {
        return <FlowTotal {...props} />
    }

    return <ConfirmTotal />
}