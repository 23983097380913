import * as actions from './constants';

export const defaultState = {
    loading: false,
    error: null,
    refunded: false
}

export const reducer = (state = defaultState, action) => {
    const { type, payload=null, error=null } = action;

    switch(type) {
        case actions.REFUND_SELLITEMS_START: {
            return {
                ...defaultState,
                loading: true
            }   
        }

        case actions.REFUND_SELLITEMS_SUCCESS: {
            return {
                ...defaultState,
                refunded: true
            }
        }

        case actions.REFUND_SELLITEMS_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }
    }
}