import * as actions from './constants'


const defaultFilter = {
    category: null,
    city: null,
    date: null,
    search: null
}

const defaultState = {
    filter: defaultFilter,
    items: null,
    total: 0,
    page: 0,
    page_size: 12, //TODO: Get a default value from a config
    loading: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {
        case actions.FETCH_FILTERED_EVENTS_START:
            return {
                ...state,
                page: state.page+1,
                loading: true
            }   

        case actions.FETCH_FILTERED_EVENTS_SUCCESS:

            const events = payload.collection || [];
            const items = state.items === null ? events : state.items.concat(events);
            const total = payload.count || 0;
            const starting_id = state.starting_id === null && (items ? items?.[0]?.id || null : state.starting_id); 

            return {
                ...state,
                items,
                total, 
                starting_id,
                loading: false
            }

        case actions.FETCH_FILTERED_EVENTS_FAILURE:
            return {
                ...state,
                loading:false
            }    

        case actions.SET_FILTERED_EVENTS_SEARCH:
            return {
                ...state,
                items: null,
                page: 0,                
                filter: {
                    ...state.filter,
                    search: payload
                }
            }

        case actions.SET_FILTERED_EVENTS_FILTERS:
            return {
                ...state,
                items: null,
                page: 0,
                filter: {
                    ...state.filter, //save search
                    category: (payload || {}).category,
                    city: (payload || {}).city,
                    date: (payload || {}).date
                }
            }     

        case actions.RESET_FILTERED_EVENTS_FILTERS:
            return {
                ...defaultState
            }

        case actions.RESET_FILTERED_EVENTS_CATEGORY:
            return {
                ...state,
                items: null,
                page: 0,                 
                filter: {
                    ...state.filter,
                    category:null
                }
            }

        case actions.RESET_FILTERED_EVENTS_CITY:
            return {
                ...state,
                items: null,
                page: 0,                 
                filter: {
                    ...state.filter,
                    city:null
                }
            }

        case actions.RESET_FILTERED_EVENTS_DATE:
            return {
                ...state,
                items: null,
                page: 0,                
                filter: {
                    ...state.filter,
                    date:null
                }
            }

        case actions.RESET_FILTERED_EVENTS_SEARCH:
            return {
                ...state,
                items: null,
                page: 0,                 
                filter: {
                    ...state.filter,
                    search:null
                }
            }

        default:
            return state;
    }
}