import axios from 'axios';
import { API as baseURL } from '../config/api';
import parseJwt from '../utils/parseJwt';
import platform from 'config/platform';

const URL_TOKEN_REFRESH = 'api/auth/refresh';

export const isTokenValid = token => {
    const { exp } = parseJwt(token); 

    if (+(new Date()) > +exp*1000 - 5000) {
        return false; //token's exp time in the past
    }

    return true;
}

// Every time the state changes, log it
// Note that subscribe() returns a function for unregistering the listener

let tokenRefreshRequest = null;

export const refreshToken = token => {

    if (!tokenRefreshRequest) {
        tokenRefreshRequest = new Promise((resolve, reject) => {
            console.log('>>> -- refreshing old token... ');
            axios.get(URL_TOKEN_REFRESH, {baseURL, headers: {Authorization: `Bearer ${token}`, platform}})
                .then(response => {
                    tokenRefreshRequest = null;
                    return response?.data?.token ? resolve(response.data.token) : reject(response)
                })
                .catch(err => {
                    tokenRefreshRequest = null;
                    return reject(err);
                });
        });
    }

    return tokenRefreshRequest;
}
