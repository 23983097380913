import { useReducer } from 'react';
import { reducer, defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';

export default function useValidateReservedTicket(id) {

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    if (!+id) {
        throw 'No order id provided';
    }

    const validate = actions.validate(dispatch, id);
    const update = actions.update(dispatch, id);

    return {
        state,
        validate,
        update
    }

}