import { useReducer } from 'react';
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';
import * as actions from './actions';

export default function useEmailRecoveryRequest() {

    const [state, dispatch] = useReducer(logger(reducer), defaultState);
    const request = actions.request(dispatch);

    return {
        state,
        request
    }
}
