import React from 'react';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { CSSTransition } from 'react-transition-group';
import { Row, Col, Container } from 'react-bootstrap';

export default props => {
    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
            <div className='zt-webshop'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="9">
                            <MiniLoader />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    );
}