import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import SetNewPassword from './SetNewPassword';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EmailRequest from './EmailRequest';
import PleaseCheck from './PleaseCheck';
import './style.scss';

export const path = '/recovery/:token?';
export const link = path.replace(':token?','');

const ResetPassword = props => {
    const [t] = useTranslation('password_recovery');
    const [sent, setSent] = useState(false);
    const { token } = useParams();

    if (token) {
        return <SetNewPassword t={(key, defaultValue) => t(`set_new_password.${key}`, defaultValue)} />;
    }

    if (sent) {
        return <PleaseCheck t={(key, defaultValue) => t(`check_email.${key}`, defaultValue)} {...{email: sent}} />;
    }

    return <EmailRequest t={(key, defaultValue) => t(`reset_password.${key}`, defaultValue)} {...{onSuccess: email => setSent(email)}} />;
}


export default props => {
    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-password-recovery__transition">
            <Container className='zt-password-recovery'>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} >
                        <ResetPassword {...props} />
                    </Col>
                </Row>
            </Container>      
        </CSSTransition>          
    );
}
