import React, { useState, useRef, useEffect, useCallback } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});

const MORE_TRUNCATE_THRESHOLD = 350; //IN PIXELS

export default ({ children, ...props }) => {
    const { scrollToTop = true } = props;
    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(true);
    const [t] = useTranslation('common');

    const contentRef = useRef(null);
    const executeScroll = useCallback(() => scrollToRef(contentRef), [contentRef]);

    const isFirstRun = useRef(true);

    useEffect(()=>{
        if (isFirstRun.current) { //To not to scroll to header at first page opening
            isFirstRun.current = false;
            return;
        }

        if (!expanded && scrollToTop) {
            executeScroll();
        }
    }, [expanded, executeScroll])

    useEffect(()=> {
         if (contentRef.current.offsetHeight < MORE_TRUNCATE_THRESHOLD ) {
            setTruncated(false);
         }
    }, [])

    return (<React.Fragment>
        <div ref={contentRef} className={`zt-p1 more__content ${expanded?'':'more__content--collapsed'}`}>
            {children}
            {truncated && !expanded &&
            <div className='more__shadow'></div>
            }
        </div>
        {truncated &&
        <div className='d-flex justify-content-end'>
            <button className='more__button' onClick={e => setExpanded(e => !e)}><span className='zt-nav light' style={{color:'white'}}>{expanded?t('buttons.more.less'):t('buttons.more.more')}</span></button>
        </div>
        }
    </React.Fragment>);
}