import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { PrimaryButton } from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Header/Banner';
import { useHistory } from 'react-router-dom';

const home = '/';

export default props => {

    const { t } = useTranslation('notifications');

    const history = useHistory();

    return (
        <>
        <Container>
            <Row>
                <Col xs lg="9">
                    <h2 className='bold zt-webshop__header'>{t('noitems.label', 'You havn\'t notifications')}</h2>
                    <PrimaryButton className='zt-webshop__gotobutton' onClick={() => history.push(home)}>{t('noitems.button', 'Go to Events ')}</PrimaryButton>
                </Col>
            </Row>
        </Container>
        <Banner />
        </>
    );
}