import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/buttons/Button';
import useProfileItemUpdate from '../../useProfileItemUpdate';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Divider from 'components/Divider';
import Document from './components/Document';

export default ({ t, refresh, ...props }) => {
    const { t:tt } = useTranslation('registration');

    
    const { profile } = useSelector(state => state.profile);
    const [document, setDocument] = useState(profile?.document);

    const { state: {loading, profile: updated, saved, error}, proceedUpdate, reset } = useProfileItemUpdate();

    const save = () => proceedUpdate({document});
    const disabled = document === profile?.document || !document;

    useEffect(() => {
        if (saved) {
            reset();
            refresh(updated);
        }
    }, [saved, updated, refresh, profile, reset]);   

    const errorMessage = error ? t('document.error','Error saving the document number') : null;
    
    return (
        <div className='zt-profile__document'>
            <Document {...{t, setDocument, profile, updated}}/>
            <PrimaryButton {...{loading, disabled, error: errorMessage}} onClick={() => save()}>{t('document.button', 'Apply Changes')}</PrimaryButton>
            <Divider />
        </div>
    );
}