import React from 'react';
import { PrimaryButton, SecondaryButton } from 'components/buttons/Button';
import { ClearIcon } from 'components/Icons/Glyphs';
import { Modal } from 'react-bootstrap';
import Panel from 'components/Panel';
import { Trans } from 'react-i18next';

export default ({ t, onClose, onDismiss, doDecline, ...props }) => {

    return (
        <Modal show={true} size="md" centered dialogClassName='zt-modal-small' className='zt-modal-centered' >
            <Panel className='zt-login d-flex flex-column justify-content-center'>
                <ClearIcon className='zt-login__closeicon' onClick={() => onClose && onClose()} />
                <div className='zt-p3'>
                    <Trans t={t} i18nKey={'actions.accept.decline.description'}>
                        Paid tickets will be sold on market when declining.<br/>
                        Kids tickets will remain with your friend.<br/>
                        Reservation will go back to your friend.                        
                    </Trans>
                </div>
                <div className='zt-notifications__modal__actions'>
                    <PrimaryButton onClick={() => doDecline()}>{t('actions.accept.decline.buttons.decline', 'Ok')}</PrimaryButton>
                    <SecondaryButton onClick={() => onClose()}>{t('actions.accept.decline.buttons.cancel', 'Cancel')}</SecondaryButton>
                </div>
            </Panel>
        </Modal>
    )
}