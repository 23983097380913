import React from 'react';
import { CSSTransition } from 'react-transition-group';

export default ({ t, email, ...props }) =>  <CSSTransition in={true} appear={true} timeout={200} classNames="zt-password-recovery__transition"><>
        <h4 className='bold zt-password-recovery__header'>{t('label', 'Please check your email!')}</h4>
        <div className='zt-password-recovery__emailused'>
            <span className='zt-nav'>{email}</span>
        </div>
        <div className='zt-password-recovery__instructions'>
        <span className='zt-p1'>{t('description', 'Instructions on how to reset your password have been sent to your email.')}</span>
        </div>
    </>
</CSSTransition>