export const FETCH_FILTERED_EVENTS_START = 'FETCH_FILTERED_EVENTS_START';
export const FETCH_FILTERED_EVENTS_SUCCESS = 'FETCH_FILTERED_EVENTS_SUCCESS';
export const FETCH_FILTERED_EVENTS_FAILURE = 'FETCH_FILTERED_EVENTS_FAILURE';

export const SET_FILTERED_EVENTS_PAGE_SIZE = 'SET_FILTERED_EVENTS_PAGE_SIZE';

export const SET_FILTERED_EVENTS_FILTERS = 'SET_FILTERED_EVENTS_FILTERS';
export const SET_FILTERED_EVENTS_SEARCH = 'SET_FILTERED_EVENTS_SEARCH';
export const RESET_FILTERED_EVENTS_FILTERS = 'RESET_FILTERED_EVENTS_FILTERS';
export const RESET_FILTERED_EVENTS_CITY = 'RESET_FILTERED_EVENTS_CITY';
export const RESET_FILTERED_EVENTS_CATEGORY = 'RESET_FILTERED_EVENTS_CATEGORY';
export const RESET_FILTERED_EVENTS_DATE = 'RESET_FILTERED_EVENTS_DATE';
export const RESET_FILTERED_EVENTS_SEARCH = 'RESET_FILTERED_EVENTS_SEARCH';
