import React from 'react';
import ReservationCheckout, { path as checkoutPath, link as reservationLink } from './pages/ReservationCheckout';
import BuyTickets, { path as buyPath , link as buyLink } from './pages/BuyTickets';
import PrivateRoute from 'components/PrivateRoute';
import { Route } from 'react-router-dom';
import Details from './pages/Details';

export const path = '/events/:id';
export const link = id => path.replace(':id', id);
export const buyTicketsLink = id => link(id)+buyLink;
export const reservationCheckoutLink = id => param => link(id)+reservationLink(param);

export default props => {
    const { match } = props;

    return  (<>
        <Route path={match.path} exact component={Details} />
        <PrivateRoute redirect={match.url} path={match.path + buyPath} component={BuyTickets} />
        <PrivateRoute redirect={match.url} path={match.path + checkoutPath} component={ReservationCheckout} />        
    </>);
}