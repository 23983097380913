import React from 'react';
import { Route } from 'react-router-dom';
import List from './pages/List';
import SellTickets, { path as sellTicketsPath, link as sellTicketsSubLink } from './pages/Sell';
import SellItem, { path as sellItemPath, link as sellItemSubLink } from './pages/SellItem';

export const path = '/tickets';
export const link = path;

export const sellLink = (...params) => link+sellTicketsSubLink(...params);
export const sellItemLink = (...params) => link+sellItemSubLink(...params);

export default props => {
    const { match } = props;

    return  (<>
        <Route path={match.path} exact component={List} />
        <Route path={match.path + sellTicketsPath} exact component={SellTickets} />
        <Route path={match.path + sellItemPath} exact component={SellItem} />
    </>);
}