import * as actions from './constants';

const defaultState = {
    items: null,
    total: null,

    selected: null,
    unread: 0,
    loading: false,
    error: null
};

export default function(state=defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.RESET_NOTIFICATIONS_LIST: {
            return {
                ...defaultState
            };
        }

        case actions.FETCH_NOTIFICATIONS_START: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }

        case actions.FETCH_NOTIFICATIONS_SUCCESS: {
            
            const { collection, count } = payload;
            const items = collection;
            const total = count || 0;
            
            const unread = items.filter(i => i.viewed === false).length;

            return {
                ...defaultState,
                items: [...items],
                total,

                unread,
                loading: false
            }
        }

        case actions.FETCH_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                loading: false,
                error
            }
        }

        case actions.SELECT_NOTIFICATIONS_ITEM: {
            
            const item = state.items.find(i => +i.id === +payload);
            if (item) {
                item.viewed = true;
            }

            const unread = state.items.filter(i => i.viewed === false).length;

            const items = [...state.items];

            return {
                items,

                ...state,
                unread,
                selected: payload
            }
        }

        default: {
            return state;
        }        

    }
}
