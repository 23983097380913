import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import useCartItemsCount from '../Cart/store/useCartItemsCount';
import EventItemsFeed from 'pages/WebShop/EventItemsFeed';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import ModalCart from './../../components/ModalCart';
import * as actions from './../../store/actions';
import NoTickets from 'pages/WebShop/NoTickets';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import Error from './components/Error';
import './style.scss';

export const path = '/webshop';
export const link = path;

const Feed = props => {
    // const [mode, setMode] = useState(MODE_EVENTS);
    const [showCart, setShowCart] = useState(false);

    const { events, loading, error } = useSelector(state => state.shop.events);

    const itemsCnt = useCartItemsCount();
    const dispatch = useDispatch();
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (itemsCnt) {
            setShowCart(true);
        }
    }, [itemsCnt, setShowCart]);

    const refresh = () => {};

    useEffect(() => {
        dispatch(actions.fetchEvents());
    }, [dispatch]);

    // useEffect(() => {
    //     dispatch(actions.fetchEventItems(4));
    // }, [dispatch]);


    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error onRetry={() => refresh()} />
    }

    if (!+events.length) {
        return <NoTickets />
    }

    return (
        <>
            {events?.map((event, key) => <EventItemsFeed {...{event, key}} />)}
            <ModalCart show={showCart} onHide={() => setShowCart(false)} />  
        </>
    )

}


export default props => {
    const { t } = useTranslation('merchandise');

    return <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-webshop'>
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="9">
                    <h4 className='zt-webshop__title'>{t('feed.label', 'WebShop')}</h4>
                </Col>
            </Row>
        </Container>
        <Feed />
    </div>
    </CSSTransition>
}