import React, { useEffect, useRef, useState } from 'react';
import Samples, { MODE_INTERNETIONAL, MODE_SWEDEN } from 'pages/Registration/methods/Document/components/DocumentId/components/Samples';
import { SecondaryButton } from 'components/buttons/Button';
import { Info } from 'components/Icons/Glyphs';
import Input from 'components/Inputs/Input';
import { Modal } from 'react-bootstrap';

export default ({ t, setDocument, profile, updated }) => {

    const ref = useRef(null);

    const [showSamples, setShowSamples] = useState(false);
    const [description, setDescription] = useState(null);

    useEffect(() => {
        if (updated) {
            ref.current.value = '';
            setDescription(t('document.saved', 'Document number saved'));
            setDocument(null);
        }
    }, [updated]);

    const document = profile?.document;


    return (
        <>
            <h4 className='zt-profile__label zt-profile__document__label'>{t('document.label', 'Document which you use as a Ticket')}</h4>
            <SecondaryButton className='zt-profile__document__samplesbutton' icon={Info} onClick={() => setShowSamples(true)}>{t('document.samples', 'Samples of valid Documents')}</SecondaryButton>
            <Input 
                ref={ref}
                label={t('document.input.label', {defaultValue: 'Document number ({{document}}):', document})} 
                placeholder={t('document.input.placeholder', 'Document or ID number')} 
                defaultValue={''} 
                onChange={e => {setDescription(null);setDocument(e.target.value);}}
                onClear={e => ref.current.value = ''}
                description={description}
            />        
            <Modal show={showSamples} size="xl" centered onHide={() => setShowSamples(false)} className='zt-modal-centered'>
                <Samples t={t} mode={MODE_INTERNETIONAL} onClose={() => setShowSamples(false)} />
            </Modal>        
        </>
    )
}