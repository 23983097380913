import { useSelector } from 'react-redux';

export default function useIsAllFriendsAdded(){
    const friends = useSelector(state => state.event.friends.friends);
    const friendsQty = useSelector(state => state.event.friends.friends?.length);
    const ticketsQty = useSelector( state => state.event.tickets.qty);
    const friendsFilled = friends.every(f => ((+f.user.id) || (f.kid || f.user.id === 0)) && f.ticket.price !== null);// && f.user.first_name && f.user.last_name))

    // console.log(`Friends filled in: ${friendsFilled}`);

    return friendsFilled && (ticketsQty - (+friendsQty) - 1 === 0);
}