import { useSelector } from 'react-redux';
import * as payTypes from './payTypes';

export default function useIsAllPricesSet() {
    const { prices, tickets } = useSelector(state => ({prices: state.event.friends.prices, tickets: [{...state.event.friends.ticket, pay: payTypes.PAYTYPE_IPAY}, ...state.event.friends.friends.map(f => ({...f.ticket, pay: f.pay, user_id: f.user.id}))]}));

    if (!prices || !tickets) {
        return false;
    }

    return tickets.every(t => t.price === 0 || !!+t.price || t.user_id === 0 );
}