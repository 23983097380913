import moment from 'moment';

export default function useTimeLeft(item) {

    const { reservation } = item
    const releaseDate = reservation?.reserved_until_utc; //2021-01-08 18:57:12

    if (releaseDate === null) {
        return null;
    }

    //Treat action time as utc
    const seconds = moment.utc(releaseDate, 'YYYY-MM-DD hh:mm:ss').diff(moment.utc(), 'seconds');
    
    return seconds;
}