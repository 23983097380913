import React, { useEffect } from 'react';
import Seats from "./Seats";
import { useDispatch, useSelector } from 'react-redux';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { SEATING_TYPE_SEATS, SEATING_TYPE_GENERALADMISSION } from '../store/seatingTypes';
import GeneralAdmission from './GeneralAdmission';
import useSectionSeatingType from './useSectionSeatingType';
import * as actions from './actions';

export default ({ event, section, ...props }) => {
    const loading = useSelector(state => state.event.maps.seats.loading);
    const type = useSectionSeatingType();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchSeatsMap(event.id, section))
    }, [dispatch, event, section]);

    if (loading) {
        return <div><MiniLoader /></div>
    }

    if (type === SEATING_TYPE_SEATS) {
        return <Seats {...{...props, event, section}} />
    }

    if (type === SEATING_TYPE_GENERALADMISSION) {
        return <GeneralAdmission {...{...props, event, section}} />
    }

    return <div>Unknown map configuration...</div>
}