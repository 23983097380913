import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { PrimaryButton } from 'components/buttons/Button';
import Banner from 'components/Header/Banner';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const HOME_URL = '/';

export default props => {

    const { t } = useTranslation('tickets');

    const history = useHistory();

    return (
        <>
        <Container>
            <Row>
                <Col xs lg="9">
                    <h2 className='bold zt-tickets__header'>{t('feed.notickets.label', 'You have no tickets')}</h2>
                    <p>{t('feed.notickets.description', 'You have to buy tickets first.')}</p>
                    <PrimaryButton className='zt-tickets__gotobutton' onClick={() => history.push(HOME_URL)}>{t('feed.notickets.button', 'Go to Events ')}</PrimaryButton>
                </Col>
            </Row>
        </Container>
        <Banner />
        </>
    );
}