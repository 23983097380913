export default elem => {
    const parent = document.getElementsByClassName('zt-seats-map')[0].getBoundingClientRect(); 
    const box = elem.getBoundingClientRect();
    const width = box.right - box.left;
    const height = box.bottom - box.top;

    return {
        top: box.top - parent.top,// + window.pageYOffset,
        bottom: Math.ceil(parent.bottom - box.top + height/2),
        left: Math.ceil(box.left - parent.left + width/2),// + window.pageXOffset,
        width,
        height
    };
};