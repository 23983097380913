import React, { useCallback, useEffect } from 'react';
import PhoneInput from 'components/Inputs/PhoneInput';
import CheckBox from 'components/Inputs/CheckBox';
import { useTranslation } from 'react-i18next';
import Input from 'components/Inputs/Input';
import { Controller, useForm } from 'react-hook-form';
import * as formRules from 'config/forms';
import { useSelector } from 'react-redux';
import './style.scss';
import isCorrectPhone from 'pages/Event/pages/BuyTickets/AddFriends/Friend/components/PhoneSearch/isCorrectPhone';

export default ({ form, t, ...props }) => {

    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, setError, watch, control } = form;
    const { email, phone } = useSelector(state => state.register.user);

    const resetField = useCallback( name => {
        setValue(name, '', false);
        clearErrors(name);
    }, [setValue, clearErrors]);

    return (
        <div className='zt-registration__form'>
            <div className='zt-registration__form__block'>
                <div className='zt-registration__form__block__inputs'>
                    <Input 
                        {...register('email', {
                            ...formRules.email,
                            required: 'Required',
                            pattern: {
                                ...formRules.email.pattern,
                                message: t('common:errors.validation.email', 'invalid email address')
                        }})}                                     
                        label={t('using_document.account.inputs.email.label', 'Email (will used as login):')}
                        placeholder={t('using_document.account.inputs.email.placeholder','Enter a valid Email')}
                        onClear={() => resetField('email')}
                        error={errors?.email && (errors.email.message || t('common:errors.validation.email', 'invalid email address'))}
                        defaultValue={email}
                    />
                    <Controller 
                        control={control}
                        name='phone'
                        rules={{
                            required: 'Required',
                            minLength: 6,
                            validate: value => isCorrectPhone(value) ? true : t('common:errors.validation.phone', 'invalid phone format')
                        }}
                        render={({ field: { onChange, value, onBlur } }) => 
                            <PhoneInput 
                                placeholder={t('using_document.profile.inputs.phone.placeholder', 'Type your phone here')}
                                label={t('using_document.profile.inputs.phone.label', 'Phone number:')}
                                search={t('using_document.profile.inputs.phone.search', 'Search')}
                                onClear={() => resetField('phone')}
                                {...{onChange, onBlur}}
                                defaultValue={value}
                                autoFocus={false}
                                error={errors?.phone && (errors.phone.message || t('common:errors.validation.phone', 'invalid phone format'))}
                            />
                        }
                    />
                </div>
            </div>
        </div>
        );
}