import * as actions from './constants';
import * as selectActions from './../selected/constants';

const defaultState = {
    loading: false,
    id: null,
    qty: 0,
    price: 0,
    fee: 0,
    item: null,
    total: null,
    validated: false,
    error: null
};

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch (type) {

        case selectActions.RESET_SELLITEMS: 
        case selectActions.SELECT_SELLITEMS: {
            return {
                ...defaultState
            }
        }

        case actions.VALIDATE_SELLITEMS_START: {
            const id = payload;
            return {
                ...defaultState,
                id,
                loading: true
            }
        }

        case actions.VALIDATE_SELLITEMS_SUCCESS: {
            // const { qty, price, fee, item } = payload;
            const { refund_fee_percentage, merchandise_total: { total_price, total_refund_fee }, merchandises: items } = payload;

            const { unit_price, unit_refund_fee, item } = items[0];
            const { quantity, refunded_quantity, delivered_quantity } = item;

            return {
                ...defaultState,
                id: state.id,
                qty: Math.max(quantity - (+refunded_quantity) - (+delivered_quantity), 0),
                price: unit_price,
                fee: unit_refund_fee,
                item: item, 
                total: {price: total_price, fee: total_refund_fee, fee_percentage: refund_fee_percentage},
                validated: true
            }
        }

        case actions.VALIDATE_SELLITEMS_FAILURE: {
            console.log(`Response status: ${+error?.response?.status}`);
            const validated =  +error?.response?.status === 406 // refund not acceptable
            return {
                ...defaultState,
                error,
                validated
            }
        }

        default: {
            return state;
        }
    }
}
