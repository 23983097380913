import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'components/buttons/Button';
import EventInfoLine, { TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE } from 'components/EventInfo';
import { useTranslation } from 'react-i18next';
import Logotype from 'components/Logotype';
import FORMAT from 'config/date/format';
import defferedLink from './defferedLink';
import { link } from 'pages/Event';
import './style.scss';

export default ({ item }) => {
    const [t] = useTranslation(['common', 'home']);
    const history = useHistory();

    const id = item.id || null;

    if (!+id) { //Broken item
        return <p>Error in data</p>;
    }

    const url = new URL(document.baseURI);

    const event_url = defferedLink(url.origin+link(id));
    const media = item.medium;

    return (
    <div className='zt-card'>
        <div className='zt-card__imageholder' onClick={() => window.open(event_url, '_blank')}>
            {media ?
            <img alt={item.name} className='zt-card__imageholder__image' src={`${media}${"?width=620&height=480"}`} />
            :
            <Logotype className='zt-card__imageholder__logotype'/>
            }
        </div>
        <div className='d-flex flex-column zt-card__body'>
            <div className='card-title'>
            <h4><a target="_blank" href={event_url}>{item.name}</a></h4>
            </div>
            <div className='zt-card__description zt-p1'>{item.performer_name}</div>
            <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION} card>{item?.map?.venue.name}</EventInfoLine>
            <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>{t('home:eventslist.card.date', {date: moment(item?.start_date, FORMAT)})}</EventInfoLine>
            <PrimaryButton onClick={() => window.open(event_url, '_blank')}>{t('buttons.buy', 'Buy Tickets')}</PrimaryButton>
        </div>
    </div>);
}