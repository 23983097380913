import React, { useCallback } from 'react';
import Dropdown from 'components/buttons/Dropdown';
import Menu from 'components/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { CityIcon } from 'components/Icons/Glyphs';
import Search from 'components/Inputs/Search';
import * as filterActions from './../actions';
import * as actions from './actions';
import { useDebouncedCallback } from 'use-debounce';
import MiniLoader from './MiniLoader';
import { useTranslation } from 'react-i18next';

export default props => {
    const { cities, loading } = useSelector(state => ({cities: state.cities.items, loading: state.cities.loading}));
    const [t] = useTranslation('filter');

    // Debounce callback for cities search
    const [debouncedCallback] = useDebouncedCallback(
        (value) => {
            dispatch(actions.setFilter(value));
        },
        // delay in ms
        1000
    );    

    const dispatch = useDispatch();

    const refresh = useCallback(() => {
        dispatch(actions.resetFilter());
        dispatch(actions.fetchCities());
    }, [dispatch])

    return (<Dropdown label={t('cities.label', 'City')} icon={CityIcon} selected={props.selected}  onUnSelect={props.onUnSelect} onOpen={() => refresh()}>
        <Menu items={cities} onSelect={city => dispatch(filterActions.setCity(city))} >
            <React.Fragment>
            <Search placeholder={t('cities.search_placeholder', 'Search City')} onChange={e => debouncedCallback(e.target.value)} />
            { (loading /*|| value !== citySearch */) &&
            <MiniLoader style={{position: 'absolute', top: 62}}/>
            }
            </React.Fragment>
        </Menu>
    </Dropdown>);
}