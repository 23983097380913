import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import SelectMethodPage from './SelectMethod';
import Document, { path as documentPath } from './methods/Document';
import Banner from 'components/Header/Banner'

import './style.scss';
import { Route } from 'react-router-dom';
import Activation, { path as activationPath } from './methods/Document/Activation';
import BankID, { path as bankIdPath, loginlink as bankIdSubLink } from './methods/BankID';

export const path = '/registration';
export const link = path;//.replace('','');
export const bankIdLink = link+bankIdSubLink;

export default props => {
    const { match } = props;
    return (
    <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <Container className='zt-registration'>
            <Row>
                <Col md={{ span: 8, offset: 2}} lg={{ span: 6, offset: 3 }} >
                    <Route path={match.url} exact component={SelectMethodPage} />
                    <Route path={match.url + documentPath} exact component={Document} />
                    <Route path={match.url + activationPath} exact component={Activation} />
                    <Route path={match.url + bankIdPath} component={BankID} />
                    <div className='zt-registration__banner'>
                        <Banner />
                    </div>
                </Col>
            </Row>
        </Container>
    </CSSTransition>
    );
}
