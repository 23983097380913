const adoptStats = stats => {
    const places = stats.ordinary.seats.count;
    const priced = stats.ordinary.seats.has_default_price;
    const available = stats.ordinary.seats.can_reserve_attendee;
    const hidden = stats.ordinary.seats.hidden;
    const level = stats.ordinary.seats.min_default_actual_price_wfees;
    const prices = stats.ordinary.seats.default_actual_prices_wfees;

    return {
        places,
        priced,
        available,
        hidden,
        level,
        prices
    }
}

export default adoptStats;