import React, { useEffect, useState } from 'react';
import EventInfoLine, { TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE } from 'components/EventInfo';
import ContentRestrictions from 'pages/Event/components/ContentRestrictions';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import useCheckoutDispatcher from './useCheckoutDispather';
import PriceLevels from './components/aside/PriceLevels';
import TicketsNumberPicker from './TicketsNumberPicker';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import GroupSeats from './components/aside/GroupSeats';
import { Container, Row, Col } from 'react-bootstrap';
import * as eventActions from './../Details/actions';
import * as seatingTypes from './store/seatingTypes';
import * as seatsActions from './PickSeats/actions';
import TotalFee from './components/aside/TotalFee';
import Error from 'pages/Event/components/Error';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Header/Banner';
import * as actions from './store/actions';
import Stepper from './components/Stepper';
import useEventType from './useEventType';
import Divider from 'components/Divider';
import { Modal } from 'react-bootstrap';
import PickSection from './PickSection';
import FORMAT from 'config/date/format';
import AddFriends from './AddFriends';
import PickSeats from './PickSeats';
import Checkout from './Checkout';
import Confirm from './Confirm';
import moment from 'moment';
import './styles.scss';
import Loading from 'components/Loading';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useFetchUnpaidReservations from '../Details/components/BuyTicketsButton/useFetchUnpaidReservations';
import ActiveReservationDialog from '../Details/components/BuyTicketsButton/components/ActiveReservationDialog';

export const path = '/buy/:mode?/:payment_id?';
export const link = path.replace('/:mode?/:payment_id?', '');

const CONFIRM_URL = 'confirm';

const STEP_HOLD = 'STEP_HOLD';
const STEP_FETCHDATA = 'STEP_FETCHDATA';
const STEP_CHOOSESECTION = 'STEP_CHOOSESECTION';
const STEP_PICKSEATS = 'STEP_PICKSEATS';
const STEP_GENERALADMISSION = 'STEP_GENERALADMISSION'; 
const STEP_ADDFRIENDS = 'STEP_ADDFRIENDS';
const STEP_CHECKOUT = 'STEP_CHECKOUT';
const STEP_CONFIRM = 'STEP_CONFIRM';

const steps =  [STEP_CHOOSESECTION, STEP_PICKSEATS, STEP_ADDFRIENDS, STEP_CHECKOUT];

const BuyTickets = props => {
    const { t } = useTranslation('event');
    const { t: tb } = useTranslation('buying');

    const { item: event, loading, error } = useSelector(store => store.event.details);

    const { map } = useSelector(store => ({map: store.event.maps.sections.map}));

    const seats = useSelector(store => store.event.maps.seats);

    const details = useSelector(store => store.event.tickets.details);
    const section = useSelector(store => store.event.tickets.section);
    
    const { id } = useParams();
    const dispatch = useDispatch();
    const [step, setStep] = useState(STEP_HOLD);
    const [showTicketsNumPicker, setShowTicketsNumPicker] = useState(false);
    const maxNumberTickets = event?.max_number_tickets || 1;

    const ticketsQty = useSelector(state => state.event.tickets.qty);

    const stepsLabels = {
        [STEP_CHOOSESECTION]: tb('steps.section', 'Pick Section'), 
        [STEP_PICKSEATS]: tb('steps.seats', 'Pick Seats'), 
        [STEP_ADDFRIENDS]: ticketsQty > 1 ? tb('steps.friends', 'Add Friends') : tb('steps.select_type', 'Select type'), 
        [STEP_CHECKOUT]: tb('steps.checkout', 'Checkout')
    };

    const { match } = props;

    const eventType = useEventType();

    useEffect(() => {
        setStep(STEP_FETCHDATA);
        return () => dispatch(actions.resetSection());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        
        switch (step) {
            
            case STEP_FETCHDATA:
                if (loading || error || details.loading || details.error) {
                    return;
                }

                if (!event || +event.id !== +id) { //Load event
                    dispatch(eventActions.fetchEvent(id));
                    return;
                }
                
                if (!details.loaded || +details.eventId !== +event?.id) { //Load event details
                    dispatch(actions.resetSection());
                    dispatch(actions.fetchEventSections(event.id));
                    return;
                }

                if (match.params?.mode === CONFIRM_URL) {
                    setStep(STEP_CONFIRM);
                    return;
                }

                switch(eventType){
                    case seatingTypes.SEATING_TYPE_GENERALADMISSION:
                        setStep(STEP_PICKSEATS);
                        return;
                    case seatingTypes.SEATING_TYPE_SECTION:
                        setStep(STEP_CHOOSESECTION);
                        return;
                    case seatingTypes.SEATING_TYPE_SEATS:
                        setStep(STEP_PICKSEATS);
                        return;
                    default:
                        return;
                }

            case STEP_CHOOSESECTION:
                if (!section) {
                    setShowTicketsNumPicker(true);
                    return;
                }
                if (section) {
                    setStep(STEP_PICKSEATS);
                }
                return;
            case STEP_PICKSEATS:
                return;
            case STEP_CONFIRM: {
                console.log('CONFIRM MODE');
                break;
            }
            case STEP_ADDFRIENDS:
            case STEP_GENERALADMISSION:
            case STEP_CHECKOUT:
            default:
                return;
        }

    }, [event, id, section, seats, step, setStep, map, loading, details, dispatch, match, error]);

    const checkoutDispatcher = useCheckoutDispatcher();

    if (loading) {
        return <MiniLoader />;
    }

    if (error) {
        return <Container><Row><Col><Error retry={() => dispatch(eventActions.fetchEvent(id))} /></Col></Row></Container>
    }

    const stepsFilter = {
        [STEP_CHOOSESECTION]: () => eventType === seatingTypes.SEATING_TYPE_SECTION
    }
 
    const validSteps = [...steps].filter((step, position) => stepsFilter[step] ? stepsFilter[step](position) : true);
    const stepperLabels = validSteps.map(step => stepsLabels[step]);
    const stepperKeys = [...validSteps];

    const handSelectStep = [STEP_CHECKOUT, STEP_CONFIRM].includes(step) ? () => {} : step => {
        switch(step){
            case STEP_CHOOSESECTION: {
                dispatch(seatsActions.reset());
                dispatch(actions.resetSection());
                setStep(STEP_CHOOSESECTION);
                break    
            }
            case STEP_CHECKOUT: {
                return;
            }
            default: {
                setStep(step);
            }
        }
    };

    if (event === null || [STEP_FETCHDATA, STEP_HOLD].includes(step)) {
        return <MiniLoader />
    }

    return (
    <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <Container className='zt-event-buy'>
            <Row>
                <Col md={9} lg={{ span: 8, offset: 1 }} className='zt-event-buy__content'>
                    <div className='zt-event-buy__header'>
                        <h1 className='bold zt-event-buy__header__title'>{event.name}</h1>
                        <h4 className='light zt-event-buy__header__performer'>{event.performer_name}</h4>
                        <Stepper steps={stepperLabels} current={step === STEP_CONFIRM ? stepperKeys.indexOf(STEP_CHECKOUT) : stepperKeys.indexOf(step)} onSelect={i => handSelectStep(stepperKeys[i])}/>
                    </div>


                    {
                        {
                            [STEP_CHOOSESECTION]: <PickSection {...{event, onSelect: () => setStep(STEP_PICKSEATS)}} />,
                            [STEP_PICKSEATS]: <PickSeats {...{event, section}} group={ticketsQty} next={() => ticketsQty === 1 ? setStep(STEP_CHECKOUT) : setStep(STEP_ADDFRIENDS)} />,
                            [STEP_ADDFRIENDS]: <AddFriends event={event} qty={ticketsQty} next={() => setStep(STEP_CHECKOUT)} dispatcher={checkoutDispatcher} />,
                            [STEP_CHECKOUT]: <Checkout event={event} />,
                            [STEP_CONFIRM]: <Confirm {...{event}} />
                        }[step] || 'default'
                    }


                    <Modal show={showTicketsNumPicker} size="md" centered dialogClassName='zt-modal-small' className='zt-modal-centered' >
                        <TicketsNumberPicker qty={ticketsQty} onSelect={qty => {dispatch(actions.setTicketsQty(qty)); setShowTicketsNumPicker(false);}} showClose={false} onClose={() => setShowTicketsNumPicker(false)} max={maxNumberTickets} />
                    </Modal>      

                    { ![STEP_CHECKOUT, STEP_CONFIRM].includes(step) &&
                    <>
                        <Divider />
                        <div className='zt-event-buy__restrictions'>
                            <div className={`zt-event-buy__restrictions__block ${[STEP_ADDFRIENDS].includes(step) ? 'zt-event-buy__restrictions__block--left' : ''}`}>
                                <ContentRestrictions t={t} event={event} />
                            </div>
                        </div>
                    </>
                    }
                </Col>
                <Col md={3} className='zt-event-buy__aside'>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION}>{event.map?.venue?.name || 'Venue Name'}</EventInfoLine>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE}>{t('date', {date: moment(event.start_date, FORMAT)})}</EventInfoLine>
                    { [STEP_CHOOSESECTION, STEP_PICKSEATS].includes(step) &&
                        <>
                            <Divider />
                            <PriceLevels t={t} />
                        </>
                    }
                    { step === STEP_PICKSEATS &&
                        <>
                        <Divider />
                        <GroupSeats next={() => setStep(STEP_ADDFRIENDS)} /> 
                        </>
                    }
                    { [STEP_ADDFRIENDS, STEP_CHECKOUT, STEP_CONFIRM].includes(step) &&
                        <TotalFee controlls={step === STEP_ADDFRIENDS} confirm={step === STEP_CONFIRM} next={() => checkoutDispatcher.proceed(() => setStep(STEP_CHECKOUT))}/>
                    }
                </Col>
            </Row>
            {[STEP_CONFIRM].includes(step) &&
            <Banner />
            }
        </Container>
    </CSSTransition>
    );
};


export default props => {

    const { state: { loading, reservation, error, checked }, fetch, reset } = useFetchUnpaidReservations();
    const { id } = useParams();
    const history = useHistory();
    const { state: { checked: isCheckedParameter = false } = {} } = useLocation();

    let isChecked = isCheckedParameter;

    const onClose = () => history.push('/');
    const onContinue = () => fetch(id);

    const { match } = props;
    if (match.params?.mode === CONFIRM_URL) {
        isChecked = true;
    }
    
    useEffect(() => {
        if (isChecked) {
            return;
        }
        fetch(id);
    }, [id, isChecked]);

    if (reservation) {
        return <ActiveReservationDialog {...{id: reservation?.id, onClose, onContinue}} />;
    }

    if (!checked && !isChecked) {
        return <Loading />
    }

    //clear state
    window.history.replaceState({}, document.title);
    //Go to buying flow
    return <BuyTickets {...props} />
};