import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from 'components/Feed/Footer';
import Loading from 'components/Loading';
import Title from './Title';
import * as actions from './actions';

import { CSSTransition } from 'react-transition-group';
import EventCard from 'components/Feed/EventCard';
import Divider from 'components/Divider';
import './style.scss';


export default () => {

    const [itemsLeft, setItemsLeft] = useState(0);
    const { items, total, loading } = useSelector(state => (
        {
            items: state.filteredevents.items,
            total: state.filteredevents.total,
            loading: state.filteredevents.loading
        }));

    const filterEmpty = useSelector(state => Object.values(state.filteredevents.filter).every(filter => (filter === null || filter === '')));
    const noResults = useSelector(state => !state.filteredevents.loading && (state.filteredevents.items || []).length === 0 );
    const dispatch = useDispatch();

    useEffect(() => {
        if (items) {
            setItemsLeft(total - items.length)
        }
    }, [total, items]);    

    if (filterEmpty) {
        return null;
    }

    const loadMore = () => dispatch(actions.fetchEvents());
    
    return (
    <Container className='zt-filtered_feed'>
        <Title />
        { !!items &&
        <Row className="justify-content-md-center">
            <Col md="12" lg="12" xl="9">
                <Row className="eventsfeed" lg={4} md={3} sm={2}>
                    { items.map(item => <Col key={item.id}>
                            <CSSTransition in={true} appear={true} timeout={200} classNames="node">
                            <EventCard item={item}/>
                            </CSSTransition>
                        </Col>) }
                </Row>      
            </Col>
        </Row>
        }         
        { loading === false ?
            ( !noResults &&
            <React.Fragment>
                { +itemsLeft > 0 &&
                <Footer left={itemsLeft} onMore={loadMore} />
                }
                <Divider />
            </React.Fragment>
            )
        :
        <Loading />
        }        
    </Container>);
}