export default function useItemQtys(item) {
    if (!item) {
        return {};
    }

    const bought = item?.quantity - item?.refunded_quantity;
    const pickedUp = item?.delivered_quantity;

    return {
        bought,
        pickedUp        
    }
}