import React from 'react';
import Seat from './../../../Seat';
import colors from './../../../colorLevels';
import usePriceLevels from '../../../usePriceLevels';

export default ({ t, ...props }) => {
    const priceLevels = usePriceLevels(); 
    const colorsCount = colors?.length;
    const levelsCount = priceLevels?.length;

    const ratio = colorsCount / levelsCount;

    if (ratio < 1) {
        const groups = [];
        priceLevels.forEach( (level, i) => {
            const groupid = Math.floor(i*ratio);
            if (!groups[groupid]) {
                groups[groupid] = [];
            }
            groups[groupid].push(level);
        });
        return <div className='zt-event-buy__aside__prices'>
            <span className='zt-p3 zt-event-buy__aside__label'>Prices(Grouped):</span>    
            <ul>
                {groups.map((group, id) => <li className='zt-event-buy__aside__prices__price' key={id}>
                        <Seat className='zt-event-buy__aside__prices__price__seat' fill={colors[id]}/> {t('common:priceGroup', {start: Number(group[0]), end: Number(group[group.length - 1])})}
                    </li>
                )}
            </ul>
        </div>
    }

    return (
    <div className='zt-event-buy__aside__prices'>
        <span className='zt-p3 zt-event-buy__aside__label'>Prices:</span>
        <ul>
            {priceLevels?.map((price, id) => <li className='zt-event-buy__aside__prices__price' key={price}><Seat className='zt-event-buy__aside__prices__price__seat' fill={colors[Math.floor(id*ratio)]}/> {t('common:price', {price: Number(price)})}</li>)}
        </ul>
    </div>
    );
}