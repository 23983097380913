import React from 'react';
import './style.scss';
import Logo from 'components/Logotype';
import { Container,Col, Row } from 'react-bootstrap';
import { Help, Conditions, Policy, Cookies, Contact } from 'components/Icons/Special';
import LangSwitcher from 'components/LangSwitcher';
import Divider from 'components/Divider';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { link as helpLink } from 'pages/Help';
import { link as contactsLink } from 'pages/Contacts';
import { link as privacyLink } from 'pages/Privacy';
import { link as cookiesLink } from 'pages/Cookies';
import { link as termsLink} from 'pages/Terms';
import useConfiguration from './useConfiguration';
import AppleStore from 'components/buttons/stores/AppleStore';
import GooglePlay from 'components/buttons/stores/GooglePlay';
import Instagram from 'components/buttons/socials/Instagram';
import Facebook from 'components/buttons/socials/Facebook';

export default prop => {
    const [t] = useTranslation('footer');

    const { trim = false } = useConfiguration();

    return (
        <Container>
            <Divider />
            <Row className='zt-footer'>
                <Col sm={6} md={6}>
                    {!trim && <ul className='zt-footer__links'>
                        <li className='zt-footer__links__link'><NavLink to={helpLink} activeClassName="zt-footer__links__link--active"><Help className='zt-footer__links__link__icon' /><span className='zt-nav light'>{t('links.help', 'Get Help')}</span></NavLink></li>
                        <li className='zt-footer__links__link'><NavLink to={termsLink} activeClassName="zt-footer__links__link--active"><Conditions className='zt-footer__links__link__icon' /><span className='zt-nav light'>{t('links.conditions', 'Terms and Conditions')}</span></NavLink></li>
                        <li className='zt-footer__links__link'><NavLink to={privacyLink} activeClassName="zt-footer__links__link--active"><Policy className='zt-footer__links__link__icon' /><span className='zt-nav light'>{t('links.policy', 'Privacy Policy')}</span></NavLink></li>
                        <li className='zt-footer__links__link'><NavLink to={contactsLink} activeClassName="zt-footer__links__link--active"><Contact className='zt-footer__links__link__icon' /><span className='zt-nav light'>{t('links.contacts', 'Contact Us')}</span></NavLink></li>
                    </ul>}
                </Col>
                {!trim &&
                <Col>
                    <div className='zt-footer__langswch mt-4 mt-sm-0'>
                        <LangSwitcher />
                    </div>
                    <div className='zt-footer__socials'>
                        <Instagram />
                        <Facebook />
                        {/* <Twitter /> */}
                    </div>
                    <div className='zt-footer__stores'>
                        <AppleStore />
                        <GooglePlay />
                    </div>
                </Col>}
            </Row>
            <Row className='zt-footer__terms'>
                <Col sm={6} md={6}>
                    <p className='zt-footer__terms__copyright zt-p3 mb-4'>{t('copyright', '© ZipperTic 2021')}</p>        
                </Col>
                <Col>
                    <div className='zt-footer__terms__logo'><Logo /></div>        
                </Col>
            </Row>
        </Container>);
}