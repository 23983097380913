export const EVENTBUY_RESET = 'EVENTBUY_RESET';
export const EVENTBUY_TICKETS_RESET = 'EVENTBUY_TICKETS_RESET';

export const EVENTBUY_SELECT_SECTION = 'EVENTBUY_SELECT_SECTION';
export const EVENTBUY_RESET_SECTION ='EVENTBUY_RESET_SECTION';

export const EVENTBUY_SET_TICKETS_QTY = 'EVENTBUY_SET_TICKETS_QTY';
export const EVENTBUY_SET_TICKETS_GROUP_MODE = 'EVENTBUY_SET_TICKETS_GROUP_MODE';
export const EVENTBUY_ADD_TICKET = 'EVENTBUY_ADD_TICKET';
export const EVENTBUY_REMOVE_TICKET = 'EVENTBUY_REMOVE_TICKET';
export const EVENTBUY_CLEAR_TICKET = 'EVENTBUY_CLEAR_TICKET';

export const EVENTBUY_FETCH_DETAILS_START = 'EVENTBUY_FETCH_DETAILS_START';
export const EVENTBUY_FETCH_DETAILS_SUCCESS = 'EVENTBUY_FETCH_DETAILS_SUCCESS';
export const EVENTBUY_FETCH_DETAILS_FAILURE = 'EVENTBUY_FETCH_DETAILS_FAILURE';
