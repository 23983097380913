import api from 'api';
const ticketsUrl = 'api/events';

const sg = [
    'event.ticket_reservations',
    'event.ticket_reservations.user', 
    'event.ticket_reservations.action_id',
    'event.merchandise_reservations_groups', 
    'event.child_events_with_item_reservations',
    'event.child_events_with_item_reservations.ticket_reservations', 
    'event.child_events_with_item_reservations.ticket_reservations.user',
    'event.child_events_with_item_reservations.ticket_reservations.action_id',
    'event.child_events_with_item_reservations.merchandise_reservations_groups'
];

const config = {
    current_user_has_items: 1,
    sg
}

export default {
    fetch: params => api.get({path:ticketsUrl, params: {...params, ...config}})
};
