import React from 'react';
import { ArrowRight as ArrowRightIcon, QR } from 'components/Icons/Glyphs';
import { PrimaryButton } from 'components/buttons/Button';
import { link as tickets_url } from './../../../List';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// import './style.scss';

const Restricted = ({ children, ...props }) => {
    const { t } = useTranslation('tickets');
    const tt = (label, params) => t(`sell_item.restricted.${label}`, params);

    const history = useHistory();
    const goToTickets = () => history.push(tickets_url);

    return (
    <div className='zt-tickets__sell__restricted'>{children}
        <PrimaryButton onClick={goToTickets} className='zt-tickets__sell__restricted__gotobutton' icon={QR}>{tt('button', 'Goto My Tickets')}  <ArrowRightIcon className='zt-tickets__sell__restricted__gotobutton__arrow'/></PrimaryButton>
    </div>);
}

const Error = ({ error }) => <div className='zt-p1 zt-tickets__sell__restricted__info'>{error}</div>

export default ({ error, event, ...props }) => {

    const { t } = useTranslation('tickets');

    const Title = () => <h4 className='bold zt-tickets__sell__restricted__header'>{t('sell_item.restricted.label', 'Item can\'t be refounded')}</h4>;

    return (<Restricted>
            <Title />
            <Error error={t('sell_item.restricted.description', 'Sorry, you have items that can not be refunded..')} />        
        </Restricted>);
}