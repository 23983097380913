import * as actions from './constants';
const defaultState = {
    items: null,
    total: 0,
    page: 0,
    page_size: 4, //TODO: Get default value from a config
    starting_id: null,
    loading: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action

    switch (type) {
        case actions.RESET_AVAILABLE_EVENTS:
            return defaultState;

        case actions.FETCH_AVAILABLE_EVENTS_START:
            return {
                ...state,
                page: state.page+(state.error ? 0 : 1),
                error: null,
                loading: true
            }   

        case actions.FETCH_AVAILABLE_EVENTS_SUCCESS:
            const items = state.items === null ? payload.collection : state.items.concat(payload.collection);
            const total = payload.count || 0;
            const starting_id = state.starting_id === null && (items ? items?.[0]?.id || null : state.starting_id); 
            return {
                ...state,
                items,
                total, 
                starting_id,
                loading: false
            }

        case actions.FETCH_AVAILABLE_EVENTS_FAILURE:
            return {
                ...state,
                loading:false,
                error
            }

        case actions.SET_AVAILABLE_EVENTS_PAGE_SIZE:
            return {
                ...state,
                page_size: action.payload
            }

        default:
            return state;

    }
}