export const COREID_TOKEN_REQUEST_START = 'COREID_TOKEN_REQUEST_START';
export const COREID_TOKEN_REQUEST_SUCCESS = 'COREID_TOKEN_REQUEST_SUCCESS';
export const COREID_TOKEN_REQUEST_FAILURE = 'COREID_TOKEN_REQUEST_FAILURE';

export const SET_COREID_TOKEN = 'SET_COREID_TOKEN';

export const RESET_BANKID = 'RESET_BANKID';

export const VALIDATE_COREID_TOKEN_START = 'VALIDATE_COREID_TOKEN_START';
export const VALIDATE_COREID_TOKEN_SUCCESS = 'VALIDATE_COREID_TOKEN_SUCCESS';
export const VALIDATE_COREID_TOKEN_FAILURE = 'VALIDATE_COREID_TOKEN_FAILURE';

export const REGISTER_COREID_USER_START = 'REGISTER_COREID_USER_START';
export const REGISTER_COREID_USER_SUCCESS = 'REGISTER_COREID_USER_SUCCESS';
export const REGISTER_COREID_USER_FAILURE = 'REGISTER_COREID_USER_FAILURE';