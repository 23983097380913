import { useReducer } from 'react';
import logger from 'use-reducer-logger';
import * as actions from './actions';
import reducer, { defaultState } from './reducer';

export default function useDeclineReservation() {

    const [state, dispatch] = useReducer(logger(reducer), defaultState);

    const doDecline = actions.doDecline(dispatch);

    return {
        state,
        doDecline
    }
}
