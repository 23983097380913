import * as actions from './constants';

export const defaultState = {
    loading: false,
    error: null,
    forgotten: false
}

export default function reducer(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch (type) {
        
        case actions.FORGETME_FOREVENT_START: {
            return {
                ...defaultState,
                loading: true
            }
        }
        
        case actions.FORGETME_FOREVENT_SUCCESS: {
            return {
                ...defaultState,
                forgotten: true
            }
        }

        case actions.FORGETME_FOREVENT_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }

    }
}