import * as constants from './constants';
import api from './api';

const flow = {
    start: payload => ({type: constants.DO_DECLINE_START, payload}),
    success: payload => ({type: constants.DO_DECLINE_SUCCESS, payload}),
    failure: error => ({type: constants.DO_DECLINE_FAILURE, error})
}

export const doDecline = (dispatch) => (id, callBack = null) => {
    dispatch(flow.start(id));
    api.decline(id)
        .then(response => {
            dispatch(flow.success(response));
            callBack && callBack();
        })
        .catch(error => dispatch(flow.failure(error)))
    // setTimeout(() => {
    //     dispatch(declineFlow.success());
    //     callBack && callBack();
    // }, 5000);
    // setTimeout(() => dispatch(declineFlow.error(error)), 5000);
}
