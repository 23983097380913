import { useEffect, useReducer } from "react";
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';
import useCurrentUserId from "../../../../../utils/useCurrentUserId";

export default function useForgetForEvent(eventId) {

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    const userId = useCurrentUserId();

    if (!userId) {
        throw 'Not authorised user, account can\'t be deleted';
    }

    if (!eventId) {
        throw 'No event id provided';
    }

    const proceedForget = actions.forgetMe(dispatch)(userId, eventId);

    return {
        state,
        proceedForget
    }

}