import React, { useState, useEffect } from 'react';
import './style.scss';

const Button = ({ onClick=false, className, children, ...props }) => <button onClick={onClick} className={className}>{children}</button>

const MinusButton = props => <Button {...props}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" className='zt-inputs-stepper__button__circle' fill="#C00000"/>
    <rect x="6" y="13" width="2" height="12" transform="rotate(-90 6 13)" fill="white"/>
</svg></Button>

const PlusButton = props => <Button {...props}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" className='zt-inputs-stepper__button__circle' fill="#C00000"/>
    <rect x="11" y="6" width="2" height="12" fill="white"/>
    <rect x="6" y="13" width="2" height="12" transform="rotate(-90 6 13)" fill="white"/>
</svg></Button>

export default React.forwardRef( ({ max = Infinity, value = 1, name, onChange, className, disabled=false, ...props }, ref) => {
    return (
    <div className={`zt-inputs-stepper ${className || ''} ${disabled ? 'zt-inputs-stepper--disabled' : ''}`}>
        <MinusButton className={`zt-inputs-stepper__button ${value === 1 ? 'zt-inputs-stepper__button--disabled' :''}`} onClick={() => (value > 1) && !disabled && onChange && onChange(value - 1) }/>
            <div className='zt-inputs-stepper__value'><span className='zt-nav'>{value}</span></div>
        <PlusButton className={`zt-inputs-stepper__button ${value === max ? 'zt-inputs-stepper__button--disabled' :''}`} onClick={() => (value < max) && !disabled && onChange && onChange(value + 1) }/>
    </div>);

});