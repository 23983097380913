
export const RESTRICTIONS = 'event.restrictions';
export const PROMOTER = 'event.promoter';
export const CHILDREN = 'event.child_events';
export const SEASONAL = 'event.seasonal_event';
export const TICKETS_SOLD = 'event.tickets_sold';


export const CONFIGURATIONS = {
    RESTRICTIONS,
    PROMOTER,
    CHILDREN,
    SEASONAL,
    TICKETS_SOLD
}

export default CONFIGURATIONS;
