import { useReducer } from "react";
import { reducer, defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';
import { useSelector } from "react-redux";

export default function useProceedRefund(eventId, item) {

    if (!+eventId) {
        throw 'No eventId provided for refund';
    }

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    const refund = actions.refund(dispatch, eventId, item);

    return {
        state,
        refund
    }
}