import api from 'api';

const eventFriendSearchUrl = 'api/events/:id/tickets/friend';
const eventGetTicketInfoUrl = 'api/events/:id/tickets/info';
const eventGetPricesInfoUrl = 'api/events/:id/tickets/info';

export default {
    pricesInfo: (id, seats) => api.post({path:eventGetPricesInfoUrl.replace(':id', id), params:seats}),
    searchFriend: (id, phone, cancelCallback) => api.get({path:eventFriendSearchUrl.replace(':id', id), params:{phone, can_get_ticket: 1}, cancelCallback}),
    ticketInfo: (id, kid = false) => api.post({path:eventGetTicketInfoUrl.replace(':id', id), params:{kid}})
}
