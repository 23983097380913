import React from "react";
import FORMAT from "config/date/format";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import EventInfoLine, {TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE} from 'components/EventInfo';
import { useTranslation } from "react-i18next";
import Logotype from "components/Logotype";
import Tickets from "./components/Tickets";
import './styles.scss';

const Header = ({ item }) => {

    const { t } = useTranslation('tickets');

    return (
        <Row>
            <Col xs md={8}>     
                <h4>{item.name}</h4>
                <div className='zt-p1 mb-4'>{item.performer_name}</div>
                <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION} card>{item?.map?.venue?.name || '--'}</EventInfoLine>
                <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>{t('date', {date: moment(item.start_date, FORMAT)})}</EventInfoLine>                 
            </Col>
            <Col className='d-none d-md-block' md={4}>
                <div className="float-right">
                    <div className='zt-webshop__event__media'>
                        {item.thumbnail ?
                        <img alt={item.name} className='zt-webshop__event__media__image' src={`${item.thumbnail}`} />
                        :
                        <Logotype className='zt-webshop__event__media__logotype'/>
                        }    
                    </div>
                </div>            
            </Col>
        </Row>
    );
}



const MerchantItems = ({ item }) => null;

export default ({ items }) => {
    if (!items || !items.length) {
        return null;
    }

    const events = items?.sort((a, b) => new moment(a.start_date, FORMAT).unix() - new moment(b.start_date, FORMAT).unix());

    return (events?.map((item, key) =><div {...{key}} className="zt-season__tickets">
        <Header {...{item}} />
        <Tickets {...{item}}/>
        <MerchantItems {...{item}} />
    </div>));
}

