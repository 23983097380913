import React from 'react';
import './style.scss';
import { ClearIcon } from 'components/Icons/Glyphs';

export default React.forwardRef(({ className=null, onClear=null, label=null, error, disabled=false, description, description2='', ...props }, ref) => <div className={`zt-input ${disabled? 'zt-input--disabled':''} ${className ? className : ''}`}>
    { !!label &&
        <div className='zt-input__label'><span className='zt-p3'>{label}</span></div>
    }
    <input ref={ref} className={`zt-input__input ${error ? 'zt-input__input--error' : ''}`} placeholder={props.placeholder} disabled={disabled} {...props}/>
    <ClearIcon className={`zt-input__clearicon ${label ? 'zt-input__clearicon--labeled': ''}`} onClick={() => onClear && onClear()} />
    { (!!error || !!description) &&
        <div className='zt-input__message'>
            <div className={`zt-input__message__first ${error ? 'zt-input__message__first--error' : ''}`}><span className='zt-p3'>{error || description}</span></div>
            <div className='zt-input__message__second'><span className='zt-p3'>{description2}</span></div>
        </div>
    }
</div>);