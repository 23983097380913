import React, { useEffect, useRef, useState } from 'react';
import useProfileItemUpdate from '../useProfileItemUpdate';
import { PrimaryButton } from 'components/buttons/Button';
import Input from 'components/Inputs/Input';
import * as formRules from 'config/forms';
import { useSelector } from 'react-redux';
import Divider from 'components/Divider';
import { useForm } from 'react-hook-form';

export default ({ t, refresh, ...props }) => {

    const { profile } = useSelector(state => state.profile);

    const { state: {loading, profile: updated, saved, error}, proceedUpdate, reset } = useProfileItemUpdate();
    
    const { register, handleSubmit, watch, formState: { errors }, reset: resetForm } = useForm();
    const [current_password, password, confirm_password] = watch(["current_password", "password", "confirm_password"]);
    
    const save = () => {
        proceedUpdate({current_password, password, password_confirmation: password});
    };

    const disabled = !current_password || !password || !confirm_password;
    
    useEffect(() => {
        if (saved) {
            reset();
            refresh(updated);
            resetForm();
        }
    }, [saved, updated, refresh, profile, reset]);    

    return <div className='zt-profile__security'>
        <form>
            <h4 className='zt-profile__label zt-profile__security__label'>{t('security.label', 'Security')}</h4> 
            <Input 
                {...register('current_password', { required: true })}
                label={t('security.inputs.current_password.label', 'Current password:')} 
                placeholder={t('security.inputs.current_password.placeholder', 'Enter current password')} 
                type="password"
                error={!!errors?.current_password && t('security.inputs.current_password.error', 'Password is not correct')}
                defaultValue='' 
                />            
            <Input 
                {...register('password', 
                    { 
                        required: true,
                        minLength: formRules.password.minLength,
                        validate: value => value !== current_password
                    }
                )}
                label={t('security.inputs.new_password.label', 'New Password:')} 
                placeholder={t('security.inputs.new_password.placeholder', 'Create a password')} 
                type="password"
                error={!!errors?.password && t('security.inputs.new_password.error', 'Password is not correct or do not comply the rules')}
                defaultValue='' 
                />
            <Input 
                 {...register('confirm_password', { 
                        required: true,
                        validate: value => value === password
                    })}
                label={t('security.inputs.confirm_password.label', 'Confirm password:')} 
                placeholder={t('security.inputs.confirm_password.placeholder', 'Confirm a password')} 
                type="password"
                error={!!errors?.confirm_password && t('security.inputs.confirm_password.error', 'Password do not match')}
                defaultValue=''
                />
            <PrimaryButton {...{disabled, loading, error: error && t('security.error')}} onClick={handleSubmit(() => save())}>{t('security.button', 'Change Password')}</PrimaryButton>
       </form>
       <Divider />
    </div>
}