import { useLocation } from "react-router-dom";
import { path as termsPath } from 'pages/Terms';
import { path as contactsPath } from 'pages/Contacts';
import { path as privacyPath } from 'pages/Privacy';
import { path as scanningPrivacyPath } from 'pages/ScanningPrivacy';
import { path as cookiesPath } from 'pages/Cookies';
import { path as registerUserPath } from 'pages/Registration';
import { path as faqPath } from 'pages/Help';
import { path as helpPath } from 'pages/Help';
import isItIos from "./isItIOS";
import isItAndroid from "./isIdAndroid";
import { useEffect } from "react";

const exclude = [
    termsPath,
    contactsPath,
    privacyPath,
    scanningPrivacyPath,
    cookiesPath,
    registerUserPath,
    faqPath,
    helpPath
];

const excludeRootPages = [
    'promo'
];

export const appStoreLink = (protocol='https') => `${protocol}://apps.apple.com/se/app/zippertic/id1544944884`;
export const googlePlayLink = (protocol='https') => `${protocol}://play.google.com/store/apps/details?id=com.codiqo.zippertic`;

export default function useGotoAppInstall() {
    const { pathname } = useLocation();

    useEffect(() => {
    
        if (exclude.includes(pathname)) {
            return;
        }

        if (excludeRootPages.includes(pathname?.split('/')?.[1])) {
            return;
        }

        if (!isItIos && !isItAndroid) {
            return;
        }

        const link = isItIos && appStoreLink() || isItAndroid && googlePlayLink();

        const timer = setTimeout(()=> {
            window.location.href = link;
        }, 3000);

        return () => clearTimeout(timer);
    }, []);
}