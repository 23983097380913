import * as actions from './constants';

export const defaultState = {
    payment: null,
    loading: false,
    error: null
}

export function reducer(state=defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {


        case actions.GET_PAYMENT_START: {
            return {
                ...defaultState,
                loading: true
            }
        }

        case actions.GET_PAYMENT_SUCCESS: {
            const payment = payload;
            return {
                ...defaultState,
                payment
            }
        }

        case actions.GET_PAYMENT_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }
    }
}

export default reducer;
