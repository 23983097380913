import React, { useState } from 'react';
import { ClearIcon, Seat, Info } from 'components/Icons/Glyphs';
import ToolTip from './ToolTip';

export default ({ row, seat, remove, ...props}) => {
    const [showToolTip, setShowToolTip] = useState(false);
    return (<div className='zt-event-buy__aside__group-seats__seat' {...props} onMouseOut={() => setShowToolTip(false)}>
        <span className='zt-nav light'>Row {row} Seat {seat}</span>
        { remove ?
        <ClearIcon className='zt-event-buy__aside__group-seats__seat__removeicon' onClick={() => remove()}/>
        :
        <>
            <Info className='zt-event-buy__aside__group-seats__seat__infoicon' onMouseOver={e => setShowToolTip(true)} onClick={() => setShowToolTip(true)}/>
            <ToolTip show={showToolTip}/>
        </>
        }
        <Seat className='zt-event-buy__aside__group-seats__seat__seaticon' />
    </div>);
}