import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import * as actions from "./actions";
import Loading from 'components/Loading';
import bsBreakpoints from 'bs-breakpoints';

import Title from 'components/Feed/Title';
import Footer from 'components/Feed/Footer';
import EventCard from 'components/Feed/EventCard';

import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { SecondaryButton } from 'components/buttons/Button';

const fetchConfig = {
    medium: 9,
    large: 12,
    xLarge: 12
};

const Error = ({ retry = () => null, props }) => <div className='zt-events-list__error'>Something going wrong...<br/><SecondaryButton onClick={retry}>Retry</SecondaryButton></div>

export default () => {
    const [itemsLeft, setItemsLeft] = useState(0);
    const { items, total, loading, error } = useSelector(state => (
        {
            items: state.events.items,
            total: state.events.total,
            loading: state.events.loading,
            error: state.events.error
        }));
    const dispatch = useDispatch();
    const [t] = useTranslation('home');

    useEffect(() => {
        dispatch(actions.resetAvailbleEvents());
        const breakpoint = bsBreakpoints.detectBreakpoint();
        if (fetchConfig.hasOwnProperty(breakpoint)) {
            dispatch(actions.setPageSize(fetchConfig[breakpoint]));
        }
        dispatch(actions.fetchAvailableEvents());
    }, [dispatch]);

    useEffect(() => {
        if (items) {
            setItemsLeft(total - items.length)
        }
    }, [total, items]);

    const loadMore = () => dispatch(actions.fetchAvailableEvents());

    return <Container>
        <Title title={t('eventslist.label','All Available Events')} />
        { !!items &&
        <Row className="justify-content-md-center">
            <Col md="12" lg="12" xl="9">
                <Row className="eventsfeed" lg={4} md={3} sm={2}>
                    { items.map(item => <Col key={item.id}>
                            <CSSTransition in={true} appear={true} timeout={200} classNames="node">
                                <EventCard item={item} />
                            </CSSTransition>
                        </Col>) }
                </Row>   
            </Col>   
        </Row>
        }  
        { loading === false ?
            (
            error ?
            <Error retry={() => dispatch(actions.fetchAvailableEvents())}/>
            :
            (+itemsLeft > 0 && <Footer left={itemsLeft} onMore={loadMore} /> )
            )
        :
            <Loading />
        }
    </Container>

}
