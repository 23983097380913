import React, { useEffect } from 'react';
import PhoneInput from 'components/Inputs/PhoneInput';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useForm } from 'react-hook-form';
import useError from './useError';
import isCorrectPhone from './isCorrectPhone';
import { useTranslation } from 'react-i18next';

export default ({ tt, friend, onEnter, last, onClear=null, entername, description, subDescription, usedPhones=null, ...props }) => {

    const { t } = useTranslation('common');
    const phoneError = t('errors.validation.phone', 'Phone number should be in the right format');

    const { register, setValue, clearErrors, setError, formState: { errors }, watch, handleSubmit } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });

    const { user } = friend;
    const [phone] = watch(['phone']);

    useEffect(() => {
        const isUniq = phone => {
            if (!usedPhones) {
                return true;
            }
            // Remove optional '+' sign and check if uniq
            // console.log(`Searching ${phone} in ${usedPhones}: ${!usedPhones.includes(phone.replace(/^[+]/g, ''))}`);
            return !usedPhones.includes(phone.replace(/^[+]/g, ''));
        }

        register('phone', {
            minLength: 5,
            validate: {
                isUniq
            }
        });
    }, [usedPhones]);

    // Debounce callback 
    const [debouncedCallback, cancelCallback] = useDebouncedCallback(
        (value) => {

            if (!value) {
                return;
            }
            handleSubmit(({ phone }) => {
                if(!isCorrectPhone(phone)){
                    setError('phone', {type: 'manual', message: phoneError}, {shouldFocus: true});
                    return;
                }
                onEnter(phone);
            })();
        },
        // delay in ms
        2000
    ); 

    useEffect(() => {
        return () => cancelCallback();
    }, [cancelCallback]);

    const friendSearchError = useError(friend);

    useEffect(() => {
        if (friendSearchError) {
            setError('phone', {type: 'manual', message: friendSearchError}, {shouldFocus: true});
        }
    }, [friendSearchError, setError]);    


    //(loading && actions.cancelSearchFriend()); 

    useEffect(() => {
        if (phone?.length >= 5) { 
            debouncedCallback(phone);
        }
    }, [phone]);

    return (
        <PhoneInput
            name='phone'
            label={tt('inputs.mobile_number', 'Mobile number:')}
            defaultValue={user?.phone || ''}
            autoFocus={last}
            onChange={value => setValue('phone', value)}
            {...{onClear, description, description2: !errors && subDescription}}
            error={errors?.phone && (errors.phone.message || (errors.phone.type === 'isUniq' && tt('inputs.phone.errors.not_uniq', 'This phone number is already in use, please select another')) ||  tt('inputs.phone.errors.wrong', 'Phone should be in a right format'))}
        />
    )
}