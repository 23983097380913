
import defaultLang from 'config/defaultLanguage';

const supportedLangCodes = {
    sv: 'se', 
    se: 'se', 
    en: 'en'
};

export default {
    name: 'zippertic',
  
    lookup: options => {
        const [language] = navigator?.languages || [null];

        if (!language) {
            return defaultLang;
        }
        
        const code = language.split('-')[0];
        if (Object.keys(supportedLangCodes).includes(code)) {
            return supportedLangCodes[code];
        }

        return defaultLang;
    }
};