import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { ClearIcon, SearchIcon } from 'components/Icons/Glyphs';
import * as actions from 'containers/FilteredEvents/actions';
import { useTranslation } from 'react-i18next';
import Hello from '../components/Hello';

export default ({ onClose }) => {
    const filter = useSelector(state => state.filteredevents.filter.search);
    const [search, setSearch] = useState(filter || '');
    const [t] = useTranslation('filter');
    const ref = useRef();

    const dispatch = useDispatch();

    const applySearch = useCallback(() => {
        if (onClose) {
            onClose();
        }
        dispatch(actions.applySearch(search));
    }, [onClose, search, dispatch]);

    useEffect( () => {
        ref.current.focus();
    }, [ref]);

    return (
        <Row className="zt-search-block">
            <Col md='3'/>
            <Col md='6'>
                <Hello />
                <div className='zt-search-block__search'>
                    <input ref={ref} className="form-control" type="text" placeholder={t('search.placeholder', 'Artists, venues or shows')} value={search} onChange={e => setSearch(e.target.value)} onKeyDown={e => e.key === 'Enter' && applySearch()}/>
                    <ClearIcon className='zt-search-block__search__clearicon' onClick={() => setSearch('')} />
                    <SearchIcon className='zt-search-block__search__searchicon' />
                </div>
            </Col>
            <Col md='3'/>
        </Row>);
}