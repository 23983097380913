import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Menu from 'components/Menu';
import Dropdown from 'components/buttons/Dropdown';
import { StarIcon, CalendarIcon } from 'components/Icons/Glyphs';
import { PrimaryFixedButton, SecondaryFixedButton } from 'components/buttons/Button';
import Calendar from 'components/Calendar';
import * as filteredEventsActions from 'containers/FilteredEvents/actions';
import * as DATEMODES from 'containers/FilteredEvents/dateModes';
import * as actions from "./actions";
import './style.scss';
import CitySelect from './City';
import { useTranslation } from 'react-i18next';

const DateMenu = ({ dateModes, onSelect, onClose, t }) => {

    const [step, setStep] = useState(0);
    const [mode, setMode] = useState(null);

    const items = dateModes.map( ({ id, label }) => ({id, label: t(`date.modes.${label}`, label)}));

    const onMenuSelect = mode => {
        if (mode.id === DATEMODES.MODE_PERIOD) {
            setMode(mode);
            setStep(s => s+1);
        } else {
            onSelect(mode);
            onClose();
        }
    }

    const setRange = range => {
        if (!mode) {
            return;
        } else {
            mode.range = range;
            onSelect(mode);
            onClose();
        }
    }

    return {
        0:<Menu items={items} onSelect={onMenuSelect} onClose={()=>null}/> ,
        1:<Calendar 
            onChange={setRange} 
            />
    }[step];
}

const periodLabel = ([ dateStart, dateEnd ], t) => t('date.range', {dateStart, dateEnd});

export default ({ onClose }) => {
    const { t } = useTranslation('filter')

    const { categories: catUnmapped, dateModes } = useSelector(state => state.filter);
    const categories = catUnmapped?.map(({ id, label, icon }) => ({id, label: t(`categories.modes.${label}`), icon}));

    const selected = useSelector(state => (
        {
            category: state.filter.category,
            city: state.filter.city,
            date: state.filter.date
        }));

    const dispatch = useDispatch();

    const applyFilter = useCallback(() => {
        dispatch(filteredEventsActions.applyFilters());
        if (onClose) {
            onClose();
        }
    }, [dispatch, onClose]);

    const emptyFilters = useSelector(state => Object.values(        {
        category: state.filter.category,
        city: state.filter.city,
        date: state.filter.date
    }).every(filter => (filter === null || filter === '' )));
    const applyDisabled = emptyFilters;
    const resetDisabled = emptyFilters;

    const ApplyButton = () => <PrimaryFixedButton disabled={applyDisabled} onClick={() => applyFilter()}>{t('buttons.apply','Show Result')}</PrimaryFixedButton>
    const ResetButton = () => <SecondaryFixedButton disabled={resetDisabled} onClick={() => dispatch(actions.resetFilter())}>{t('buttons.reset','Reset')}</SecondaryFixedButton>

    const selectedDateLabel = selected.date && (selected.date.id === DATEMODES.MODE_PERIOD? periodLabel(selected.date.range, t) : selected.date.label);
    
    return (
    <Row sm={1}>
        <Col className='d-block d-sm-flex justify-content-center filter'>
            <Dropdown label={t('categories.label','Categories')} icon={StarIcon} selected={(selected.category || {}).label} onUnSelect={() => dispatch(actions.setCategory(null))}>
                <Menu items={categories} onSelect={category => dispatch(actions.setCategory(category))} />
            </Dropdown>

            <CitySelect selected={(selected.city || {}).label} onUnSelect={() => dispatch(actions.setCity(null))} />

            <Dropdown label={t('date.label', 'Date')} icon={CalendarIcon} selected={selectedDateLabel} onUnSelect={() => dispatch(actions.setDate(null))}>
                <DateMenu t={t} dateModes={dateModes} onSelect={date => dispatch(actions.setDate(date))} />
            </Dropdown>

            <div className="zt-filter__controls d-none d-lg-block">
                <ApplyButton />
                <ResetButton />        
            </div> 
        </Col>
        <Col className='zt-filter__controls zt-filter__controls--small d-block d-lg-none d-flex justify-content-center align-items-start filter'>
            <ApplyButton />
            <ResetButton />
        </Col>
    </Row>);
}