import React from 'react';
import { UserIcon, QR as QRIcon, Price as PriceIcon } from 'components/Icons/Glyphs';
import InfoElement from 'pages/Event/pages/BuyTickets/AddFriends/InfoElement';
import PriceSelector from './components/PriceSelector';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap'

export default ({ setPriceType, num, ticket, ...props}) => {

    const { t } = useTranslation('buying');
    const tt = (code, def) => t(`add_friends.${code}`, def);

    const { alternative_prices, price, ticket_type: type } = ticket;

    console.log(ticket);

    const prices = alternative_prices?.map((price, i) => ({id: i, label: price.original_price, type: price.ticket_type.name, kids: price.ticket_type.kids}));

    const no_choice = !!ticket.type && !(prices?.length > 1);

    return (
        <Row>
            <Col md={5}>
                <span className='zt-event-buy__add-friends__myticket__number'>{+num}.</span>
                <InfoElement bold icon={UserIcon} className='zt-event-buy__add-friends__myticket__label'>{tt('labels.my_ticket', 'My ticket')}</InfoElement>
            </Col>
            <Col md={2}>
                <InfoElement dark icon={QRIcon}>{t(`pricetypes.${type}`, type)}</InfoElement>
            </Col>
            <Col md={2}>
                { no_choice ?
                <InfoElement icon={PriceIcon}>{t('common:price', {price: Number(price)})}</InfoElement>
                :
                <PriceSelector {...{ticket, t, items: prices}} onChange={p => setPriceType(p.type)} />
                }
            </Col>
        </Row>        
    )
}