import * as actions from './constants';

const defaultState = {
    email: null,
    phone: null,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;
    switch (type) {
        case actions.REGISTER_USER_RESET:
            return {
                ...defaultState
            }
        case actions.REGISTER_USER_SET:
            const { email, phone } = payload;
            return {
                ...defaultState,
                email, 
                phone
            }
        case actions.REGISTER_USER_ERROR: 
            return {
                ...state,
                error
            }
        default:
            return state;
    }
}