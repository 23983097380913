const STATUS_CONFIRMED = "confirmed";

export default function useTotal(info) {
    if (!info) {
        return null;
    }

    const { active_payment, confirmation_status } = info;

    if (confirmation_status === STATUS_CONFIRMED) {
        return {
            serviceFee: 0, 
            adminFee: 0, 
            ticketsFee: 0, 
            totalPrice: 0,
            discount: 0
        }        
    }      

    const { summary: { ticket, ticket_admin_fee, ticket_service_fee, total } = {ticket: {}, ticket_admin_fee: {}, ticket_service_fee: {}, total: {}} } = active_payment || {};

    return {
        serviceFee: ticket_service_fee.original_price, 
        adminFee: ticket_admin_fee.original_price, 
        ticketsFee: ticket.original_price, 
        totalPrice: total?.net_price,
        discount: total?.original_price - total?.net_price
    }
}