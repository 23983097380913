import React from 'react';

export const RequestSent = () => <svg width="183" height="128" viewBox="0 0 183 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
        <path d="M107.02 85.9926L181 42.1357V125.579H31V42.1357L104.98 85.9926L106 86.5973L107.02 85.9926Z" stroke="white" strokeWidth="4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M179 41.0931L106 4.47503L33 41.0931V123.578H179V41.0931ZM106 0L183 38.6246V127.578H29V91V38.6246L106 0Z" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="67" y="2" width="100" height="83">
        <path d="M68.0125 61.1603L68.9444 3.44876L166.13 23.8192L159.64 51.8675L106.012 84.005L68.0125 61.1603Z" fill="#C4C4C4" stroke="white"/>
        </mask>
        <g mask="url(#mask0)">
            <path d="M81.4593 13.2618C81.937 11.1049 84.077 9.72383 86.2391 10.177L155.225 24.6367C157.387 25.0899 158.753 27.2058 158.275 29.3627L138.586 118.26C138.109 120.417 135.969 121.798 133.807 121.345L64.8209 106.885C62.6588 106.432 61.2932 104.316 61.7709 102.159L81.4593 13.2618Z" fill="black"/>
            <path d="M80.1495 15.5706C80.866 12.3353 84.076 10.2637 87.3193 10.9435L152.994 24.7091C156.237 25.3889 158.285 28.5627 157.569 31.798L151.804 57.8244L138.637 117.28C137.92 120.516 134.71 122.587 131.467 121.907L65.7924 108.142C62.5492 107.462 60.5009 104.288 61.2174 101.053L74.3853 41.597L80.1495 15.5706Z" stroke="white" strokeWidth="4"/>
            <path d="M111.603 63.4712L106.747 85.3963L85.7184 80.9886" stroke="white" strokeWidth="3"/>
            <rect x="1.14375" y="1.77223" width="13.2344" height="14.965" transform="matrix(0.978731 0.205146 -0.216234 0.976342 92.2005 57.7204)" stroke="white" strokeWidth="3"/>
            <path d="M112.819 86.6701L113.898 81.7979L124.647 84.0508" stroke="white" strokeWidth="3"/>
            <rect x="1.14375" y="1.77223" width="10.3695" height="10.9728" transform="matrix(0.978731 0.205146 -0.216234 0.976342 116.212 64.1603)" stroke="white" strokeWidth="3"/>
            <path d="M88.3477 21.062L147.784 33.5201" stroke="white" strokeWidth="3"/>
            <path d="M86.3516 30.0264L114.506 35.9276" stroke="white" strokeWidth="3"/>
            <path d="M108.254 34.6162L123.895 37.8947" stroke="white" strokeWidth="3"/>
            <path d="M130.148 39.2065L145.79 42.485" stroke="white" strokeWidth="3"/>
        </g>
    </g>
    <rect x="29" y="94" width="4" height="4" fill="black"/>
    <rect x="29" y="100" width="4" height="4" fill="black"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 91H37.841V94.2205H0V91ZM11.2734 97.4414H37.8427V100.662H11.2734V97.4414ZM37.8413 103.883H20.9336V107.103H37.8413V103.883Z" fill="#999999"/>
</svg>

export default RequestSent;