import React from 'react';
import { link as tickets_url } from 'pages/MyTickets/pages/List';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from './components/Button';
import Ticket from './components/Ticket';

export default ({ tickets, onCheckout, setPriceType, disabled, total, id, ...props }) => {
    const { t } = useTranslation('buying'); 
    
    const { totalPrice } = total || {};

    const modeAccept = totalPrice === 0;
    const history = useHistory();
    const goToTickets = () => history.push(tickets_url);

    return (
        <>
            <div className='zt-checkout__select-type'>
                {tickets?.map((ticket, key) => <Ticket {...{ticket, key, num: key+1, setPriceType: type => setPriceType(ticket.id, type)}} />)}
            </div>
            <div className='zt-checkout__controls'>
                <Button {...{disabled, t, onCheckout, totalPrice, modeAccept, onSuccess: () => goToTickets(), id}} />
            </div>        
        </>
    )
}