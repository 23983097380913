import * as constants from './constants';
import api from './api';

export const reset = () => ({type: constants.UPDATTE_PROFILE_ITEM_RESET});

const flow = {
    start: () => ({type: constants.UPDATTE_PROFILE_ITEM_START}),
    success: payload => ({type: constants.UPDATTE_PROFILE_ITEM_SUCCESS, payload}),
    failure: error => ({type: constants.UPDATTE_PROFILE_ITEM_FAILURE, error})    
}

export const update = dispatch => data => {
    dispatch(flow.start());
    api.save(data)
        .then(response => dispatch(flow.success(response?.data)))
        .catch(error => dispatch(flow.failure(error)));
}
