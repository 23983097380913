import React, { useState, useCallback, useEffect } from 'react';
import { PrimaryButton } from 'components/buttons/Button';
import CheckBox from 'components/Inputs/CheckBox';
import useAccountDelete from './useAccountDelete';
import { useHistory } from 'react-router-dom';

const home = '/';

export default ({ t, ...props}) => {
    const history = useHistory();
    const [confirmed, setConfirmed] = useState(false);

    const { state: { loading, forgotten, error }, proceedDelete } = useAccountDelete();

    const deleteAccount = () => {
        proceedDelete();
    }

    useEffect(() => {
        if (forgotten) {
            history.push(home);
        }
    }, [forgotten, history]);

    return (
     <div className='zt-profile__forget-me'>
         <h4 className='zt-profile__label'>{t('delete_account.label', 'Delete account')}</h4>
         <CheckBox value={confirmed} onChange={e => setConfirmed(e.target.checked)}>{t('delete_account.description', 'Please delete my account. I understand that data cannot be restored and my tickets and purchases will be deleted immediately.')}</CheckBox>
         <PrimaryButton loading={loading} onClick={deleteAccount} disabled={!confirmed} error={error ? t('delete_account.error', 'Account cannot be deleted. Please try again later'):null}>{t('delete_account.button', 'Delete my account')}</PrimaryButton>
     </div>
    );
}