import * as actions from './constants';
import moment from 'moment';
import FORMAT from 'config/date/format';

const defaultState = {
    events: null,
    count: null, 
    loading: false,
    error: null
}

export default function(state=defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch (type) {

        case actions.FETCH_TICEKTS_RESET: {
            return {
                ...defaultState
            }
        }

        case actions.FETCH_TICKETS_START: {
            return {
                ...defaultState,
                events: state.events,
                loading: true
            }            
        }

        case actions.FETCH_TICEKTS_SUCCESS: {
            const events = payload?.collection;
            const count = payload?.count;

            //Sort by date
            if (events && events.length) {
                events.sort((a, b) => moment(a.start_date, FORMAT).diff(moment(b.start_date, FORMAT)));
            }

            return {
                ...defaultState,
                events,
                count
            }
        }

        case actions.FETCH_TICEKTS_FAILURE: {
            return {
                ...state,
                loading: false, 
                error
            }
        }

        default: {
            return state;
        }
    }
}
