import { useSelector } from "react-redux";
import * as payTypes from './../../../AddFriends/payTypes';
import useTickets from "../../../AddFriends/useTickets";

export default function useServiceFee() {
    
    const { prices } = useSelector(state => state.event.friends);
    const tickets = useTickets();

    let fee = 0;

    tickets.forEach(t => {
        if ([t.price, t.type].includes(null)) {
            return;
        }
        if (t.pay === payTypes.PAYTYPE_IPAY) {
            fee += prices[t.price]?.[t.type]?.service_fee;
        }
    });
  
    return fee;
}
