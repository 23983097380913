import api from 'api';

const urls = {
    all: '/api/events/:eventId/tickets/sell/info',
    one: '/api/tickets/:ticketId/sell/info'
}

export default {
    all: eventId => api.get({path:urls.all.replace(':eventId', eventId)}),
    one: ticketId => api.get({path:urls.one.replace(':ticketId', ticketId)})
}
