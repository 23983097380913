import * as actions from './constants';

const defaultState = {
    selected: null, 
    events: [],
    fees: null,
    loading: false,
    error: null
}

export default function reducer(state = defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.WEBSHOP_SELECT_EVENT: {

            if (payload !== null && !state.events.includes(payload)) {
                throw('No such event in the state');
            }

            return {
                ...state,
                selected: {...payload}
            }
        }

        case actions.WEBSHOP_FETCH_EVENTS_START: {
            return {
                ...defaultState,
                events: state.events,
                loading: true
            }
        }

        case actions.WEBSHOP_FETCH_EVENTS_SUCCESS: {
            const { collection: events, fees } = payload;
            return {
                ...defaultState,
                events,
                fees
            }
        }

        case actions.WEBSHOP_FETCH_EVENTS_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default: {
            return state;
        }

    }
}
