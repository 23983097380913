import React, { useEffect, useState } from 'react';
import useProfileItemUpdate from 'pages/Profile/useProfileItemUpdate';
import { PrimaryButton } from 'components/buttons/Button';
import Button from 'components/buttons/Button';
import Input from 'components/Inputs/Input';

const isEmail = email => /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(`${email}`);

export default ({ t, profile, refresh, ...props}) => {

    const { state: {loading, profile: updated, saved, error}, proceedUpdate, reset } = useProfileItemUpdate();
    const [email, setEmail] = useState(profile?.email);
    
    const saveEmail = () => {
        proceedUpdate({email});
    };

    const inputerror = !isEmail(email);
    const enabled = email !== profile?.email && !inputerror;

    useEffect(() => {
        if (saved) {
            reset();
            refresh(updated);
        }
    }, [saved, updated, refresh, profile, reset]);

    const unconfirmed = !loading && !profile?.email_verified_at;

    return (
        <form>
            <Input 
                label={t('contacts.email.input.label', 'Email (will used as login):')} 
                placeholder={t('contacts.email.input.placeholder', 'email@email.com')} 
                description={unconfirmed ? t('contacts.email.confirmed.warning', 'EMAIL IS UNCONFIRMED! Confirmation link has been sent to your email.') : null}
                error={inputerror && t('contacts.email.error.input', 'Please, input a correct email address')}
                defaultValue={profile?.email}
                onChange={e => setEmail(e.target.value)}
            />
            {unconfirmed && <Button>{t('contacts.email.confirmed.button', 'Send link again')}</Button>}
            <PrimaryButton {...{loading, disabled: !enabled, error: error && t('contacts.email.error.save','Error saving the email')}} onClick={() => saveEmail()}>{t('contacts.email.button', 'Change email')}</PrimaryButton>
        </form>);
}