import React from 'react';
import { LightButton } from 'components/buttons/Button';
import { ArrowRight } from 'components/Icons/Glyphs';
import { FormBlock, Form } from 'components/forms';
import Label from 'components/Inputs/Label';

export default ({ t, history, login, ...props }) => {

    return (
        <div className='zt-bankid__success'>
            <Form>
                <FormBlock>
                    <Label>Nu är du registrerad och kan köpa biljetter på zippertic.se.</Label>
                    <Label>Om du inte har en smartphone gäller ditt körkort, nationellt ID eller pass som biljett.</Label>
                    <Label>Om du har en smartphone så rekommenderar vi att du installerar och loggar in på appen ZipperTic där du kan aktivera och hämta dina biljetter. Det finns fler funktioner för att hantera dina biljetter i appen</Label><br/>
                    <LightButton className='zt-form__block__submitbutton font-weight-bold' onClick={() => history.push('/')}>Fortsätt <ArrowRight /></LightButton>
                </FormBlock>
                <FormBlock>
                </FormBlock>
            </Form>
        </div>
    )
}