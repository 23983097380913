import React from 'react';
import { UserIcon, SettingsIcon, SignOut, WebShop, Cart, Bell, QR } from 'components/Icons/Glyphs';
import useCartItemsCount from 'pages/WebShop/pages/Cart/store/useCartItemsCount';
import { link as notificationsLink } from 'pages/Notifications';
import { link as webShopLink, cartLink } from 'pages/WebShop';
import { NavigationButton } from 'components/buttons/Button';
import useIsAuthorised from 'hooks/auth/useIsAuthorised';
import { logout } from 'containers/Login/state/actions';
import { link as ticketsLink } from 'pages/MyTickets';
import { link as profileLink } from 'pages/Profile';
import MenuButton from 'components/buttons/Menu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Login from 'containers/Login';
import Menu from 'components/Menu';

export default () => {
    const authorised = useIsAuthorised();
    const history = useHistory();

    const dispatch = useDispatch();
    
    const { t }= useTranslation('menu');

    const gotoCartPage = () => history.push(cartLink);

    const actions = action => {
        switch(action.id) {
            case 'webshop':
                history.push(webShopLink);                
                break;
            case 'tickets':
                history.push(ticketsLink);                
                break;                
            case 'cart':
                gotoCartPage();
                break;
            case 'profile':
                history.push(profileLink);
                break;
            case 'notifications':
                history.push(notificationsLink);
                break;
            case 'signout':
                dispatch(logout()); 
                break;
            default:
                break;
        }
    }

    const cartCnt = useCartItemsCount();
    
    const menu = [
        {id: 'webshop', label: t('webshop', 'Webshop'), rightIcon: WebShop },
        {id: 'cart', label: t('cart', 'Cart'), rightIcon: Cart, disabled: !cartCnt},
        {id: 'tickets', label: t('tickets', 'My Tickets'), rightIcon: QR},
        {},
        {id: 'notifications', label: t('notifications', 'Notifications'), rightIcon: Bell},
        {id: 'profile', label: t('profile', 'Profile'), rightIcon: UserIcon},
        {},
        {id: 'signout', label: t('signout', 'Sign out'), rightIcon: SignOut }
    ];

    if (authorised) {
        return (
            <>
                <MenuButton icon={UserIcon} label={<SettingsIcon />}>
                    <Menu items={menu} onSelect={action => actions(action)} ></Menu> 
                </MenuButton>
                { !!cartCnt && <NavigationButton icon={Cart} className='zt-button--navigation--red' onClick={() => gotoCartPage()}>{cartCnt}</NavigationButton>}
            </>
        )
    }
    return <MenuButton icon={UserIcon} label={t('login', 'Login')}>
        <Login />
    </MenuButton>;
}