import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Row, Col, Container } from 'react-bootstrap';
import Error from 'pages/Event/components/Error';

export default ({ onRetry, ...props }) => {
    return ( 
    <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-webshop'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="9">
                        <Error retry={() => onRetry && onRetry()} />
                    </Col>
                </Row>
            </Container>
        </div>
    </CSSTransition>         
    );
}