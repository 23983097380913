import { combineReducers } from 'redux';

import details from './pages/Details/reducer';
import tickets from './pages/BuyTickets/store/reducer';
import friends from './pages/BuyTickets/AddFriends/reducer';
import sections from './pages/BuyTickets/PickSection/reducer';
import seats from './pages/BuyTickets/PickSeats/reducer';

const maps = combineReducers({
    sections,
    seats
})

export default combineReducers({
    details,
    tickets,
    friends,
    maps
});