import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import knownCodes from 'utils/useErrorByCode/knownCodes';
import useErrorCodeFromResponse from 'utils/useErrorCodeFromResponse';

const findAlias = code => Object.keys(knownCodes).find(key => knownCodes[key] === code);

const errorCode = (code, t) => {
    if (!code && code !== 0) {
        return null;
    }

    const alias =  findAlias(code) || findAlias(knownCodes.UNKNOWN_ERROR)

    return t(`${alias}`, { defaultValue: `Operation error, please try again later ({{code}}).`, code })
}

export default function useError(friend) {

    const { t } = useTranslation('friends_errors');

    const error = useSelector(state => state.event.friends.friends?.[friend?.id]?.error);

    const code = useErrorCodeFromResponse(error?.response);
    // if (error && +error.response?.status === 403) {
    //     return errorCode(error.response?.data?.error?.code, t);
    // }

    if (!error) {
        return null;
    }

    return errorCode(code, t);

}