//post('tickets/{ticket_id}/sell-to-friend
//post('tickets/{ticket_id}/sell-on-market
//events/{event_id}/tickets/sell-to-friend <- sell all to friend
//events/{event_id}/tickets/sell-on-market <- sell all to a market

import api from 'api';

const urls = {
    all: {
        toFriend: '/api/events/:eventId/tickets/sell-to-friend',
        toMarket: '/api/events/:eventId/tickets/sell-on-market'
    },
    one: {
        toFriend: '/api/tickets/:ticketId/sell-to-friend',
        toMarket: '/api/tickets/:ticketId/sell-on-market'
    }
}

export default {
    all: {
        toFriend: (eventId, params) => api.post({path:urls.all.toFriend.replace(':eventId', eventId), params}),
        toMarket: (eventId, params) => api.get({path:urls.all.toMarket.replace(':eventId', eventId), params})
    },
    one: {
        toFriend: (ticketId, params) => api.post({path:urls.one.toFriend.replace(':ticketId', ticketId), params}),
        toMarket: (ticketId, params) => api.post({path:urls.one.toMarket.replace(':ticketId', ticketId), params})
    }
};