import React, { useState } from 'react';
import Samples, { MODE_SWEDEN } from '../DocumentId/components/Samples';
import { SecondaryButton } from 'components/buttons/Button';
import { Info } from 'components/Icons/Glyphs';
import Input from 'components/Inputs/Input';
import Personnummer from 'personnummer';
import { Modal } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

let lastValue = '';

function adoptPersonalNumber(value) {
    
    const currentYear = +(new Date()).getFullYear().toString().substr(-2);
    const deleting = +value?.length < +lastValue?.length;

    lastValue = value;

    if (value?.length !== 2 || [19,20].includes(+value) || deleting) {
        return value
    }
    const year = +value || 0;
    return currentYear-year < 10 ? `19${value}` : `20${value}`;
    // return +value < 13 ? `20${value}` : `19${value}`;
}

export default ({ t, register, errors, resetField, control, ...props }) => {
    const [samples, showSamples] = useState(false);

    return (
        <>
            <div className='zt-registration__document__form__block'>
                <div className='zt-registration__document__form__block__label'>
                    <h4>{t('using_document.personal_id.label', 'Enter your Personal ID')}</h4>
                </div>
                <div className='zt-registration__document__form__block__inputs'>
                <SecondaryButton icon={Info} onClick={() => showSamples(true)}>{t('using_document.document.buttons.samples', 'Samples of valid Documents')}</SecondaryButton>
                <Controller 
                    control={control}
                    name="document"
                    rules={{required: 'Required', validate: value => (Personnummer.valid(value) && value.length === 12) || t('using_document.personal_id.input.error', 'Entered number is not correct')}}
                    render={({ field: { value }, field, ...props }) => {
                        return <Input 
                            {...field}
                            {...{value: value || ''}}
                            label={t('using_document.personal_id.input.label', 'Personal ID number:')}
                            placeholder={t('using_document.personal_id.input.placeholder', 'Enter a valid Personal ID number')}
                            onClear={() => resetField('document')}
                            error={errors?.document?.message}  
                            onChange={(e) => field.onChange(adoptPersonalNumber(e.target.value))}  
                        />}}         
                />
                </div>
            </div>
            <Modal show={samples} size="xl" centered onHide={() => showSamples(false)} className='zt-modal-centered'>
                <Samples t={t} mode={MODE_SWEDEN} onClose={() => showSamples(false)} />
            </Modal>
        </>
    );
}