import * as constants from './constants';
import api from './api';

const flow = {
    start: () => ({type: constants.GET_PROFILE_START}),
    success: payload => ({type: constants.GET_PROFILE_SUCCESS, payload}),
    failure: error => ({type: constants.GET_PROFILE_FAILURE, error})
}

export const update = data => flow.success(data);

export function getProfile() {
    return (dispatch, getState) => {
        dispatch(flow.start());
        api.get()
            .then(response => dispatch(flow.success(response.data || null)))
            .catch(error => dispatch(flow.failure(error)));         
    }
}