import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useLocation } from 'react-router-dom';
import './style.scss'

const configuration = {
    ['promo']: {
        header: false,
        footer: false
    }
};


const defconfig = {
    header: true,
    footer: true
}

export default ({ children }) => {

    const location = useLocation();
    const slug = location?.pathname?.split('/')[1] || null;

    const { header, footer } = configuration[slug] || defconfig;

    return <>
        <header>
            {header && <Header />}
        </header>
        <article className='zt-article'>
            {children}
        </article>
        <footer>
            {footer && <Footer />}
        </footer>        
    </>
}