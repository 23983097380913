import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Feed from './components/Feed';
import './styles.scss';

export const path = '/notifications';
export const link = path;

const Notifications = ({ ...props }) => {
    const { t } = useTranslation('notifications');


    return <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-notifications'>
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="9">
                    <h4 className='zt-notifications__title'>{t('label', 'Notifications')}</h4>
                    <Feed />
                </Col>
            </Row>
        </Container>
    </div>
    </CSSTransition>
}

Notifications.path = path;
Notifications.link = link;

export default Notifications;