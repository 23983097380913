import React from 'react';
import { ArrowRight } from 'components/Icons/Glyphs';
import { PrimaryButton } from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default ({ valid, error, onPress, loading, ...props }) => {
    const {t} = useTranslation('tickets');

    return <PrimaryButton {...{error, loading}} disabled={!valid || loading} onClick={() => onPress && onPress()} className='zt-tickets__sell__checkoutbutton'>{t('sell.buttons.confirm', 'Confirm')} <ArrowRight /></PrimaryButton>
}