import React, { useEffect } from 'react';
import './style.scss';

const CheckMark = props => <svg width="12" height="10" viewBox="0 0 12 10" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6L4 9L10.5 1.5" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
</svg>


export default React.forwardRef(({ className, name, value, children, disabled = false, ...props }, ref) => {
    return (
        <label className={`zt-inputs--checkbox ${disabled ? 'zt-inputs--checkbox--disabled' : ''} ${className || ''}`} >
            <span className='zt-nav light'>{children}</span>
            <input disabled={disabled} type="checkbox" name={name} ref={ref} defaultChecked={value} checked={value} {...props}></input>
            <span className='zt-inputs--checkbox__checkmark'><CheckMark className='zt-inputs--checkbox__checkmark__icon'/></span>
        </label>
    );
});