import { useReducer } from 'react';
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';
import * as actions from './actions';

export default function useCreateReservation() {

    const [state, dispatch] = useReducer(logger(reducer), defaultState);

    const create = actions.createReservation(dispatch);
    const fetch = actions.fetchReservation(dispatch);
    const reset = actions.reset(dispatch);

    return {
        state,
        activePaymentId: state?.reservation?.active_payment?.id || null,
        created: !!state.reservation,
        create,
        fetch,
        reset
    }
}
