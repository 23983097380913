import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import useActivationToken from './useActivationToken';
import * as actions from './actions';
import './style.scss';

export const path = '/activation/:id?/:token?';

const Message = ({ title, message }) => <div className='zt-registration__activate'>
    <h4 className='zt-registration__activate__header'>{title}</h4>
    <div className='zt-p1'>{message}</div>
</div>;

export default props => {
    const [t] = useTranslation('registration');
    const { isValid:urlValid, id, token, expires, signature } = useActivationToken();

    const { loading, activated, error } = useSelector( state => ({
        loading: state.activation.loading,
        activated: state.activation.activated,
        error: state.activation.error
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (urlValid && !activated) {
            dispatch(actions.activateUser({id, token, expires, signature}));
        }
    },[dispatch, activated, token, urlValid, expires, id, signature]);    

    if (loading) {
        return <Loading />
    }        

    if (error) {
        return <Message
            title={t('activation.error.header','Activation Error')}
            message={t('activation.error.message', 'Please, check the link or try again later.')}
        />
    }    

    if (activated) {
        return <Message 
                title={t('activation.success.header', 'User has been activated.')} 
                message={t('activation.success.message', 'Please, login with your credentials and buy tickets.')}
            />
    }

    if (!urlValid) {
        return <Message 
            title={t('activation.link_error.header', 'Error in link')} 
            message={t('activation.link_error.message', 'Please, check your email for the correct link.')}
        />
    }

    return <Loading />
}
