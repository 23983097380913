
const DIR_RIGHT = 'DIR_RIGHT';
const DIR_LEFT = 'DIR_LEFT';

export default function useSelectSeatRules(matrix, tickets) {

    //Validation:
    const isSelected = (row, seat, tickets) => tickets.filter(t => t.row === row).find(t => t.seat === seat);
    const isVacant = (rowMap, seat) => !!rowMap.get(seat);
    
    const applyRules = (seatIndex, seats, isVacant) => {
        const next = (index, dir, steps = 1) => (+index + ({[DIR_RIGHT]: 1, [DIR_LEFT]: -1}[dir])*steps);

        const isOnEdge = seatIndex => +seatIndex === 0 || +seatIndex === seats.length-1;
        const isNearEdge = seatIndex => +seatIndex === 1 || +seatIndex === seats.length-2;
        const isNearSeatOccuped = (seatIndex, dir) => !isVacant(next(seatIndex, dir));
        const isNearSeatsFree = num => (seatIndex, dir) => Array.from(Array(num).keys()).map(i => i+1).every(steps => isVacant(next(seatIndex, dir, steps)));

        const rules = [
            isNearSeatOccuped, 
            isNearEdge, 
            isNearSeatsFree(2)
        ];

        return [DIR_LEFT, DIR_RIGHT].every(dir => rules.some(r => r(seatIndex, dir)));
    }
    
    return (row, seat, selected = tickets) => {
        
        const rowMap = matrix.get(row);
        const seats = Array.from(rowMap.keys());
        const seatIndex = seats.indexOf(seat);
        
        return isVacant(rowMap, seat) && applyRules(seatIndex, seats, /* isVacant function for rules: */ index => isVacant(rowMap, seats[index]) && !isSelected(row, seats[index], selected) );

    }
}