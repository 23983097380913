import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSection, resetSection } from './store/actions';
import * as seatingTypes from './store/seatingTypes';

export default function useEventType() {
   
    const { item } = useSelector(store => store.event.details)
    const { loading: detailsLoading, error: detailsError, eventId } = useSelector(store => store.event.tickets.details);
    const { sections, loading, map, error } = useSelector(store => store.event.maps.sections);
    const dispatch = useDispatch();

    useEffect(() => {

        if(!item || !eventId || item.id !== eventId) {
            return;
        }

        if (!sections || loading || detailsLoading) {
            return;
        }
        
        if (Object.keys(sections).length === 1) {
            dispatch(selectSection(Object.keys(sections)[0]));
        }
    }, [dispatch, sections, item, eventId]);

    if (+item?.id !== +eventId) { //Cached event
        return null;
    }

    if (detailsError || error) {
        return null;
    }

    if (detailsLoading || loading) {
        return null;
    }

    if (!sections) {
        return null;
    }

    if (Object.keys(sections).length === 1) {
        return seatingTypes.SEATING_TYPE_SEATS;
    }

    return seatingTypes.SEATING_TYPE_SECTION;
}