import React, { useState, useEffect, useCallback, useRef } from 'react';
import { NavigationButton } from 'components/buttons/Button';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export default ({ icon, label, className, ...props }) => {
    const [open, setOpen] = useState(false);

    const onClick = useCallback(() => {
        setOpen(open => !open);
    }, [setOpen]);

    const ref = useRef();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });    

    const history = useHistory();
    useEffect(() => {
        setOpen(false);
    }, [history.location.pathname]);
    

    return (<div className={`zt-menubutton ${className || ''}`} ref={ref}>
        <NavigationButton icon={icon} onClick={onClick} selected={open}>{label}</NavigationButton>
        {open && 
            <CSSTransition in={true} appear={true} timeout={200} classNames="zt-menu__transition">
                <div className='zt-menubutton__content'>
                    {React.cloneElement(props.children, {onClose: () => setOpen(false)})}
                </div>
            </CSSTransition>
        }
    </div>);
}