import React, { useEffect } from 'react';
import useProcessAcceptOrDecline from 'pages/Notifications/components/Feed/components/Notification/components/Actions/components/AcceptOrDecline/useProcessAcceptOrDecline';
import { PrimaryButton } from 'components/buttons/Button';
import { ArrowRight } from 'components/Icons/Glyphs';

export default ({ t, id, onSuccess, disabled, ...props}) => {

    const { doAccept, state: { processing, loading, done, error } } = useProcessAcceptOrDecline();


    useEffect(() => {
        if (loading) {
            return;
        }

        if (!done) {
            return;
        }

        onSuccess && onSuccess();
    }, [done]);


    return <PrimaryButton {...{disabled, loading: processing, error: error ? 'Error' : null}} className='zt-checkout__controls__checkout' onClick={() => doAccept(id)}>{t('buttons.accept', 'Accept')} <ArrowRight /></PrimaryButton>
}