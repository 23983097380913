import { useEffect, useReducer } from "react";
import reducer, { defaultState } from './reducer';
import useCurrentUserId from "utils/useCurrentUserId";
import { logout } from 'containers/Login/state/actions';
import * as actions from './actions';
import { useDispatch } from "react-redux";

export default function useAccountDelete() {

    const [ state, dispatch ] = useReducer(reducer, defaultState);
    const dispatchReducerAction = useDispatch();

    const { forgotten, loading, error } = state;

    const userId = useCurrentUserId();

    if (!userId) {
        throw 'Not authorised user, account can\'t be deleted';
    }

    const proceedDelete = actions.forgetMe(dispatch)(userId);

    useEffect(() => {
        if (forgotten) {
            dispatchReducerAction(logout());
        }
    }, [forgotten, dispatchReducerAction]);

    return {
        state,
        proceedDelete
    }

}