import React from 'react';
import './Text.scss';
import './App.scss';

import Sizer from './Sizer';

import { Switch, Route, Redirect } from 'react-router-dom';

import PasswordRecovery, { path as passwordRecoveryPath } from './pages/PasswordRecovery';
import ScanningPrivacy, { path as scanningPrivacyPath } from './pages/ScanningPrivacy';
import Notifications, { path as notificationsPath } from 'pages/Notifications';
import Registration, { path as registrationPath } from './pages/Registration';
import Contacts, { path as contactsPath } from './pages/Contacts';
import Tickets, { path as ticketsPath } from './pages/MyTickets';
import Profile, { path as profilePath } from './pages/Profile';
import Privacy, { path as privacyPath } from './pages/Privacy';
import Cookies, { path as cookiesPath } from './pages/Cookies';
import Terms, { path as termsPath } from './pages/Terms';
import Event, { path as eventPath } from './pages/Event';
import Help, { path as helpPath } from './pages/Help';
import Home from './pages/Home';
import Test from './pages/Test';

import WebShop, { path as webShopPath } from 'pages/WebShop';
import PrivateRoute from 'components/PrivateRoute';
import ScrollToTop from 'ScrollToTop';
import useGotoAppInstall from 'utils/useGotoAppInstall';
import Layout from 'components/Layout';
import PromoWidget from 'pages/PromoWidget';

function App () {

    useGotoAppInstall()

    return (
        <React.Fragment>
            <ScrollToTop />
            {/* <Sizer /> */}
            <Switch>
                <Layout>
                    <Switch>
                    <Route path='/events' exact>
                        {props => <Redirect {...props} to={{ pathname: '/', state: {from: props.location}}} />}
                    </Route> 
                    <Route path='/(filter)?' exact component={Home} />
                    <Route path={eventPath} component={Event} />
                    <Route path={passwordRecoveryPath} component={PasswordRecovery} />
                    <Route path={registrationPath} component={Registration} />
                    <Route path={helpPath} component={Help} />
                    <PrivateRoute redirect={'/'} path={webShopPath} component={WebShop} />
                    <PrivateRoute redirect={'/'} path={profilePath} component={Profile} />
                    <PrivateRoute redirect={'/'} path={ticketsPath} component={Tickets} />
                    <PrivateRoute redirect={'/'} path={notificationsPath} component={Notifications} />
                    <Route path={contactsPath} component={Contacts} />
                    <Route path={privacyPath} component={Privacy} />
                    <Route path={cookiesPath} component={Cookies} />
                    <Route path={termsPath} component={Terms} />
                    <Route path={scanningPrivacyPath} component={ScanningPrivacy} />
                    <Route path={'/promo/:pid'}>
                        {props => <PromoWidget {...props} />}
                    </Route>
                    {/* <Route path='/test' component={Test} /> */}
                    </Switch>
                </Layout>
            </Switch>
        </React.Fragment>
    );
}

export default App;
