import React from 'react';
import Divider from 'components/Divider';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FeeRow = ({ children, label, ...props }) => <Row>
    <Col xs md="6" className="zt-tickets__sell__total__info"><span className='zt-p3'>{label}</span></Col>
    <Col xs md="6" className="zt-tickets__sell__total__info"><span className='zt-p1'>{children}</span></Col>
</Row>;

const TotalRow = ({ children, label, ...props }) => <Row>
    <Col xs md="6" className="zt-tickets__sell__total__info"><span className='zt-p3'>{label}</span></Col>
    <Col xs md="6" className="zt-tickets__sell__total__info"><h4>{children}</h4></Col>
</Row>;

export default ({ total }) => {

    const { t } = useTranslation('tickets')

    const price = price => t('common:price', {price})

    if (!total) {
        return null;
    }

    const { total_price, total_refund_fee } = total;

    return <div className="zt-tickets__sell__total">
        <Row>
            <Col xs lg="12">
                <p className='zt-p1 zt-tickets__sell__form__description'>{t('sell.total.description', 'Service fee and other fees are not refundable.')}</p>
            </Col>
        </Row>        
        <Row>
            <Col sm md="6">
                <FeeRow label={t('sell.total.price', "Tickets price:")}>{price(total_price)}</FeeRow>
                <FeeRow label={t('sell.total.fee', "Refund fee:")}>{price(-(total_refund_fee))}</FeeRow>
                <Divider />
                <TotalRow label={t('sell.total.total', "Total refund:")}>{price(+total_price - total_refund_fee)}</TotalRow>
            </Col>
        </Row>
    </div>
}