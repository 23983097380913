import { useState } from "react"

//Currently supports only one subscriber!
export default function useCheckoutDispatcher() {
    const [subs, setSubs] = useState(null);

    return {
        subscribe: f => setSubs(() => f),
        unsubscribe: () => setSubs(null),
        proceed: f => subs? subs() : f()
    }
}