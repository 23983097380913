import * as constants from "./constants";
import api from "./api";

const flow = {
    start: () => ({type: constants.GET_UNPAIDRESERVATIONS_START}),
    success: payload => ({type: constants.GET_UNPAIDRESERVATIONS_SUCCESS, payload }),
    failure: error => ({type: constants.GET_UNPAIDRESERVATIONS_FAILED, error})
}

export const fetch = dispatch => id => {
    if (!+id) {
        return null;
    }

    dispatch(flow.start());
    api.fetch(id)
        .then(response => dispatch(flow.success(response?.data)))
        .catch(error => dispatch(flow.failure(error)));
}

export const reset = dispatch => () => dispatch({type: constants.RESET_UNPAIDRESERVATIONS});
