import * as constants from './constants';

export function setCategory(category = null) {
    return {type: constants.FILTER_SET_CATEGORY, payload: category};
}

export function setCity(city = null) {
    return {type: constants.FILTER_SET_CITY, payload: city};
}

export function setDate(date = null) {
    return {type: constants.FILTER_SET_DATE, payload: date};
}

export function resetFilter(){
    return {type: constants.FILTER_RESET}; 
}