import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { CSSTransition } from "react-transition-group";
import useGetPromoEvents from "./useGetPromoEvents";
import EventCard from "./components/EventCard";
import Error from "pages/Event/components/Error";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Title from "components/Feed/Title";
import Loading from "components/Loading";
import i18next from "i18next";

const LANG = 'sv';

const Items = ({ t }) => {

    const { state, get } = useGetPromoEvents();
    const { pid:id } = useParams();
    
    useEffect(() => {
        get(id);
    }, []);

    const { items, loading, error } = state;

    if (loading) {
       return <Loading />;
    }

    if (error) {
        return <Error retry={() => get()} />
    }

    if (!items?.length) {
        return null;
    }

    return  <Row className="justify-content-md-center zt-promo">
        <Col md="12" lg="12" xl="9">
            <Row className="eventsfeed" lg={4} md={3} sm={2}>
                {items.map(item => <Col key={item.id}>
                    <CSSTransition in={true} appear={true} timeout={200} classNames="node">
                        <EventCard {...{item}} />
                    </CSSTransition>
                </Col>)}
            </Row>   
        </Col>   
    </Row>;
}

export default props => {
    i18next.changeLanguage(LANG);
    const { t } = useTranslation('home');

    return <Container>
        <Title title={t('eventslist.label','All Available Events')} />
        <Items {...{t}} />
    </Container>
}
