import React from 'react';
import { Back as BackIcon, BackPlus as BackPlusIcon, Forward as ForwardIcon, ForwardPlus as ForwardPlusIcon } from 'components/Icons/Controls';
import Calendar from 'react-calendar';
import './styles.scss';
import moment from 'moment';

export default props => <Calendar 
    returnValue='range' 
    selectRange={true} 
    minDate={new Date()}
    minDetail={'decade'}
    formatMonthYear={(locale, date) => moment(date).format('MMMM')} 
    formatShortWeekday={(locale, date) => moment(date).format('dd')}
    formatMonth={(locale, date) => moment(date).format('MMM')}
    nextLabel={<ForwardIcon style={{stroke:'#fff'}}/>}
    next2Label={<ForwardPlusIcon style={{stroke:'#fff'}}/>}
    prevLabel={<BackIcon style={{stroke:'#fff'}}/>}
    prev2Label={<BackPlusIcon style={{stroke:'#fff'}}/>}
    {...props}
/>