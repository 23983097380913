import React from 'react';
import AcceptButton from './components/AcceptButton';
import PayButton from './components/PayButton';
import Loading from 'components/Loading';
const MODE_ACCEPT = 'MODE_ACCEPT';
const MODE_PAY = 'MODE_PAY';

export default ({ t, id, onCheckout, onSuccess, disabled, modeAccept, ...props}) => {

    const mode = modeAccept ? MODE_ACCEPT : MODE_PAY;

    if (mode === MODE_ACCEPT) {
        return <AcceptButton {...{t, onSuccess, id}} />;
    }

    if (mode === MODE_PAY) {
        return <PayButton {...{t, onCheckout}} />
    }

    return <Loading />

}