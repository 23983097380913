import * as actions from './constants';

export const defaultState = {
    
    mode: null, //Mode of selling target - can be: sell to friend or sell to all (market)
    kidsFriend: null, //Friend to pickups kids
    friendId: null, //User to who we want to sell tickets (if mode === to friend)

    loading: false,
    error: null,
    done: false
}


export const reducer = (state = defaultState, action) => {
    const { type, payload=null, error=null } = action;

    switch (type) {

        case actions.RESET_TICKETSELL: {
            return {
                ...defaultState
            }
        }

        case actions.SET_TICKETSELL_MODE: {
            return {
                ...state,
                mode: payload
            }
        }

        case actions.SET_TICKETSELL_KIDSFRIEND: {
            return {
                ...state,
                kidsFriend: payload
            }
        }

        case actions.SET_TICKETSELL_TOFRIEND: {
            return {
                ...state,
                friendId: payload
            }
        }

        case actions.SELL_TICKETSELL_START: {
            return {
                ...state,
                loading: true
            }
        }

        case actions.SELL_TICKETSELL_SUCCESS: {
            return {
                ...defaultState,
                mode: state.mode,
                done: true
            }
        }

        case actions.SELL_TICKETSELL_FAILURE: {
            return {
                ...state,
                loading: false,
                error
            }
        }

        default: {
            return state;
        }
    }
}