import React, { useState } from 'react';
import { PrimaryButton } from 'components/buttons/Button';
import { ArrowRight, ClearIcon } from 'components/Icons/Glyphs';
import './style.scss';
import Panel from 'components/Panel';
import { useTranslation } from 'react-i18next';

const Button = ({ onClick=false, className, children, ...props }) => <button onClick={onClick} className={className}>{children}</button> 

const MinusButton = props => <Button {...props}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#C00000"/>
    <rect x="6" y="13" width="2" height="12" transform="rotate(-90 6 13)" fill="white"/>
</svg></Button>

const PlusButton = props => <Button {...props}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#C00000"/>
    <rect x="11" y="6" width="2" height="12" fill="white"/>
    <rect x="6" y="13" width="2" height="12" transform="rotate(-90 6 13)" fill="white"/>
</svg></Button>

const CloseIcon = ({ show, ...props }) => show ? <ClearIcon className='zt-event-tickets-number-picker__closeicon' {...props}/> : null;

export default ({qty, ...props}) => {
    const [selected, setSelected] = useState(qty);
    const { max = 1, onSelect, showClose = true } = props;
    const close = () => props.onClose && props.onClose();

    const { t } = useTranslation('event');

    return (
        <Panel className='zt-event-tickets-number-picker'>
            <CloseIcon show={showClose} onClick={close}/>            
            <h4 className='zt-event-tickets-number-picker__title'>{t('number_of_tickets.label', 'Number of ticktes')}</h4>
            <div className='zt-event-tickets-number-picker__controll'>
                <MinusButton className={`zt-event-tickets-number-picker__controll__button ${selected === 1 ? 'zt-event-tickets-number-picker__controll__button--disabled' :''}`} onClick={() => setSelected(s => s > 1 ? s-1 : 1)}/>
                <div className='zt-event-tickets-number-picker__controll__value'><span className='zt-nav'>{selected}</span></div>
                <PlusButton className={`zt-event-tickets-number-picker__controll__button ${selected === max ? 'zt-event-tickets-number-picker__controll__button--disabled' :''}`} onClick={() => setSelected(s => (s < max ? s+1 : s))}/>
            </div>
            <div className='zt-event-tickets-number-picker__info zt-p3'>{max} {t('restrictions.max_in_group', 'Max number of tickets in a group')}</div>
            <PrimaryButton className='zt-event-tickets-number-picker__button' onClick={() => onSelect(selected)}>{t('number_of_tickets.button', 'Pick Seats')} <ArrowRight /></PrimaryButton>
        </Panel>);
}