import React, { useState } from "react";
import FORMAT from "config/date/format";
import { useTranslation } from "react-i18next";
import moment from "moment";
import EventInfoLine, { TYPE_EVENT_INFO_LINE_DATE } from "components/EventInfo";


const DateTime = ({ start_date, end_date, t }) => {
    const mdate_start = start_date ? moment(start_date, FORMAT) : null;
    const mdate_end = end_date ? moment(end_date, FORMAT) : null;

    if (mdate_end.isSame(mdate_start, 'date')) {
        return t('season.event.date.range_samedate', {defaultValue: '{{start, ddd. MMM D, h.mm a}} - {{end, h.mm a}}', start: mdate_start || '...', end: mdate_end || '...'});
    }

    return t('season.event.date.range', {defaultValue: '{{start, ddd. MMM D, HH.mm}} - {{end, ddd. MMM D, HH.mm}}', start: mdate_start || '...', end: mdate_end || '...'});
}

const Event = ({ event, t }) => {
    const { start_date, end_date } = event;

    return <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>
                <DateTime {...{start_date, end_date, t}} /> 
            </EventInfoLine>;
}


export default ({ items }) => {

    const { t } = useTranslation('event');
    const [closed, setClosed] = useState(true);

    if (!items) {
        return null;
    }    
    
    const child_events = items?.sort((a, b) => moment(a.start_date, FORMAT).diff(moment(b.start_date, FORMAT)));
    return <>
    <div class="zt-p1 mt-4">{t('season.label', 'Event schedule')}</div>
    {child_events?.map((event, key) => <Event {...{key, event}} t={t} />)}
    </>
}