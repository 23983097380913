import { useSelector } from 'react-redux';
import * as payTypes from '../../../AddFriends/payTypes';
import useTickets from '../../../AddFriends/useTickets';

const DOCUMENT = 'document';

export default function useAdminFee() {

    const { prices } = useSelector(state => state.event.friends);
    const { register_type: owner_type } = useSelector(state => state.event.friends.user);
    const tickets = useTickets();

    let fee = 0;

    tickets.forEach(t => {
        if ([t.price, t.type].includes(null)) {
            return;
        }

        if ((t.kid && owner_type === DOCUMENT) 
            || 
            (t.pay === payTypes.PAYTYPE_IPAY && t.register_type === DOCUMENT)) {
            fee += +prices[t.price]?.[t.type]?.admin_fee;
        }
    });

    return fee;
}