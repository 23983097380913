import { useSelector } from "react-redux";
import * as types from './types';
import { ALL } from './actions';

export default function useReturnType() {
    const { selected } = useSelector(state => state.shop.sell.selected);

    if (selected === null) {
        return null;
    }

    if (selected === ALL) {
        return types.ALLRETURN;
    }

    const { id } = selected;

    if (+id) {
        return types.ITEMRETURN;
    }

    return null;
}