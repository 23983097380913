import * as constants from './constants';
import * as modes from './../sellMode';
import api from './api';


const flow = {
    start: () => ({type: constants.VALIDATE_TICKETSELL_START}),
    success: payload => ({type: constants.VALIDATE_TICKETSELL_SUCCESS, payload}),
    failure: error => ({type: constants.VALIDATE_TICKETSELL_FAILURE, error})
}

export const validate = (dispatch, mode, eventId, ticketId) => () => {
    dispatch(flow.start());

    const call = (() => {
        switch(mode) {

            case (modes.SELL_ALL): {
                return api.all(eventId);
            }

            case (modes.SELL_ONLY_MINE): 
            case (modes.SELL_TICKET):
            {
                return api.one(ticketId);
            }

            default: {
                return Promise.reject(`no appropriate mode: ${mode}`);
            }
    }});

    call()
        .then(data => dispatch(flow.success(data.data)))
        .catch(error => dispatch(flow.failure(error)));

}