import * as actions from './constants';

const defaultState = {
    selected: null
};

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;

    switch (type) {
        case actions.SELECT_SELLITEMS: {
            return {
                ...defaultState,
                selected: payload
            }
        }

        case actions.RESET_SELLITEMS: {
            return {
                ...defaultState
            }
        }

        default: {
            return state;
        }
    }    
}
