import seatElement from "./seatElement";
import styleClasses from "./styleClasses";
import getCoords from './getCoords';
import { useSelector } from "react-redux";

export default ({ matrix, mapRef, setHover, setGroup, selectGroup, debouncedHide }) => {
    const { qty, grouped: groupMode } = useSelector(state => state.event.tickets);

    return element => {
        
        if (mapRef.current) {
            mapRef.current.querySelectorAll(`.${styleClasses['hover']}`).forEach( element => element.classList.remove(styleClasses['hover']));
        }
        
        const seat = element?.dataset?.seat;

        if (!seat) {
            debouncedHide();
            return;
        }

        const row = element?.parentElement?.dataset?.row;
        const coords = getCoords(element); 
        setHover({seat:seat, row:row, coords:{x:coords.left, y:coords.bottom, w:coords.width, h:coords.height}});

        element.classList.add(styleClasses['hover']);

        if (groupMode) {
            //Group seats logic
            const groupSeats = selectGroup({row, seat});
            if (groupSeats) {
                groupSeats.forEach( seat => seatElement(mapRef.current, row, seat)?.classList.add(styleClasses['hover']));
                setGroup(groupSeats);
            }
        }
    }

}