import * as constants from './constants';
import api from './api';

export const fetch = {
    start: () => ({type: constants.FETCH_TICKETS_START}),
    success: payload => ({type: constants.FETCH_TICEKTS_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_TICEKTS_FAILURE, error})
}

export function getTickets() {
    return (dispatch, getState) => {
        dispatch(fetch.start());
        api.fetch()
            .then(data => dispatch(fetch.success(data?.data || null)))
            .catch(error => dispatch(fetch.failure(error)));
    };
}

export const reset = () => ({type: constants.FETCH_TICEKTS_RESET});