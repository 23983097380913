import * as actions from './constants';

const defaultState = {
    loading: false,
    success: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;
    switch (type) {
        case actions.REGISTER_REQUEST_START:
            return {
                ...defaultState,
                loading: true
            }
        case actions.REGISTER_REQUEST_SUCCESS:
            return {
                ...defaultState,
                success: true
            }
        case actions.REGISTER_REQUEST_FAILURE:
            return {
                ...defaultState,
                error
            }
        case actions.REGISTER_REQUEST_RESET: {
            return {
                ...defaultState
            }
        }
        default:
            return state;
    }
}