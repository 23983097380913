export const adaptor = p => ({
    id: p.id,
    admin_fee: p.admin_fee,
    service_fee: p.service_fee,
    value: p.actual_price,
    type: p.ticket_type.name,
    kids: p.ticket_type.kids,
    default: p.ticket_type.default
});

const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const transformPriceTypes = prices => prices?.length >= 1 ? prices?.map(s => s.reduce((acc, tt) => ({...acc, [tt.ticket_type?.name]: {...tt}}), {})) : null;

export default function priceAdapter(prices) {
    if (!prices) {
        return;
    }
    
    return prices.map(p => objectMap(p, adaptor));
}
