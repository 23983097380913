
const DIR_RIGHT = 'DIR_RIGHT';
const DIR_LEFT = 'DIR_LEFT';

export default function useUnselectSeatRules(matrix, tickets) {

    //Validation:
    const isSelected = (row, seat, tickets) => tickets.filter(t => t.row === row).find(t => `${t.seat}` === `${seat}`);
    const isVacant = (rowObj, seat) => !!rowObj?.[seat];
    
    const applyRules = (seatIndex, seats, isVacant) => {
        const next = (index, dir, steps = 1) => (+index + ({[DIR_RIGHT]: 1, [DIR_LEFT]: -1}[dir])*steps);

        const isOnEdge = seatIndex => +seatIndex === 0 || +seatIndex === seats.length-1;
        const isNearSeatsFree = num => (seatIndex, dir) => Array.from(Array(num).keys()).map(i => i+1).every(steps => isVacant(next(seatIndex, dir, steps)));

        const rules = [
            isOnEdge, 
            isNearSeatsFree(1)
        ];

        return [DIR_RIGHT, DIR_LEFT].some(dir => rules.some(r => r(seatIndex, dir)));
    }
    
    return (row, seat, selected = tickets) => {
        
        const rowObj = matrix?.[row];
        const seats = Object.keys(rowObj || {});
        const seatIndex = seats.indexOf(`${seat}`);

        return applyRules(seatIndex, seats, /* isVacant function for rules: */ index => isVacant(rowObj, seats[index]) && !isSelected(row, seats[index], selected) );

    }

}