import * as actions from './constants';

export const defaultState = {
    loading: false,
    items: null,
    total: null, 
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;
    switch (type) {
        case actions.FETCH_FAQ_START:
            return {
                ...defaultState,
                loading: true,
            }
        case actions.FETCH_FAQ_SUCCESS:
            const { collection: items, count: total } = payload || null;
            return {
                ...defaultState,
                items,
                total
            }
        case actions.FETCH_FAQ_FAILED:
            return {
                ...defaultState,
                error: error
            }
        default:
            return state;
    }
}
