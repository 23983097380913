import * as constants from './constants';
import api from './api';

const flow = {
    start: () => ({type: constants.FORGETME_FOREVENT_START}),
    success: payload => ({type: constants.FORGETME_FOREVENT_SUCCESS, payload}),
    failure: error => ({type: constants.FORGETME_FOREVENT_FAILURE, error})
}

export const forgetMe = dispatch => (id, eventId) => () => {
    
    if (!+id){
        dispatch(flow.failure(true));
        return ;
    }

    dispatch(flow.start());

    api.forgetMe(id, eventId)
        .then(data => {
            dispatch(flow.success(data.data));
        })
        .catch(error => dispatch(flow.failure(error)));

}