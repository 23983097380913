import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { ClearIcon } from 'components/Icons/Glyphs';
import './style.scss';

export default React.forwardRef(({ label, search, name, onClear, error, description, description2='', placeholder='Type your phone here', disabled=false, onChange, autoFocus=true, defaultValue='', onBlur, ...props }, ref) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        if (/^(07.+)/.exec(value)) {
            setValue(value => value.replace(/^(07+)/g, '467'));
        }
        if (/^(4607+)/.exec(value)) {
            setValue(value => value.replace(/^(4607+)/g, '467'));
        }
        if (/^(00.+)/.exec(value)) {
            setValue(value => value.replace(/^(00)/g, '46'));
        }
        if(/^(010.+)/.exec(value)) {
            setValue(value => value.replace(/^(010)/g, '4610'));
        }
    }, [value]);

    useEffect(() => {
        onChange && onChange(value);
    }, [value]);

    return (
        <div className={`zt-inputs-phone ${error ? 'zt-inputs-phone--error' : ''} ${disabled ? 'zt-inputs-phone--disabled' : ''}`}>
            <input className='zt-inputs-phone__shadowinput' ref={ref} name={name} defaultValue={value} />
            { label &&
            <div className='zt-inputs-phone__label'>
                <span className='zt-p3'>{label}</span>
            </div>
            }
            <PhoneInput
                specialLabel={null}
                enableSearch={true}
                countryCodeEditable={true}
                country={'se'}
                disabled={disabled}
                searchPlaceholder={search}
                masks={{se: '... ... .. ..'}}
                placeholder={placeholder}
                preserveOrder={['preferredCountries']}
                preferredCountries={['se', 'no','dk', 'fi']}
                onChange={(value, country)=>{setValue(value);}}
                value={value}
                {...{onBlur}}
                inputProps={{
                    required: true,
                    autoFocus: autoFocus
                }}
            /> 
            <ClearIcon className={`zt-inputs-phone__clearicon ${label ? 'zt-inputs-phone__clearicon--labeled': ''}`} onClick={() => {setValue(v => ''); if (onClear) {onClear()};}} />   
            { (!!error || !!description) &&
            <div className='zt-inputs-phone__message'>
                <p className={`zt-p3 ${error ? 'zt-inputs-phone__message--error' : ''}`}>{error || description}</p>
                <p className='zt-p3'>{description2}</p>
            </div>
            }            
        </div>
    );
});