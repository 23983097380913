import { useSelector } from "react-redux";

const CODE_VALIDATION_ERROR = 1;

export default function useCreateUserError(t) {
    const { error } = useSelector(state => state.register.document);
    if (!error) {
        return null;
    }

    const code = error.response?.data?.error?.code || null;

    if (code === CODE_VALIDATION_ERROR) {
        return t('using_document.error.validation', 'Error in entered information, please correct it and try again');
    }

    return t('using_document.error.server', 'Network or server error, please try again later or contuct support');
}