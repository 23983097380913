import React, { useEffect, useState } from 'react';
import EventInfoLine, { TYPE_EVENT_INFO_LINE_DATE, TYPE_EVENT_INFO_LINE_LOCATION } from 'components/EventInfo';
import Total from '../BuyTickets/components/aside/TotalFee/components/Total';
import useValidateReservedTicket from './state/useValidateReservedTicket';
import Loading from 'pages/WebShop/pages/List/components/Loading';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from 'react-bootstrap';
import Error from 'pages/Event/components/Error';
import * as actions from './../Details/actions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Checkout from './components/Checkout';
import Select from './components/Select';
import format from 'config/date/format';
import moment from 'moment';
import './styles.scss';
import useTotal from './useTotal';

export const path = '/reservation/:rid/checkout';
export const link = id => path.replace(':rid', id);

const transformTotal = total => ({admin: total.adminFee, service: total.serviceFee, tickets: total.ticketsFee, total: total.totalPrice, discount: total.discount});

const CHECKOUT_STEP_SELECT = 'CHECKOUT_STEP_SELECT';
const CHECKOUT_STEP_PAY = 'CHECKOUT_STEP_PAY';


const OrderPage = ({ children, ...props }) => <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
    <Container className='zt-checkout'>
        <Row>
            {children}
        </Row>
    </Container>
</CSSTransition>;

const Main = ({ children, ...props }) => <Col md={9} lg={{ span: 8, offset: 1 }} className='zt-checkout__content'>{children}</Col>
const Side = ({ children, ...props }) => <Col md={3} className='zt-checkout__aside'>{children}</Col>

export default props => {

    const [step, setStep] = useState(CHECKOUT_STEP_SELECT);
    const { id, rid: reservationId } = useParams();

    const { item, loading, error } = useSelector(store => store.event.details);
    const dispatch = useDispatch();

    const [ initalised, setInitalised ] = useState(false);

    const loadEvent = () => dispatch(actions.fetchEvent(id));

    useEffect(() => {
        if (+id) {
            loadEvent();            
            setInitalised(true);
        }
    }, [dispatch, id]);

    const { state: { info, error: validateError, loading: validateLoading }, state, validate, update } = useValidateReservedTicket(reservationId);
    const tickets = info?.items.filter(t => t.type === 'ticket');


    useEffect(() => {
        if (initalised && !!item) {
            validate();
        }
    }, [initalised, item]);

    const { t } = useTranslation('tickets');

    if (!initalised || loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <OrderPage>
                <Main>
                    <Error retry={() => loadEvent()} />
                </Main>
            </OrderPage>
        );
    }

    const { active_payment } = info || {};
    
    const total = useTotal(info);

    const disabled = !state.validated || tickets?.reduce((a, ticket) => a || !ticket.type, false) || false;

    const setPriceType = (id, ticket_type) => update({items: [{id, ticket_type}]});

    return (
        <OrderPage>
            <Main>
                <div className='zt-checkout__header'>
                    <h1 className='bold zt-checkout__header__title'>{item.name}</h1>
                    <h4 className='light zt-checkout__header__performer'>{item.performer_name}</h4>
                </div>                        
                {validateError ? 
                    <Error retry={() => validate()} />
                :
                    {
                        [CHECKOUT_STEP_SELECT]: validateLoading ? <MiniLoader /> : <Select {...{setPriceType, id: reservationId, total}} onCheckout={() => setStep(CHECKOUT_STEP_PAY)} {...{tickets, disabled}} />,
                        [CHECKOUT_STEP_PAY]: <Checkout {...{event: item, reservationId}} />
                    }[step]
                }
            </Main>
            <Side>
                <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION}>{item.map?.venue?.name || 'Venue Name'}</EventInfoLine>
                <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE}>{t('date', {date: moment(item.start_date, format)})}</EventInfoLine>                        
                {!!total && <Total fees={transformTotal(total)} >
                </Total>}
            </Side>
        </OrderPage>
    )
}