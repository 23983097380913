import * as actions from './constants';

export const defaultState = {
    item: null,
    processing: false, 
    error: null,
    done: false
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch(type) {
     
        case actions.DO_DECLINE_START: {
            return {
                ...defaultState,
                item: payload,
                processing: true
            }
        }

        case actions.DO_DECLINE_SUCCESS: {
            return {
                ...defaultState,
                item: state.item,
                done: true
            }
        }

        case actions.DO_DECLINE_FAILURE: {
            return {
                ...defaultState,
                item: state.item,
                error
            }
        }
        
        default: {
            return state;
        }
    }
}