import { useSelector } from "react-redux";
import useSectionSeatingType from "../../PickSeats/useSectionSeatingType";
import { SEATING_TYPE_GENERALADMISSION } from "../../store/seatingTypes";
import orderInfo from "./orderInfo";

export default function useTicketsOrder() {

    const is_GA = useSectionSeatingType() === SEATING_TYPE_GENERALADMISSION;

    const state = useSelector(state => state);
    const order = orderInfo(state, is_GA);

    return order;
}