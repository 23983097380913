import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { UserIcon, QR as QRIcon, Price as PriceIcon } from 'components/Icons/Glyphs';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { useDispatch, useSelector } from 'react-redux';
import usePrices from '../../usePrices';
import PriceSelector from '../PriceSelector';
import * as friendsActions from './../../actions';
import InfoElement from '../../InfoElement';

export default ({ t, tt, ...props}) => {

    const { ticket, user, loading } = useSelector(state => state.event.friends);
    const prices = usePrices(user, null, false); 

    const dispatch = useDispatch();

    const setPriceType = p => {
        dispatch(friendsActions.setPrice(null, p.id, p.type));
    }

    return (
        <Row>
            <Col md={5}>
                <span className='zt-event-buy__add-friends__myticket__number'>1.</span>
                <InfoElement bold icon={UserIcon} className='zt-event-buy__add-friends__myticket__label'>{tt('labels.my_ticket', 'My ticket')}</InfoElement>
            </Col>
            { loading ?
            <Col md={6}>
                <InfoElement dark icon={MiniLoader}></InfoElement>
            </Col>                
            :
            <>
            <Col md={3}>
                <InfoElement dark icon={QRIcon}>{t(`pricetypes.${ticket.type}`, ticket.type)}</InfoElement>
            </Col>
            <Col md={3}>
                { (!!ticket.price || ticket.price === 0) && prices?.length === 1 ?
                <InfoElement icon={PriceIcon}>{t('common:price', {price: Number(prices[0].label)})}</InfoElement>
                :
                <PriceSelector {...{ticket, t}} items={prices} onChange={p => setPriceType(p)} />
                }
            </Col>
            </>
            }
        </Row>        
    )
}