import * as constants from "./constants";
import api from "./api";

const create = {
    start: () => ({type: constants.CREATE_RESERVATION_START}),
    success: payload => ({type: constants.CREATE_RESERVATION_SUCCESS, payload }),
    failure: error => ({type: constants.CREATE_RESERVATION_FAILED, error})
}

const fetch = {
    start: () => ({type: constants.FETCH_RESERVATION_START}),
    success: payload => ({type: constants.FETCH_RESERVATION_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_RESERVATION_FAILED, error})
}

export const createReservation = dispatch => order => {
    if (!order) {
        return null;
    }

    dispatch(create.start());
    api.createReservation(order)
        .then(response => dispatch(create.success(response?.data)))
        .catch(error => dispatch(create.failure(error)));
}

export const fetchReservation = dispatch => id => {
    dispatch(fetch.start());
    api.fetchReservation(id)
        .then(response => dispatch(fetch.success(response?.data)))
        .catch(error => dispatch(fetch.failure(error)));
}

export const reset = dispatch => () => dispatch({type: constants.RESET_CREATE_RESERVATION});
