import { useDispatch, useSelector } from 'react-redux';
import useCoreIdToken from './RegisterUser/useCoreIdToken';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import coreid_config from 'config/coreid';
import useScript from "hooks/useScript";
import * as actions from './actions';
import useIsAuthorised from 'hooks/auth/useIsAuthorised';
import bankIdSource from './bankIdSource';

export const STEP_RESET = 'STEP_RESET';
export const STEP_PREPARE = 'STEP_PREPARE';
export const STEP_LOADBANKID = 'STEP_LOADBANKID';
export const STEP_START_COREIDAUTH = 'STEP_START_COREIDAUTH';
export const STEP_VALIDATE_USER = 'STEP_VALIDATE_USER';
export const STEP_REGISTER_USER = 'STEP_REGISTER_USER';
export const STEP_AUTHORISE_USER = 'STEP_AUTHORISE_USER';

export default function useBankId({ onError = null }) {

    const [, i18n] = useTranslation('registration');

    const loggedIn = useIsAuthorised();

    const [step, setStep] = useState(loggedIn ? STEP_AUTHORISE_USER : STEP_PREPARE);
    const [bankIdScript, setBankIdScript] = useState(null);
    const [coreid_loaded, coreid_error] = useScript(bankIdScript);

    const { token, test, loading, error } = useSelector(state => state.register.coreid)
    const { registered } = useSelector(state => state.register.coreid.register);

    const { token: coreid_token } = useCoreIdToken();
  
    const userTokenValidate = useSelector(state => state.register.coreid.validate);
  
    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) {
            return;
        }

        if (test) {
            setBankIdScript(bankIdSource.test);
            return;
        }

        setBankIdScript(bankIdSource.production);
        return;

    }, [token, test]);
    
    useEffect(() => {
        switch(step){
            case(STEP_RESET):
                dispatch(actions.resetBankId());
                setStep(STEP_PREPARE);
            break;
    
            case(STEP_PREPARE):
                if (token) {
                    setStep(STEP_LOADBANKID);
                } else {
                    dispatch(actions.requestToken());
                }
            break;

            case(STEP_LOADBANKID):
                if (coreid_loaded) {
                    setStep(STEP_START_COREIDAUTH);
                }
            break;
    
            case(STEP_START_COREIDAUTH):
                console.log('STEP_START_COREIDAUTH');
                const lang = i18n.language;
                const coreid_client = window.coreid_client;
                coreid_client.init({
                config: {
                    ...coreid_config.config,
                    language: lang === 'se' ? 'sv' : lang,
                },
                token,
                callback: function(data) {
                    if (data.success === true) {
                        coreid_client.close();
                        setStep(STEP_VALIDATE_USER); //GO TO VALIDATE STEP
                        dispatch(actions.setAssentlyToken(data.token));
                        return;
                    }

                    if (data.type === 'failed') {
                        console.log('authentication failed', data.errorMessage);
                        onError && onError();
                        return;
                    } 
                    
                    if (data.type === 'cancelled') {
                        onError && onError();
                        return;
                    }
                    
                    if (data.type === 'error') {
                        console.log('an error occurred', data.errorMessage);
                        return;
                    }
                }
                });
                
                coreid_client.start();   
                return () => coreid_client.close();     
    
            break;
          
            case(STEP_VALIDATE_USER):
            
                if (!coreid_token) {
                    return;
                }
                
                if (userTokenValidate.loading) {
                    break;
                }

                if (!userTokenValidate.checked) {
                    dispatch(actions.validateCoreIDToken(coreid_token));
                    break;
                } 

                if (userTokenValidate.exist) {
                    setStep(STEP_AUTHORISE_USER);  
                    break;
                }

                if (userTokenValidate.valid) {
                    setStep(STEP_REGISTER_USER);
                    break;
                }

                setStep(STEP_RESET);
            break;
    
    
            case(STEP_REGISTER_USER):
                console.log('STEP_REGISTER_USER');
                if (registered) {
                    setStep(STEP_AUTHORISE_USER);
                }
            break;
            
            case(STEP_AUTHORISE_USER):
                console.log('STEP_AUTHORISE_USER');
            break;

            default:
            break;
        }

      }, [step, setStep, coreid_loaded, token, dispatch, i18n.language, userTokenValidate, registered, coreid_token]);

    return {
        step,
        token, 
        error,
        loading,
        coreid_error,
        coreid_loaded,
        reset: () => setStep(s => -1)
    }
}