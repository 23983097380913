import * as constants from './constants';
import api from './api';

const refundFlow = {
    start: () => ({type: constants.REFUND_SELLITEMS_START}),
    success: payload => ({type: constants.REFUND_SELLITEMS_SUCCESS, payload}),
    failure: error => ({type: constants.REFUND_SELLITEMS_FAILURE, error})
}

const params = (item, qty) => ({merchandise_order_id: item.orderId, quantity: qty});

export const refund = (dispatch, eventId, item) => qty => {
    dispatch(refundFlow.start());
    api.refund(eventId, item ? params(item, qty) : null)
        .then(response => dispatch(refundFlow.success(response?.data)))
        .catch(error => dispatch(refundFlow.failure(error)));
}