import * as actions from './constants';


const defaultCoreId = {
    token: null
}

const defaultValidate = {
    loading: false,
    checked: false,
    exist: false, // Code is valid and user exist
    valid: false // Code is valid but user not found
}

const defaultRegister = {
    registered: false,
    loading: false,
    error: null
}

const defaultState = {
    token: null,
    test: null,
    loading: false,
    coreid: defaultCoreId,
    validate: defaultValidate,
    register: defaultRegister
}

export default function(state = defaultState, action) {
    const { type, payload, error=null } = action;
    switch (type) {
        case(actions.RESET_BANKID):
            return {
                ...defaultState
            };
        case(actions.COREID_TOKEN_REQUEST_START):
            return {
                ...defaultState,
                loading:true
            };
        case(actions.COREID_TOKEN_REQUEST_SUCCESS):
            const { token, test=false } = payload;
            return {
                ...defaultState,
                token,
                test
            };
        case(actions.COREID_TOKEN_REQUEST_FAILURE):
            return {
                ...defaultState,
                error: error
            };
        case(actions.SET_COREID_TOKEN): 
            return {
                ...state,
                validate: defaultValidate,
                register: defaultRegister,
                coreid: {
                    ...defaultCoreId,
                    token: payload?.token
                }
            };
        case(actions.REGISTER_COREID_USER_START):
            return {
                ...state,
                register: {
                    ...defaultRegister,
                    loading: true
                }
            };
        case(actions.REGISTER_COREID_USER_SUCCESS):
            return {
                ...state,
                register: {
                    ...defaultRegister,
                    loading: false,
                    registered: true
                }
            };
        case(actions.REGISTER_COREID_USER_FAILURE):
            return {
                ...state,
                register: {
                    ...defaultRegister,
                    error: error
                }
            };
        case(actions.VALIDATE_COREID_TOKEN_START):
            return {
                ...state,
                validate: {
                    ...defaultValidate,
                    loading: true
                }
            };
        case(actions.VALIDATE_COREID_TOKEN_SUCCESS):
            return {
                ...state,
                validate: {
                    ...defaultValidate,
                    checked: true,
                    exist: true,
                    valid: true
                }
            };
        case(actions.VALIDATE_COREID_TOKEN_FAILURE):
            const valid = error?.response?.status === 401;
            return {
                ...state,
                validate: {
                    ...defaultValidate,
                    checked: true,
                    valid
                }
            };
        default:
            return state;
    }
}