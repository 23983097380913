import * as constants from './constants';
import api from './api';
import { auth } from 'containers/Login/state/actions';

const request = {
    start: () => ({type: constants.COREID_TOKEN_REQUEST_START}),
    success: payload => ({type: constants.COREID_TOKEN_REQUEST_SUCCESS, payload}),
    failure: error => ({type: constants.COREID_TOKEN_REQUEST_FAILURE, error})
}

export function requestToken() {
    return dispatch => {
        dispatch(request.start());
        api.requestToken()
            .then(data => dispatch(request.success(data.data)))
            .catch(error => dispatch(request.failure(error)))
    }
}

export const setAssentlyToken = token => ({type: constants.SET_COREID_TOKEN, payload:{token}});

const register = {
    start: () => ({type: constants.REGISTER_COREID_USER_START}),
    success: () => ({type: constants.REGISTER_COREID_USER_SUCCESS}),
    failure: error => ({type: constants.REGISTER_COREID_USER_FAILURE, error}) 
}

const authorise = token => dispatch => {
    dispatch(auth.success({token}));
}

export function registerCoreIDUser(data){
    return dispatch => {
        dispatch(register.start());
        api.registerCoreIDUser(data)
            .then(response => {
                const token = response?.data?.token || null;
                dispatch(register.success())
                authorise(token)(dispatch);
            })
            .catch(error => dispatch(register.failure(error)));
    }
}

const validate = {
    start: token => ({type: constants.VALIDATE_COREID_TOKEN_START, payload: token}),
    success: () => ({type: constants.VALIDATE_COREID_TOKEN_SUCCESS}),
    failure: error => ({type: constants.VALIDATE_COREID_TOKEN_FAILURE, error})
}

export function validateCoreIDToken(token){
    return dispatch => {
        dispatch(validate.start());
        api.validateCoreIDToken(token)
            .then(response => {
                const token = response?.data?.token || null;
                authorise(token)(dispatch);
                dispatch(validate.success());
            })
            .catch(error => dispatch(validate.failure(error)));
    }
}

export const resetBankId = () => ({type: constants.RESET_BANKID})