import React, { useEffect, useState } from 'react';
import bsBreakpoints from 'bs-breakpoints';
import { NavLink } from 'react-router-dom';

const getSize = () => {
  const isClient = typeof window === 'object'
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}

// Hook
function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getSize);
    const isClient = typeof window === 'object'

    useEffect(() => {
      if (!isClient) {
        return false;
      }
      
      function handleResize() {
        setWindowSize(getSize());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [isClient]); // Empty array ensures that effect is only run on mount and unmount
  
    return windowSize;
}

export default props => {
    const size = useWindowSize();

    return  <div style={{position:'absolute', top: 5, left:300, margin:'auto', zIndex:1000, fontSize: '10px'}}><NavLink to='/test' style={{color:'white', textDecoration:'underline'}}>{`> Size: ${bsBreakpoints.detectBreakpoint()}, ${size.width}px`}</NavLink></div>
}