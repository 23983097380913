import * as constants from './constants';
import api from './api';
import * as modes from './../modes';

const searchFlow = {
    start: payload => ({type: constants.SEARCHFRIEND_START, payload}),
    success: payload => ({type: constants.SEARCHFRIEND_SUCCESS, payload}),
    failure: error => ({type: constants.SEARCHFRIEND_FAILURE, error})
}

let searchRequestCancel = null;

export const search = (dispatch, eventId, mode = modes.SEARCH_FRIEND_TO_SELL_TICKET) => searchPhone => {

    let phone;
    if (searchPhone.charAt(0) === '+') {
        phone = searchPhone.substring(1);
    } else {
        phone = searchPhone;
    }

    dispatch(searchFlow.start(phone));
    api.search(eventId, phone, +(mode === modes.SEARCH_FRIEND_TO_PICKUP_KIDS), c => searchRequestCancel = c)
        .then(response => dispatch(searchFlow.success(response?.data || null)))
        .catch( error => dispatch(searchFlow.failure(error)));
    // setTimeout(() => dispatch(searchFlow.success({id:21, first_name: 'Alexsey', last_name: 'Kaplun'})), 5000);
    // setTimeout(() => dispatch(searchFlow.failure('You can\'t return items because this TEST error')), 3000 );
}

export const reset= dispatch => () => {
    searchRequestCancel && searchRequestCancel();
    searchRequestCancel = null;
    dispatch({type: constants.SEARCHFRIEND_RESET});
};