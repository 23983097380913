import React, { useRef, useEffect } from 'react';
import useUnselectSeatRules from '../../../PickSeats/Seats/useUnselectSeatRules';
import useSectionSeatingType from '../../../PickSeats/useSectionSeatingType';
import * as seatingTypes from '../../../store/seatingTypes';
import { PrimaryButton } from 'components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRight } from 'components/Icons/Glyphs';
import * as actions from './../../../store/actions';
import CheckBox from 'components/Inputs/CheckBox';
import Stepper from 'components/Inputs/Stepper';
import { useTranslation } from 'react-i18next';
import Divider from 'components/Divider';
import TicketInfo from './TicketInfo';

const Aside = ({ next = () => {}, ...props }) => {
    const { qty: ticketsQty, grouped: mode, tickets } = useSelector( state =>  state.event.tickets);
    const { t } = useTranslation('buying');

    const type = useSectionSeatingType();
    const dispatch = useDispatch();
    const checkBoxRef = useRef();

    const remove = index => () => dispatch(actions.removeTicket(index));

    useEffect(() => {
        if (checkBoxRef.current) {
            checkBoxRef.current.checked = mode;
        }
    }, [mode, checkBoxRef]);

    const allselected = tickets?.length === ticketsQty;

    const { matrix } = useSelector(state => state.event.maps.seats.seats);
    const isValidToRemove = useUnselectSeatRules(matrix, tickets);

    const Button = props => <PrimaryButton onClick={() => next()} {...props}>{ticketsQty === 1 ? t('buttons.checkout', 'Checkout') : t('buttons.add_friend', 'Add Friends')} <ArrowRight /></PrimaryButton>;

    if (type === seatingTypes.SEATING_TYPE_GENERALADMISSION) {
        return <Button />;
    }

    if (type !== seatingTypes.SEATING_TYPE_SEATS) {
        return null;
    }

    return (
    <>
        <div className='zt-event-buy__aside__group-seats__controlls'>
            <CheckBox ref={checkBoxRef} disabled={ticketsQty === 1} className='zt-event-buy__aside__group-seats__controlls__checkbox' value={mode} onChange={e => dispatch(actions.setTicketsGroupMode(e.target.checked))}>Group Seats</CheckBox>
            <Stepper value={ticketsQty} onChange={qty => dispatch(actions.setTicketsQty(qty))} className='zt-event-buy__aside__group-seats__controlls__stepper'/>
        </div>
        {!!tickets?.length &&
        <>
            <span className='zt-p3 zt-event-buy__aside__label'>{t('aside.selected_seats', {defaultValue: 'Selected Seats ({{count}})', count: +tickets?.length})}</span>
            {tickets.map((ticket, index) => <TicketInfo row={ticket.row} seat={ticket.seat} key={index} remove={isValidToRemove(ticket.row, ticket.seat, tickets) && remove(index)}/>)}
        </>
        }
        <Divider />
        <Button disabled={!allselected} />        
    </>
    );
}

export default props => <div className='zt-event-buy__aside__group-seats'><Aside {...props} /></div>;