import * as actions from './constants';
import allowed_tags from 'config/allowed_tags';

const defaultState = {
    item: null,
    loading: false,
    error: null
}

const strip_tags = input => {
    const allowed = (((allowed_tags || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return input?.replace(tags, ($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''))
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    switch (type) {
        case actions.FETCH_EVENT_START:
            return {
                ...defaultState,
                loading: true
            }
        case actions.FETCH_EVENT_FAILURE:
            return {
                ...defaultState,
                loading: false,
                error
            }
        case actions.FETCH_EVENT_SUCCESS:
            const item = payload;
            item.description = strip_tags(item?.description);

            return {
                ...defaultState,
                item,
                loading: false
            }

        case actions.FETCH_EVENT_RESET: {
            return {
                ...defaultState
            }
        }

        default:
            return state;
    }
}