import React from 'react';
import { LightButton } from 'components/buttons/Button';
import { ArrowRight } from 'components/Icons/Glyphs';
import { FormBlock, Form } from 'components/forms';
import Label from 'components/Inputs/Label';

export default ({ t, history, login, ...props }) => {

    const label = login ? t('coreid.success.login.label','You are login!') : t('coreid.success.label','You are authorised!');

    return (
        <div className='zt-bankid__success'>
            <Form>
                <FormBlock header={label}>
                    <Label>{t('coreid.success.description', 'Now you are prepared to buy tickets!')}</Label>
                    <LightButton className='zt-form__block__submitbutton font-weight-bold' onClick={() => history.push('/')}>{t('coreid.success.button', 'Go!')} <ArrowRight /></LightButton>
                </FormBlock>
                <FormBlock>
                </FormBlock>
            </Form>
        </div>
    )
}