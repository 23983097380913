import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function useResetToken() {
    const { search } = useLocation();
    const { id, token } = useParams();
    const { expires, signature } = queryString.parse(search);
    const isValid = (!!token && !!signature && !!expires); 

    return { isValid, id, token, signature, expires }
}