import React from 'react';
import './style.scss';

const onClick = (onSelect, onClose, item, id) => e => {
    e.stopPropagation();
    if (onSelect) {
        onSelect(item, id);
    };
    if (onClose) {
        onClose(e);
    }
}

export default ({ items, children, onSelect, onClose, className='' }) => <div className={`zt-menu ${className}`}>
    {children}
    <ul className='zt-menu__items'>
        { !!items && items.map( (item, id) => (
            item.id || item.id === 0 ?
            <li className={`zt-menu__items__item ${item.icon ? 'zt-menu__items__item--iconed' : ''} ${item.rightIcon ? 'zt-menu__items__item--right-iconed' : ''} ${item.disabled ? 'zt-menu__items__item--disabled' : ''}`} key={id} onClick={!!item.disabled?null:onClick(onSelect, onClose, item, id)}>
                <span className='zt-nav light'>{item.label}</span>
                { item.icon &&
                <item.icon className='zt-menu__items__item__icon'/>
                }
                { item.rightIcon &&
                <item.rightIcon className='zt-menu__items__item__righticon'/>
                }
            </li>
            :
            <li className={`zt-menu__items__divider`} key={id}></li>
            
            ))
        }
    </ul>
</div>