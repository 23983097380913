import React from 'react';
import * as Restriction from 'components/Icons/Restrictions';
import './style.scss';

const RestrictionLine = ({ Icon, children }) =>  <li className='d-flex align-items-center zt-event-content-restrictions__line'><Icon className='zt-event-content-restrictions__line__icon'/><span className='p1'>{children}</span></li>

export default ({ t, event, ...props }) => {
    
    if (!event) {
        return null;
    }

    return (
    <>
        <h4>{t('restrictions.label', 'Restrictions')}</h4>
        <ul className='zt-event-content-restrictions'>
            { !!event.kids_allowed ?
            <RestrictionLine Icon={Restriction.BabyAllowed}>{t('restrictions.kids_allowed','Kids are allowed!')}</RestrictionLine>
            :
            <RestrictionLine Icon={Restriction.BabyNotAllowed}>{t('restrictions.kids_not_allowed', 'Kids are not allowed!')}</RestrictionLine>
            }
            <RestrictionLine Icon={props => <Restriction.PolygonNumber count={+event.age_restriction} {...props}/>}>{t('restrictions.age_restrictions', 'Age restriction')}</RestrictionLine>
            <RestrictionLine Icon={props => <Restriction.PolygonNumber count={+event.max_number_tickets} plus {...props}/>}>{t('restrictions.max_in_group', 'Tickets is a maximum number in a group')}</RestrictionLine>
        </ul> 
    </>);
}