import React, {useEffect} from 'react';
import { PrimaryButton } from 'components/buttons/Button';
import useSetNewPassword from './useSetNewPassword';
import Input from 'components/Inputs/Input';
import useResetToken from './useResetToken';
import { useForm } from 'react-hook-form';
import * as formRules from 'config/forms';

const SuccessMessage = ({ children, ...props }) => <h4 className='bold zt-password-recovery__header'>{children}</h4>
const ErrorMessage = ({ children, ...props }) => <h4 className='bold zt-password-recovery__header'>{children}</h4>

export default ({ t }) => {
    const { isValid:urlValid, token, email } = useResetToken();
    const { register, handleSubmit, watch, formState: { errors }, reset, setValue, setError } = useForm({validateCriteriaMode: "all"});
    const { state: { error, loading, done }, resetPassword } = useSetNewPassword();

    useEffect(() => {
        if (error) {
            setError('password', {type: 'notMatch', message: t('errors.updateerror', 'Update password error')});
        }
    }, [error])

    const [ password, password_confirmation ] = watch(['password', 'password_confirmation']); 
    const filledin = (password && password_confirmation);

    if (done) {
        return <SuccessMessage>{t('results.success', 'New password is set')}</SuccessMessage> 
    }

    if (!urlValid) {
        return <ErrorMessage>{t('results.url_invalid', 'Invalid link or token')}</ErrorMessage>
    }

    const onSubmit = data => resetPassword({ ...data, token, email })

    return (<>
        <h4 className='bold zt-password-recovery__header'>{t('label', 'Reset password')}</h4>
        <div className='zt-password-recovery__emailused'>
            <div className='zt-password-recovery__emailused__label'><span className='zt-p3'>{t('inputs.email.label', 'Email (login name):')}</span></div>
            <span className='zt-nav'>{email}</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} >
            <Input
                autoFocus
                {...register('password', {
                    ...formRules.password,
                    required: 'Required',
                })}    
                disabled={loading}             
                label={t('inputs.password.label', 'New password:')}
                placeholder={t('inputs.password.placeholder', 'Enter new password')}
                type="password"             
                error={errors?.password ? (errors?.password?.message ||  t('errors.passwordnotvalid', 'Password is weak or not correct')) : false}
                onClear={() => reset()}
            />        
            <Input
                {...register('password_confirmation', {
                    ...formRules.password,
                    required: 'Required',
                    validate: value => value === password,
                    message: 'Not valid'
                })}
                disabled={!password || loading}           
                label={t('inputs.password_confirm.label', 'Confirm new password:')}
                placeholder={t('inputs.password_confirm.placeholder', 'Reenter new password')}
                type="password"
                error={errors?.password_confirmation ? t('errors.passwordnotmatch','Password doesn\'t match') : false}  
                onClear={() => setValue('password_confirmation', '')}       
            />
        </form>
        <PrimaryButton 
            disabled={!filledin}
            loading={loading}
            className='zt-password-recovery__button zt-password-recovery__button--change' 
            onClick={handleSubmit(onSubmit)}>
                {t('buttons.change_password', 'Change Password')}
        </PrimaryButton>        
    </>);
}