import React, { useEffect, useState } from 'react';
import { SecondaryButton } from 'components/buttons/Button';
import RadioGroup from 'components/Inputs/RadioGroup';
import { Info } from 'components/Icons/Glyphs';
import Input from 'components/Inputs/Input';
import Samples, { MODE_INTERNETIONAL } from './components/Samples';
import { Modal } from 'react-bootstrap';

export default ({ t, errors, type, onChange, register, unregister, resetField }) => {
    const [samples, showSamples] = useState(false);

    return (
        <>
        <div className='zt-registration__document__form__block'>
            <div className='zt-registration__document__form__block__label'>
                <h4>{t('using_document.document.label', 'Document which you\'ll use as a Ticket')}</h4>
            </div>
            <div className='zt-registration__document__form__block__inputs'>
                <SecondaryButton icon={Info} onClick={() => showSamples(true)}>{t('using_document.document.buttons.samples', 'Samples of valid Documents')}</SecondaryButton>
                <Input 
                    {...register('document', {required: 'Required'})}
                    label={t('using_document.document.inputs.document.label', 'Document number:')}
                    placeholder={t('using_document.document.inputs.document.placeholder', 'Enter valid document number')}
                    onClear={() => resetField('document')}
                    error={errors?.document?.message}    
                />
            </div>                 
        </div>      
        <Modal show={samples} size="xl" centered onHide={() => showSamples(false)} className='zt-modal-centered'>
            <Samples t={t} mode={MODE_INTERNETIONAL} onClose={() => showSamples(false)} feedback={type => onChange(type)} />
        </Modal>
        </>        
    )
}