import { useReducer } from 'react';
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';
import * as actions from './actions';

export default function useFetchUnpaidReservations() {

    const [state, dispatch] = useReducer(logger(reducer), defaultState);

    const fetch = actions.fetch(dispatch);
    const reset = actions.reset(dispatch);

    return {
        state,
        fetch,
        reset
    }
}
