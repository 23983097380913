export default {
    UNKNOWN_ERROR: 0,
    VALIDATION_ERROR: 1,
    INVALID_TICKET_TOKEN: 2,
    INVALID_TICKET_EVENT: 3,
    TICKET_NOT_CONFIRMED: 4,
    INVALID_TIME_HASH: 5,
    TICKET_ALREADY_SCANNED: 6,
    SCAN_MERCHANDISE_BY_CHILD_TICKET: 7,
    INVALID_MERCHANDISE_EVENT: 8,
    USER_NOT_FOUND: 9,
    TICKET_NOT_FOUND: 10,
    NOT_ENOUGH_MERCHANDISE: 11,
    SCAN_MERCHANDISE_TOKEN_EXPIRED: 12,
    REFUND_RESTRICTIONS: 13,
    REFUND_STEP_VALIDATION: 14,
    PURCHASE_RESTRICTIONS: 15,
    NOT_ENOUGH_FREE_SEATS_IN_GA: 16,
    NO_DEFAULT_TICKET_TYPE: 17,
    MULTIPLE_DEFAULT_TICKET_TYPES: 18,
    AUTH_TOKEN_REFRESH_TIME_EXPIRED: 19,
    SEAT_ALREADY_RESERVED: 20,
    CANNOT_CHANGE_TICKET_TYPE: 21,
    CANNOT_UPDATE_TICKET: 22,
    CANNOT_SEARCH_YOURSELF: 23,
    INCORRECT_ORDER_STATUS_FOR_PAY: 24,
    NO_ACTIVE_PAYMENTS: 25,
    MULTIPLE_ACTIVE_PAYMENTS: 26,
    INCORRECT_ORDER_AMOUNT: 27,
    INVALID_PAY_PARAMETERS: 28,
    ORDER_ALREADY_PAID: 29,
    FAILED_TO_CREATE_SVEA_PAYMENT: 30,
    INVALID_TICKET_REQUEST_SEATS_COUNT: 31,
    NOT_ALL_SEATS_FOUND: 32,
    SEAT_UNAVAILABLE: 33,
    CANNOT_CREATE_ORDER_AS_NON_ATTENDEE: 34,
    ORDER_FRIEND_NOT_FOUND: 35,
    INVALID_TICKET_REQUEST_USER: 36,
    COULD_NOT_DISTRIBUTE_ALL_SEATS: 37,
    INVALID_MERCHANDISE_REQUEST: 38,
    MERCHANDISE_OPTIONS_NOT_FOUND: 39,
    MERCHANDISES_NOT_FOUND: 40,
    USER_HAS_NO_BIRTHDAY: 41,
    PAYMENT_HAS_NO_CREATE_TRANSACTION: 42,
    ACTIVE_PAYMENT_FROM_ANOTHER_PAYGATE: 43,
    INVALID_ORDER_STATUS_FOR_REVALIDATE: 44,
    ACCESS_DENIED: 45,
    UNAUTHORIZED: 46,
    USER_INACTIVE: 47,
    USER_DELETED: 48,
    INVALID_STORAGE_ID: 49,
    UNFORCED_STORAGE_ID: 50,
    INVALID_CREDENTIALS: 51    
}