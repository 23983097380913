import React, { useEffect } from 'react';
import Divider from 'components/Divider';
import { useTranslation } from 'react-i18next';
import Info from 'components/Inputs/Info';
import { useSelector } from 'react-redux';
import moment from 'moment';

import countries_source from 'config/countries';
import flag from 'country-code-emoji'
const contries_iconed = Object.keys(countries_source).map( code => ({id:code, label:countries_source[code], icon: props => <span role="img" aria-label={code} {...props}>{flag(code)}</span> }))


const Country = ({ country }) => <><country.icon /><span>{country.label}</span></>

export default ({ t, isSwedenUser, ...props }) => {

    const { profile } = useSelector(state => state.profile);
   
    const country = profile?.country && contries_iconed.find(c => c.id === profile.country);
    
    useEffect(() => {
        console.log(country);
    }, [country]);

    const FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

    return (
    <div>
        <h4 className='zt-profile__label'>{t('personal_data.label', 'Personal Data')}</h4>
        <Info label={t('personal_data.first_name','First name:')} value={profile?.first_name} />
        <Info label={t('personal_data.last_name','Last name:')} value={profile?.last_name} />
        <Info label={t('personal_data.country', 'Country:')} value={profile?.country ? <Country country={country} /> : '--'} />
        <Info label={t('personal_data.birthday.label', 'Birth date:')} value={profile?.birthday ? t('personal_data.birthday.format', {date: moment(profile.birthday, FORMAT)}) : '--'} />
        {isSwedenUser && <Info label={t('personal_data.personal_id', 'Personal Id(shown partially):')} value={profile?.document} />}
        {/* <div className='zt-p3 zt-profile__description'>{t('personal_data.description', 'In case if you need to change your personal data, please contact to Customer Support +46 (000) 000 00 00 or support@zippertic.com')}</div> */}
        <Divider />
    </div>
    );
}