import * as constants from './constants';
import store from '../../store';
import api from './api';
import * as dateModes from 'containers/FilteredEvents/dateModes';
import moment from 'moment';

export const fetch = {
    start: () => ({type: constants.FETCH_FILTERED_EVENTS_START}),
    success: payload => ({type: constants.FETCH_FILTERED_EVENTS_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_FILTERED_EVENTS_FAILURE, error})
}

export function setFilters(){
    const { category, city, date } = store.getState().filter;
    return {type: constants.SET_FILTERED_EVENTS_FILTERS, payload: {category, city, date}};
} 

export function applyFilters() {
    return (dispatch, getState) => {
        dispatch(setFilters());
        dispatch(fetchEvents());
    }
}

export function setSearch(search) {
    return {type: constants.SET_FILTERED_EVENTS_SEARCH, payload: search};
}

export function applySearch(search) {
    return (dispatch, getState) => {
        dispatch(setSearch(search));
        dispatch(fetchEvents());
    }
}

export function fetchEvents() {
    return (dispatch, getState) => {
        
        const filterState = store.getState().filteredevents.filter;
        const filter = {};
        
        ['category', 'city'].forEach(item => (filterState[item] ? filter[item] = filterState[item].id : null));

        if (filterState['date']) {
            const mode = filterState['date'].id;
            if ([dateModes.MODE_TODAY, dateModes.MODE_NEXT_WEEK, dateModes.MODE_NEXT_MONTH, dateModes.MODE_THIS_YEAR].includes(mode)) {
                filter['date_term'] = mode;
            } else if (mode === dateModes.MODE_PERIOD) {
                const [ startDate, endDate ] = filterState['date'].range;
                const formatDate = date => moment(date).format('YYYY-MM-DD');
                // filter['date_range'] = `${formatDate(startDate)};${formatDate(endDate)}`;
                filter['date_from'] = formatDate(startDate);
                filter['date_to'] = formatDate(endDate);
            }
        }

        if (filterState['search']) {
            filter['search'] = filterState['search']
        }

        if (Object.keys(filter).length === 0) { //Empty filter, nothing to find
            return;
        }
        
        dispatch(fetch.start());
        const { page } = getState().filteredevents;
        const { page_size } = getState().events;

        //Rename category to type to conform api
        const { category=null, ...params }  = filter;
        params['type'] = category;

        api.fetch({page, per_page:page_size, ...params})
            .then(response => dispatch(fetch.success(response?.data || null)))
            .catch(error => dispatch(fetch.failure(error)));       

    }
}

export function resetFilters(){
    return {type: constants.RESET_FILTERED_EVENTS_FILTERS};    
}

export function resetCategory(){
    return (dispatch, getState) => {
        dispatch({type: constants.RESET_FILTERED_EVENTS_CATEGORY});
        dispatch(fetchEvents());
    }      
}

export function resetCity(){
    return (dispatch, getState) => {
        dispatch({type: constants.RESET_FILTERED_EVENTS_CITY});
        dispatch(fetchEvents());
    }    
}

export function resetDate(){
    return (dispatch, getState) => {
        dispatch({type: constants.RESET_FILTERED_EVENTS_DATE});
        dispatch(fetchEvents());
    }
}

export function resetSearch(){
    return (dispatch, getState) => {
        dispatch({type: constants.RESET_FILTERED_EVENTS_SEARCH});
        dispatch(fetchEvents());
    }    
}