import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DropdownIcon, Price as PriceIcon } from 'components/Icons/Glyphs';
import usePriceForTicket from '../../usePriceForTicket';
import Menu from 'components/Menu';
import './style.scss';

const Selected = ({ t, price }) => <span className={["zt-nav"]}>
    {price ? t('common:price', {price: Number(price)}) :  t('common:select', 'Select')}
</span>

export default React.forwardRef(({ name, items, placeholder, searchplaceholder, ticket, t, onChange, ...props }, selectRef) => {
    const [value, setValue] = useState('');
    const [active, setActive] = useState(false);
    const ref = useRef();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setActive(false);
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });   

    const ticketPrice = usePriceForTicket(ticket);

    const onSelect = useCallback(item => {
        setActive(false);
        onChange && onChange(item);
    }, [setActive, setValue, onChange]);


    return (
        <div className='zt-inputs--priceselect zt-inputs--priceselect--selected' ref={ref} >
            <select ref={selectRef} name={name} value={value} onChange={()=>null} >
                {items?.map(({ id, label }, key) => <option value={id} key={key}>{label}</option>)}
            </select>
            <button type="button" className={`zt-inputs--priceselect__button ${value ? 'zt-inputs--priceselect__button--selected' : ''} ${!ticketPrice ? 'zt-inputs--priceselect__button--empty' : ''}`} onClick={() => setActive(a => !a)}>
                <Selected {...{price:ticketPrice, t}} />
                <DropdownIcon className='zt-inputs--priceselect__button__icon'/>
            </button>
            { active && 
            <Menu items={items?.map(item => ({...item, label: `${t('common:price', {price: Number(item.label)})} ${item.type}`}))} className='zt-inputs--priceselect__menu' onSelect={item => onSelect(item)} />
            }
        </div>
    )
});