import * as constants from './constants';
import api from './api';

const flow = {
    start: () => ({type: constants.FETCH_FAQ_START}),
    success: payload => ({type: constants.FETCH_FAQ_SUCCESS, payload }),
    failure: error => ({type: constants.FETCH_FAQ_FAILED, error})
}

export const fetchItems = dispatch => eventId => {
    dispatch(flow.start());
    api.fetch(eventId)
        .then(response => dispatch(flow.success(response?.data || [])))
        .catch(error => dispatch(flow.failure(error)));
}