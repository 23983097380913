import * as constants from './constants';
import api from './api';

const register = {
    start: () => ({type: constants.REGISTER_REQUEST_START}),
    success: payload => ({type: constants.REGISTER_REQUEST_SUCCESS, payload }),
    failure: error => ({type: constants.REGISTER_REQUEST_FAILURE, error})
}

export function registerUser(data) {
    return dispatch => {
        dispatch(register.start());
        api.register(data)
            .then(data => dispatch(register.success(data.data || null)))
            .catch(error => dispatch(register.failure(error)));
    }
}

export const reset = () => ({type: constants.REGISTER_REQUEST_RESET});
