import { useEffect, useReducer } from "react";
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';

import * as actions from './actions';
import useCurrentUserId from "utils/useCurrentUserId";

export default function usePrivacyModeUpdate() {

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    const userId = useCurrentUserId();

    if (!userId) {
        throw 'Not authorised user, account can\'t be updated';
    }

    useEffect(() => {
        dispatch(actions.reset());
    }, [dispatch]);
    
    const proceedUpdate = actions.update(dispatch);

    return {
        state,
        proceedUpdate,
        reset: () => dispatch(actions.reset())
    }

}