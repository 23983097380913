import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import './style.scss';
import { StarIcon, CityIcon, CalendarIcon, SearchIcon } from 'components/Icons/Glyphs';
import Chip from 'components/buttons/Chip';
import { PrimaryFixedButton } from 'components/buttons/Button';
import * as actions from "./../actions";
import * as DATEMODES from 'containers/FilteredEvents/dateModes';
import { useTranslation } from 'react-i18next';

const periodLabel = ([ dateStart, dateEnd ], t) => t('eventslist.filtered.filter.range', {dateStart, dateEnd});

const DateChip = ({ onClick, filter, t }) => {
    const label = filter.id === DATEMODES.MODE_PERIOD ? periodLabel(filter.range, t) : filter.label;
    return <Chip icon={CalendarIcon} onClick={onClick}>{label}</Chip>;
}

export default () => {
    const filter = useSelector(state => state.filteredevents.filter);
    const noResults = useSelector(state => !state.filteredevents.loading && (state.filteredevents.items || []).length === 0 );
    const filterMultiple = useSelector(state => !!(Object.values(state.filteredevents.filter).filter(filter => (filter !== null && filter !== '')).length > 1));

    const dispatch = useDispatch();
    const [t] = useTranslation('home');

    return (<Row xs={1} className={`zt-filtered_feed__header ${noResults ? 'zt-filtered_feed__header--empty' : null}`}>
        <Col className={['zt-filtered_feed__title d-flex justify-content-center align-items-end']}>
            <h4>{`${noResults ? t('eventslist.filtered.noresult', 'Did not match any Events') : t('eventslist.filtered.label')}`}</h4>
        </Col>
        <Col className={['zt-filtered_feed__categories d-flex justify-content-center align-items-center']}>
            { !!filter.category &&
            <Chip icon={StarIcon} onClick={() => dispatch(actions.resetCategory())}>{filter.category.label || '--'}</Chip>
            }
            { !!filter.city &&
            <Chip icon={CityIcon} onClick={() => dispatch(actions.resetCity())}>{filter.city.label || '--'}</Chip>
            }
            { !!filter.date &&
            <DateChip t={t} filter={filter.date} onClick={() => dispatch(actions.resetDate())}/>
            }
            { !!filter.search &&
            <Chip icon={SearchIcon} onClick={() => dispatch(actions.resetSearch())}>{filter.search}</Chip>
            }
        </Col>
        { filterMultiple && noResults &&
        <Col className={['d-flex justify-content-center align-items-start zt-filtered-title__reset']}>
            <PrimaryFixedButton onClick={() => dispatch(actions.resetFilters())}>{t('eventslist.filtered.buttons.reset', 'Reset Filter')}</PrimaryFixedButton>
        </Col>
        }
    </Row>);
}