import { SEATING_TYPE_SEATS, SEATING_TYPE_GENERALADMISSION } from './../store/seatingTypes';
import { useSelector } from 'react-redux';

export default function useSectionSeatingType() {
    const { data: seatsData, map, loading, general_admission } = useSelector(state => state.event.maps.seats.seats);

    if (loading) {
        return null;
    }

    if (!seatsData) {
        return null;
    }
    
    if (general_admission) {
        return SEATING_TYPE_GENERALADMISSION;
    }

    const rows = Object.keys(seatsData);

    if (rows?.length >= 1) {
        return SEATING_TYPE_SEATS;
    }

    return null;
}