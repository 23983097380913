import { useReducer } from 'react';
import reducer, { defaultState } from './reducer';
import logger from 'use-reducer-logger';
import * as actions from './actions';

export default function useProcessAcceptOrDecline(actionId) {

    const [ state, dispatch ] = useReducer(logger(reducer), defaultState);

    const doAccept = actions.doAccept(dispatch);
    const doDecline = actions.doDecline(dispatch);

    return {
        state,
        doAccept,
        doDecline
    }
}