import { useSelector } from 'react-redux';
import useDefaultTypesFromPrices from './useDefaultTypesFromPrices';
import useKidTypesFromPrices from './useKidTypesFromPrices';

const priceMap = (price, i) => type => ({id: i, label: price?.[type]?.value, type: type, kids: price?.[type]?.kids});

const reducePrices = prices => {
    const reduced = prices.reduce((acc, price) => acc[price.type]?.[price?.label]?.[price?.kids] !== undefined ? acc : {...acc, [price.type]: {[price.label]: {[price.kids]: price.id}}}, {});
    const unpacked = Object.keys(reduced).map(type => Object.keys(reduced[type]).map(label => Object.keys(reduced[type][label]).map(kids => ({id: reduced[type][label][kids], type, label: +label, kids: kids === "true"}))));

    return unpacked.flat(3);
}

export default function usePrices(user, friendId = null, includekid = false) {
    
    const { prices, iamnotattending=false } = useSelector(state => state.event.friends);
    const ticket = useSelector(state => ((friendId === null ? state.event.friends : state.event.friends.friends[friendId]).ticket))
    
    const selected = useSelector(state => [...(iamnotattending ? [] : [state.event.friends.ticket.price]), ...state.event.friends.friends.map(f => f.ticket.price)]);
    const kidtypes = useKidTypesFromPrices(prices);
    const defaultTypes = useDefaultTypesFromPrices(prices);
    const allButKidsTypes = [...new Set(prices?.map(p => Object.keys(p).filter(t => !p[t].kids)).flat())];
    
    if (!prices) {
        return null;
    }
    
    if (!user) {
        return null;
    }
    
    const types = user.id === 0 ?  allButKidsTypes : user?.types; //User added, but not found in the system

    if (!includekid && (!types || !types?.length)) {
        return null;
    }

    if (![...(types||[]), kidtypes].length) {
        return null;
    }

    // merged from pay for unregistered
    // const mapped = user.id === 0 ? 
    //     prices.map((price, i) => Object.keys(price).filter(k => !price[k]?.kids)?.map(k => price[k]?.type)?.map(priceMap(price, i))).flat()  //All (exept kids) types for unregistered user
    //     :
    //     prices.map((price, i) => [...(types||[]), ...(includekid ? kidtypes||[] : [])]?.map(priceMap(price, i))).flat();

//    const mapped = prices.map((price, i) => [...(types||[]), ...(includekid ? kidtypes||[] : [])]?.map(priceMap(price, i))).flat();
//    console.log(mapped);

    const mapped = [];
    for (const [i, price] of prices.entries()) {
        for (const type of [...(types||[]), ...(includekid ? kidtypes||[] : [])]) {
            if (price?.[type]?.value) { //If there is a price of such type
                mapped.push(priceMap(price, i)(type));
            }
        }        
    }
    
    if (prices?.length === 1) {
        return mapped;
    }
    
    if (friendId === null){
        return reducePrices(mapped);
    }

    //Here we filtering already selected prices:
    const result = mapped.filter(p => !selected.includes(p.id) || ticket.price === p.id);

    return reducePrices(result);

}