import React from 'react';
import { PrimaryButton } from 'components/buttons/Button';
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRight } from 'components/Icons/Glyphs';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Header/Banner';
import Divider from 'components/Divider';
import './style.scss';

export const path = '/contacts';
export const link = path;

const Email = ({ label, email, ...props }) => {
    return (
        <div className='zt-p1 zt-contacts__address--email'>Email: <a href={`mailto:${email}`}>{email}</a></div>
    );
}

const Address = ({ item, ...props}) => <Col>
    <h4 className='zt-contacts__title light'>{item?.city}</h4>
    <div className='zt-p1 zt-contacts__address'>{item?.line1}</div>
    <div className='zt-p1 zt-contacts__address'>{item?.line2}</div>
    <div className='zt-p1 zt-contacts__address'>{item?.country}</div>
</Col>

export default props => {
    const { t } = useTranslation("contacts");

    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
            <div className='zt-contacts'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs md={10} lg={8}>
                            <h4 className='zt-contacts__header bold'>{t('label', 'Contact Us')}</h4>

                            {/* <Divider className='zt-divider--contacts' /> */}
                            <Row>
                                {t('addresses').map(a => <Address item={a} />)}
                            </Row>
                            <Row>
                                {t('emails', [

                                ])?.map(e => <Col md={6}>
                                    <Email label={e.title} email={e.email} />
                                </Col>
                                )}
                            </Row>                            

                            <Banner />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    );
}