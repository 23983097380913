import { useLocation } from "react-router";
import { path as scanningPrivacyPath } from './../../pages/ScanningPrivacy';

export default function useConfiguration() {
    const { pathname } = useLocation();

    let trim = false;    

    if (pathname === scanningPrivacyPath) {
        trim = true;
    }

    return {
        trim
    }
}