import React, { useEffect, useState } from 'react';
import RadioGroup from 'components/Inputs/RadioGroup';
import { Col, Row } from 'react-bootstrap';
import { SELLTOANYONE, SELLTOFRIEND } from './../../store/sell/sellToModes';
import FriendSearch from './components/FriendSearch';
import { useTranslation } from 'react-i18next';
import * as phoneSearchModes from './components/FriendSearch/modes';

export default ({ mode, eventId, targetModes, onSelectMode, setFriend, ...props })=> {

    useEffect(() => {
        onSelectMode(Object.keys(targetModes)[0]);
    }, []);

    const { t } = useTranslation('tickets');

    return (
        <div className="zt-tickets__sell__form">
            <Row>
                <Col xs lg="12">
                    <RadioGroup
                        name='mode_select'
                        defaultValue={Object.keys(targetModes)[0]}
                        items={[
                           ...([{id: SELLTOFRIEND, label: t('sell.modes.sell_to_friend.label', 'Sell to a Friend')}]),
                           ...([{id: SELLTOANYONE, label: t('sell.modes.sell_on_market.label', 'Sell to the Marketplace')}])
                        ]}
                        onChange={id => onSelectMode(id)}
                        />
                </Col>
            </Row>
            { mode === SELLTOFRIEND &&
                <FriendSearch t={t} mode={phoneSearchModes.SEARCH_FRIEND_TO_SELL_TICKET} isFound={friendId => setFriend(friendId)} eventId={eventId} />
            }
        </div>
    )
}