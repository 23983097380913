import React from 'react';

export default ({ t }) => <div className='zt-registration__document__success'>
    <p className='zt-nav light'>{t('using_document.success.p1')}</p>
    <p className='zt-nav light'>{t('using_document.success.p2')}</p>
    <p className='zt-nav light'>{t('using_document.success.p3')}</p>
    {/*
                "header": "To continue buy tickets You must activate account.",
                "link_sent": "Activation link has been sent to your email",
                "to_activate": "Check yor email, press link and buy tickets.",
                "thank_you": "Thank you!"    
    */}
</div>;