import React, { useEffect } from 'react';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import useProceedPayment from './store/useProceedPayment';
import usePaymentError from './store/usePaymentError';
import useTicketsOrder from './store/useTicketsOrder';
import InnerHTML from 'dangerously-set-html-content'
import Error from './../../../components/Error';
import './style.scss';

const Loader = () => <div className='zt-event-buy__checkout zt-event-buy__checkout--loading'><h4>Loading, please wait...</h4><MiniLoader /></div>;

export default ({ orderId=null, ...props }) => {
    const order = useTicketsOrder();
    const { proceedPayment, processing, reset, step, gui } = useProceedPayment({ order });
    const error = usePaymentError();

    useEffect(() => {
        proceedPayment();
    }, []);
    
    if (processing) {
        return <Loader />
    }

    if (error) {
        return <Error retry={() => proceedPayment()}/>
    }

    return (
    <div className='zt-event-buy__checkout'>
        <div className='zt-event-buy__checkout__gui'>
            {gui && <InnerHTML html={gui} />}
        </div>
    </div>
    );
}